import React, { useContext } from 'react';
import BookingPageContext from 'web/components/BookingPageContext';
import { LinkButton } from 'web/components/elements';
import Fake from 'web/components/elements/Fake';
import ListUnstyled from 'web/components/elements/ListUnstyled';
import { ServicesButtonsGroup } from 'web/components/Services/ServicesCommon';
import useErrorHandler from 'web/hooks/useErrorHandler';
import themeClasses from 'web/styles/themeClasses.css';
import SeriesSlot from './SeriesSlot';

const SchedulingSeries = () => {
  const [page, loading, error] = useContext(BookingPageContext);
  useErrorHandler(error);

  if (loading) {
    return <Fake height={16}>Loading...</Fake>;
  }

  if (error || !page) {
    return <p>Failed to load services</p>;
  }

  const seriesSorted = page.series
    ? Object.keys(page.series)
        .map((key) => page.series[key])
        .sort((a, b) => (!!a.hidden === !!b.hidden ? a.order - b.order : a.hidden ? 1 : -1))
    : [];

  const hasManyNonHiddenSeries = seriesSorted.filter((s) => !s.hidden).length > 1;

  return (
    <div>
      <ListUnstyled style={{ maxWidth: 450 }} className={themeClasses({ display: 'grid', rowGap: 2 })}>
        {seriesSorted.map((series) => (
          <li key={series.id}>
            <SeriesSlot series={series} linkTo={`series/${series.id}`} />
          </li>
        ))}
      </ListUnstyled>
      <ServicesButtonsGroup>
        <LinkButton variant="secondary" size="md" to="series/create">
          Add new series
        </LinkButton>
        {hasManyNonHiddenSeries && (
          <LinkButton md secondary to="series/reorder">
            Reorder
          </LinkButton>
        )}
      </ServicesButtonsGroup>
    </div>
  );
};

export default SchedulingSeries;
