import { doc } from 'firebase/firestore';
import React, { useContext } from 'react';
import { Outlet, useParams } from 'react-router';
import Fake from 'web/components/elements/Fake';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import UserContext from 'web/components/UserContext';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useFirestoreDocumentData from 'web/hooks/useFirestoreDocumentData';
import { firestoreLocationConverter } from 'web/utils/convert';
import LocationContext from './LocationContext';

const SettingsCallsLocationsContainer = () => {
  const firestore = useFirestore();
  const { user } = useContext(UserContext);
  const { locationId } = useParams();

  const [workflow, loading, error] = useFirestoreDocumentData(
    doc(firestore, 'users', user.uid, 'locations', locationId).withConverter(firestoreLocationConverter),
  );

  useErrorHandler(error);

  return (
    <>
      {loading && (
        <Fake height={24} animated>
          Loading...
        </Fake>
      )}
      {!loading && error && <p>Error: {`${error}`}</p>}
      {!loading && !error && !workflow && <p>Not found</p>}
      {!loading && !error && workflow && (
        <LocationContext.Provider value={workflow}>
          <Outlet />
        </LocationContext.Provider>
      )}
    </>
  );
};

export default SettingsCallsLocationsContainer;
