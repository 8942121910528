import { collection, doc, serverTimestamp, writeBatch } from 'firebase/firestore';
import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BackLink from 'web/components/BackLink';
import BookingPageContext from 'web/components/BookingPageContext';
import ColumnContainer from 'web/components/ColumnContainer';
import { FormError } from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import Meta from 'web/components/Meta';
import Spinner from 'web/components/Spinner';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import useFeatureOverrideCheck from 'web/hooks/useFeatureOverrideCheck';
import useTracking from 'web/components/TrackingContext/useTracking';
import { convertToDecimal } from 'web/utils/currency';
import SeriesForm, { FormValues } from './SeriesForm';

const SeriesCreate = () => {
  const { userData } = useContext(UserContext);
  const firestore = useFirestore();
  const tracking = useTracking();
  const [page, pageLoading, pageError] = useContext(BookingPageContext);
  useErrorHandler(pageError);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useErrorStateHandler();
  const increasedPriceLimit = useFeatureOverrideCheck('increasedPriceLimit');
  const largeSessionLimits = useFeatureOverrideCheck('largeSessionLimits');
  const navigate = useNavigate();
  const { state } = useLocation();

  const seriesToClone = (state as { series?: introwise.Series } | undefined)?.series;

  const { bookingPageId } = userData;

  const create = async (values: FormValues) => {
    const pageRef = doc(firestore, 'pages', bookingPageId);
    const seriesRef = doc(collection(pageRef, 'series'));

    const id = seriesRef.id;
    const series: introwise.FirestoreWriteData<introwise.Series> = {
      order: Date.now(), // current time will always be bigger than any other stored value
      createdAt: serverTimestamp(),
      ...values,
    };

    const update: introwise.FirestoreUpdateData<introwise.Page> = {
      [`series.${id}`]: {
        id,
        ...series,
      },
    };

    const batch = writeBatch(firestore);
    batch.update(pageRef, update);
    batch.set(seriesRef, series);

    setSubmitting(true);
    try {
      await batch.commit();
      tracking.trackEvent('Series Created', {
        price: convertToDecimal(values.price, values.currency),
        currency: page.currency,
        clone: !!seriesToClone,
      });
      navigate(`../${id}`, { replace: true });
    } catch (err) {
      setError(err);
      setSubmitting(false);
    }
  };

  return (
    <>
      {!page && pageLoading && <Spinner />}
      {!page && !pageLoading && <p>Something went wrong. Please try again later.</p>}
      {page && (
        <>
          <Meta title="Create a new series" />
          <BackLink to="/dashboard/scheduling" />
          <ColumnContainer>
            <WithHeaderContentColumn header="Create a new series" whiteBackground>
              <SeriesForm
                onSubmit={create}
                submitting={submitting}
                currency={page.currency}
                increasedPriceLimit={increasedPriceLimit}
                largeSessionLimits={largeSessionLimits}
                initialValues={
                  seriesToClone ? { ...seriesToClone, title: `${seriesToClone.title} (clone)` } : undefined
                }
                withWorkflows={true}
              />
              {error && <FormError>Failed to create a service. Please try again later.</FormError>}
            </WithHeaderContentColumn>
            <div />
          </ColumnContainer>
        </>
      )}
    </>
  );
};

export default SeriesCreate;
