import { collection, deleteField, doc, query, updateDoc, where } from 'firebase/firestore';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import BackLink from 'web/components/BackLink';
import ColumnContainer from 'web/components/ColumnContainer';
import { Button, FormError, FormGroup, LinkStyled } from 'web/components/elements';
import ListUnstyled from 'web/components/elements/ListUnstyled';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import SchedulingRulesFormCommon, {
  makeDefaultValues,
  SchedulingRulesFormValues,
  toSubmitValues,
} from 'web/components/SchedulingRulesForm';
import ScreenTracker from 'web/components/ScreenTracker';
import Spinner from 'web/components/Spinner';
import useTracking from 'web/components/TrackingContext/useTracking';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import useFirestoreCollectionData from 'web/hooks/useFirestoreCollectionData';
import themeClasses from 'web/styles/themeClasses.css';
import { firestoreServiceConverter } from 'web/utils/convert';

const SchedulingRulesForm = ({
  initialValues,
  onSubmit,
  submitting,
}: {
  initialValues: introwise.User['settings']['schedulingRules'];
  onSubmit: (values: introwise.User['settings']['schedulingRules']) => void;
  submitting: boolean;
}) => {
  const defaultValues = useMemo(() => makeDefaultValues({ schedulingRules: initialValues }), [initialValues]);
  const form = useForm<SchedulingRulesFormValues>({
    defaultValues,
  });
  const { handleSubmit } = form;

  const onSubmitInternal = useCallback(
    (values: SchedulingRulesFormValues) => onSubmit(toSubmitValues(values)),
    [onSubmit],
  );

  return (
    <form onSubmit={handleSubmit(onSubmitInternal)}>
      <fieldset disabled={submitting}>
        <SchedulingRulesFormCommon form={form} />
        <FormGroup>
          <Button primary type="submit">
            {submitting && <Spinner />}
            <span>Save</span>
          </Button>
        </FormGroup>
      </fieldset>
    </form>
  );
};

const ServicesWithRulesOverride = () => {
  const firestore = useFirestore();
  const { userData } = useContext(UserContext);

  const [services, loading, error] = useFirestoreCollectionData(
    query(
      collection(firestore, 'pages', userData.bookingPageId, 'services').withConverter(firestoreServiceConverter),
      where('schedulingRules', '!=', null),
    ),
  );

  useErrorHandler(error);

  return (
    <>
      {error && <>Failed to load services</>}
      {!loading && !error && services && services.length > 0 && (
        <p className={themeClasses({ marginBottom: 0 })}>
          <h4>Services with customized rules</h4>
          <ListUnstyled>
            {services.map((service) => (
              <li key={service.id}>
                <LinkStyled to={`/dashboard/scheduling/services/${service.id}`}>{service.title}</LinkStyled>
              </li>
            ))}
          </ListUnstyled>
        </p>
      )}
    </>
  );
};

const SchedulingRules = () => {
  const { user, userData } = useContext(UserContext);
  const firestore = useFirestore();
  const tracking = useTracking();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useErrorStateHandler();
  const navigate = useNavigate();

  const saveRules = useCallback(
    async (values: introwise.User['settings']['schedulingRules']) => {
      setSubmitting(true);
      setError(undefined);
      try {
        const { minNotice, maxNotice, bufferAfter, bufferBefore, frequency, alignment } = values;
        const userRef = doc(firestore, 'users', user.uid);
        await updateDoc(userRef, {
          'settings.schedulingRules.minNotice': minNotice,
          'settings.schedulingRules.maxNotice': maxNotice,
          'settings.schedulingRules.bufferAfter': bufferAfter,
          'settings.schedulingRules.bufferBefore': bufferBefore,
          'settings.schedulingRules.frequency': frequency
            ? {
                daily: frequency.daily || null,
                weekly: frequency.weekly || null,
              }
            : deleteField(),
          'settings.schedulingRules.alignment': alignment ? alignment : deleteField(),
        });
        tracking.trackEvent('Scheduling Rules Updated');
        navigate('/dashboard/scheduling');
      } catch (err) {
        setError(error);
        setSubmitting(false);
      }
    },
    [error, firestore, navigate, setError, user.uid, tracking],
  );

  return (
    <>
      <Helmet title="Scheduling Rules" />
      <ScreenTracker screenName="SettingsSchedulingRules" />
      <BackLink to="/dashboard/scheduling" />
      <ColumnContainer>
        <WithHeaderContentColumn header="Scheduling rules" whiteBackground>
          <SchedulingRulesForm
            initialValues={userData.settings?.schedulingRules}
            onSubmit={saveRules}
            submitting={submitting}
          />
          {error && <FormError>Something went wrong. Please try again later.</FormError>}
        </WithHeaderContentColumn>
        <WithHeaderContentColumn header="Rules for specific services" whiteBackground>
          <p className={themeClasses({ marginY: 0 })}>
            You can override these rules for individual services to fine-tune your scheduling.
          </p>
          <ServicesWithRulesOverride />
        </WithHeaderContentColumn>
      </ColumnContainer>
    </>
  );
};

export default SchedulingRules;
