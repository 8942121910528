import React from 'react';
import { Outlet } from 'react-router-dom';
import DashboardNav from 'web/components/DashboardNav';

const DashboardHome = () => {
  return (
    <>
      <DashboardNav
        items={[
          { text: 'Sessions', to: '/dashboard/home/sessions' },
          { text: 'Packages', to: '/dashboard/home/packages' },
          { text: 'Clients', to: '/dashboard/home/clients' },
        ]}
      />
      <Outlet />
    </>
  );
};

export default DashboardHome;
