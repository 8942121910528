import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FunctionsError, httpsCallable } from 'firebase/functions';
import React, { useContext, useState } from 'react';
import { Button, FormError, InlineButton } from 'web/components/elements';
import useFunctions from 'web/components/FirebaseContext/useFunctions';
import Spinner from 'web/components/Spinner';
import UserContext from 'web/components/UserContext';
import useErrorReporter from 'web/hooks/useErrorReporter';
import useMountedRef from 'web/hooks/useMountedRef';
import useOAuthPopup, { OAuthPopupError } from 'web/hooks/useOAuthPopup';
import useTracking from 'web/components/TrackingContext/useTracking';
import settings from 'web/utils/settings';
import { ConnectButtonsGroup, IntegrationBlock, IntegrationBlockTitle, IntegrationTitle } from './common';
import MicrosoftOutlookIcon from './MicrosoftOutlookIcon';

const useMicrosoftCalendarConnect = () => {
  const functions = useFunctions();
  const oauthPopup = useOAuthPopup();
  const errorReporter = useErrorReporter();

  const handleAuthClick = async (reauthenticate?: boolean, preferredUsername?: string) => {
    let authResult;
    const redirectUri = `${window.location.origin}/oauth-callback`;
    try {
      authResult = await oauthPopup({
        clientId: settings.microsoft.clientId,
        authorizeUrl: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
        redirectUri,
        scopes: ['Calendars.ReadWrite', 'User.Read', 'email', 'profile', 'offline_access', 'openid'],
        ...(!reauthenticate && { prompt: 'consent' }),
        ...(preferredUsername && { loginHint: preferredUsername }),
      });
    } catch (err) {
      if (err instanceof OAuthPopupError) {
        switch (err.code) {
          case 'user_cancelled':
            throw new Error('Microsoft Outlook connection was interrupted. Please try again.');
          case 'access_denied':
            throw new Error('Required permissions were not granted. Please try again and allow calendar access.');
          default:
            errorReporter.report(new Error(`Unknown msal error: ${err.code} ${err.message}`));
            throw new Error(`Something went wrong. Please try again later.`);
        }
      } else {
        errorReporter.report(new Error(`Unknown msal error: ${err}`));
        throw new Error(`Something went wrong. Please try again later.`);
      }
    }

    const code = authResult;

    try {
      await httpsCallable(
        functions,
        'integrationsConnectAccount',
      )({ type: 'calendar', provider: 'microsoft', code, redirectUri, reauthenticate });
    } catch (err) {
      errorReporter.report(new Error(`Error on microsoft account connect: ${err}`));
      // TODO: err.code reports the error as 'functions-exp/permission-denied', this seems like a bug and .includes() is a workaround
      if ((err as FunctionsError).code?.includes?.('permission-denied')) {
        throw new Error(
          `Required permissions were not granted. Please make sure to allow Introwise access to your calendar account.`,
        );
      } else if ((err as FunctionsError).code?.includes?.('failed-precondition')) {
        throw new Error(`This account is already connected.`);
      } else {
        throw new Error(`Something went wrong.`);
      }
    }
  };

  return handleAuthClick;
};

const MicrosoftCalendarIntegrationConnected = ({ accountId }: { accountId: string }) => {
  const functions = useFunctions();
  const { userData } = useContext(UserContext);
  const [error, setError] = useState(null);
  const errorReporter = useErrorReporter();
  const [connecting, setConnecting] = useState(false);
  const [disconnecting, setDisconnecting] = useState(false);
  const microsoftCalendarConnect = useMicrosoftCalendarConnect();
  const busy = connecting || disconnecting;

  const connectedAccounts = userData.connectedAccounts;
  const microsoftAccount = connectedAccounts?.[accountId];

  const handleAuthClick = async () => {
    setConnecting(true);
    setError(null);
    try {
      await microsoftCalendarConnect(true, (microsoftAccount as introwise.MicrosoftCalendarAccount).principalName);
    } catch (err) {
      errorReporter.report(new Error(`Error on Microsoft Account connection: ${err}`));
      setError(err.message);
    }
    setConnecting(false);
  };

  const handleDisconnectClick = async (account: introwise.MicrosoftCalendarAccount) => {
    const res = window.confirm(
      `Are you sure you want to disconnect '${account.email || account.principalName}' calendar?`,
    );
    if (!res) {
      return;
    }
    setDisconnecting(true);
    setError(null);
    try {
      await httpsCallable(functions, 'integrationsDisconnectAccount')({ accountId: account.id });
    } catch (err) {
      errorReporter.report(new Error(`Error on microsoft account disconnect: ${err}`));
      setError('Something went wrong.');
    }
    setDisconnecting(false);
  };

  if (!microsoftAccount || microsoftAccount.type !== 'calendar' || microsoftAccount.provider !== 'microsoft') {
    // This should never happen
    return (
      <>
        <IntegrationBlock>
          Something went wrong. Connected calendar cannot be shown. Please try again later.
        </IntegrationBlock>
      </>
    );
  }

  return (
    <>
      <IntegrationBlock>
        <IntegrationBlockTitle>
          <MicrosoftOutlookIcon width={40} style={{ margin: 4 }} />
          <div>
            <>Microsoft Outlook</>{' '}
            <>
              <IntegrationTitle>
                {microsoftAccount.email || microsoftAccount.principalName}{' '}
                {microsoftAccount.hasAuthError && (
                  <span style={{ color: 'red' }}>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                  </span>
                )}
              </IntegrationTitle>
              {microsoftAccount.hasAuthError && (
                <div>
                  <p>There is a problem with your calendar credentials. Please re-connect your calendar account.</p>
                </div>
              )}
            </>
          </div>
        </IntegrationBlockTitle>
        <ConnectButtonsGroup>
          {microsoftAccount.hasAuthError && (
            <div style={{ marginBottom: 16 }}>
              <Button primary onClick={() => handleAuthClick()} disabled={busy}>
                {connecting && <Spinner />}
                <span>Reconnect</span>
              </Button>
            </div>
          )}
          <InlineButton onClick={() => handleDisconnectClick(microsoftAccount)} disabled={busy}>
            {disconnecting && <Spinner />}
            <span>Disconnect</span>
          </InlineButton>
        </ConnectButtonsGroup>
      </IntegrationBlock>

      {error && (
        <FormError>
          <p>{error}</p>
        </FormError>
      )}
    </>
  );
};

const MicrosoftCalendarIntegrationNew = ({ onConnect }: { onConnect?: () => void }) => {
  const tracking = useTracking();
  const [error, setError] = useState(null);
  const errorReporter = useErrorReporter();
  const [connecting, setConnecting] = useState(false);
  const mounted = useMountedRef();

  const microsoftCalendarConnect = useMicrosoftCalendarConnect();

  const handleAuthClick = async () => {
    setConnecting(true);
    setError(null);
    try {
      await microsoftCalendarConnect();
      tracking.trackEvent('Calendar Connected', { provider: 'microsoft' });
      onConnect?.();
    } catch (err) {
      errorReporter.report(new Error(`Error on Microsoft Account connection: ${err}`));
      setError(err.message);
    }
    if (mounted.current) {
      setConnecting(false);
    }
  };

  return (
    <>
      <div>
        <IntegrationBlock>
          <IntegrationBlockTitle>
            <MicrosoftOutlookIcon width={40} style={{ margin: 4 }} />
            <div>
              <>Microsoft Outlook</>{' '}
              <div>
                <small>Office 365, Outlook.com, live.com, and hotmail calendar</small>
              </div>
            </div>
          </IntegrationBlockTitle>
          <ConnectButtonsGroup>
            <Button primary onClick={() => handleAuthClick()} disabled={connecting}>
              {connecting && <Spinner />}
              <span>Connect</span>
            </Button>
          </ConnectButtonsGroup>
        </IntegrationBlock>
      </div>
      {error && (
        <FormError>
          <p>{error}</p>
        </FormError>
      )}
    </>
  );
};

export { MicrosoftCalendarIntegrationConnected, MicrosoftCalendarIntegrationNew };
