import React, { useContext } from 'react';
import styled from 'styled-components';
import { LinkButton } from 'web/components/elements';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import themeConstants from 'web/styles/themeConstants';
import themeVars from 'web/styles/themeVars.css';
import BookingPageLink from '../BookingPageLink';
import ExpertShare from '../share/ExpertShare';

const Container = styled.div`
  display: grid;
  grid-template-rows: auto;
  row-gap: 16px;
  position: relative;
  fill: ${themeVars.color.halfMuted};
`;

const StyledSpan = styled.span`
  color: ${themeVars.color.halfMuted};
  font-size: 12px;
`;

const Arrow = ({ className }: { className?: string }) => (
  <svg className={className} width="99" height="198" viewBox="0 0 99 198" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.59629 183.854C1.38229 183.68 1.35028 183.365 1.5248 183.151L4.36877 179.664C4.54329 179.45 4.85825 179.418 5.07225 179.592C5.28625 179.767 5.31826 180.082 5.14374 180.296L2.61577 183.395L5.71567 185.923C5.92967 186.098 5.96168 186.413 5.78716 186.627C5.61264 186.841 5.29768 186.873 5.08368 186.698L1.59629 183.854ZM82.2337 9.02541C85.4139 18.4939 87.5758 33.5784 87.6462 51.0017C87.7166 68.4324 85.6939 88.2399 80.4808 107.161C70.0581 144.989 46.8309 179.405 1.96284 183.964L1.86174 182.97C46.133 178.471 69.1436 144.544 79.5167 106.895C84.7015 88.0771 86.7163 68.3623 86.6462 51.0058C86.5761 33.6418 84.4195 18.674 81.2857 9.34381L82.2337 9.02541Z" />
  </svg>
);

const StyledArrow = styled(Arrow)`
  display: none;

  ${themeConstants.media.xl} {
    display: block;
    position: absolute;
    right: -40px;
    bottom: 20px;
  }
`;

const BookingPageReminder = () => {
  return (
    <WithHeaderContentColumn header="Create your booking page">
      <LinkButton primary to="/dashboard/booking" style={{ width: '100%', textAlign: 'center' }}>
        Create booking page
      </LinkButton>
      <p>Create your personal booking page and share it with your clients to start earning money on Introwise</p>
    </WithHeaderContentColumn>
  );
};

const BookingPageLinkBlock = () => {
  const { userData } = useContext(UserContext);

  if (!userData.bookingPageSlug) {
    return <BookingPageReminder />;
  }

  return (
    <WithHeaderContentColumn header="Share your booking link regularly">
      <Container>
        <div>
          <BookingPageLink />
        </div>
        <StyledArrow />
        <StyledSpan>Share this link with your clients and add it to your website or social media pages</StyledSpan>
        <ExpertShare />
      </Container>
    </WithHeaderContentColumn>
  );
};

export default BookingPageLinkBlock;
