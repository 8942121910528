import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import BackLink from 'web/components/BackLink';
import ExpertAvailabilityCalendar from 'web/components/calendar/ExpertAvailabilityCalendar';
import ColumnContainer from 'web/components/ColumnContainer';
import ContentColumn from 'web/components/ContentColumn';
import { FormDescription, LinkStyled } from 'web/components/elements';
import ListUnstyled from 'web/components/elements/ListUnstyled';
import ScreenTracker from 'web/components/ScreenTracker';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import PersonalAvailabilityContext from './PersonalAvailabilityContext';
import PersonalAvailabilityInfo from './PersonalAvailabilityInfo';

const TimeZoneDisplay = ({ timeZone }: { timeZone: string }) => {
  const [now, setNow] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => setNow(new Date()), 1000);
    return () => clearInterval(interval);
  }, []);
  return (
    <>
      <b>{timeZone.replace('_', ' ')}</b> ({now.toLocaleTimeString('en', { hour: 'numeric', minute: 'numeric' })})
    </>
  );
};

const PersonalAvailabilityEdit = () => {
  const availability = useContext(PersonalAvailabilityContext);

  const timeZone = useMemo(() => Intl.DateTimeFormat().resolvedOptions().timeZone, []);

  return (
    <>
      <Helmet title={`Availability – ${availability.name}`} />
      <ScreenTracker screenName="SettingsAvailabilityPersonal" />
      <BackLink to="/dashboard/scheduling" />
      <ColumnContainer>
        <div>
          <WithHeaderContentColumn header="Manage availability" whiteBackground>
            <PersonalAvailabilityInfo availability={availability} withActions />
          </WithHeaderContentColumn>
          <ContentColumn whiteBackground>
            {/* TODO: maybe remove top margin from the calendar */}
            <div style={{ marginBottom: -44 }} />
            <ExpertAvailabilityCalendar initialTimezone={timeZone} availabilityId={availability.id} />
            <FormDescription>
              Hint: Click or drag on the calendar to create an availability period. Click on an existing period to edit
              or remove it.
            </FormDescription>
          </ContentColumn>
        </div>

        <div>
          <WithHeaderContentColumn header="Your time zone">
            <p>Showing your availability in the current time zone:</p>
            <p>
              <TimeZoneDisplay timeZone={timeZone} />
            </p>
          </WithHeaderContentColumn>
          <WithHeaderContentColumn header="More settings">
            <ListUnstyled>
              <li>
                <LinkStyled to="/dashboard/scheduling/rules">Scheduling rules</LinkStyled>
              </li>
              <li>
                <LinkStyled to="/dashboard/scheduling/connected-calendars">Connected calendars</LinkStyled>
              </li>
              <li>
                <LinkStyled to="/dashboard/scheduling/time-off">Time off</LinkStyled>
              </li>
            </ListUnstyled>
          </WithHeaderContentColumn>
        </div>
      </ColumnContainer>
    </>
  );
};

export default PersonalAvailabilityEdit;
