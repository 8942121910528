import React from 'react';
import themeClasses from 'web/styles/themeClasses.css';
import joinClassNames from 'web/utils/joinClassNames';
import { cardListItem } from './cardList.css';
import listUnstyled from './elements/listUnstyled.css';

const CardsListItem = React.forwardRef<HTMLLIElement, React.LiHTMLAttributes<HTMLLIElement> & { inactive?: boolean }>(
  ({ children, className, inactive, ...props }, ref) => (
    <li className={joinClassNames(className, cardListItem({ inactive }))} {...props} ref={ref}>
      {children}
    </li>
  ),
);

CardsListItem.displayName = 'CardsListItem';

const CardsList = React.forwardRef<HTMLUListElement, React.BaseHTMLAttributes<HTMLUListElement>>(
  ({ children, className, ...props }, ref) => (
    <ul
      className={joinClassNames(className, listUnstyled, themeClasses({ display: 'grid', gap: 4 }))}
      {...props}
      ref={ref}
    >
      {children}
    </ul>
  ),
);
CardsList.displayName = 'CardsList';

export { CardsList, CardsListItem };
