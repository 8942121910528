import { httpsCallable } from '@firebase/functions';
import React, { useContext, useEffect } from 'react';
import { Navigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import BackLink from 'web/components/BackLink';
import ColumnContainer from 'web/components/ColumnContainer';
import useFunctions from 'web/components/FirebaseContext/useFunctions';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';

const SettingsPaymentsAccountsPayPalVerify = () => {
  const { userData } = useContext(UserContext);
  const functions = useFunctions();
  const [error, setError] = useErrorStateHandler<string>();
  const [params] = useSearchParams();

  const token = params.get('token');
  const connected = !!userData.paypalAccountEmail;
  const verified = userData.paypalAccountVerified;

  useEffect(() => {
    if (!token) {
      setError('Cannot verify your PayPal account connection. Invalid URL');
      return;
    }
    if (connected && !verified) {
      const verify = async () => {
        try {
          await httpsCallable<{ token: string }, never>(functions, 'paypalVerify')({ token });
        } catch (err) {
          setError(`Something went wrong: ${err.message}`);
        }
      };
      void verify();
    }
  }, [connected, verified, token, setError, functions]);

  return (
    <>
      <BackLink to="../.." />
      <ColumnContainer>
        <WithHeaderContentColumn header="PayPal account" whiteBackground>
          {connected && verified && <>You&apos;ve succesfully connected a PayPal account.</>}
          {!connected && <Navigate to="../.." />}
          {connected && !verified && !error && <div>Verifying your PayPal account...</div>}
          {connected && !verified && error && <div>{error}</div>}
        </WithHeaderContentColumn>
      </ColumnContainer>
    </>
  );
};

export default SettingsPaymentsAccountsPayPalVerify;
