import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { httpsCallable } from 'firebase/functions';
import React, { useContext, useState } from 'react';
import { FormError, InlineButton, LinkButton } from 'web/components/elements';
import useFunctions from 'web/components/FirebaseContext/useFunctions';
import Spinner from 'web/components/Spinner';
import UserContext from 'web/components/UserContext';
import useErrorReporter from 'web/hooks/useErrorReporter';
import AppleCalendarIcon from './AppleCalendarIcon';
import { ConnectButtonsGroup, IntegrationBlock, IntegrationBlockTitle, IntegrationTitle } from './common';

const AppleCalendarIntegrationConnected = ({ accountId, reconnectTo }: { accountId: string; reconnectTo: string }) => {
  const functions = useFunctions();
  const { userData } = useContext(UserContext);
  const [error, setError] = useState(null);
  const errorReporter = useErrorReporter();
  const [disconnecting, setDisconnecting] = useState(false);

  const busy = disconnecting;

  const connectedAccounts = userData.connectedAccounts;
  const appleAccount = connectedAccounts?.[accountId];

  const handleDisconnectClick = async (account: introwise.AppleCalendarAccount) => {
    const res = window.confirm(`Are you sure you want to disconnect '${account.appleId}' calendar?`);
    if (!res) {
      return;
    }
    setDisconnecting(true);
    setError(null);
    try {
      await httpsCallable(functions, 'integrationsDisconnectAccount')({ accountId: account.id });
    } catch (err) {
      errorReporter.report(new Error(`Error on apple account disconnect: ${err}`));
      setError('Something went wrong.');
    }
    setDisconnecting(false);
  };

  if (!appleAccount || appleAccount.type !== 'calendar' || appleAccount.provider !== 'apple') {
    // This should never happen
    return (
      <>
        <IntegrationBlock>
          Something went wrong. Connected calendar cannot be shown. Please try again later.
        </IntegrationBlock>
      </>
    );
  }

  return (
    <>
      <IntegrationBlock>
        <IntegrationBlockTitle>
          <AppleCalendarIcon width={40} style={{ margin: 4 }} />
          <div>
            <>Apple Calendar</>{' '}
            <>
              <IntegrationTitle>
                {appleAccount.appleId}{' '}
                {appleAccount.hasAuthError && (
                  <span style={{ color: 'red' }}>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                  </span>
                )}
              </IntegrationTitle>
              {appleAccount.hasAuthError && (
                <div>
                  <p>There is a problem with your calendar credentials. Please re-connect your calendar account.</p>
                </div>
              )}
            </>
          </div>
        </IntegrationBlockTitle>
        <ConnectButtonsGroup>
          {appleAccount.hasAuthError && (
            <div style={{ marginBottom: 16 }}>
              <LinkButton primary to={reconnectTo}>
                Reconnect
              </LinkButton>
            </div>
          )}
          <InlineButton onClick={() => handleDisconnectClick(appleAccount)} disabled={busy}>
            {disconnecting && <Spinner />}
            <span>Disconnect</span>
          </InlineButton>
        </ConnectButtonsGroup>
      </IntegrationBlock>

      {error && (
        <FormError>
          <p>{error}</p>
        </FormError>
      )}
    </>
  );
};

const AppleCalendarIntegrationNew = ({ connectTo }: { connectTo: string }) => {
  return (
    <>
      <div>
        <IntegrationBlock>
          <IntegrationBlockTitle>
            <AppleCalendarIcon width={40} style={{ margin: 4 }} />
            <div>
              <>Apple Calendar</>{' '}
              <div>
                <small>iCloud calendar</small>
              </div>
            </div>
          </IntegrationBlockTitle>
          <ConnectButtonsGroup>
            <LinkButton primary to={connectTo}>
              Connect
            </LinkButton>
          </ConnectButtonsGroup>
        </IntegrationBlock>
      </div>
    </>
  );
};

export { AppleCalendarIntegrationConnected, AppleCalendarIntegrationNew };
