import React from 'react';
import { Outlet } from 'react-router-dom';
import DashboardNav from 'web/components/DashboardNav';

const SettingsAutomation = () => {
  return (
    <>
      <DashboardNav
        items={[
          { text: 'Workflows', to: '/dashboard/automation/workflows' },
          { text: 'Integrations', to: '/dashboard/automation/integrations' },
        ]}
      />
      <Outlet />
    </>
  );
};

export default SettingsAutomation;
