import { httpsCallable } from '@firebase/functions';
import { collection, doc, documentId, limit, query, where } from 'firebase/firestore';
import React, { useContext, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import ColumnContainer from 'web/components/ColumnContainer';
import ContentColumn from 'web/components/ContentColumn';
import { Button, LinkStyled, Table } from 'web/components/elements';
import Fake from 'web/components/elements/Fake';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import useFunctions from 'web/components/FirebaseContext/useFunctions';
import Flare from 'web/components/Flare';
import ScreenTracker from 'web/components/ScreenTracker';
import Spinner from 'web/components/Spinner';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useErrorReporter from 'web/hooks/useErrorReporter';
import useFeatureIncludedCheck from 'web/hooks/useFeatureIncludedCheck';
import useFirestoreCollectionData from 'web/hooks/useFirestoreCollectionData';
import useFirestoreDocumentData from 'web/hooks/useFirestoreDocumentData';
import themeClasses from 'web/styles/themeClasses.css';
import { firestoreCallsUsageByMonthConverter, firestoreCallsUsageCreditsBalanceConverter } from 'web/utils/convert';
import { featuresUpgradePath, isValidPricingPlan, pricingPlans } from 'web/utils/pricingPlans';

const BuyMinutesButton = () => {
  const [submitting, setSubmitting] = useState(false);
  const functions = useFunctions();
  const errorReporter = useErrorReporter();
  const createCheckoutSession = async () => {
    setSubmitting(true);
    try {
      const { data } = await httpsCallable<
        { planId: string; priceId: string; successUrl: string; cancelUrl: string },
        { url: string }
      >(
        functions,
        'stripeCreateCheckoutSession',
      )({
        planId: 'calls_credits',
        priceId: 'onetime_usd_500',
        successUrl: window.location.href,
        cancelUrl: window.location.href,
      });
      if (!data.url) {
        throw new Error("stripeCreateCheckoutSession function didn't return a session URL");
      }
      window.location.href = data.url;
    } catch (err) {
      errorReporter.report(`Failed to create checkout session: ${err}`);
      setSubmitting(false);
    }
  };
  return (
    <Button md secondary onClick={createCheckoutSession} disabled={submitting}>
      {submitting && <Spinner />}
      <span>Buy minutes</span>
    </Button>
  );
};

const CallsCredits = () => {
  const { user, userData } = useContext(UserContext);
  const firestore = useFirestore();
  const [credits, creditsLoading, creditsError] = useFirestoreDocumentData(
    doc(firestore, 'callsUsage', user.uid, 'callsCreditsBalance', 'total').withConverter(
      firestoreCallsUsageCreditsBalanceConverter,
    ),
  );
  useErrorHandler(creditsError);

  const { pricingPlanId } = userData;
  const monthlyCallsParticipantSeconds = isValidPricingPlan(pricingPlanId)
    ? pricingPlans[pricingPlanId].monthlyCallsParticipantSeconds
    : 0;

  return (
    <>
      <WithHeaderContentColumn whiteBackground header="Extra minutes">
        <div>
          {!creditsLoading && !creditsError && (
            <>
              <b style={{ fontSize: 72, lineHeight: '60px' }}>{((credits?.participantSeconds ?? 0) / 60).toFixed(0)}</b>{' '}
              minute credits
            </>
          )}
        </div>
      </WithHeaderContentColumn>
      <ContentColumn whiteBackground>
        <div>
          Your plan includes {(monthlyCallsParticipantSeconds / 60).toFixed(0)} participant-minutes of HD Introwise
          calls each month. If you run out of included minutes you can allways purchase additional minutes. Bought
          minutes do not expire.
        </div>
        <div className={themeClasses({ marginTop: 4, display: 'flex', justifyContent: 'space-between', gap: 5 })}>
          <BuyMinutesButton />
          <div>
            <LinkStyled to="/help">How minutes work</LinkStyled>
          </div>
        </div>
      </ContentColumn>
    </>
  );
};

const getMonth = (date: Date) => `${date.getUTCFullYear()}-${`${date.getUTCMonth() + 1}`.padStart(2, '0')}`;

const PastUsage = () => {
  const { user } = useContext(UserContext);
  const firestore = useFirestore();
  const month = useMemo(() => getMonth(new Date()), []);
  const monthPastSix = useMemo(() => getMonth(new Date(Date.now() - 7 * 30 * 24 * 60 * 60 * 1000)), []);
  const [usage, loading, error] = useFirestoreCollectionData(
    query(
      collection(firestore, 'callsUsage', user.uid, 'callsUsageByMonth'),
      where(documentId(), '<', month),
      where(documentId(), '>=', monthPastSix),
      limit(6),
    ).withConverter(firestoreCallsUsageByMonthConverter),
  );
  useErrorHandler(error);

  const usageReverse = usage?.slice().reverse();

  return (
    <>
      {loading && <Fake height={20} />}
      {!loading && error && <div>Something went wrong. Please try again later.</div>}
      {!loading && !error && usage.length === 0 && <div>You haven&apos;t used Introwise calls lately.</div>}
      {!loading && !error && usage.length > 0 && (
        <Table>
          <thead>
            <tr>
              <th>Month</th>
              <th>Minutes</th>
            </tr>
          </thead>
          <tbody>
            {usageReverse.map((monthUsage) => {
              const minutes = monthUsage.participantSeconds / 60;
              const [year, month] = monthUsage.id.split('-').map((s) => parseInt(s, 10));
              const date = new Date(year, month - 1);
              return (
                <tr key={monthUsage.id}>
                  <td>{date.toLocaleDateString('en', { year: 'numeric', month: 'long' })}</td>
                  <td>{minutes.toFixed(0)}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </>
  );
};

const CustomBackgrounds = () => {
  const enabled = useFeatureIncludedCheck('customCallBackgrounds');
  const upgradePath = !enabled && pricingPlans[featuresUpgradePath['customCallBackgrounds']];
  return (
    <>
      <div className={themeClasses({ display: 'flex', gap: 2 })}>
        <h4 className={themeClasses({ marginTop: 0 })}>Custom virtual backgrounds</h4>
        <div>
          {enabled ? (
            <Flare variant="success">Enabled</Flare>
          ) : (
            <Flare variant="warning">{upgradePath.name} plan feature</Flare>
          )}
        </div>
      </div>
      {enabled ? (
        <p className={themeClasses({ marginTop: 0 })}>
          You can upload and use your own custom virtual backgrounds on Introwise calls.
        </p>
      ) : (
        <p className={themeClasses({ marginTop: 0 })}>
          You can use pre-defined virtual background images and background blur effects on Introwise calls. To use your
          own custom background images{' '}
          <LinkStyled to="/dashboard/account/billing/subscription">upgrade to the {upgradePath.name} plan</LinkStyled>.
        </p>
      )}
    </>
  );
};

const Recording = () => {
  const enabled = useFeatureIncludedCheck('recording');
  const upgradePath = !enabled && pricingPlans[featuresUpgradePath['recording']];
  return (
    <>
      <div className={themeClasses({ display: 'flex', gap: 2, marginTop: 8 })}>
        <h4 className={themeClasses({ marginTop: 0 })}>Recording and live streaming</h4>
        <div>
          {enabled ? (
            <Flare variant="success">Enabled</Flare>
          ) : (
            <Flare variant="warning">{upgradePath.name} plan feature</Flare>
          )}
        </div>
      </div>
      {enabled ? (
        <p className={themeClasses({ marginTop: 0 })}>
          You can record Introwise calls directly to the cloud in 1080p HD and live stream to a broadcast platform of
          your choice from any device.
        </p>
      ) : (
        <p className={themeClasses({ marginTop: 0 })}>
          Introwise calls can be recorded directly to the cloud in 1080p HD and live streamed to a broadcast platform of
          your choice from any device. To use these features{' '}
          <LinkStyled to="/dashboard/account/billing/subscription">upgrade to the {upgradePath.name} plan</LinkStyled>.
        </p>
      )}
    </>
  );
};

const SettingsCallsUsageImpl = () => {
  const { user } = useContext(UserContext);
  const firestore = useFirestore();
  const month = useMemo(() => {
    const now = new Date();
    return `${now.getUTCFullYear()}-${`${now.getUTCMonth() + 1}`.padStart(2, '0')}`;
  }, []);
  const [usage, loading, error] = useFirestoreDocumentData(
    doc(firestore, 'callsUsage', user.uid, 'callsUsageByMonth', month).withConverter(
      firestoreCallsUsageByMonthConverter,
    ),
  );
  useErrorHandler(error);

  const minutes = (usage?.participantSeconds ?? 0) / 60;
  const minutesRemaining = (usage?.remaining.participantSeconds ?? 0) / 60;

  return (
    <>
      <WithHeaderContentColumn header="Usage" whiteBackground>
        <>
          <h3 className={themeClasses({ marginTop: 0 })}>Current month</h3>
          <div
            style={{ gridAutoColumns: 'minmax(0, 2fr) minmax(0, 1fr)' }}
            className={themeClasses({ display: 'grid', gap: 5, gridAutoFlow: { all: 'row', md: 'column' } })}
          >
            {loading && <Fake height={16} />}
            {!loading && error && <div>Something went wrong. Please try again later</div>}
            {!loading && !error && (
              <>
                <div>
                  <b style={{ fontSize: 72, lineHeight: '60px' }}>{minutesRemaining.toFixed(0)}</b> minutes remaining
                </div>
                <div>
                  <b>{minutes.toFixed(0)}</b> minutes used
                </div>
              </>
            )}
          </div>
        </>
      </WithHeaderContentColumn>
      <CallsCredits />
      <WithHeaderContentColumn header="Past 6 months usage" whiteBackground>
        <PastUsage />
      </WithHeaderContentColumn>
      <WithHeaderContentColumn header="Additional features" whiteBackground>
        <CustomBackgrounds />
        <Recording />
      </WithHeaderContentColumn>
    </>
  );
};

const SettingsCallsUsage = () => {
  return (
    <>
      <Helmet title="Introwise Calls" />
      <ScreenTracker screenName="SettingsCalls" />
      <ColumnContainer>
        <div>
          <SettingsCallsUsageImpl />
        </div>
        <div></div>
      </ColumnContainer>
    </>
  );
};

export default SettingsCallsUsage;
