import React, { useContext } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import UserContext from 'web/components/UserContext';
import PersonalAvailabilityContext from './PersonalAvailabilityContext';

const PersonalAvailabilityContainer = () => {
  const { availabilityId } = useParams();
  const { userData } = useContext(UserContext);
  const availability = userData.availabilities
    ? userData.availabilities[availabilityId]
    : { id: 'availability', name: 'Working hours', order: 0 };

  return availability ? (
    <PersonalAvailabilityContext.Provider value={availability}>
      <Outlet />
    </PersonalAvailabilityContext.Provider>
  ) : (
    <Navigate to="/dashboard/scheduling" />
  );
};

export default PersonalAvailabilityContainer;
