import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import BookingPageContext from 'web/components/BookingPageContext';
import ColumnContainer from 'web/components/ColumnContainer';
import ContentColumn from 'web/components/ContentColumn';
import { AnchorButton, LinkButton, LinkStyled } from 'web/components/elements';
import ScreenTracker from 'web/components/ScreenTracker';
import Spinner from 'web/components/Spinner';
import StripeDashboardButton from 'web/components/stripe-buttons/StripeDashboardButton';
import StripeOAuthButton from 'web/components/stripe-buttons/StripeOAuthButton';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorHandler from 'web/hooks/useErrorHandler';
import themeClasses from 'web/styles/themeClasses.css';
import { formatCurrencyName, formatCurrencySymbol } from 'web/utils/currency';
import { defaultPricingPlanId, PricingPlanId, pricingPlans } from 'web/utils/pricingPlans';

const SettingsAccounts = () => {
  const { userData } = useContext(UserContext);

  return (
    <>
      {userData.payoutGateway === 'manual' ? (
        <>
          <p>We use Stripe to securely process payments from your clients.</p>
          <p>
            Your account is&nbsp;under a&nbsp;custom payout agreement. Please{' '}
            <LinkStyled to="/contact">contact us</LinkStyled> to&nbsp;change your funds destination, adjust schedule
            or&nbsp;get the status of&nbsp;your payout.
          </p>
        </>
      ) : (
        <>
          {(userData.settings?.payments?.gateway === 'none' || !userData.settings?.payments?.gateway) && (
            <>
              <p>
                <b>Payment processing:</b> <span style={{ color: 'red', fontWeight: 'bold' }}>not connected</span>
              </p>
              <p>
                Payments are not processed on your booking pages. Please connect a payment account or arrange payments
                with your clients individually.
              </p>
            </>
          )}
          {userData.settings?.payments?.gateway === 'paypal' && (
            <>
              <p>
                <b>Payment processing:</b> PayPal
              </p>
              <p>Payments from your clients are securely processed by&nbsp;PayPal.</p>
              <div>
                <AnchorButton
                  variant="secondary"
                  size="md"
                  href="https://www.paypal.com/mep/dashboard"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Open PayPal
                </AnchorButton>
              </div>
            </>
          )}
          {userData.settings?.payments?.gateway === 'stripe' && (
            <>
              <SettingsStripeConnect />
            </>
          )}
          <div className={themeClasses({ marginTop: 5 })}>
            <LinkStyled to="accounts">Manage payment accounts</LinkStyled>
          </div>
        </>
      )}
    </>
  );
};

const SettingsStripeConnect = () => {
  const { userData } = useContext(UserContext);
  const connected = !!userData?.stripeAccountId;
  const shouldShowTransactions = userData.stripeAccountType !== 'standard';

  return (
    <>
      {connected && (
        <>
          <p>
            <b>Payment processing:</b> Stripe
          </p>
          <p>Payments from your clients are securely processed by&nbsp;Stripe.</p>
          <p>
            Please use the button below for detailed transactions information or&nbsp;to&nbsp;update your payout
            settings.
          </p>
          {shouldShowTransactions && (
            <LinkButton variant="secondary" size="md" to="accounts/stripe/balance" style={{ marginRight: 20 }}>
              View transactions
            </LinkButton>
          )}
          <StripeDashboardButton size="md" text="Open Stripe" />
        </>
      )}
      {!connected && (
        <>
          <p>
            <b>Payment processing:</b> <span style={{ color: 'red', fontWeight: 'bold' }}>action needed</span>
          </p>
          <p>We&nbsp;use Stripe to&nbsp;securely store your bank information and process payments from your clients.</p>
          <p>Please use the button below to&nbsp;create your Stripe profile.</p>
          <StripeOAuthButton text="Connect" size="md" redirect="/dashboard/payments/stripe-oauth" />
        </>
      )}
    </>
  );
};

const SettingsCurrency = () => {
  const [page, loading, error] = useContext(BookingPageContext);
  useErrorHandler(error);

  if (loading) {
    return <Spinner />;
  }

  if (error || !page) {
    return <p>Failed to load currency settings</p>;
  }

  const symbol = formatCurrencySymbol(page.currency);
  const name = formatCurrencyName(page.currency);
  const nameCapitalized = name.charAt(0).toUpperCase() + name.slice(1);

  return (
    <>
      <p>
        <b>Currency:</b> {nameCapitalized} {symbol}
      </p>
      <p>
        We recommend to use the currency that works for most of your clients, or your bank account currency to avoid
        conversion loses.
      </p>
      <LinkButton variant="secondary" size="md" to="currency">
        Change currency
      </LinkButton>
    </>
  );
};

const SettingsPaymentsOverview = () => {
  const { userData } = useContext(UserContext);

  const { pricingPlanId } = userData;

  const sanitizedPlanId = (pricingPlanId as PricingPlanId) || defaultPricingPlanId;
  const pricingPlan = pricingPlans[sanitizedPlanId];

  return (
    <>
      <Helmet title="Payments" />
      <ScreenTracker screenName="SettingsPayments" />
      <ColumnContainer>
        <div>
          <WithHeaderContentColumn header={'Accepting payments'} whiteBackground>
            <SettingsAccounts />
          </WithHeaderContentColumn>
          <ContentColumn whiteBackground>
            <SettingsCurrency />
          </ContentColumn>
        </div>
        <div>
          <WithHeaderContentColumn header="Pricing plan" whiteBackground>
            <p>
              <b>Your plan:</b> {pricingPlan.name}
            </p>
            <p>{pricingPlan.description}</p>
            <LinkStyled to="/dashboard/account/billing">Manage your plan</LinkStyled>
          </WithHeaderContentColumn>
          {/* <WithHeaderContentColumn header="How much can you make per month?" whiteBackground>
            <p>Price per session</p>
            <p>Number of sessions per month</p>
            <p>Your earnings</p>
            <h1 className={themeClasses({ margin: 0 })}>$1000</h1>
          </WithHeaderContentColumn>
          <ContentColumn whiteBackground>
            <p>
              Connect your payment account<br />
              Share your booking link<br />
              Start earning<br />
            </p>
          </ContentColumn> */}
          {/* <WithHeaderContentColumn header="Your earnings" whiteBackground>
            <p>April 2023</p>
            <h1 className={themeClasses({ margin: 0 })}>$1000</h1>
          </WithHeaderContentColumn> */}
        </div>
      </ColumnContainer>
    </>
  );
};

export default SettingsPaymentsOverview;
