import React, { useEffect, useState } from 'react';
import themeVars from 'web/styles/themeVars.css';

const calcTimeBeforeSession = (start: Date) => {
  const diff = Math.abs(start.getTime() - Date.now());
  const days = diff / (1000 * 60 * 60 * 24);
  const hours = (days - Math.floor(days)) * 24;
  const minutes = (hours - Math.floor(hours)) * 60;
  const daysStr = days >= 1 ? `${Math.floor(days)} d ` : '';
  const hoursStr = hours >= 1 ? `${Math.floor(hours)} h ` : '';
  const minStr = minutes >= 1 && days < 1 ? `${Math.floor(minutes)} min${minutes >= 2 ? 's' : ''} ` : '';
  const timeStr = daysStr + hoursStr + minStr;
  return timeStr.length ? timeStr : 'less than a minute';
};

const makeTimeDiffStr = (start: Date) => {
  const now = new Date();
  const hasStarted = start < now;
  const timeDiff = calcTimeBeforeSession(start);
  return !hasStarted ? `Starts in ${timeDiff}` : `Started ${timeDiff} ago`;
};

const SessionTimeDiff = ({ start }: { start: Date }) => {
  const [timeDiffStr, setTimeDiffStr] = useState(makeTimeDiffStr(start));
  useEffect(() => {
    const interval = setInterval(() => setTimeDiffStr(makeTimeDiffStr(start)), 5000);
    return () => clearInterval(interval);
  }, [start]);

  return <div style={{ whiteSpace: 'nowrap', color: themeVars.color.navLink }}>{timeDiffStr}</div>;
};

export default SessionTimeDiff;
