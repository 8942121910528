import { collection, collectionGroup, query, where } from 'firebase/firestore';
import React, { useContext, useMemo } from 'react';
import { LinkStyled, Table } from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import Spinner from 'web/components/Spinner';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useFirestoreCollectionData from 'web/hooks/useFirestoreCollectionData';
import themeVars from 'web/styles/themeVars.css';
import { firestoreBookedPackageConverter, firestoreBookingConverter } from 'web/utils/convert';
import { formatCurrencyAmount } from 'web/utils/currency';
import { formatSessionDateLong } from 'web/utils/dateFormat';
import DiscountCodeContext from './DiscountCodeContext';

const SettingsPaymentsDiscountsUsage = () => {
  const firestore = useFirestore();
  const discountCode = useContext(DiscountCodeContext);
  const { userData } = useContext(UserContext);
  const [bookings, bookingsLoading, bookingsError] = useFirestoreCollectionData(
    query(
      collectionGroup(firestore, 'bookings'),
      where('expertId', '==', userData.id),
      where('pageId', '==', userData.bookingPageId),
      where('discountCodeId', '==', discountCode.id),
    ).withConverter(firestoreBookingConverter),
  );
  useErrorHandler(bookingsError);

  const [bookedPackages, bookedPackagesLoading, bookedPackagesError] = useFirestoreCollectionData(
    query(
      collection(firestore, 'bookedPackages'),
      where('package.pageId', '==', userData.bookingPageId),
      where('discountCodeId', '==', discountCode.id),
    ).withConverter(firestoreBookedPackageConverter),
  );
  useErrorHandler(bookedPackagesError);

  const allBookingsSorted = useMemo(
    () =>
      [...(bookings || []), ...(bookedPackages || [])].sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime()),
    [bookings, bookedPackages],
  );

  const loading = bookingsLoading || bookedPackagesLoading;

  return (
    <WithHeaderContentColumn header="Usage" whiteBackground>
      {loading && <Spinner />}
      {!loading && (bookingsError || bookedPackagesError) && <p>Something went wrong. Please try again later.</p>}
      {!loading &&
        !bookingsError &&
        !bookedPackagesError &&
        (allBookingsSorted.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <th>Client</th>
                <th>Date used</th>
                <th>Discount</th>
              </tr>
            </thead>
            <colgroup>
              <col />
              <col style={{ width: 160 }} />
              <col style={{ width: 160 }} />
            </colgroup>
            <tbody>
              {allBookingsSorted.map((booking) => (
                <tr key={booking.id}>
                  <td>
                    <LinkStyled
                      to={
                        'sessionId' in booking
                          ? `/dashboard/home/sessions/${booking.sessionId}`
                          : `/dashboard/home/packages/${booking.id}`
                      }
                    >
                      {booking.client.firstName} {booking.client.lastName}
                    </LinkStyled>
                  </td>
                  <td>{formatSessionDateLong(booking.createdAt)}</td>
                  <td>
                    {formatCurrencyAmount(booking.discountAmountOff, booking.currency)}{' '}
                    {booking.discount.valueType === 'percentage' && (
                      <span style={{ color: themeVars.color.muted }}>({booking.discount.value.percentOff}%)</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p>This code has&apos;t been used yet.</p>
        ))}
    </WithHeaderContentColumn>
  );
};

export default SettingsPaymentsDiscountsUsage;
