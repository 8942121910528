import React from 'react';
import { Helmet } from 'react-helmet-async';
import BookingPageLink from 'web/components/BookingPageLink';
import ColumnContainer from 'web/components/ColumnContainer';
import { LinkStyled } from 'web/components/elements';
import ListUnstyled from 'web/components/elements/ListUnstyled';
import ScreenTracker from 'web/components/ScreenTracker';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import themeClasses from 'web/styles/themeClasses.css';
import SettingsPackages from './Packages';

const SettingsSchedulingPackagesIndex = () => {
  return (
    <>
      <Helmet title="Manage packages" />
      <ScreenTracker screenName="SettingsSchedulingPackages" />
      <ColumnContainer>
        <div>
          <WithHeaderContentColumn whiteBackground header="Packages">
            <p className={themeClasses({ marginTop: 0 })}>Offer multiple sessions for a single packaged price</p>
            <SettingsPackages />
          </WithHeaderContentColumn>
        </div>
        <div>
          <WithHeaderContentColumn header="View as a client">
            <BookingPageLink />
            <div style={{ marginTop: 16 }}>
              <small>View availabile personal and group sessions on your booking page</small>
            </div>
          </WithHeaderContentColumn>
          <WithHeaderContentColumn header="More settings">
            <ListUnstyled>
              <li>
                <LinkStyled to="/dashboard/booking">Edit booking page</LinkStyled>
              </li>
              <li>
                <LinkStyled to="/dashboard/payments/currency">Change currency</LinkStyled>
              </li>
            </ListUnstyled>
          </WithHeaderContentColumn>
        </div>
      </ColumnContainer>
    </>
  );
};

export default SettingsSchedulingPackagesIndex;
