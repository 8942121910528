import React from 'react';
import { Outlet } from 'react-router-dom';
import DashboardNav from 'web/components/DashboardNav';

const SettingsCalls = () => {
  return (
    <>
      <DashboardNav
        items={[
          { text: 'Calls', to: '/dashboard/calls', end: true },
          { text: 'Locations', to: '/dashboard/calls/locations' },
          { text: 'Recordings', to: '/dashboard/calls/recordings' },
        ]}
      />
      <Outlet />
    </>
  );
};

export default SettingsCalls;
