import { httpsCallable, FunctionsErrorCode } from '@firebase/functions';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  AnchorStyled,
  Button,
  Checkbox,
  FormDescription,
  FormError,
  FormGroup,
  Input,
  Label,
  LabelText,
} from 'web/components/elements';
import useFunctions from 'web/components/FirebaseContext/useFunctions';
import Spinner from 'web/components/Spinner';
import UserContext from 'web/components/UserContext';
import useErrorReporter from 'web/hooks/useErrorReporter';
import themeClasses from 'web/styles/themeClasses.css';

const PayPalConnectForm = () => {
  const { userData } = useContext(UserContext);
  const functions = useFunctions();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const errorReporter = useErrorReporter();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({ defaultValues: { email: userData.email, confirm: false } });
  const connect = async ({ email }: { email: string }) => {
    setSubmitting(true);
    setError(null);
    try {
      await httpsCallable<{ email: string }, never>(functions, 'paypalConnect')({ email });
    } catch (err) {
      errorReporter.report(`Failed to connect PayPal account: ${err.message}`);
      const code = err.code as `functions/${FunctionsErrorCode}`;
      if (code === 'functions/failed-precondition') {
        setError(`Failed to connect PayPal account: ${err.message}`);
      } else {
        setError(`Something went wrong. Please try again later.`);
      }
    }
    setSubmitting(false);
  };

  const confirmed = watch('confirm');

  return (
    <div>
      <p className={themeClasses({ marginY: 0 })}>
        Connected PayPal account <b>must be a business account</b>,{' '}
        <AnchorStyled
          href="https://www.paypal.com/us/smarthelp/article/how-do-i-upgrade-my-paypal-account-type-faq900"
          target="_blank"
          rel="noopener noreferrer"
        >
          learn more here
        </AnchorStyled>{' '}
        on how to upgrade your account to business. PayPal integration only allows for accepting payments on your
        booking page, to issue refunds and to see the history of your transactions you will need to use your PayPal
        account dashboard.
      </p>
      <form onSubmit={handleSubmit(connect)}>
        <fieldset disabled={submitting}>
          <FormGroup>
            <Label>
              <LabelText>PayPal account email</LabelText>
              <Input
                placeholder="example@example.com"
                {...register('email', {
                  required: 'Please enter an email address',
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: 'Please enter an email address',
                  },
                  setValueAs: (v) => v.trim(),
                })}
              />
            </Label>
            {errors.email && <FormError>{errors.email.message}</FormError>}
            <FormDescription></FormDescription>
          </FormGroup>
          <FormGroup>
            <Checkbox {...register('confirm', { required: 'Required' })}>
              I understand and confirm that this is a PayPal business account
            </Checkbox>
            {errors.confirm && <FormError>{errors.confirm.message}</FormError>}
          </FormGroup>
          <FormGroup>
            <Button type="submit" disabled={!confirmed}>
              {submitting && <Spinner />}
              <span>Connect</span>
            </Button>
          </FormGroup>
          {error && (
            <FormGroup>
              <FormError>{error}</FormError>
            </FormGroup>
          )}
        </fieldset>
      </form>
    </div>
  );
};

export default PayPalConnectForm;
