import React from 'react';
import { useNavigate } from 'react-router';
import BackLink from 'web/components/BackLink';
import ColumnContainer from 'web/components/ColumnContainer';
import Meta from 'web/components/Meta';
import ServicesReorderImpl from 'web/components/Services/ServicesReorder';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';

const ServicesReorder = () => {
  const navigate = useNavigate();

  return (
    <>
      <Meta title="Reorder services" />
      <BackLink to="/dashboard/scheduling" />
      <ColumnContainer>
        <WithHeaderContentColumn header="Reorder services" whiteBackground>
          <ServicesReorderImpl onSuccess={() => navigate('/dashboard/scheduling')} />
        </WithHeaderContentColumn>
      </ColumnContainer>
    </>
  );
};

export default ServicesReorder;
