import React, { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { stringifyDescription } from 'web/components//form-fields/DescriptionField';
import BookingPageFormCommon from './BookingPageFormCommon';

type CommonValues = Pick<introwise.Page, 'slug' | 'headline' | 'cover'>;
type PageFormValues = CommonValues & {
  description: string;
  descriptionRich: null;
};

export type SubmitValues = CommonValues & Pick<introwise.Page, 'coverUpload' | 'description'>;

type FormObject<T> = { [K in keyof T]: T[K] };
type FormValues<T> = FormObject<T> | T;

const isFormObject = <T,>(val: FormObject<T> | T): val is FormObject<T> => val && Object.keys(val).length !== 0;

const sanitizeDefaultValues = <T,>(val: FormValues<T>, defaultVal: FormValues<T>): FormValues<T> =>
  isFormObject(defaultVal) && isFormObject(val)
    ? (Object.fromEntries(
        Object.keys(defaultVal).map((key) => [
          key,
          sanitizeDefaultValues(val[key as keyof FormValues<T>], defaultVal[key as keyof FormValues<T>]),
        ]),
      ) as FormObject<T>)
    : val !== undefined
    ? val
    : defaultVal;

const toFormValues = (page: introwise.Page) =>
  sanitizeDefaultValues<Omit<PageFormValues, 'description' | 'descriptionRich'>>(page, {
    cover: null,
    slug: '',
    headline: '',
  });

const BookingPageForm = ({
  page,
  onSubmit,
  submitting,
  buttonRight,
}: {
  page: introwise.Page;
  onSubmit: (page: SubmitValues) => void;
  submitting: boolean;
  buttonRight?: boolean;
}) => {
  const defaultValues = toFormValues(page);

  const methods = useForm<PageFormValues>({
    defaultValues,
  });

  const { setError } = methods;

  const onSubmitInternal = useCallback(
    async (values: PageFormValues) => {
      try {
        // All the Schema validations should pass at this point
        // Check that the URL wasn't taken while it was cached

        const { description: descriptionPlain, descriptionRich, ...rest } = values;
        const description = stringifyDescription(descriptionPlain, descriptionRich);
        const submitValues: SubmitValues = {
          ...rest,
          description,
          coverUpload: null,
        };
        onSubmit(submitValues);
      } catch (err) {
        setError('slug', {
          type: 'unique',
          message: `Failed to check URL uniqueness: ${err.code === 'unavailable' ? `You're offline` : err}`,
        });
        return;
      }
    },
    [onSubmit, setError],
  );

  return (
    <FormProvider {...methods}>
      <BookingPageFormCommon
        page={page}
        onSubmit={onSubmitInternal}
        submitting={submitting}
        buttonRigth={buttonRight}
      ></BookingPageFormCommon>
    </FormProvider>
  );
};

export default BookingPageForm;
