import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import BackLink from 'web/components/BackLink';
import ColumnContainer from 'web/components/ColumnContainer';
import ContentColumn from 'web/components/ContentColumn';
import { AppleCalendarIntegrationNew } from 'web/components/integrations/AppleCalendarIntegration';
import CalendarPrivacyNotice from 'web/components/integrations/CalendarPrivacyNotice';
import { GoogleCalendarIntegrationNew } from 'web/components/integrations/GoogleCalendarIntegration';
import { MicrosoftCalendarIntegrationNew } from 'web/components/integrations/MicrosoftCalendarIntegration';
import ScreenTracker from 'web/components/ScreenTracker';
import UpgradeButton from 'web/components/UpgradeButton';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useFeatureIncludedCheck from 'web/hooks/useFeatureIncludedCheck';
import themeClasses from 'web/styles/themeClasses.css';
import { featuresUpgradePath, pricingPlans } from 'web/utils/pricingPlans';

const ConnectedCalendarsConnectNew = ({ onConnect, prefixTo }: { onConnect?: () => void; prefixTo?: string }) => (
  <>
    <WithHeaderContentColumn header="Connect a calendar" whiteBackground>
      Connect your calendar account to let Introwise know when you&apos;re available and automatically add new sessions
      to your calendar.
    </WithHeaderContentColumn>
    <ContentColumn whiteBackground>
      <GoogleCalendarIntegrationNew onConnect={onConnect} />
    </ContentColumn>
    <ContentColumn whiteBackground>
      <MicrosoftCalendarIntegrationNew onConnect={onConnect} />
    </ContentColumn>
    <ContentColumn whiteBackground>
      <AppleCalendarIntegrationNew connectTo={`${prefixTo || ''}apple`} />
    </ContentColumn>
    <ContentColumn whiteBackground>
      <CalendarPrivacyNotice />
    </ContentColumn>
  </>
);

const standardCalendarsLimit = 2;
const proCalendarsLimit = 4;
const upgradePath = pricingPlans[featuresUpgradePath['increasedCalendarsLimit']];

const ConnectedCalendarsConnect = () => {
  const { userData } = useContext(UserContext);
  const connectedCalendarsCount = Object.values(userData.connectedAccounts).filter(
    (account) => account.type === 'calendar',
  ).length;
  const increasedCalendarsLimit = useFeatureIncludedCheck('increasedCalendarsLimit');
  const calendarsLimit = increasedCalendarsLimit ? proCalendarsLimit : standardCalendarsLimit;
  const overLimit = connectedCalendarsCount >= calendarsLimit;
  const navigate = useNavigate();
  return (
    <>
      <Helmet title="Connect a calendar" />
      <ScreenTracker screenName="ConnectCalendar" />
      <BackLink to=".." />
      <ColumnContainer equal={overLimit}>
        <div>
          {overLimit ? (
            <>
              <WithHeaderContentColumn header="Connect a calendar" whiteBackground>
                {increasedCalendarsLimit ? (
                  <>
                    <p className={themeClasses({ marginTop: 0 })}>
                      You have reached the maximum number of connected calendar accounts.
                    </p>
                    <p className={themeClasses({ marginBottom: 0 })}>
                      The number of connected calendars is currently limited to {proCalendarsLimit}. Please disconnect
                      one of your other accounts to connect a new one.
                    </p>
                  </>
                ) : (
                  <>
                    <p className={themeClasses({ marginTop: 0 })}>
                      You&apos;ve reached the limit of {standardCalendarsLimit} connected calendars.
                    </p>
                    <p>
                      Upgrade to the <b>{upgradePath.name}</b> plan to connect up to {proCalendarsLimit} calendar
                      accounts and get reduced <b>{upgradePath.applicationFeePercentage * 100}%</b> Introwise
                      commission. You can also disconnect one of your other accounts to connect a new one.
                    </p>
                    <div>
                      <UpgradeButton to="/dashboard/account/billing/subscription">
                        Upgrade to {upgradePath.name}
                      </UpgradeButton>
                    </div>
                  </>
                )}
              </WithHeaderContentColumn>
            </>
          ) : (
            <ConnectedCalendarsConnectNew onConnect={() => navigate('..', { replace: true })} />
          )}
        </div>
      </ColumnContainer>
    </>
  );
};

export { ConnectedCalendarsConnectNew };
export default ConnectedCalendarsConnect;
