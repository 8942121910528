import { collection, limit, orderBy, query } from 'firebase/firestore';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import BackLink from 'web/components/BackLink';
import ColumnContainer from 'web/components/ColumnContainer';
import { LinkStyled, Table } from 'web/components/elements';
import Fake from 'web/components/elements/Fake';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import Flare from 'web/components/Flare';
import ScreenTracker from 'web/components/ScreenTracker';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useFirestoreCollectionData from 'web/hooks/useFirestoreCollectionData';
import themeClasses from 'web/styles/themeClasses.css';
import { firestoreWorkflowScheduleConverter } from 'web/utils/convert';
import WorkflowContext from './WorkflowContext';

const isWorkflowScheduleInProgress = (workflowSchedule: introwise.WorkflowSchedule) => {
  const now = new Date();
  const latestExecutionAt = Object.values(workflowSchedule.executions).reduce(
    (latestExecutionAt, execution) =>
      execution.executionAt > latestExecutionAt ? execution.executionAt : latestExecutionAt,
    new Date(0),
  );
  return now < latestExecutionAt;
};

const WorkflowMonitoringIndex = () => {
  const { userData } = useContext(UserContext);
  const workflow = useContext(WorkflowContext);
  const { bookingPageId: pageId } = userData;
  const firestore = useFirestore();
  const [workflowSchedules, loading, error] = useFirestoreCollectionData(
    query(
      collection(firestore, 'pages', pageId, 'workflows', workflow.id, 'workflowSchedules'),
      orderBy('createdAt', 'desc'),
      limit(10),
    ).withConverter(firestoreWorkflowScheduleConverter),
  );

  return (
    <>
      <Helmet title={`Workflows – ${workflow.name}`} />
      <ScreenTracker screenName="SettingsSchedulingWorkflowEdit" />
      <BackLink to={`/dashboard/scheduling/workflows/${workflow.id}`} />
      <ColumnContainer>
        <div>
          <WithHeaderContentColumn header="Monitor a workflow" whiteBackground>
            <div className={themeClasses({ display: 'flex', justifyContent: 'space-between' })}>
              <h3 className={themeClasses({ margin: 0 })}>{workflow.name}</h3>
              <div>
                {workflow.active ? (
                  <Flare variant="success">Active</Flare>
                ) : workflow.deleted ? (
                  <Flare variant="error">Deleted</Flare>
                ) : (
                  <Flare variant="disabled">Paused</Flare>
                )}
              </div>
            </div>
          </WithHeaderContentColumn>
          <WithHeaderContentColumn header="Latest schedules" whiteBackground>
            {loading && (
              <Fake height={16} animated>
                Loading...
              </Fake>
            )}
            {error && <>Error: {error.message}</>}
            {!loading && !error && workflowSchedules.length === 0 && <>This workflow has not been executed yet.</>}
            {!loading && !error && workflowSchedules.length > 0 && (
              <>
                <Table>
                  <thead>
                    <tr>
                      <th>Created</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {workflowSchedules.map((workflowSchedule) => (
                      <tr key={workflowSchedule.id}>
                        <td>
                          <LinkStyled to={`/dashboard/home/sessions/${workflowSchedule.sessionId}`}>
                            {workflowSchedule.createdAt.toLocaleString('en')}
                          </LinkStyled>
                        </td>
                        <td>
                          {workflowSchedule.cancelled ? (
                            <Flare variant="disabled">Cancelled</Flare>
                          ) : isWorkflowScheduleInProgress(workflowSchedule) ? (
                            <Flare variant="warning">In progress</Flare>
                          ) : (
                            <Flare variant="success">Completed</Flare>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            )}
          </WithHeaderContentColumn>
        </div>
        <div />
      </ColumnContainer>
    </>
  );
};

export default WorkflowMonitoringIndex;
