import '@reach/tooltip/styles.css';
import { doc, updateDoc } from 'firebase/firestore';
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import BackLink from 'web/components/BackLink';
import ColumnContainer from 'web/components/ColumnContainer';
import { Button, FormDescription, FormError, FormGroup, InlineButton } from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import DateTimeSelector from 'web/components/form-fields/DateTimeSelector';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import themeClasses from 'web/styles/themeClasses.css';
import { formatSessionDateLong } from 'web/utils/dateFormat';
import BookedPackageContext from './BookedPackageContext';
import BookedPackageInfo from './BookedPackageInfo';

const ExpirationForm = ({
  initialValue,
  onSubmit,
  onCancel,
  submitting,
}: {
  initialValue: Date;
  onSubmit: (values: { expiresAt: Date }) => void;
  onCancel: () => void;
  submitting: boolean;
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues: { expiresAt: initialValue } });
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset disabled={submitting}>
        <FormGroup>
          <Controller
            name="expiresAt"
            control={control}
            rules={{
              required: true,
              validate: {
                notInThePast: (value) => (value <= new Date() ? 'Expiration date must be in the future' : undefined),
              },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <DateTimeSelector
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                isTimeOn
                disabled={submitting}
                hasError={!!errors.expiresAt}
                dateLabel="Expires on"
                timeLabel="Expires at"
              />
            )}
          />
          <FormDescription>All sessions must be booked for no later than that date</FormDescription>
          {errors.expiresAt && <FormError>{errors.expiresAt.message}</FormError>}
        </FormGroup>
        <FormGroup className={themeClasses({ display: 'flex', alignItems: 'center', justifyContent: 'space-between' })}>
          <Button type="submit" variant="primary">
            Save
          </Button>
          <InlineButton onClick={onCancel} disabled={submitting}>
            Cancel
          </InlineButton>
        </FormGroup>
      </fieldset>
    </form>
  );
};

const DashboardPackagesPackageExpiration = () => {
  const bookedPackage = useContext(BookedPackageContext);
  const firestore = useFirestore();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useErrorStateHandler();
  const navigate = useNavigate();

  const save = async (values: { expiresAt: Date }) => {
    setSubmitting(true);
    setError(undefined);
    try {
      await updateDoc(doc(firestore, 'bookedPackages', bookedPackage.id), {
        expiresAt: values.expiresAt,
      });
      setSubmitting(false);
      navigate('..', { replace: true });
    } catch (e) {
      setError(e.message);
    }
    setSubmitting(false);
  };

  return (
    <>
      <BackLink to="../.." />
      <ColumnContainer>
        <div>
          <BookedPackageInfo bookedPackage={bookedPackage} />
          <WithHeaderContentColumn header="Manage expiration" whiteBackground>
            <p className={themeClasses({ margin: 0 })}>
              This package is time-limited and will expire on <b>{formatSessionDateLong(bookedPackage.expiresAt)}</b>.
              You can adjust the expiration date below.
            </p>
            <div>
              <ExpirationForm
                initialValue={bookedPackage.expiresAt}
                onSubmit={save}
                onCancel={() => navigate('..', { replace: true })}
                submitting={submitting}
              />
              {error && <FormError>Something went wrong. Please try again later.</FormError>}
            </div>
          </WithHeaderContentColumn>
        </div>
      </ColumnContainer>
    </>
  );
};

export default DashboardPackagesPackageExpiration;
