import { collection, orderBy, query, Timestamp, where } from 'firebase/firestore';
import React, { useContext, useMemo } from 'react';
import BackLink from 'web/components/BackLink';
import BookingPageLink from 'web/components/BookingPageLink';
import ColumnContainer from 'web/components/ColumnContainer';
import ContentColumn from 'web/components/ContentColumn';
import { LinkButton, LinkStyled, Table } from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import Spinner from 'web/components/Spinner';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useFirestoreCollectionData from 'web/hooks/useFirestoreCollectionData';
import { firestoreGroupSessionConverter } from 'web/utils/convert';
import { formatSessionDate, formatSessionTime } from 'web/utils/dateFormat';
import SeriesContext from './SeriesContext';
import SeriesInfo from './SeriesInfo';

const nowMinute = () => Math.floor(Date.now() / (60 * 1000)) * 60 * 1000;

const SeriesSessions = ({ seriesId }: { seriesId: string }) => {
  const { user } = useContext(UserContext);
  const now = useMemo(() => Timestamp.fromMillis(nowMinute()), []);
  const firestore = useFirestore();
  const expertId = user.uid;

  const [sessions, loading, error] = useFirestoreCollectionData(
    query(
      collection(firestore, 'sessions'),
      where('seriesId', '==', seriesId),
      where('status', '==', 'confirmed'),
      where('expert.id', '==', expertId),
      where('type', '==', 'group'),
      where('start', '>', now),
      orderBy('start', 'asc'),
      // TODO: bring back the limit
      // limit(sessionsLimit || defaultLimit),
    ).withConverter(firestoreGroupSessionConverter),
  );
  useErrorHandler(error);

  return (
    <ContentColumn whiteBackground>
      {loading && <Spinner />}
      {!loading && error && <p>Failed to load sessions</p>}
      {!loading && !error && sessions.length === 0 && <p>No upcoming sessions scheduled</p>}
      {!loading && !error && sessions.length > 0 && (
        <Table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Booked</th>
              <th></th>
            </tr>
          </thead>
          <colgroup>
            <col style={{ width: 200 }} />
            <col style={{ width: 80 }} />
            <col />
          </colgroup>
          <tbody>
            {sessions.map((session) => (
              <tr key={session.id}>
                <td>
                  <LinkStyled to={`/dashboard/home/sessions/${session.id}`}>
                    {formatSessionDate(session.start)}, {formatSessionTime(session.start)}
                  </LinkStyled>
                </td>
                <td>
                  {session.groupSize.current}/{session.groupSize.max}
                </td>
                <td style={{ textAlign: 'right' }}>
                  <LinkStyled to={`sessions/${session.id}`}>Edit</LinkStyled>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </ContentColumn>
  );
};

const SeriesView = () => {
  const series = useContext(SeriesContext);

  return (
    <>
      <BackLink to="/dashboard/scheduling" />
      <ColumnContainer>
        <div>
          <SeriesInfo series={series} />
          <WithHeaderContentColumn
            header="Upcoming sessions"
            extendedHeader={
              <LinkButton variant="primary" size="sm" to="sessions/create">
                Schedule
              </LinkButton>
            }
          >
            <SeriesSessions seriesId={series.id} />
          </WithHeaderContentColumn>
        </div>
        <WithHeaderContentColumn header="Link to this series">
          <BookingPageLink path={`/series/${series.id}`} />
        </WithHeaderContentColumn>
      </ColumnContainer>
    </>
  );
};

export default SeriesView;
