import { faCheck, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { doc, updateDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import React, { useCallback, useContext, useState } from 'react';
import BackLink from 'web/components/BackLink';
import ColumnContainer from 'web/components/ColumnContainer';
import ContentColumn from 'web/components/ContentColumn';
import { AnchorButton, Button, InlineButton, LinkButton } from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import useFunctions from 'web/components/FirebaseContext/useFunctions';
import Flare from 'web/components/Flare';
import {
  IntegrationBlock,
  IntegrationBlockTitle,
  IntegrationIcon,
  IntegrationInlineMark,
  IntegrationTitle,
} from 'web/components/integrations/common';
import PayPalConnectButton from 'web/components/PayPalConnectButton';
import Spinner from 'web/components/Spinner';
import StripeDashboardButton from 'web/components/stripe-buttons/StripeDashboardButton';
import useStripeOAuthButton from 'web/components/stripe-buttons/useStripeOAuthButton';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorReporter from 'web/hooks/useErrorReporter';
import themeClasses from 'web/styles/themeClasses.css';
import { getCountryByISO } from 'web/utils/countries';

const PayPalAccount = ({
  isDefault,
  submitting,
  onClickDefault,
}: {
  isDefault: boolean;
  submitting: boolean;
  onClickDefault: () => Promise<void>;
}) => {
  const { userData } = useContext(UserContext);
  const functions = useFunctions();
  const [submittingDisconnect, setSubmittingDisconnect] = useState(false);
  const errorReporter = useErrorReporter();

  const connectedPlatform = !!userData.paypalMerchantId;

  const connected = !!userData.paypalAccountEmail || connectedPlatform;
  const verified = userData.paypalAccountVerified || connectedPlatform;

  const disconnect = async () => {
    const res = window.confirm('Are you sure you want to disconnect your PayPal account?');
    if (!res) {
      return;
    }
    setSubmittingDisconnect(true);
    try {
      await httpsCallable<never, never>(functions, 'paypalDisconnect')();
    } catch (err) {
      errorReporter.report(`Failed to disconnect PayPal account: ${err.message}`);
    }
    setSubmittingDisconnect(false);
  };

  return (
    <IntegrationBlock>
      <div>
        <IntegrationBlockTitle>
          <IntegrationIcon>
            <img width={32} src="/integrations-paypal-icon.svg" />
          </IntegrationIcon>
          <div>
            <div className={themeClasses({ display: 'flex', alignItems: 'center' })}>
              <IntegrationTitle>
                PayPal
                {((connected && !verified) || (connected && !connectedPlatform)) && (
                  <>
                    {' '}
                    <span style={{ color: 'red' }}>
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                    </span>
                  </>
                )}
              </IntegrationTitle>
            </div>
            {connected && <div>{userData.paypalAccountEmail}</div>}
            <div>
              <small>Convenient payments with PayPal, credit and debit cards.</small>
            </div>
            {connected && !verified && (
              <div className={themeClasses({ marginTop: 3 })}>
                We need to verify your PayPal account connection. Please check your inbox and click the link in the
                verification email we sent you.
              </div>
            )}
            {connected && !connectedPlatform && (
              <div className={themeClasses({ marginTop: 3 })}>
                Please update your PayPal account integration by disconnecting and connecting again.
              </div>
            )}
          </div>
        </IntegrationBlockTitle>
        {connected && verified && (
          <div className={themeClasses({ marginTop: 4 })}>
            <AnchorButton
              sm
              secondary
              href="https://www.paypal.com/mep/dashboard"
              target="_blank"
              rel="noopener noreferrer"
            >
              Open PayPal
            </AnchorButton>
          </div>
        )}
      </div>
      <div>
        {connected ? (
          verified && (
            <>
              <div>
                {isDefault ? (
                  <IntegrationInlineMark>
                    <FontAwesomeIcon icon={faCheck} /> Default
                  </IntegrationInlineMark>
                ) : (
                  <InlineButton onClick={onClickDefault} disabled={submitting}>
                    Make default
                  </InlineButton>
                )}
              </div>

              <div className={themeClasses({ marginTop: 2 })}>
                <InlineButton onClick={disconnect} disabled={submitting || submittingDisconnect}>
                  {submittingDisconnect && <Spinner />}
                  <span>Disconnect</span>
                </InlineButton>
              </div>
            </>
          )
        ) : (
          <PayPalConnectButton variant="secondary" returnTo="/dashboard/payments/paypal-return" />
        )}
      </div>
    </IntegrationBlock>
  );
};

const StripeAccount = ({
  isDefault,
  submitting,
  onClickDefault,
}: {
  isDefault: boolean;
  submitting: boolean;
  onClickDefault: () => Promise<void>;
}) => {
  const { userData } = useContext(UserContext);
  const connected = !!userData.stripeAccountId;
  const shouldShowTransactions = userData.stripeAccountType !== 'standard';
  const country = getCountryByISO(userData.country);
  const supported = !country || country.stripe;
  const redirectToStripe = useStripeOAuthButton('/dashboard/payments/stripe-oauth');

  return (
    <IntegrationBlock>
      <div>
        <IntegrationBlockTitle>
          <IntegrationIcon>
            <img width={60} src="/integrations-stripe-icon.svg" />
          </IntegrationIcon>
          <div>
            <div className={themeClasses({ display: 'flex', alignItems: 'center' })}>
              <IntegrationTitle>Stripe</IntegrationTitle>
              {!connected && (
                <div className={themeClasses({ marginLeft: 2 })}>
                  {supported ? (
                    <Flare variant="success">Recommended</Flare>
                  ) : (
                    <Flare variant="warning">Not available in {country.name}</Flare>
                  )}
                </div>
              )}
            </div>
            {connected && <div>Connnected</div>}
            <div>
              <small>Fast credit cards, debit cards and local payments with low fees.</small>
            </div>
          </div>
        </IntegrationBlockTitle>
        {connected && (
          <div className={themeClasses({ marginTop: 4 })}>
            {shouldShowTransactions && (
              <LinkButton secondary sm to="stripe/balance" className={themeClasses({ marginRight: 5 })}>
                View transactions
              </LinkButton>
            )}
            <LinkButton
              to="stripe/payment-methods"
              size="sm"
              variant="secondary"
              className={themeClasses({ marginRight: 5 })}
            >
              Manage payment methods
            </LinkButton>
            <StripeDashboardButton size="sm" text="Open Stripe" />
          </div>
        )}
      </div>
      <div>
        {connected ? (
          <>
            <div>
              {isDefault ? (
                <IntegrationInlineMark>
                  <FontAwesomeIcon icon={faCheck} /> Default
                </IntegrationInlineMark>
              ) : (
                <InlineButton onClick={onClickDefault} disabled={submitting}>
                  Make default
                </InlineButton>
              )}
            </div>
          </>
        ) : (
          <Button md secondary onClick={redirectToStripe}>
            Connect
          </Button>
        )}
      </div>
    </IntegrationBlock>
  );
};

const NoPayments = ({
  isDefault,
  submitting,
  onClickDefault,
}: {
  isDefault: boolean;
  submitting: boolean;
  onClickDefault: () => Promise<void>;
}) => (
  <IntegrationBlock>
    <IntegrationBlockTitle>
      <IntegrationIcon>
        <img width={32} src="/integrations-none-icon.svg" />
      </IntegrationIcon>
      <div>
        <IntegrationTitle>No payments</IntegrationTitle>
        <div>
          <small>
            <FontAwesomeIcon icon={faExclamationTriangle} fixedWidth style={{ marginRight: 4 }} />
            This option disables payment collection on your booking pages.
          </small>
        </div>
      </div>
    </IntegrationBlockTitle>
    <div>
      <div>
        {isDefault ? (
          <IntegrationInlineMark>
            <FontAwesomeIcon icon={faCheck} /> Default
          </IntegrationInlineMark>
        ) : (
          <InlineButton onClick={onClickDefault} disabled={submitting}>
            Make default
          </InlineButton>
        )}
      </div>
    </div>
  </IntegrationBlock>
);

const SettingsPaymentsAccounts = () => {
  const { user, userData } = useContext(UserContext);
  const firestore = useFirestore();
  const [submitting, setSubmitting] = useState(false);
  const errorReporter = useErrorReporter();

  const onClickDefault = useCallback(
    async (gateway: introwise.User['settings']['payments']['gateway']) => {
      setSubmitting(true);
      try {
        await updateDoc(doc(firestore, 'users', user.uid), {
          'settings.payments.gateway': gateway,
        });
      } catch (err) {
        errorReporter.report(`Failed to set payment gateway: ${err.message}`);
      }
      setSubmitting(false);
    },
    [errorReporter, firestore, user.uid],
  );

  return (
    <>
      <BackLink to="../.." />
      <ColumnContainer>
        <div>
          <WithHeaderContentColumn header="Payment accounts" whiteBackground>
            <StripeAccount
              isDefault={userData.settings?.payments?.gateway === 'stripe'}
              onClickDefault={() => onClickDefault('stripe')}
              submitting={submitting}
            />
          </WithHeaderContentColumn>
          <ContentColumn whiteBackground>
            <PayPalAccount
              isDefault={userData.settings?.payments?.gateway === 'paypal'}
              onClickDefault={() => onClickDefault('paypal')}
              submitting={submitting}
            />
          </ContentColumn>
          <WithHeaderContentColumn header="Other options" whiteBackground>
            <NoPayments
              isDefault={userData.settings?.payments?.gateway === 'none'}
              onClickDefault={() => onClickDefault('none')}
              submitting={submitting}
            />
          </WithHeaderContentColumn>
        </div>
      </ColumnContainer>
    </>
  );
};

export default SettingsPaymentsAccounts;
