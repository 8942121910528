import React from 'react';
import { Button } from 'web/components/elements';
import useStripeOAuthButton from './useStripeOAuthButton';

const StripeOAuthButton = ({ text, redirect, size }: { text: string; redirect: string; size?: 'md' | 'sm' }) => {
  const redirectToStripe = useStripeOAuthButton(redirect);

  return (
    <Button variant="primary" size={size} onClick={redirectToStripe}>
      {text}
    </Button>
  );
};

export default StripeOAuthButton;
