import { collection, doc, updateDoc } from 'firebase/firestore';
import React, { useContext, useState } from 'react';
import BookingPageFormBranding from 'web/components/booking-page-form/BookingPageFormBranding';
import BookingPageContext from 'web/components/BookingPageContext';
import ColumnContainer from 'web/components/ColumnContainer';
import { FormError } from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import useTracking from 'web/components/TrackingContext/useTracking';
import UpgradeButton from 'web/components/UpgradeButton';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorReporter from 'web/hooks/useErrorReporter';
import useFeatureIncludedCheck from 'web/hooks/useFeatureIncludedCheck';
import themeClasses from 'web/styles/themeClasses.css';
import { featuresUpgradePath, pricingPlans } from 'web/utils/pricingPlans';
import BookingPageLink from 'web/components/BookingPageLink';
import { Helmet } from 'react-helmet-async';
import ScreenTracker from 'web/components/ScreenTracker';

const SettingsBookingPageBrandingImpl = () => {
  const firestore = useFirestore();
  const tracking = useTracking();
  const [error, setError] = useState<string>();
  // const [showSavedMark, setShowSavedMark] = useState(false);
  const [pageData] = useContext(BookingPageContext);
  const [submitting, setSubmitting] = useState(false);
  const errorReporter = useErrorReporter();

  const edit = async (page: Pick<introwise.Page, 'branding'>) => {
    setSubmitting(true);
    const pageRef = doc(collection(firestore, 'pages'), pageData.id);
    const updateData = {
      branding:
        !page.branding?.accentColor && !page.branding?.backgroundColor
          ? null
          : {
              accentColor: page.branding?.accentColor || null,
              backgroundColor: page.branding?.backgroundColor || null,
            },
    };
    try {
      await updateDoc(pageRef, updateData);
    } catch (err) {
      setError("Can't save the booking page at the moment. Please try again later.");
      errorReporter.report(new Error(`Failed to update a booking page: ${err}`));
    }
    tracking.trackEvent('Booking Page Branding Updated');
    setSubmitting(false);
    // setShowSavedMark(true);
  };

  const page = pageData;

  return (
    <WithHeaderContentColumn header="Custom branding" whiteBackground>
      <BookingPageFormBranding
        page={page}
        submitting={submitting}
        onSubmit={edit}
        // showSavedMark={showSavedMark}
        // setShowSavedMark={setShowSavedMark}
      />
      {error && <FormError>{`${error}`}</FormError>}
    </WithHeaderContentColumn>
  );
};

const SettingsBookingPageBranding = () => {
  const featureIncluded = useFeatureIncludedCheck('branding');
  const upgradePath = pricingPlans[featuresUpgradePath['branding']];

  return (
    <>
      <Helmet title="Branding" />
      <ScreenTracker screenName="SettingsBookingBranding" />
      <ColumnContainer>
        {featureIncluded ? (
          <SettingsBookingPageBrandingImpl />
        ) : (
          <WithHeaderContentColumn header="Custom branding" whiteBackground>
            <p className={themeClasses({ marginTop: 0 })}>
              Customize the look and feel of your booking page to match your brand and personality.
            </p>
            <p>
              Upgrade to the <b>{upgradePath.name}</b> plan to get access to the custom branding feature and{' '}
              <b>{upgradePath.applicationFeePercentage * 100}%</b> Introwise commission.
            </p>
            <div>
              <UpgradeButton to="/dashboard/account/billing/subscription">Upgrade to {upgradePath.name}</UpgradeButton>
            </div>
          </WithHeaderContentColumn>
        )}
        <WithHeaderContentColumn header="View as a client">
          <BookingPageLink />
          <div style={{ marginTop: 16 }}>
            <small>View your booking page exactly as your clients see it</small>
          </div>
        </WithHeaderContentColumn>
      </ColumnContainer>
    </>
  );
};

export default SettingsBookingPageBranding;
