import { httpsCallable } from 'firebase/functions';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import BackLink from 'web/components/BackLink';
import { Button, InlineButton, Table } from 'web/components/elements';
import useFunctions from 'web/components/FirebaseContext/useFunctions';
import GenericReactModal from 'web/components/GenericReactModal';
import ScreenTracker from 'web/components/ScreenTracker';
import Spinner from 'web/components/Spinner';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import themeConstants from 'web/styles/themeConstants';
import themeVars from 'web/styles/themeVars.css';
import { convertToDecimal, formatCurrencyAmount } from 'web/utils/currency';

type BalanceResponse = {
  balance: {
    available: {
      amount: number;
      currency: introwise.Currency;
    }[];
    pending: {
      amount: number;
      currency: introwise.Currency;
    }[];
  };
  transactions: {
    id: string;
    created: number;
    available_on: number;
    type: string;
    amount: number;
    currency: introwise.Currency;
    description: string;
    net: number;
    fee: number;
    fee_details: { type: string; amount: number; currency: introwise.Currency }[];
  }[];
  paymentIntents: {
    id: string;
    created: number;
    amount: number;
    currency: introwise.Currency;
    description: string;
    charges: {
      data: {
        status: 'succeeded' | 'failed';
        refunded: boolean;
        billing_details: { name: string; email: string };
      }[];
    };
  }[];
};

const formatCurrency = (currency: introwise.Currency) => currency.toUpperCase();

const BalanceGrid = styled.div`
  display: grid;
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  column-gap: 40px;
`;

const BalanceAmount = styled.div`
  ${themeConstants.typography.h3}
  white-space: pre;
`;

type BalanceConverted = {
  [currency in introwise.Currency]?: {
    total: number;
    available: number;
    pending: number;
  };
};

const convertBalance = (balance: BalanceResponse['balance']) => {
  const result: BalanceConverted = {};
  balance.available.forEach(({ currency, amount }) => {
    result[currency] = result[currency] || { total: 0, available: 0, pending: 0 };
    result[currency].total += amount;
    result[currency].available += amount;
  });
  balance.pending.forEach(({ currency, amount }) => {
    result[currency] = result[currency] || { total: 0, available: 0, pending: 0 };
    result[currency].total += amount;
    result[currency].pending += amount;
  });
  return result;
};

const Balance = ({ balance }: { balance: BalanceResponse['balance'] }) => {
  const converted = useMemo(() => convertBalance(balance), [balance]);

  return (
    <BalanceGrid>
      {Object.keys(converted)
        .sort()
        .map((currency: introwise.Currency) => (
          <div key={currency}>
            <BalanceAmount>{formatCurrencyAmount(converted[currency].total, currency)}</BalanceAmount>
            <div>{formatCurrency(currency)}</div>
          </div>
        ))}
    </BalanceGrid>
  );
};

const formatNetAmount = (amount: number, currency: introwise.Currency) =>
  Intl.NumberFormat([], { style: 'currency', currency, currencySign: 'accounting' }).format(
    convertToDecimal(amount, currency),
  );

const formatTransactionType = (type: string) => {
  switch (type) {
    case 'charge':
      return 'Payment';
    case 'refund':
      return 'Refund';
    case 'payout':
      return 'Payout';
    case 'adjustment':
      return 'Adjustment';
    default:
      return type;
  }
};

const Cell = styled.td<{ bold?: boolean; muted?: boolean }>`
  ${({ bold }) => bold && 'font-weight: bolder;'}
  ${({ muted }) => muted && `color: ${themeVars.color.muted};`}
`;

const formatOptionalFee = (transaction: BalanceResponse['transactions'][0], type: string) =>
  ((fee) => (fee?.amount ? formatNetAmount(fee.amount, fee.currency) : '\u2014'))(
    transaction.fee_details.find((f) => f.type === type),
  );

const Transactions = ({ transactions }: { transactions: BalanceResponse['transactions'] }) => {
  return (
    <div style={{ overflowX: 'auto' }}>
      <Table>
        <thead>
          <tr>
            <th>Type</th>
            <th>Net</th>
            <th>Amount</th>
            <th>Stripe</th>
            <th>Introwise</th>
            <th></th>
            <th>Description</th>
            {/* <th>Created</th> */}
            <th style={{ whiteSpace: 'nowrap' }}>Available on</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction) => (
            <tr key={transaction.id}>
              <Cell bold muted={transaction.net <= 0}>
                {formatTransactionType(transaction.type)}
              </Cell>
              <Cell bold muted={transaction.net <= 0}>
                {formatNetAmount(transaction.net, transaction.currency)}
              </Cell>
              <td>{formatNetAmount(transaction.amount, transaction.currency)}</td>
              <td>{formatOptionalFee(transaction, 'stripe_fee')}</td>
              <td>{formatOptionalFee(transaction, 'application_fee')}</td>
              <Cell muted>{formatCurrency(transaction.currency)}</Cell>
              <td>{transaction.description}</td>
              {/* <td>{new Date(transaction.created * 1000).toLocaleDateString('en')}</td> */}
              <td>{new Date(transaction.available_on * 1000).toLocaleDateString('en')}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

const formatChargeStatus = (charge: BalanceResponse['paymentIntents'][0]['charges']['data'][0]) =>
  !charge
    ? ''
    : charge.refunded
    ? 'Refunded'
    : { succeeded: 'Succeeded', failed: 'Failed', pending: 'Pending' }[charge.status];

const PaymentIntents = ({ paymentIntents }: { paymentIntents: BalanceResponse['paymentIntents'] }) => {
  return (
    <div style={{ overflowX: 'auto' }}>
      <Table>
        <thead>
          <tr>
            <th>Amount</th>
            <th></th>
            <th></th>
            <th>Description</th>
            <th>Client</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {paymentIntents.map((paymentIntent) => (
            <tr key={paymentIntent.id}>
              <Cell bold>{formatNetAmount(paymentIntent.amount, paymentIntent.currency)}</Cell>
              <Cell muted>{formatCurrency(paymentIntent.currency)}</Cell>
              <td>{formatChargeStatus(paymentIntent.charges.data[0])}</td>
              <td>{paymentIntent.description}</td>
              <td>{`${paymentIntent.charges.data[0]?.billing_details.name || ''} <${
                paymentIntent.charges.data[0]?.billing_details.email || ''
              }>`}</td>
              <td>{new Date(paymentIntent.created * 1000).toLocaleString('en')}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

const HelpModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div style={{ lineHeight: 'calc(20px * 1.62)' }}>
        <InlineButton onClick={() => setIsOpen(true)}>What is it?</InlineButton>
      </div>
      <GenericReactModal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
        <div style={{ width: 600, maxWidth: '96vw', maxHeight: '80vh' }}>
          <h3>Balance</h3>
          <p>
            Your Stripe account balance shows the net sum of all processed but not yet paid out payments. The currencies
            of your stored balances are determined by your connected bank accounts.
          </p>
          <h3>Transactions</h3>
          <p>
            Transactions show the movement of funds in and out of your Stripe account, the Stripe fees and Introwise
            fees. Funds are paid out automatically daily after a hold period of 2-14 business days. You can see the
            expected date of the payout in the last column of the table.
          </p>
          <h3>Payments</h3>
          <p>
            Payments show actual amounts that were charged to your clients. They will see that exact amount and currency
            on their account statements. If the payment was refunded or failed for any reason the corresponding line
            will show that status.
          </p>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 12 }}>
            <Button md secondary onClick={() => setIsOpen(false)}>
              Okay
            </Button>
          </div>
        </div>
      </GenericReactModal>
    </>
  );
};

const SettingPaymentsStripeBalance = () => {
  const { userData } = useContext(UserContext);
  const functions = useFunctions();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useErrorStateHandler();
  const [data, setData] = useState<BalanceResponse>(null);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const response = await httpsCallable<unknown, BalanceResponse>(functions, 'stripeRetrieveBalance')({});
        setData(response.data);
      } catch (err) {
        setError(err);
      }
      setLoading(false);
    };
    if (userData.stripeAccountId && userData.stripeAccountType !== 'standard') {
      void fetch();
    }
  }, [functions, setError, userData.stripeAccountId, userData.stripeAccountType]);

  if (!userData.stripeAccountId) {
    return <p>No Stripe account connected</p>;
  }

  if (userData.stripeAccountType === 'standard') {
    return <p>Cannot show the balance for a standard Stripe account</p>;
  }

  return (
    <>
      <Helmet title="Stripe Balance" />
      <ScreenTracker screenName="SettingsPaymentsBalanceStripe" />
      {loading && <Spinner />}
      {!loading && error && <p>{`${error}`}</p>}
      {!loading && !error && (
        <div style={{ display: 'grid', gridTemplateColumns: '100%' }}>
          <BackLink to="/dashboard/payments" />
          <WithHeaderContentColumn whiteBackground header="Balances" extendedHeader={<HelpModal />}>
            <Balance balance={data.balance} />
          </WithHeaderContentColumn>
          <WithHeaderContentColumn whiteBackground header="Transactions">
            <Transactions transactions={data.transactions} />
          </WithHeaderContentColumn>
          <WithHeaderContentColumn whiteBackground header="Payments">
            <PaymentIntents paymentIntents={data.paymentIntents} />
          </WithHeaderContentColumn>
        </div>
      )}
    </>
  );
};

export default SettingPaymentsStripeBalance;
