import React from 'react';
import { formatSessionDateTimeString, isToday, isTomorrow } from 'web/utils/dateFormat';
import Bullet from '../Bullet';

const SessionDateTime = ({ start, end }: { start: Date; end: Date }) => {
  const split = formatSessionDateTimeString(start, end).split(', ');
  const date = isToday(start) ? 'Today' : isTomorrow(start) ? 'Tomorrow' : split[0];

  return (
    <span>
      {date}
      <Bullet />
      <span style={{ whiteSpace: 'nowrap' }}>{split.slice(1).join(',')}</span>
    </span>
  );
};

export default SessionDateTime;
