import { PortableText } from '@portabletext/react';
import React from 'react';
import Bullet from 'web/components/Bullet';
import { InlineButton } from 'web/components/elements';
import { defaultSerializers } from 'web/components/portable-text/default-serializers';
import themeClasses from 'web/styles/themeClasses.css';
import themeVars from 'web/styles/themeVars.css';
import { formatSessionDate, formatSessionTime } from 'web/utils/dateFormat';
import NoteAddModalButton from './NoteAddModalButton';
import NoteEdit from './NoteEdit';

const NoteViewer = ({
  note,
  pageId,
  clientId,
  withAddButton,
}: {
  note: introwise.Note;
  pageId: string;
  clientId: string;
  withAddButton?: boolean;
}) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const noteDate = note.createdAt;
  return (
    <div>
      <div className={themeClasses({ display: 'flex', justifyContent: 'space-between' })}>
        <div>
          <h4 className={themeClasses({ marginY: 0 })}>
            <time dateTime={noteDate.toISOString()}>
              {formatSessionDate(noteDate)}
              <Bullet />
              {formatSessionTime(noteDate)}
            </time>
          </h4>
        </div>
        <div className={themeClasses({ display: 'flex', gap: 4, alignItems: 'center' })}>
          {isEditing ? (
            <InlineButton onClick={() => setIsEditing(false)}>Cancel</InlineButton>
          ) : (
            <>
              {withAddButton && (
                <NoteAddModalButton pageId={pageId} clientId={clientId}>
                  {(onClick) => <InlineButton onClick={onClick}>Add a note</InlineButton>}
                </NoteAddModalButton>
              )}
              <InlineButton onClick={() => setIsEditing(true)}>Edit</InlineButton>
            </>
          )}
        </div>
      </div>
      <div>
        {isEditing ? (
          <NoteEdit note={note} clientId={clientId} pageId={pageId} onDone={() => setIsEditing(false)} />
        ) : note.content.length === 1 && note.content[0].children.length === 0 ? (
          <p style={{ color: themeVars.color.halfMuted }}>Empty note</p>
        ) : (
          <PortableText value={note.content} components={defaultSerializers} />
        )}
      </div>
    </div>
  );
};

export default NoteViewer;
