import { doc } from 'firebase/firestore';
import React, { useContext } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import UserContext from 'web/components/UserContext';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useFirestoreDocumentData from 'web/hooks/useFirestoreDocumentData';
import { firestoreClientConverter } from 'web/utils/convert';
import ClientContext from './ClientContext';

const DashboardClientsClientContainer = () => {
  const { userData } = useContext(UserContext);
  const firestore = useFirestore();
  const { clientId } = useParams();

  const [client, loading, error] = useFirestoreDocumentData(
    doc(firestore, 'pages', userData.bookingPageId, 'clients', clientId).withConverter(firestoreClientConverter),
  );
  useErrorHandler(error);

  return (
    <>
      {loading && <p>Loading...</p>}
      {!loading && error && <p>Error: {`${error}`}</p>}
      {!loading && !error && !client && <p>Not found</p>}
      {!loading && !error && client && (
        <ClientContext.Provider value={client}>
          <Outlet />
        </ClientContext.Provider>
      )}
    </>
  );
};

export default DashboardClientsClientContainer;
