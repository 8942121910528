import React from 'react';
import { Outlet } from 'react-router-dom';
import DashboardNav from 'web/components/DashboardNav';

const SettingsScheduling = () => (
  <>
    <DashboardNav
      items={[
        { text: 'Services', to: '/dashboard/scheduling/services' },
        { text: 'Packages', to: '/dashboard/scheduling/packages' },
        { text: 'Group sessions', to: '/dashboard/scheduling/group' },
      ]}
    />
    <Outlet />
  </>
);

export default SettingsScheduling;
