import Tooltip from '@reach/tooltip';
import '@reach/tooltip/styles.css';
import { collection, deleteDoc, doc, orderBy, query, where } from 'firebase/firestore';
import React, { useMemo, useState } from 'react';
import { AnchorStyled, InlineButton, LinkStyled, Table } from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import Spinner from 'web/components/Spinner';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useErrorReporter from 'web/hooks/useErrorReporter';
import useFirestoreCollectionData from 'web/hooks/useFirestoreCollectionData';
import { firestoreResourceRecordingConverter } from 'web/utils/convert';
import { getDownloadUrl } from 'web/utils/resources';

const formatRecordingDate = (date: Date) =>
  date.toLocaleString('en', { month: 'short', day: 'numeric', minute: 'numeric', hour: 'numeric', second: 'numeric' });

const DeleteButton = ({ recording }: { recording: introwise.ResourceRecording }) => {
  const firestore = useFirestore();
  const errorReporter = useErrorReporter();
  const [deleting, setDeleting] = useState(false);

  const deleteRecording = async () => {
    const res = window.confirm(
      `Are you sure you want to delete this recording from ${formatRecordingDate(recording.createdAt)}?`,
    );
    if (!res) {
      return;
    }
    setDeleting(true);
    try {
      await deleteDoc(doc(firestore, 'resources', recording.id));
    } catch (err) {
      errorReporter.report(err);
    }
    setDeleting(false);
  };

  return (
    <InlineButton onClick={deleteRecording} disabled={deleting}>
      <span>Delete</span>
    </InlineButton>
  );
};

const RecordingsTable = ({ userId, sessionId }: { sessionId?: string; userId: string }) => {
  const firestore = useFirestore();
  const [recordings, loading, error] = useFirestoreCollectionData(
    query(
      collection(firestore, 'resources'),
      where('kind', '==', 'recording'),
      ...(sessionId ? [where('recording.sessionId', '==', sessionId)] : []),
      where('userId', '==', userId),
      orderBy('createdAt', 'desc'),
    ).withConverter(firestoreResourceRecordingConverter),
  );
  useErrorHandler(error);

  const recordingsSorted = useMemo(
    () => recordings?.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime()),
    [recordings],
  );

  return (
    <>
      {loading && <Spinner />}
      {!loading && error && <>Failed to load recordings</>}
      {!loading && !error && recordings && recordings.length === 0 && <>No recordings</>}
      {!loading && !error && recordings && recordings.length > 0 && (
        <Table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Duration</th>
              <th />
              <th />
              <th />
              <th />
            </tr>
          </thead>
          <colgroup>
            <col />
            <col />
            <col style={{ width: 32 }} />
            <col style={{ width: 80 }} />
            <col style={{ width: 130 }} />
            <col style={{ width: 70 }} />
          </colgroup>
          <tbody>
            {recordingsSorted.map((recording) => (
              <tr key={recording.id}>
                <td>{formatRecordingDate(recording.createdAt)}</td>
                <td>
                  {recording.status === 'ready' &&
                    ((duration) => `${duration} min${duration > 1 ? 's' : ''}`)(Math.ceil(recording.duration / 60))}
                  {recording.status === 'inprogress' && <>In progress</>}
                  {recording.status === 'finished' && 'Finished'}
                  {recording.status === 'processing' && 'Processing'}
                  {recording.status === 'error' && 'Error'}
                </td>
                <td>
                  {(recording.status === 'inprogress' ||
                    recording.status === 'finished' ||
                    recording.status === 'processing' ||
                    (recording.status === 'ready' && recording.hosting?.downloadsStatus === 'inprogress')) && (
                    <>
                      {' '}
                      <Tooltip
                        label={
                          recording.status === 'inprogress'
                            ? 'Recording in progress'
                            : recording.status === 'finished'
                            ? 'Finalizing the recording'
                            : recording.status === 'processing'
                            ? `Preparing for viewing${
                                recording.processingPercentComplete != null &&
                                typeof recording.processingPercentComplete === 'number'
                                  ? ` ${recording.processingPercentComplete.toFixed(0)}%`
                                  : ''
                              }`
                            : recording.hosting?.downloadsStatus === 'inprogress'
                            ? `Preparing MP4 for downloading${
                                recording.hosting?.downloadsPercentComplete != null &&
                                typeof recording.hosting?.downloadsPercentComplete === 'number'
                                  ? ` - ${recording.hosting?.downloadsPercentComplete.toFixed(0)}%`
                                  : ''
                              }`
                            : ''
                        }
                      >
                        <span>
                          <Spinner />
                        </span>
                      </Tooltip>
                    </>
                  )}
                </td>
                <td>{recording.status === 'ready' && <LinkStyled to={`/r/${recording.id}`}>View</LinkStyled>}</td>
                <td>
                  {recording.status === 'ready' && recording.hosting?.downloads && (
                    <AnchorStyled href={getDownloadUrl(recording)} target="_blank">
                      Download
                    </AnchorStyled>
                  )}
                </td>
                <td>{recording.status === 'ready' && <DeleteButton recording={recording} />}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default RecordingsTable;
