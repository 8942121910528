import React, { useContext } from 'react';
import useErrorHandler from 'web/hooks/useErrorHandler';
import themeClasses from 'web/styles/themeClasses.css';
import BookingPageContext from './BookingPageContext';
import CopyLink from './share/CopyLink';

const BookingPageLink = ({ path, active = true }: { path?: string; active?: boolean }) => {
  const [page, , error] = useContext(BookingPageContext);
  useErrorHandler(error);

  return (
    <>
      {error && <div>Cannot display the link at the moment.</div>}
      {!error && page && (
        <>
          <CopyLink to={`/${page.slug}${path || ''}`} active={active} />
          {page.customDomains && page.customDomains.length > 0 && (
            <div className={themeClasses({ paddingTop: 4 })}>
              <CopyLink to={`https://${page.customDomains[0]}${path || ''}`} external active={active} />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default BookingPageLink;
