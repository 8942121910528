import React, { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { formatDurationMins } from 'web/App/BookingPage/common';
import { Button, FormDescription, InlineButton, Input, Label, LabelText, Select } from 'web/components/elements';
import themeConstants from 'web/styles/themeConstants';
import { formatCurrencyAmount } from 'web/utils/currency';

const ServiceCard = styled.div`
  padding: 14px 20px;

  border: 1px solid ${themeConstants.borders.light};
  border-radius: ${themeConstants.borderRadius.sm};

  display: grid;
  grid-template: auto auto / auto;
  gap: 20px;
  ${themeConstants.media.sm} {
    grid-template: auto / 1fr 90px;
  }
`;

const ServiceInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ServiceCount = styled.div``;

const ServiceEdit = ({
  service,
  currency,
  defaultValue,
  onRemove,
  onChange,
}: {
  service: introwise.Service;
  currency: introwise.Currency;
  defaultValue: number;
  onRemove: () => void;
  onChange: (count: number) => void;
}) => {
  return (
    <ServiceCard>
      <ServiceInfo>
        <div>
          <b>{service.title}</b>
        </div>
        <div>
          {formatCurrencyAmount(service.price, currency)}
          <span>&nbsp;· </span>
          {formatDurationMins(service.duration)}
          <span>&nbsp;· </span>
          <InlineButton onClick={onRemove}>Remove</InlineButton>
        </div>
      </ServiceInfo>
      <ServiceCount>
        <Label>
          <LabelText>Sessions</LabelText>
          <Input defaultValue={defaultValue} type="number" min={0} onChange={(e) => onChange(Number(e.target.value))} />
        </Label>
      </ServiceCount>
    </ServiceCard>
  );
};

const ServiceAdd = ({
  personalSessions,
  services,
  onAdd,
}: {
  personalSessions: introwise.PackagePersonalSessions;
  services: { [id: string]: introwise.Service };
  onAdd: (serviceId: string) => void;
}) => {
  const availableServiceIds = useMemo(
    () => Object.keys(services).filter((serviceId) => !personalSessions[serviceId]),
    [services, personalSessions],
  );
  const [adding, setAdding] = useState(false);
  // const [selectedServiceId, setSelectedServiceId] = useState(() => availableServiceIds[0]);
  const selectRef = useRef<HTMLSelectElement>();
  return (
    <>
      {adding && (
        <ServiceCard>
          <div>
            <Label>
              <LabelText>Service</LabelText>
              <Select ref={selectRef}>
                {availableServiceIds.map((serviceId) => (
                  <option value={serviceId} key={serviceId}>
                    {services[serviceId].title}
                  </option>
                ))}
              </Select>
            </Label>
          </div>
          <div style={{ display: 'grid' }}>
            <Button
              secondary
              onClick={() => {
                onAdd(selectRef.current.value);
                setAdding(false);
              }}
            >
              Add
            </Button>
          </div>
        </ServiceCard>
      )}
      <div>
        {availableServiceIds.length > 0 && (
          <InlineButton onClick={() => setAdding((prev) => !prev)}>{adding ? 'Cancel' : 'Add a service'}</InlineButton>
        )}
        {availableServiceIds.length === 0 && <FormDescription>You don&apos;t have any more services</FormDescription>}
      </div>
    </>
  );
};

const PersonalSessionSelector = ({
  personalSessions,
  services,
  currency,
  onChange,
}: {
  personalSessions: introwise.PackagePersonalSessions;
  services: { [id: string]: introwise.Service };
  currency: introwise.Currency;
  onChange?: (value: introwise.PackagePersonalSessions) => void;
}) => {
  const onAdd = (id: string) => {
    onChange({
      ...personalSessions,
      [id]: {
        id,
        title: services[id].title,
        count: 0,
      },
    });
  };

  const onRemove = (id: string) => {
    const { [id]: remove, ...rest } = personalSessions;
    onChange(rest);
  };

  const onEdit = (id: string, value: number) => {
    const { [id]: edited, ...rest } = personalSessions;
    onChange({
      ...rest,
      [id]: {
        ...edited,
        count: value,
      },
    });
  };

  return (
    <div>
      <div style={{ display: 'grid', gap: 12 }}>
        {Object.keys(personalSessions).map((serviceId) => (
          <ServiceEdit
            service={services[serviceId]}
            currency={currency}
            defaultValue={personalSessions[serviceId].count}
            key={serviceId}
            onRemove={() => onRemove(serviceId)}
            onChange={(value) => onEdit(serviceId, value)}
          />
        ))}
        <ServiceAdd personalSessions={personalSessions} services={services} onAdd={onAdd} />
      </div>
    </div>
  );
};

export default PersonalSessionSelector;
