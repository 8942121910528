import pick from 'lodash/pick';
import React, { useMemo } from 'react';
import { RegisterOptions, useForm } from 'react-hook-form';
import {
  Button,
  Checkbox,
  FormDescription,
  FormError,
  FormFootnote,
  FormGroup,
  InlineButton,
  Input,
  Label,
  LabelText,
  LinkStyled,
  Select,
  TextArea,
} from 'web/components/elements';
import PriceInput from 'web/components/form-fields/PriceInput';
import Spinner from 'web/components/Spinner';
import {
  convertFromDecimal,
  convertToDecimal,
  exchangeRateToUsd,
  formatCurrencyDecimal,
  isZeroDecimal,
  minChargeAmount,
} from 'web/utils/currency';
import FormValueLength from 'web/components/form-fields/FormValueLength';
import themeClasses from 'web/styles/themeClasses.css';
import WorkflowsField from 'web/components/form-fields/WorkflowsField';
import LocationField from 'web/components/form-fields/LocationField';
import PaymentAccountField from 'web/components/form-fields/PaymentAccountField';

const maxTitleLength = 60;

const titleValidationRules: RegisterOptions = {
  maxLength: {
    value: maxTitleLength,
    message: `Too long, please limit the title to ${maxTitleLength} characters`,
  },
  required: 'Required',
  setValueAs: (v) => v.trim(),
};

const maxDescriptionLength = 750;

const descriptionValidationRules: RegisterOptions = {
  maxLength: {
    value: maxDescriptionLength,
    message: `Too long, please limit the description to ${maxDescriptionLength} characters`,
  },
  setValueAs: (v) => v.trim(),
};

const maxPriceUsdDefault = 1000;
const increasedPriceLimitMultiplier = 5;

const makePriceValidationRules = (currency: introwise.Currency, increasedPriceLimit?: boolean): RegisterOptions => {
  const minPriceValue = convertToDecimal(minChargeAmount[currency], currency);
  const maxPriceValue =
    maxPriceUsdDefault * exchangeRateToUsd[currency] * (increasedPriceLimit ? increasedPriceLimitMultiplier : 1);
  return {
    required: 'Required',
    validate: (strValue: string) => {
      const value = parseFloat(strValue);
      if (isNaN(value)) {
        return 'Must be a number';
      }
      if (value > maxPriceValue) {
        return `That's too much! The service price is limited to ${formatCurrencyDecimal(
          maxPriceValue,
          currency,
        )}. Contact us if you need this limit increased.`;
      }

      if (value < minPriceValue && value !== 0) {
        return `Must be at least ${formatCurrencyDecimal(minPriceValue, currency)}. Use ${formatCurrencyDecimal(
          0,
          currency,
        )} for a free service.`;
      }

      if (isZeroDecimal(currency) && !Number.isInteger(value)) {
        return `Must be a whole number, e.g. ${value.toFixed(0)}`;
      }
    },
  };
};

const groupSizeLimit = 42;
const groupSizeLimitLarge = 500;

const makeGroupSizeValidationRules = (largeSessionLimits?: boolean): RegisterOptions => ({
  required: 'Required',
  valueAsNumber: true,
  validate: {
    min: (value: number) => {
      if (value <= 0) {
        return `Group size must be greater than 0`;
      }
    },
    max: (value: number) => {
      const limit = largeSessionLimits ? groupSizeLimitLarge : groupSizeLimit;
      if (value > limit) {
        return `Group sessions are limited to ${limit} participants`;
      }
    },
  },
});

export type FormValues = Pick<
  introwise.Series,
  | 'title'
  | 'description'
  | 'duration'
  | 'price'
  | 'currency'
  | 'hidden'
  | 'groupSizeMax'
  | 'workflowsIds'
  | 'locationId'
  | 'paymentAccountId'
>;

type FormInternalValues = Omit<FormValues, 'duration' | 'price'> & {
  duration: string;
  price: string;
};

const defaultDuration = '30';
const defaultPrice = '';

const sanitizeDefaultValues = (val: introwise.Series): Omit<FormValues, 'duration' | 'price' | 'currency'> =>
  pick(val, ['title', 'description', 'groupSizeMax', 'hidden']);

const toFormValues = (series: introwise.Series, currency: introwise.Currency): FormInternalValues =>
  series
    ? {
        ...sanitizeDefaultValues(series),
        currency: series.currency,
        price: convertToDecimal(series.price, series.currency).toString(),
        duration: series.duration.toString(),
        hidden: !!series.hidden,
      }
    : {
        title: '',
        description: '',
        duration: defaultDuration,
        price: defaultPrice,
        currency,
        groupSizeMax: 6,
        hidden: false,
      };

const SeriesForm = ({
  currency,
  initialValues,
  onSubmit,
  onClone,
  onDelete,
  submitting,
  increasedPriceLimit,
  largeSessionLimits,
  withWorkflows,
}: {
  currency: introwise.Currency;
  initialValues?: introwise.Series;
  onSubmit: (values: FormValues) => void;
  onClone?: () => void;
  onDelete?: () => void;
  submitting: boolean;
  increasedPriceLimit?: boolean;
  largeSessionLimits?: boolean;
  withWorkflows?: boolean;
}) => {
  const priceValidationRules = useMemo(
    () => makePriceValidationRules(initialValues?.currency || currency, increasedPriceLimit),
    [currency, increasedPriceLimit, initialValues?.currency],
  );
  const groupSizeValidationRules = useMemo(
    () => makeGroupSizeValidationRules(largeSessionLimits),
    [largeSessionLimits],
  );

  const defaultValues = toFormValues(initialValues, currency);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormInternalValues>({
    defaultValues,
  });

  const convertAndSubmit = (values: FormInternalValues) =>
    onSubmit({
      ...values,
      duration: Number(values.duration),
      price: convertFromDecimal(parseFloat(values.price), values.currency),
      workflowsIds: values.workflowsIds || [],
    });

  return (
    <form onSubmit={handleSubmit(convertAndSubmit)}>
      <fieldset disabled={submitting} style={{ padding: 0 }}>
        <FormGroup>
          <Label>
            <LabelText>Title</LabelText>
            <Input
              {...register('title', titleValidationRules)}
              placeholder="Weekly Workshop, Office Hours, Group class, etc..."
              hasError={!!errors.title}
            />
          </Label>
          <FormFootnote>
            <FormValueLength control={control} name="title" maxLength={maxTitleLength} />
          </FormFootnote>
          <FormError>{errors.title ? errors.title.message : <>&nbsp;</>}</FormError>
          <div style={{ marginBottom: '-0.75em' }} />
        </FormGroup>
        <FormGroup>
          <Label>
            <TextArea
              {...register('description', descriptionValidationRules)}
              placeholder={`These are the group sessions that I'm offering...`}
              hasError={!!errors.description}
              rows={6}
            />
            <LabelText>Description (optional)</LabelText>
          </Label>
          <FormFootnote>
            <FormValueLength control={control} name="description" maxLength={maxDescriptionLength} />
          </FormFootnote>
          <FormError>{errors.description ? errors.description.message : <>&nbsp;</>}</FormError>
          <div style={{ marginBottom: '-0.75em' }} />
        </FormGroup>
        <FormGroup>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, minmax(0, 1fr))', columnGap: 20 }}>
            <div>
              <Label>
                <LabelText>Duration</LabelText>
                <Select {...register('duration', { required: true, valueAsNumber: true })} hasError={!!errors.duration}>
                  <option value="10">10 mins</option>
                  <option value="15">15 mins</option>
                  <option value="30">30 mins</option>
                  <option value="45">45 mins</option>
                  <option value="60">1 hour</option>
                  <option value="90">1.5 hours</option>
                  <option value="120">2 hours</option>
                </Select>
              </Label>
              {errors.duration && <FormError>{errors.duration.message}</FormError>}
            </div>
            <div>
              <Label>
                <LabelText>Price</LabelText>
                <PriceInput
                  {...register('price', priceValidationRules)}
                  hasError={!!errors.price}
                  currency={currency}
                />
              </Label>
              {errors.price && <FormError>{errors.price.message}</FormError>}
            </div>
          </div>
        </FormGroup>
        <FormGroup>
          <Label>
            <LabelText>Group size</LabelText>
            <Input
              type="number"
              {...register('groupSizeMax', groupSizeValidationRules)}
              hasError={!!errors.groupSizeMax}
            />
          </Label>
          {errors.groupSizeMax && <FormError>{errors.groupSizeMax.message}</FormError>}
        </FormGroup>
        <FormDescription>
          Default duration, price and group size of a regular session. You can override it for individual sessions.
        </FormDescription>
        <FormGroup>
          <Label>
            <LabelText>Location</LabelText>
            <LocationField
              initialValue={initialValues ? initialValues.locationId : null}
              control={control}
              name="locationId"
              disabled={submitting}
            />
          </Label>
          {errors.locationId && <FormError>{errors.locationId.message}</FormError>}
          <FormDescription>
            Where the session will be held. You can edit and add additional locations in the{' '}
            <LinkStyled to="/dashboard/calls/locations" target="_blank">
              calls settings
            </LinkStyled>
          </FormDescription>
        </FormGroup>
        <FormGroup>
          <Label>
            <LabelText>Payment processing</LabelText>
            <PaymentAccountField
              initialValue={initialValues ? initialValues.paymentAccountId : null}
              control={control}
              name="paymentAccountId"
              disabled={submitting}
            />
          </Label>
          {errors.paymentAccountId && <FormError>{errors.paymentAccountId.message}</FormError>}
          <FormDescription>
            How the payments should be processed. You can add payment accounts in the{' '}
            <LinkStyled to="/dashboard/payments/processing/accounts" target="_blank">
              payments settings
            </LinkStyled>
          </FormDescription>
        </FormGroup>
        <FormGroup style={{ ...(!withWorkflows && { display: 'none' }) }}>
          <Label>
            <LabelText>Workflows</LabelText>
            <WorkflowsField
              initialValue={initialValues ? initialValues.workflowsIds : null}
              control={control}
              name="workflowsIds"
              workflowType="session"
              disabled={submitting}
            />
          </Label>
          <FormDescription>Use workflows to automate repeating tasks, like reminders and follow-ups</FormDescription>
          {errors.workflowsIds && <FormError>{errors.workflowsIds.message}</FormError>}
        </FormGroup>
        <FormGroup>
          <Checkbox {...register('hidden')}>
            Hide on the booking page and only show to clients with a direct link
          </Checkbox>
        </FormGroup>
      </fieldset>
      <FormGroup className={themeClasses({ display: 'flex', justifyContent: 'space-between' })}>
        <Button primary type="submit" disabled={submitting}>
          {submitting && <Spinner />}
          <span>Save</span>
        </Button>
        {(onDelete || onClone) && (
          <div className={themeClasses({ display: 'flex', gap: 4 })}>
            {onClone && (
              <InlineButton onClick={onClone} disabled={submitting}>
                Clone
              </InlineButton>
            )}
            {onDelete && (
              <InlineButton onClick={onDelete} disabled={submitting}>
                Delete
              </InlineButton>
            )}
          </div>
        )}
      </FormGroup>
    </form>
  );
};

export default SeriesForm;
