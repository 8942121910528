import React from 'react';
import { RegisterOptions, useForm } from 'react-hook-form';
import { Button, FormDescription, FormError, FormGroup, Input, Label, LabelText } from './elements';
import Spinner from './Spinner';

const emailValidationRules: RegisterOptions = {
  required: 'Required',
  pattern: {
    value:
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
    message: 'Invalid email address',
  },
  setValueAs: (v) => v.trim(),
};

const EmailChangeForm = ({
  email,
  onSubmit,
  submitting,
  showSubmitButton,
}: {
  email: string;
  onSubmit: (values: { email: string }) => void;
  submitting: boolean;
  showSubmitButton: boolean;
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      email,
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset disabled={submitting}>
        <FormGroup>
          <Label>
            <LabelText>Email</LabelText>
            <Input {...register('email', emailValidationRules)} hasError={!!errors.email} autoComplete="email" />
          </Label>
          <FormDescription>
            This email address is used for logging you in and sending notifications to you.
          </FormDescription>
          {errors.email && <FormError>{errors.email.message}</FormError>}
        </FormGroup>
        {showSubmitButton && (
          <FormGroup>
            <Button type="submit">
              {submitting && <Spinner />}
              <span>Save</span>
            </Button>
          </FormGroup>
        )}
      </fieldset>
    </form>
  );
};

export default EmailChangeForm;
