import { FirebaseError } from 'firebase/app';
import { EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';
import { collection, doc, query, where, writeBatch } from 'firebase/firestore';
import { FunctionsError, httpsCallable } from 'firebase/functions';
import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import ColumnContainer from 'web/components/ColumnContainer';
import { Button, FormError, FormGroup, InlineButton, LinkButton, LinkStyled } from 'web/components/elements';
import ListUnstyled from 'web/components/elements/ListUnstyled';
import EmailChangeForm from 'web/components/EmailChangeForm';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import useFunctions from 'web/components/FirebaseContext/useFunctions';
import Flare from 'web/components/Flare';
import { NoticeCard } from 'web/components/NoticeCard';
import PasswordReauthForm from 'web/components/PasswordReauthForm';
import ProfileForm, { SubmitValues } from 'web/components/ProfileForm';
import ScreenTracker from 'web/components/ScreenTracker';
import Spinner from 'web/components/Spinner';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useErrorReporter from 'web/hooks/useErrorReporter';
import useFirestoreCollectionData from 'web/hooks/useFirestoreCollectionData';
import themeClasses from 'web/styles/themeClasses.css';
import { firestorePartnerConverter } from 'web/utils/convert';

const AffiliateDashboardButton = () => {
  const functions = useFunctions();
  const [loading, setLoading] = useState(false);
  const errorReporter = useErrorReporter();

  const openDashboard = async () => {
    const newTab = window.open('', '_blank');
    newTab.document.write('<html><head></head><body>Please wait while we redirect you</body></html>');
    newTab.document.close();
    setLoading(true);
    try {
      const res = await httpsCallable<undefined, { url: string }>(functions, 'rewardfulGetSsoLink')();
      if (!res.data.url) {
        throw new Error('No URL returned from Rewardful SSO function');
      }
      newTab.location.href = res.data.url;
    } catch (err) {
      errorReporter.report(new Error(`Failed to create Rewardful SSO link: ${err}`));
    }
    setLoading(false);
  };

  return (
    <Button variant="secondary" size="md" onClick={openDashboard}>
      <span>Open dashboard</span>
      {loading && <Spinner />}
    </Button>
  );
};

const ProfileInfo = () => {
  const { user, userData } = useContext(UserContext);
  const firestore = useFirestore();
  const errorReporter = useErrorReporter();
  const [submitting, setSubmitting] = useState(false);
  const [showSavedMark, setShowSavedMark] = useState(false);

  const save = async (values: SubmitValues) => {
    setSubmitting(true);
    try {
      const { firstName, lastName, photo, photoUpload, timezone, displayName } = values;
      const batch = writeBatch(firestore);
      batch.update(doc(firestore, 'users', user.uid), {
        firstName,
        lastName,
        photo,
        photoUpload,
        timezone,
        displayName,
      });
      if (userData.bookingPageId) {
        batch.update(doc(firestore, 'pages', userData.bookingPageId), {
          firstName,
          lastName,
          displayName,
          photo,
        });
      }
      await batch.commit();
      setShowSavedMark(true);
    } catch (err) {
      errorReporter.report(new Error(`Failed to save profile: ${err}`));
    }
    setSubmitting(false);
  };

  return (
    <WithHeaderContentColumn header="Profile" whiteBackground>
      <ProfileForm
        user={userData}
        submitting={submitting}
        onSubmit={save}
        showSavedMark={showSavedMark}
        setShowSavedMark={setShowSavedMark}
      />
    </WithHeaderContentColumn>
  );
};

const PartnerDashboardsLinks = () => {
  const { user } = useContext(UserContext);

  const firestore = useFirestore();

  const [partners, loading, error] = useFirestoreCollectionData(
    query(collection(firestore, 'partners'), where('adminsIds', 'array-contains', user.uid)).withConverter(
      firestorePartnerConverter,
    ),
  );

  useErrorHandler(error);

  return (
    <>
      {loading && <Spinner />}
      {error && <>Something went wrong</>}
      {!loading && !error && partners.length === 0 && (
        <>
          <p>You are not an admin of any partner accounts</p>
        </>
      )}
      {!loading && !error && partners.length > 0 && (
        <ListUnstyled>
          {partners.map((partner) => (
            <li key={partner.id}>
              <LinkStyled to={`/dashboard-partner/${partner.id}`}>{partner.name}</LinkStyled>
            </li>
          ))}
        </ListUnstyled>
      )}
    </>
  );
};

const EmailReauthButton = ({ onSuccess, onError }: { onSuccess: () => void; onError: (error: string) => void }) => {
  const errorReporter = useErrorReporter();
  const functions = useFunctions();
  const { user } = useContext(UserContext);
  const [submitting, setSubmitting] = useState(false);

  const sendLink = async () => {
    setSubmitting(true);
    const redirectUrl = window.location.href;
    try {
      await httpsCallable(functions, 'authSendSignInLink')({ email: user.email, redirectUrl });
      try {
        window.localStorage.setItem('introwise.signin.email', user.email);
      } catch (err) {
        // Do nothing
      }
      onSuccess();
    } catch (err) {
      onError('Something went wrong. Please try again');
      errorReporter.report(new Error(`Failed to request sending sign in email: ${err}`));
    }
    setSubmitting(false);
  };

  return (
    <Button size="md" onClick={sendLink} disabled={submitting}>
      {submitting && <Spinner />}
      <span>Continue with email</span>
    </Button>
  );
};

const PasswordReauth = ({ onSuccess, onError }: { onSuccess: () => void; onError: (error: string) => void }) => {
  const errorReporter = useErrorReporter();
  const { user } = useContext(UserContext);
  const [submitting, setSubmitting] = useState(false);

  const loginWithPassword = async ({ password }: { password: string }) => {
    setSubmitting(true);
    try {
      const credential = EmailAuthProvider.credential(user.email, password);
      await reauthenticateWithCredential(user, credential);
      onSuccess();
    } catch (err) {
      const errorCode = err.code as string;
      if (errorCode === 'auth/invalid-email') {
        onError('Invalid email');
      } else if (errorCode === 'auth/user-disabled') {
        onError('Cannot log you in');
      } else if (errorCode === 'auth/user-not-found') {
        onError("Introwise account with this email doesn't exist");
      } else if (errorCode === 'auth/wrong-password') {
        onError('Invalid password');
      } else if (errorCode === 'auth/too-many-requests') {
        onError('Too many attempts. Please try again later or reset your password');
      } else {
        onError('Something went wrong. Please try again');
        errorReporter.report(new Error(`Failed to log in with a password: ${err}`));
      }
    }
  };

  return <PasswordReauthForm onSubmit={loginWithPassword} submitting={submitting} />;
};

const ContactInfo = () => {
  const errorReporter = useErrorReporter();
  const { user, userData } = useContext(UserContext);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string>(null);
  const [requireSignIn, setRequireSignIn] = useState(false);
  const [emailInProgress, setEmailInProgress] = useState<string>(null);
  const [signInEmailSent, setSignInEmailSent] = useState(false);
  const [changeEmailSent, setChangeEmailSent] = useState(false);
  const [usePassword, setUsePassword] = useState(false);
  const functions = useFunctions();

  const save = async ({ email }: { email: string }) => {
    if (email === user.email) {
      return;
    }
    setSubmitting(true);
    setError(null);
    setEmailInProgress(email);
    setUsePassword(false);
    try {
      await httpsCallable(functions, 'authSendEmailChangeLink')({ email, redirectUrl: window.location.href });
      setChangeEmailSent(true);
      setRequireSignIn(false);
    } catch (err) {
      if (err instanceof FirebaseError) {
        if (err.code === 'functions/failed-precondition') {
          if (((err as FunctionsError).details as { code: string } | null)?.code === 'auth/requires-recent-login') {
            setRequireSignIn(true);
          } else {
            setRequireSignIn(false);
            setError(err.message);
          }
        } else {
          setRequireSignIn(false);
          setError(`Something went wrong. Please try again later.`);
          errorReporter.report(`Failed to request email change link: ${err}`);
        }
      } else {
        setRequireSignIn(false);
        setError(`Something went wrong. Please try again later.`);
        errorReporter.report(`Failed to request email change link: ${err}`);
      }
    }
    setSubmitting(false);
  };

  return (
    <WithHeaderContentColumn
      whiteBackground
      header="Contact info"
      extendedHeader={!userData.emailVerified && <Flare variant="error">Unverified</Flare>}
    >
      <EmailChangeForm
        email={user.email}
        onSubmit={save}
        submitting={submitting}
        showSubmitButton={!requireSignIn && !changeEmailSent}
      />
      {requireSignIn && !signInEmailSent && (
        <FormGroup>
          <NoticeCard>
            For security reasons, you must sign in again to change your email. Click the button below to receive a magic
            link or use your password.
          </NoticeCard>
          {usePassword ? (
            <div>
              <PasswordReauth onSuccess={() => save({ email: emailInProgress })} onError={(error) => setError(error)} />
            </div>
          ) : (
            <FormGroup className={themeClasses({ display: 'flex', gap: 4, alignItems: 'center' })}>
              <EmailReauthButton onSuccess={() => setSignInEmailSent(true)} onError={setError} />
              <InlineButton onClick={() => setUsePassword(true)}>Use a password instead</InlineButton>
            </FormGroup>
          )}
        </FormGroup>
      )}
      {requireSignIn && signInEmailSent && (
        <FormGroup>
          <NoticeCard>
            Check your <b>{user.email}</b> inbox &mdash; we have just sent you a link to log in.
          </NoticeCard>
        </FormGroup>
      )}
      {changeEmailSent && (
        <FormGroup>
          <NoticeCard>
            Check your <b>{emailInProgress}</b> inbox &mdash; we have just sent you a link to confirm your new email and
            complete the email change.
          </NoticeCard>
        </FormGroup>
      )}
      {error && (
        <FormGroup>
          <FormError>{error}</FormError>
        </FormGroup>
      )}
    </WithHeaderContentColumn>
  );
};

const SettingsProfile = () => {
  const { userData } = useContext(UserContext);

  return (
    <>
      <Helmet title="Edit profile" />
      <ScreenTracker screenName="SettingsProfile" />
      <ColumnContainer>
        <div>
          <ProfileInfo />
          <ContactInfo />
          <WithHeaderContentColumn header="Security and access" whiteBackground>
            <div className={themeClasses({ display: 'flex', gap: 4 })}>
              <LinkButton size="md" variant="secondary" to="/reset-password">
                Reset password
              </LinkButton>
              <LinkButton size="md" variant="secondary" to="/logout">
                Logout
              </LinkButton>
            </div>
          </WithHeaderContentColumn>
        </div>
        <div>
          {userData.partnerAdminIds && userData.partnerAdminIds.length > 0 && (
            <WithHeaderContentColumn header="Partner dashboard">
              <PartnerDashboardsLinks />
            </WithHeaderContentColumn>
          )}
          <WithHeaderContentColumn header="Support">
            <ListUnstyled>
              <li>
                <LinkStyled to="/faq">FAQs</LinkStyled>
              </li>
              <li>
                <LinkStyled to="/help">Help center</LinkStyled>
              </li>
              <li>
                <LinkStyled to="/pricing">Pricing &amp; Features</LinkStyled>
              </li>
              <li>
                <LinkStyled to="/contact">Contact us</LinkStyled>
              </li>
            </ListUnstyled>
          </WithHeaderContentColumn>
          {userData.rewardfulAffiliateId && (
            <WithHeaderContentColumn header="Affiliate program">
              <AffiliateDashboardButton />
            </WithHeaderContentColumn>
          )}
        </div>
      </ColumnContainer>
    </>
  );
};

export default SettingsProfile;
