import { collection, doc, getDoc, updateDoc, writeBatch } from 'firebase/firestore';
import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import BackLink from 'web/components/BackLink';
import ColumnContainer from 'web/components/ColumnContainer';
import { FormError, FormGroup } from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import useTracking from 'web/components/TrackingContext/useTracking';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import ClientContext from './ClientContext';
import ClientForm, { FormValues } from './ClientForm';

const DashboardClientsClientEdit = () => {
  const client = useContext(ClientContext);
  const tracking = useTracking();
  const { userData } = useContext(UserContext);
  const [submitting, setSubmitting] = useState(false);
  const firestore = useFirestore();
  const [error, setError] = useErrorStateHandler();
  const [emailInUse, setEmailInUse] = useState(false);
  const navigate = useNavigate();

  const edit = async (values: FormValues) => {
    setSubmitting(true);
    setError(null);
    setEmailInUse(false);
    try {
      const emailNormalized = values.email.toLowerCase();
      const previousEmailNormalized = client.primaryEmail.toLowerCase();

      if (emailNormalized !== previousEmailNormalized) {
        const existingEmail = await getDoc(
          doc(firestore, 'pages', userData.bookingPageId, 'clientsEmailMappings', emailNormalized),
        );
        if (existingEmail.exists()) {
          setEmailInUse(true);
          setSubmitting(false);
          return;
        }
      }

      const firstNameLower = values.firstName.toLowerCase();
      const lastNameLower = values.lastName.toLowerCase();

      const clientUpdate: introwise.FirestoreUpdateData<introwise.Client> = {
        firstName: values.firstName,
        lastName: values.lastName,
        firstNameLower,
        lastNameLower,
        firstNameChar: firstNameLower.charAt(0),
        lastNameChar: lastNameLower.charAt(0),
        searchPrefixes: Array.from(
          new Set([
            firstNameLower.charAt(0),
            lastNameLower.charAt(0),
            firstNameLower.slice(0, 2),
            lastNameLower.slice(0, 2),
            emailNormalized.charAt(0),
            emailNormalized.slice(0, 2),
          ]),
        ),
        primaryEmail: values.email,
        emails: [values.email],
        emailsNormalized: [emailNormalized],
      };
      const clientRef = doc(firestore, 'pages', userData.bookingPageId, 'clients', client.id);
      if (emailNormalized !== previousEmailNormalized) {
        const batch = writeBatch(firestore);
        batch.update(clientRef, clientUpdate);
        const clientEmailMappingsCol = collection(firestore, 'pages', userData.bookingPageId, 'clientsEmailMappings');
        batch.delete(doc(clientEmailMappingsCol, previousEmailNormalized));
        const emailMapping = {
          clientId: clientRef.id,
          emailNormalized,
        };
        batch.set(doc(clientEmailMappingsCol, emailNormalized), emailMapping);
        await batch.commit();
      } else {
        await updateDoc(clientRef, clientUpdate);
      }
      tracking.trackEvent('Client Updated');
      navigate('..', { replace: true });
    } catch (error) {
      setError(error);
      setSubmitting(false);
    }
  };
  return (
    <>
      <Helmet title="Edit a client" />
      <BackLink to=".." />
      <ColumnContainer>
        <WithHeaderContentColumn header="Edit a client" whiteBackground>
          <ClientForm client={client} onSubmit={edit} submitting={submitting} />
          {(error || emailInUse) && (
            <FormGroup>
              <FormError>
                {error && 'Something went wrong. Please try again later.'}
                {emailInUse && 'This email is already assigned to another client.'}
              </FormError>
            </FormGroup>
          )}
        </WithHeaderContentColumn>
        <div></div>
      </ColumnContainer>
    </>
  );
};

export default DashboardClientsClientEdit;
