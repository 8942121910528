import styled from 'styled-components';
import themeConstants from 'web/styles/themeConstants';
import themeVars from 'web/styles/themeVars.css';

export const SlotTitle = styled.div``;

export const SlotDescription = styled.div`
  ${themeConstants.typography.regular};
  font-weight: bold;
  font-size: 12px;
`;

export const SlotContainer = styled.div<{ readOnly?: boolean }>`
  display: flex;
  margin-top: 20px;
  padding: 8px;
  ${({ readOnly }) => readOnly && 'max-width: 450px;'};
  background-color: ${themeVars.backgrounds.sessionSlot};
  border-radius: ${themeConstants.borderRadius.sm};

  ${themeConstants.media.sm} {
    padding: 20px;
  }
`;
