import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import BackLink from 'web/components/BackLink';
import BookingPageContext from 'web/components/BookingPageContext';
import ColumnContainer from 'web/components/ColumnContainer';
import DiscountCodeForm, { DiscountCodeFormSubmitValues } from 'web/components/DiscountCodeForm';
import { FormError, FormGroup } from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import Spinner from 'web/components/Spinner';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import { UpgradePrompt } from './common';
import useFeatureIncludedCheck from '../../../../hooks/useFeatureIncludedCheck';

const SettingsPaymentsDiscountsCreateImpl = () => {
  const navigate = useNavigate();
  const firestore = useFirestore();
  const [submitting, setSubmitting] = useState(false);
  const [page, pageLoading, pageError] = useContext(BookingPageContext);
  useErrorHandler(pageError);
  const [submitError, setSubmitError] = useErrorStateHandler();

  const create = async (values: DiscountCodeFormSubmitValues) => {
    setSubmitting(true);
    setSubmitError(null);
    try {
      const codeLower = values.code.toLowerCase();
      const discountCode: introwise.FirestoreWriteData<introwise.DiscountCode> = {
        code: values.code,
        codeLower,
        valueType: values.valueType,
        value: values.value,
        createdAt: serverTimestamp(),
        active: true,
        timesRedeemed: 0,
        maxRedemptions: values.maxRedemptions,
      };
      await addDoc(collection(firestore, 'pages', page.id, 'discountCodes'), discountCode);
      navigate('..', { replace: true });
    } catch (err) {
      setSubmitError(err);
      setSubmitting(false);
    }
  };

  return (
    <>
      {!page && pageLoading && <Spinner />}
      {((!page && !pageLoading) || pageError) && <p>Something went wrong. Please try again later.</p>}
      {page && (
        <DiscountCodeForm
          onSubmit={create}
          bookingPageId={page.id}
          initialCurrency={page.currency}
          submitting={submitting}
        />
      )}
      {submitError && (
        <FormGroup>
          <FormError>Something went wrong. Please try again later.</FormError>
        </FormGroup>
      )}
    </>
  );
};

const SettingsPaymentsDiscountsCreate = () => {
  const featureIncluded = useFeatureIncludedCheck('discounts');
  return (
    <>
      <Helmet title="Create a discount code" />
      <BackLink to=".." />
      <ColumnContainer equal={!featureIncluded}>
        <WithHeaderContentColumn header="Create a discount code" whiteBackground>
          {featureIncluded ? <SettingsPaymentsDiscountsCreateImpl /> : <UpgradePrompt />}
        </WithHeaderContentColumn>
        <div></div>
      </ColumnContainer>
    </>
  );
};

export default SettingsPaymentsDiscountsCreate;
