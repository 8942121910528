import { doc } from 'firebase/firestore';
import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import Spinner from 'web/components/Spinner';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useFirestoreDocumentData from 'web/hooks/useFirestoreDocumentData';
import { firestoreGroupSessionConverter } from 'web/utils/convert';
import SeriesSessionContext from './SeriesSessionContext';

const SeriesSessionContainer = () => {
  const { sessionId } = useParams();
  const firestore = useFirestore();
  const [session, sessionLoading, sessionError] = useFirestoreDocumentData(
    doc(firestore, 'sessions', sessionId).withConverter(firestoreGroupSessionConverter),
  );
  useErrorHandler(sessionError);

  return (
    <>
      {sessionLoading && <Spinner />}
      {!sessionLoading && sessionError && <p>Something went wrong</p>}
      {!sessionLoading &&
        !sessionError &&
        (session ? (
          <SeriesSessionContext.Provider value={session}>
            <Outlet />
          </SeriesSessionContext.Provider>
        ) : (
          <p>Not found</p>
        ))}
    </>
  );
};

export default SeriesSessionContainer;
