import { useContext } from 'react';
import UserContext from 'web/components/UserContext';

const useFeatureExperimentalCheck = (feature?: keyof introwise.ExperimentalFeatureFlags) => {
  const { loading, userData } = useContext(UserContext);
  return (
    !loading &&
    (userData?.experimentalFeaturesEnabled === true ||
      (feature &&
        typeof userData?.experimentalFeaturesEnabled === 'object' &&
        userData?.experimentalFeaturesEnabled?.[feature]))
  );
};

export default useFeatureExperimentalCheck;
