import React from 'react';
import { Outlet } from 'react-router-dom';
import DashboardNav from 'web/components/DashboardNav';

const SettingsBookingPage = () => {
  return (
    <>
      <DashboardNav
        items={[
          { text: 'Page', to: '/dashboard/booking', end: true },
          { text: 'Branding', to: '/dashboard/booking/branding' },
          { text: 'Domain', to: '/dashboard/booking/domain' },
        ]}
      />
      <Outlet />
    </>
  );
};

export default SettingsBookingPage;
