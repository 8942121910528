import { doc, writeBatch } from 'firebase/firestore';
import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import BackLink from 'web/components/BackLink';
import ColumnContainer from 'web/components/ColumnContainer';
import { FormError, FormGroup } from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import ScreenTracker from 'web/components/ScreenTracker';
import useTracking from 'web/components/TrackingContext/useTracking';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import PersonalAvailabilityContext from './PersonalAvailabilityContext';
import PersonalAvailabilityForm, { FormValues } from './PersonalAvailabilityForm';

const PersonalAvailabilityRename = () => {
  const availability = useContext(PersonalAvailabilityContext);
  const { user } = useContext(UserContext);
  const firestore = useFirestore();
  const tracking = useTracking();
  const [error, setError] = useErrorStateHandler();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);

  const save = async (values: FormValues) => {
    setSubmitting(true);
    try {
      const batch = writeBatch(firestore);
      const userRef = doc(firestore, 'users', user.uid);
      const id = availability.id;
      const availabilityRef = doc(userRef, 'cronofy', id);

      const availabilityUpdate: introwise.FirestoreUpdateData<introwise.Availability> = {
        name: values.name,
      };
      const userUpdate = {
        [`availabilities.${id}.name`]: availabilityUpdate.name,
      };

      batch.update(availabilityRef, availabilityUpdate);
      batch.update(userRef, userUpdate);

      await batch.commit();
      tracking.trackEvent('Availability Updated');
      navigate(`/dashboard/scheduling/availabilities/${id}`, { replace: true });
    } catch (err) {
      setError(err);
      setSubmitting(false);
    }
  };

  return (
    <>
      <Helmet title="Rename availability" />
      <ScreenTracker screenName="SettingsAvailabilityPersonalRename" />
      <BackLink to=".." />
      <ColumnContainer>
        <div>
          <WithHeaderContentColumn header="Rename availability" whiteBackground>
            <PersonalAvailabilityForm
              defaultValues={{ name: availability.name }}
              onSubmit={save}
              submitting={submitting}
            />
            {error && (
              <FormGroup>
                <FormError>Failed to rename this availability. Please try again later.</FormError>
              </FormGroup>
            )}
          </WithHeaderContentColumn>
        </div>
        <div></div>
      </ColumnContainer>
    </>
  );
};

export default PersonalAvailabilityRename;
