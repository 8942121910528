import React from 'react';
import ContentColumn from 'web/components/ContentColumn';
import UpgradeButton from 'web/components/UpgradeButton';
import themeClasses from 'web/styles/themeClasses.css';
import { formatCurrencyAmount } from 'web/utils/currency';
import { featuresUpgradePath, pricingPlans } from 'web/utils/pricingPlans';

export const formatDiscountValue = (code: introwise.DiscountCodeFixed | introwise.DiscountCodePercentage) =>
  code.valueType === 'fixed'
    ? `${formatCurrencyAmount(code.value.amountOff, code.value.currency)} off`
    : `${code.value.percentOff}% off`;

const upgradePath = pricingPlans[featuresUpgradePath['discounts']];
export const UpgradePrompt = () => (
  <>
    <p className={themeClasses({ marginTop: 0 })}>Discount codes feature is not available on your plan.</p>
    <p>
      Upgrade to the <b>{upgradePath.name}</b> plan to get access to the discount codes feature and{' '}
      <b>{upgradePath.applicationFeePercentage * 100}%</b> Introwise commission.
    </p>
    <div>
      <UpgradeButton to="/dashboard/account/billing/subscription">Upgrade to {upgradePath.name}</UpgradeButton>
    </div>
  </>
);

export const UprgadeColumn = () => (
  <div>
    <ContentColumn whiteBackground>
      <h4 className={themeClasses({ marginTop: 0 })}>Discount codes and less commission</h4>
      <UpgradePrompt />
    </ContentColumn>
  </div>
);
