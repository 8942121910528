import React from 'react';
import { Outlet } from 'react-router-dom';
import DashboardNav from 'web/components/DashboardNav';

const SettingsAccount = () => {
  return (
    <>
      <DashboardNav
        items={[
          { text: 'Account', to: '/dashboard/account', end: true },
          { text: 'Billing', to: '/dashboard/account/billing' },
        ]}
      />
      <Outlet />
    </>
  );
};

export default SettingsAccount;
