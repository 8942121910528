import React, { useContext } from 'react';
import BackLink from 'web/components/BackLink';
import ColumnContainer from 'web/components/ColumnContainer';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import PayPalConnectForm from 'web/components/PayPalConnectForm';

const SettingsPaymentsAccountsPayPal = () => {
  const { userData } = useContext(UserContext);
  const connected = !!userData.paypalAccountEmail;
  const verified = userData.paypalAccountVerified;
  return (
    <>
      <BackLink to="../.." />
      <ColumnContainer>
        <WithHeaderContentColumn header="PayPal account" whiteBackground>
          {!connected && <PayPalConnectForm />}
          {connected && verified && <>You&apos;ve succesfully connected a PayPal account.</>}
          {connected && !verified && (
            <>
              We&apos;ve sent you an email to <b>{userData.paypalAccountEmail}</b>. Please click the link in that email
              to verify your PayPal account connection.
            </>
          )}
        </WithHeaderContentColumn>
      </ColumnContainer>
    </>
  );
};

export default SettingsPaymentsAccountsPayPal;
