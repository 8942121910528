import pick from 'lodash/pick';
import React from 'react';
import { Controller, RegisterOptions, useForm } from 'react-hook-form';
import PictureUpload from 'web/components/form-fields/PictureUpload';
import SavedMark from 'web/components/SavedMark';
import ExpertTimeZoneSelect from 'web/components/timezone/ExpertTimeZoneSelect';
import { Button, FormError, FormGroup, Input, Label, LabelText } from '../components/elements';
import FormDescription from './elements/FormDescription';
import Spinner from './Spinner';

const maxNameLength = 40;

const nameValidationRules: RegisterOptions = {
  required: 'Required',
  minLength: {
    value: 1,
    message: 'Too short, please use at least 1 character',
  },
  maxLength: {
    value: maxNameLength,
    message: `Too long, please limit the name to ${maxNameLength} characters`,
  },
  setValueAs: (v) => v.trim(),
};

const maxDisplayNameLength = 40;

const displayNameValidationRules: RegisterOptions = {
  required: 'Required',
  minLength: {
    value: 2,
    message: 'Too short, please use at least 2 characters',
  },
  maxLength: {
    value: maxDisplayNameLength,
    message: `Too long, please limit the name to ${maxDisplayNameLength} characters`,
  },
  setValueAs: (v) => v.trim(),
};

const photoValidationRules: RegisterOptions = {
  required: 'Required',
};

const timeZoneValidationRules: RegisterOptions = {
  required: 'Required',
};

type CommonValues = Pick<introwise.User, 'firstName' | 'lastName' | 'timezone' | 'photo'> &
  Partial<Pick<introwise.User, 'displayName'>>;
type ProfileFormValues = CommonValues;
export type SubmitValues = CommonValues & Pick<introwise.User, 'photoUpload'>;

const sanitizeDefaultValues = (val: introwise.User): ProfileFormValues =>
  pick(val, ['photo', 'firstName', 'lastName', 'timezone']);

// TODO: discard non-form values
const toFormValues = (user: introwise.User): ProfileFormValues => ({
  ...(user
    ? sanitizeDefaultValues(user)
    : {
        firstName: '',
        lastName: '',
        photo: null,
      }),
});

const toSubmitValues = (values: ProfileFormValues): SubmitValues => ({
  ...values,
  photoUpload: null,
  timezone: values.timezone,
});

const ProfileForm = ({
  user,
  submitting,
  onSubmit,
  onChange,
  showSavedMark,
  setShowSavedMark,
}: {
  user: introwise.User;
  submitting: boolean;
  onSubmit: (values: SubmitValues) => void;
  onChange?: () => void;
  showSavedMark?: boolean;
  setShowSavedMark?: (showSavedMark: boolean) => void;
}) => {
  const defaultValues = toFormValues(user);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<ProfileFormValues>({
    defaultValues,
  });

  const avatarSize = {
    width: 200,
    height: 200,
  };

  const convertAndSubmit = (values: ProfileFormValues) => {
    onSubmit(toSubmitValues(values));
  };

  return (
    <form onSubmit={handleSubmit(convertAndSubmit)} onChange={onChange}>
      <fieldset disabled={submitting}>
        <FormGroup>
          <Controller
            name="photo"
            control={control}
            rules={photoValidationRules}
            render={({ field: { value, onChange } }) => (
              <PictureUpload
                value={{ url: value }}
                onChange={({ url }) => onChange(url)}
                customPrefix="userpic-"
                size={avatarSize}
                circle
              />
            )}
          />
          {errors.photo && <FormError>{errors.photo.message}</FormError>}
        </FormGroup>

        <FormGroup>
          <Label>
            <LabelText>Display name</LabelText>
            <Input
              {...register('displayName', { ...displayNameValidationRules, shouldUnregister: true })}
              defaultValue={user.displayName}
              placeholder="Mary Doe Inc."
              hasError={!!errors.displayName}
            />
          </Label>
          <FormDescription>
            Publicly visible name for your clients, e.g. your full name, or your business name.
          </FormDescription>
          {errors.displayName && <FormError>{errors.displayName.message}</FormError>}
        </FormGroup>

        <FormGroup>
          <Label>
            <Input {...register('firstName', nameValidationRules)} placeholder="Mary" hasError={!!errors.firstName} />
            <LabelText>First name</LabelText>
          </Label>
          {errors.firstName && <FormError>{errors.firstName.message}</FormError>}
        </FormGroup>

        <FormGroup>
          <Label>
            <Input {...register('lastName', nameValidationRules)} placeholder="Doe" hasError={!!errors.lastName} />
            <LabelText>Last name</LabelText>
          </Label>
          {errors.lastName && <FormError>{errors.lastName.message}</FormError>}
        </FormGroup>

        <FormGroup>
          <Label>
            <ExpertTimeZoneSelect {...register('timezone', timeZoneValidationRules)} />
            <LabelText>Timezone for notifications</LabelText>
          </Label>
          <FormDescription>
            We use this timezone to show the correct time in email notifications and reminders.
          </FormDescription>
        </FormGroup>
      </fieldset>
      <FormGroup style={{ display: 'flex' }}>
        <Button primary type="submit" disabled={submitting}>
          {submitting && <Spinner />}
          <span>Save</span>
        </Button>
        <SavedMark animate={showSavedMark} onAnimationEnd={() => setShowSavedMark(false)} />
      </FormGroup>
    </form>
  );
};

export default ProfileForm;
