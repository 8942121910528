import { deleteField, doc, writeBatch } from 'firebase/firestore';
import React, { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BackLink from 'web/components/BackLink';
import BookingPageContext from 'web/components/BookingPageContext';
import BookingPageLink from 'web/components/BookingPageLink';
import ColumnContainer from 'web/components/ColumnContainer';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import Meta from 'web/components/Meta';
import PackageForm, { SubmitValues } from 'web/components/packages/PackageForm';
import useTracking from 'web/components/TrackingContext/useTracking';
import UpgradeButton from 'web/components/UpgradeButton';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import { useDocumentData } from 'web/hooks/firebase';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import useFeatureExperimentalCheck from 'web/hooks/useFeatureExperimentalCheck';
import useFeatureIncludedCheck from 'web/hooks/useFeatureIncludedCheck';
import useFeatureOverrideCheck from 'web/hooks/useFeatureOverrideCheck';
import themeClasses from 'web/styles/themeClasses.css';
import { firestorePackageConverter } from 'web/utils/convert';
import { featuresUpgradePath, pricingPlans } from 'web/utils/pricingPlans';

const upgradePath = pricingPlans[featuresUpgradePath['timeLimitedPackages']];

const PackageEdit = () => {
  const firestore = useFirestore();
  const tracking = useTracking();
  const { userData } = useContext(UserContext);
  const { packageId } = useParams();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useErrorStateHandler();
  const navigate = useNavigate();
  const increasedPriceLimit = useFeatureOverrideCheck('increasedPriceLimit');
  const timeLimitingEnabled = useFeatureIncludedCheck('timeLimitedPackages');
  const richTextDescriptionEnabled = useFeatureExperimentalCheck('richTextDescription');
  const paymentPlanEnabled = useFeatureIncludedCheck('paymentPlans');

  const pageRef = doc(firestore, 'pages', userData.bookingPageId);
  const packageRef = doc(pageRef, 'packages', packageId);

  const [page, pageLoading, pageError] = useContext(BookingPageContext);
  useErrorHandler(pageError);

  const [pack, packLoading, packError] = useDocumentData(
    packageId && packageRef.withConverter(firestorePackageConverter),
  );
  useErrorHandler(packError);

  const loading = packLoading || pageLoading;

  const edit = async (values: SubmitValues) => {
    const {
      title,
      description,
      personalSessions,
      groupSessionSeries,
      price,
      hidden,
      expiryDays,
      paymentPlan,
      paymentAccountId,
    } = values;
    const editedPackage: introwise.FirestoreUpdateData<introwise.Package> = {
      title,
      description,
      personalSessions,
      groupSessionSeries,
      price,
      paymentAccountId,
      ...(expiryDays ? { expiryDays } : { expiryDays: deleteField() }),
      ...(hidden != null && { hidden: !!hidden }),
      ...(paymentPlan ? { paymentPlan } : { paymentPlan: deleteField() }),
    };

    const pageUpdate: introwise.FirestoreUpdateData<introwise.Page> = Object.fromEntries(
      Object.keys(editedPackage).map((key) => [`packages.${packageId}.${key}`, editedPackage[key]]),
    );

    const batch = writeBatch(firestore);
    batch.update(pageRef, pageUpdate);
    batch.update(packageRef, editedPackage);

    setSubmitting(true);
    try {
      await batch.commit();
      tracking.trackEvent('Package Updated');
      navigate('/dashboard/scheduling/packages');
    } catch (err) {
      setError(err);
      setSubmitting(false);
    }
  };

  const remove = async () => {
    // TODO add approved page
    const batch = writeBatch(firestore);
    const pageUpdate: introwise.FirestoreUpdateData<introwise.Page> = {
      [`packages.${packageId}`]: deleteField(),
    };
    batch.update(pageRef, pageUpdate);
    batch.update(packageRef, { isDeleted: true });
    await batch.commit();
    tracking.trackEvent('Package Removed');
    navigate('/dashboard/scheduling');
  };

  const clone = () => navigate(`/dashboard/scheduling/packages/create`, { state: { package: pack } });

  if (packError || pageError) return <p>Failed to load package</p>;

  return (
    <>
      <Meta title="Edit a package" />
      <BackLink to="/dashboard/scheduling" />
      <ColumnContainer>
        <WithHeaderContentColumn header="Edit a package" whiteBackground>
          {loading && <p>Loading...</p>}
          {!loading && (
            <PackageForm
              currency={page.currency}
              pack={pack}
              services={page.services}
              series={page.series}
              onClone={clone}
              onDelete={remove}
              onSubmit={edit}
              submitting={submitting}
              increasedPriceLimit={increasedPriceLimit}
              timeLimitingEnabled={timeLimitingEnabled}
              richTextDescriptionEnabled={richTextDescriptionEnabled}
              paymentPlanEnabled={paymentPlanEnabled}
            />
          )}
          {error && <p>{error.message}</p>}
        </WithHeaderContentColumn>
        <div>
          <WithHeaderContentColumn header="Link to this package">
            <BookingPageLink path={`/packages/${packageId}`} />
          </WithHeaderContentColumn>
          {!timeLimitingEnabled && (
            <WithHeaderContentColumn header="Time-limited packages" whiteBackground>
              <p className={themeClasses({ marginTop: 0 })}>
                Upgrade to the <b>{upgradePath.name}</b> plan to set package expiration rules and get access to lower{' '}
                <b>{upgradePath.applicationFeePercentage * 100}%</b> Introwise commission.
              </p>
              <div>
                <UpgradeButton to="/dashboard/payments/subscription">Upgrade to {upgradePath.name}</UpgradeButton>
              </div>
            </WithHeaderContentColumn>
          )}
        </div>
      </ColumnContainer>
    </>
  );
};

export default PackageEdit;
