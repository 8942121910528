import React, { useContext } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import BookingPageContext from 'web/components/BookingPageContext';
import Spinner from 'web/components/Spinner';
import useErrorHandler from 'web/hooks/useErrorHandler';
import SeriesContext from './SeriesContext';

const SeriesContainer = () => {
  const { seriesId } = useParams();
  const [page, pageLoading, pageError] = useContext(BookingPageContext);
  useErrorHandler(pageError);

  const series = page?.series[seriesId];

  return (
    <>
      {pageLoading && <Spinner />}
      {!pageLoading && (pageError || !page) && <p>Something went wrong</p>}
      {!pageLoading &&
        !pageError &&
        page &&
        (series ? (
          <SeriesContext.Provider value={series}>
            <Outlet />
          </SeriesContext.Provider>
        ) : (
          <p>Not found</p>
        ))}
    </>
  );
};

export default SeriesContainer;
