import React from 'react';
import { Label, LabelText } from 'web/components/elements';

const WithOptionalLabel = ({ label, children }: { label?: string; children: React.ReactNode }) =>
  label ? (
    <Label>
      <LabelText>{label}</LabelText>
      {children}
    </Label>
  ) : (
    <>{children}</>
  );

export default WithOptionalLabel;
