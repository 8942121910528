import React from 'react';
import styled from 'styled-components';
import themeConstants from 'web/styles/themeConstants';
import themeVars from 'web/styles/themeVars.css';

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 0;
`;

const NotificationMark = styled.span`
  display: ${({ notification }: { notification: boolean }) => (notification ? 'initial' : 'none')};
  position: absolute;
  top: -6px;
  right: -12px;

  background-color: ${themeVars.color.notification};
  border: 3px solid #ffffff;
  border-radius: 50%;

  padding: 3px;

  ${themeConstants.media.md} {
    padding: 5px;
  }
`;

type Props = {
  children: React.ReactNode;
  notification: boolean;
  className?: string;
};

const WithNotificationContainer = ({ children, notification, className }: Props) => (
  <Container className={className}>
    {children}
    <NotificationMark notification={notification} />
  </Container>
);

export default WithNotificationContainer;
