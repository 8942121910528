import React from 'react';
import { Outlet } from 'react-router-dom';
import DashboardNav from 'web/components/DashboardNav';

const SettingsPayments = () => (
  <>
    <DashboardNav
      items={[
        { text: 'Payments', to: '/dashboard/payments/processing' },
        { text: 'Discounts', to: '/dashboard/payments/discounts' },
      ]}
    />{' '}
    <Outlet />
  </>
);

export default SettingsPayments;
