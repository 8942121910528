import { httpsCallable } from 'firebase/functions';
import React, { useContext, useState } from 'react';
import { Navigate } from 'react-router-dom';
import BackLink from 'web/components/BackLink';
import ColumnContainer from 'web/components/ColumnContainer';
import { Button, FormError, FormGroup, LinkStyled } from 'web/components/elements';
import useFunctions from 'web/components/FirebaseContext/useFunctions';
import Spinner from 'web/components/Spinner';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import { defaultPricingPlanId, paidFeaturesNames, PricingPlanId, pricingPlans } from 'web/utils/pricingPlans';
import { formatBillDate } from '../common';

const defaultPricingPlan = pricingPlans[defaultPricingPlanId];

const SubscriptionCancel = () => {
  const functions = useFunctions();
  const { userData } = useContext(UserContext);
  const { stripeSubscription } = userData;
  // We save initial state as the subscription may change after cancellation
  const [initialStripeSubscription] = useState(stripeSubscription);
  const [submitting, setSubmitting] = useState(false);
  const [cancelled, setCancelled] = useState(initialStripeSubscription?.cancelAtPeriodEnd);
  const [error, setError] = useErrorStateHandler();

  const stripeSubscriptionId = initialStripeSubscription?.id;

  if (!stripeSubscriptionId) {
    return <Navigate to="/dashboard/account/billing" />;
  }

  const immediately =
    !initialStripeSubscription?.cancelAtPeriodEnd &&
    (initialStripeSubscription?.status === 'incomplete' ||
      initialStripeSubscription?.status === 'past_due' ||
      initialStripeSubscription?.status === 'unpaid');

  const trialing = initialStripeSubscription?.status === 'trialing';

  const pricingPlan = pricingPlans[userData.pricingPlanId as PricingPlanId];

  const cancel = async () => {
    setSubmitting(true);
    try {
      await httpsCallable(
        functions,
        'stripeCancelSubscription',
      )({
        stripeSubscriptionId,
        immediately,
      });
      setCancelled(true);
    } catch (err) {
      setError(err);
    }
    setSubmitting(false);
  };

  return (
    <>
      <BackLink to="/dashboard/account/billing" />
      <ColumnContainer equal>
        <WithHeaderContentColumn header="Cancel subscription" whiteBackground>
          {cancelled && immediately && (
            <p>
              Your subscription has been cancelled effective immediately. Your new pricing plan is{' '}
              <b>{pricingPlan.name} plan</b>.
            </p>
          )}
          {cancelled && !immediately && (
            <>
              <p>
                Your subscription will be cancelled on {formatBillDate(initialStripeSubscription.currentPeriodEnd)}.
              </p>
              {!trialing && (
                <p>
                  If you fall into our 30-days money back guarantee and didn&apos;t find Introwise useful, please{' '}
                  <LinkStyled to="/contact">let us now</LinkStyled> and we&apos;ll refund the charged amount.
                </p>
              )}
              <p>
                You can help us a lot by sharing what didn&apos;t work for you and how can we improve the product. Our
                team cares deeply about helping experts succeed and your feedback will help us do it better.
              </p>
            </>
          )}
          {!cancelled && (
            <>
              <p>
                You&apos;re about to cancel your <b>{pricingPlan.name} plan</b>.
              </p>
              {immediately && (
                <p>
                  Your plan will be changed to <p>{defaultPricingPlan.name}</p> immediately and no further charges will
                  be made.
                </p>
              )}
              {!immediately && (
                <p>
                  At the end of your {trialing ? 'trial period' : 'current billing cycle'} on{' '}
                  {formatBillDate(initialStripeSubscription.currentPeriodEnd)} you plan will automatically be changed to{' '}
                  <b>{defaultPricingPlan.name}</b>.
                </p>
              )}
              <p>
                All future paid sessions will be subject to {defaultPricingPlan.applicationFeePercentage * 100}%
                Introwise commission and you will loose access to these features:
              </p>
              <ul>
                {Object.keys(pricingPlan.features).map((feature) => (
                  <li key={feature}>{paidFeaturesNames[feature as keyof introwise.PaidFeaturesFlags]}</li>
                ))}
              </ul>
              <FormGroup>
                <Button primary onClick={cancel} disabled={submitting}>
                  {submitting && <Spinner />}
                  <span>Cancel subscription</span>
                </Button>
              </FormGroup>
              {error && (
                <FormGroup>
                  <FormError>{`${error}`}</FormError>
                </FormGroup>
              )}
            </>
          )}
        </WithHeaderContentColumn>
      </ColumnContainer>
    </>
  );
};

export default SubscriptionCancel;
