import React from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  FormDescription,
  FormError,
  FormGroup,
  InlineButton,
  Input,
  Label,
  LabelText,
} from 'web/components/elements';
import Spinner from 'web/components/Spinner';
import themeClasses from 'web/styles/themeClasses.css';

const LocationForm = ({
  initialValues,
  onSubmit,
  onDelete,
  submitting,
}: {
  initialValues?: { name: string; location: string };
  onSubmit: (data: { name: string; location: string }) => void;
  onDelete?: () => void;
  submitting?: boolean;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues || {
      name: '',
      location: '',
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset disabled={submitting}>
        <FormGroup>
          <Label>
            <LabelText>Name</LabelText>
            <Input
              {...register('name', {
                required: 'Name is required',
                maxLength: {
                  value: 60,
                  message: 'Too long, please limit the name to 60 characters',
                },
              })}
              placeholder="Virtual meeting room, My studio, etc."
            />
          </Label>
          <FormDescription>Give your location a name</FormDescription>
          {errors.name && <FormError>{errors.name.message}</FormError>}
        </FormGroup>
        <FormGroup>
          <Label>
            <LabelText>Location</LabelText>
            <Input
              {...register('location', {
                required: 'Location is required',
                maxLength: {
                  value: 2000,
                  message: 'Too long, please limit the location to 2000 characters',
                },
              })}
              placeholder="https://meet.jit.si/my-virtual-room"
            />
          </Label>
          <FormDescription>A URL for a virtual meeting, or a physical address</FormDescription>
          {errors.location && <FormError>{errors.location.message}</FormError>}
        </FormGroup>
        <FormGroup className={themeClasses({ display: 'flex', justifyContent: 'space-between' })}>
          <Button primary type="submit" disabled={submitting}>
            {submitting && <Spinner />}
            <span>Save</span>
          </Button>
          {onDelete && (
            <div className={themeClasses({ display: 'flex', gap: 4 })}>
              {onDelete && (
                <InlineButton onClick={onDelete} disabled={submitting}>
                  Delete
                </InlineButton>
              )}
            </div>
          )}
        </FormGroup>
      </fieldset>
    </form>
  );
};

export default LocationForm;
