import React, { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, FormGroup } from 'web/components/elements';
import Spinner from 'web/components/Spinner';
import useSlugUniquenessValidator from 'web/hooks/useSlugUniquenessValidator';
import BookingPageFormPartialSlug from './BookingPageFormPartialSlug';

type CommonValues = Pick<introwise.Page, 'slug'>;
type SlugFormValues = CommonValues;

export type SubmitValues = CommonValues;

const BookingPageFormSlug = ({
  page,
  onSubmit,
  submitting,
}: {
  page: introwise.Page;
  onSubmit: (page: SubmitValues) => void;
  submitting: boolean;
}) => {
  const initialSlug = page.slug;

  const slugValidator = useSlugUniquenessValidator(initialSlug);

  const methods = useForm<SlugFormValues>({
    defaultValues: {
      slug: initialSlug,
    },
  });

  const { setError, formState, handleSubmit } = methods;

  const { isSubmitting, isDirty } = formState;

  const busy = submitting || isSubmitting;

  const onSubmitInternal = useCallback(
    async (values: SlugFormValues) => {
      try {
        // All the Schema validations should pass at this point
        // Check that the URL wasn't taken while it was cached
        const slugUnique = await slugValidator(values.slug, false, false);
        if (!slugUnique) {
          setError('slug', { type: 'unique', message: `This URL is already taken` });
          return;
        }

        const submitValues: SubmitValues = {
          slug: values.slug,
        };
        onSubmit(submitValues);
      } catch (err) {
        setError('slug', {
          type: 'unique',
          message: `Failed to check URL uniqueness: ${err.code === 'unavailable' ? `You're offline` : err}`,
        });
        return;
      }
    },
    [onSubmit, setError, slugValidator],
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmitInternal)}>
        <fieldset disabled={submitting}>
          <BookingPageFormPartialSlug initialSlug={initialSlug} />
        </fieldset>
        <FormGroup>
          <Button primary type="submit" disabled={busy || !isDirty}>
            {busy && <Spinner />}
            <span>Save</span>
          </Button>
        </FormGroup>
      </form>
    </FormProvider>
  );
};

export default BookingPageFormSlug;
