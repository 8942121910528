import { assignInlineVars } from '@vanilla-extract/dynamic';
import React, { ReactNode, useContext, useMemo } from 'react';
import mintTheme from 'web/styles/mintTheme';
import { themeClass as mintThemeClass } from 'web/styles/mintTheme.css';
import { Theme } from 'web/styles/styles';
import themeVars from 'web/styles/themeVars.css';
import joinClassNames from 'web/utils/joinClassNames';
import ThemeContext from './ThemeContext';

const MintThemeProvider = ({ children }: { children: ReactNode }) => {
  const [themeClass, themeStyle] = useContext(ThemeContext);
  const newThemeStyle = useMemo(() => assignInlineVars(themeVars, mintTheme), []);
  const newTheme = useMemo(
    () =>
      [
        joinClassNames(themeClass, mintThemeClass),
        { ...themeStyle, ...newThemeStyle },
        mintTheme as unknown as Theme,
      ] as const,
    [newThemeStyle, themeClass, themeStyle],
  );
  return (
    <ThemeContext.Provider value={newTheme}>
      <div className={mintThemeClass}>{children}</div>
    </ThemeContext.Provider>
  );
};

export default MintThemeProvider;
