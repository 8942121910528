import { CardElement, useElements } from '@stripe/react-stripe-js';
import React, { useContext, useState } from 'react';
import { Navigate } from 'react-router-dom';
import BackLink from 'web/components/BackLink';
import ColumnContainer from 'web/components/ColumnContainer';
import { Button, FormError, FormGroup, InlineButton } from 'web/components/elements';
import CardElementInput from 'web/components/form-fields/CardElementInput';
import Spinner from 'web/components/Spinner';
import { subscriptionUpdateMachine } from 'web/components/subscription/subscriptionMachine';
import useSubscriptionMachine from 'web/components/subscription/useSubscriptionMachine';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import { formatCard } from '../common';

const SubscriptionUpdateImpl = ({ subscriptionId }: { subscriptionId: string }) => {
  const { userData } = useContext(UserContext);
  const { stripeSubscription, stripeCard } = userData;
  const [state, send] = useSubscriptionMachine(subscriptionUpdateMachine, { stripeSubscriptionId: subscriptionId });
  const elements = useElements();

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { matches } = state;

  const error = state.context.error;
  const cardComplete = state.context.cardComplete;

  const ready = !matches('initializing');
  const busy = ['creating', 'updating', 'confirming', 'activating'].some(matches);
  const buttonDisabled = !ready || busy || !cardComplete;

  if (elements?.getElement(CardElement)) {
    const cardDisabled = ['creating', 'updating', 'confirming'].some(matches);
    const cardElement = elements.getElement(CardElement);
    cardElement.update({ disabled: cardDisabled });
  }
  const onCardChange = (event: { complete: boolean }) => send({ type: 'CARD_CHANGED', complete: event.complete });
  const onSubscribeClick = () => send('CREATE_OR_UPDATE');
  const onConfirmCardClick = () => send('CONFIRM_CARD');
  const onChangeCardClick = () => send('CHANGE_CARD');

  // TS doesn't pick up the correct states enum here
  /* eslint-disable @typescript-eslint/ban-ts-comment */
  // @ts-ignore
  const updated = matches('updated');
  // @ts-ignore
  const readyConfirm = matches('readyConfirm');
  // @ts-ignore
  const retrieving = matches('retrieving');
  /* eslint-enable @typescript-eslint/ban-ts-comment */

  if (!ready) {
    return <></>;
  }

  return (
    <>
      <BackLink to="/dashboard/account/billing" />
      <ColumnContainer equal>
        <WithHeaderContentColumn header="Update subscription payment method" whiteBackground>
          {updated && <p>You subscription payment method has been updated</p>}
          {retrieving && <p>Retrieving your subscription data...</p>}
          {!retrieving && !updated && (
            <>
              <p>
                Your currently saved card: <b>{formatCard(stripeCard)}</b>
              </p>
              {stripeSubscription.paymentStatus === 'requires_payment_method' && (
                <p>
                  We weren&apos;t able to charge your credit card. Update your payment details to avoid subscription
                  cancellation.
                </p>
              )}
              {readyConfirm ? (
                <>
                  <p>We need to confirm your card with the bank.</p>
                  <FormGroup style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Button primary onClick={onConfirmCardClick}>
                      {busy && <Spinner />}
                      <span>Confirm</span>
                    </Button>
                    <InlineButton style={{ padding: '0 20px' }} onClick={onChangeCardClick}>
                      Use another card
                    </InlineButton>
                  </FormGroup>
                </>
              ) : (
                <>
                  <p>Please provide your new credit card information below:</p>
                  <FormGroup>
                    <CardElementInput onChange={onCardChange} />
                  </FormGroup>
                  <FormGroup>
                    <Button primary onClick={onSubscribeClick} disabled={buttonDisabled}>
                      {busy && <Spinner />}
                      <span>Update</span>
                    </Button>
                  </FormGroup>
                </>
              )}

              {error && (
                <FormGroup>
                  <FormError>{`${error}`}</FormError>
                </FormGroup>
              )}
            </>
          )}
        </WithHeaderContentColumn>
      </ColumnContainer>
    </>
  );
};

const SubscriptionUpdate = () => {
  // This component handles rare cases when the subscription
  // was replaced or cancelled while the page was open.
  const { userData } = useContext(UserContext);
  const { stripeSubscription } = userData;
  const [initialSubscriptionId] = useState(stripeSubscription.id);

  if (!initialSubscriptionId) {
    return <Navigate to="/dashboard/account/billing" />;
  }

  // If the subscription is changed the change of `key` will force the
  // child component to re-render with the new subscription and restart the state machine
  return <SubscriptionUpdateImpl subscriptionId={stripeSubscription.id} key={stripeSubscription.id} />;
};

export default SubscriptionUpdate;
