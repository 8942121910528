import { collection, doc, getDoc, serverTimestamp, writeBatch } from 'firebase/firestore';
import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import BackLink from 'web/components/BackLink';
import ColumnContainer from 'web/components/ColumnContainer';
import { FormError, FormGroup } from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import useTracking from 'web/components/TrackingContext/useTracking';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import ClientForm, { FormValues } from './ClientForm';

const DashboardClientsCreate = () => {
  const tracking = useTracking();
  const { userData } = useContext(UserContext);
  const [submitting, setSubmitting] = useState(false);
  const firestore = useFirestore();
  const [error, setError] = useErrorStateHandler();
  const [emailInUse, setEmailInUse] = useState(false);
  const navigate = useNavigate();

  const create = async (values: FormValues) => {
    setSubmitting(true);
    setError(null);
    setEmailInUse(false);
    try {
      const emailNormalized = values.email.toLowerCase();

      const existingEmail = await getDoc(
        doc(firestore, 'pages', userData.bookingPageId, 'clientsEmailMappings', emailNormalized),
      );
      if (existingEmail.exists()) {
        setEmailInUse(true);
        setSubmitting(false);
        return;
      }

      const firstNameLower = values.firstName.toLowerCase();
      const lastNameLower = values.lastName.toLowerCase();

      const client: introwise.FirestoreWriteData<introwise.Client> = {
        firstName: values.firstName,
        lastName: values.lastName,
        firstNameLower,
        lastNameLower,
        firstNameChar: firstNameLower.charAt(0),
        lastNameChar: lastNameLower.charAt(0),
        searchPrefixes: Array.from(
          new Set([
            firstNameLower.charAt(0),
            lastNameLower.charAt(0),
            firstNameLower.slice(0, 2),
            lastNameLower.slice(0, 2),
            emailNormalized.charAt(0),
            emailNormalized.slice(0, 2),
          ]),
        ),
        primaryEmail: values.email,
        emails: [values.email],
        emailsNormalized: [emailNormalized],
        createdAt: serverTimestamp(),
        lastActiveAt: null,
        lastSessionAt: null,
      };
      const clientRef = doc(collection(firestore, 'pages', userData.bookingPageId, 'clients'));
      const emailMapping = {
        clientId: clientRef.id,
        emailNormalized,
      };
      const emailMappingRef = doc(firestore, 'pages', userData.bookingPageId, 'clientsEmailMappings', emailNormalized);
      const batch = writeBatch(firestore);
      batch.set(clientRef, client);
      batch.set(emailMappingRef, emailMapping);
      await batch.commit();
      tracking.trackEvent('Client Created');
      navigate('..', { replace: true });
    } catch (error) {
      setError(error);
      setSubmitting(false);
    }
  };
  return (
    <>
      <Helmet title="Add a client" />
      <BackLink to=".." />
      <ColumnContainer>
        <WithHeaderContentColumn header="Add a client" whiteBackground>
          <ClientForm onSubmit={create} submitting={submitting} />
          {(error || emailInUse) && (
            <FormGroup>
              <FormError>
                {error && 'Something went wrong. Please try again later.'}
                {emailInUse && 'This email is already assigned to another client.'}
              </FormError>
            </FormGroup>
          )}
        </WithHeaderContentColumn>
        <div></div>
      </ColumnContainer>
    </>
  );
};

export default DashboardClientsCreate;
