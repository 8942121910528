import React from 'react';
import { Outlet } from 'react-router-dom';
import StripeProvider from 'web/components/StripeProvider';

const SettingsPaymentsSubscription = () => (
  <StripeProvider>
    <Outlet />
  </StripeProvider>
);

export default SettingsPaymentsSubscription;
