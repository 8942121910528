import { collection, doc, increment, serverTimestamp, writeBatch } from 'firebase/firestore';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ColumnContainer from 'web/components/ColumnContainer';
import { AnchorStyled, FormError } from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import useTracking from 'web/components/TrackingContext/useTracking';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import themeClasses from 'web/styles/themeClasses.css';
import WorkflowForm, { WorkflowFormValues } from './WorkflowForm';

const WorkflowCreate = () => {
  const firestore = useFirestore();
  const { userData } = useContext(UserContext);
  const { bookingPageId: pageId } = userData;
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useErrorStateHandler();
  const navigate = useNavigate();
  const tracking = useTracking();

  const save = async (values: WorkflowFormValues) => {
    setSubmitting(true);
    try {
      const workflow: introwise.FirestoreWriteData<introwise.Workflow> = {
        type: 'session',
        name: values.name,
        default: values.default,
        createdAt: serverTimestamp(),
        active: false,
        deleted: false,
        scheduled: false,
        actions: {},
      };
      const batch = writeBatch(firestore);
      const pageRef = doc(firestore, 'pages', pageId);
      const workflowRef = doc(collection(pageRef, 'workflows'));
      const pageUpdate: introwise.FirestoreUpdateData<introwise.Page> = {
        workflowsCount: increment(1),
      };
      batch.set(workflowRef, workflow);
      batch.update(pageRef, pageUpdate);
      await batch.commit();
      tracking.trackEvent('Workflow Created', {
        type: 'session',
        default: values.default,
      });
      navigate(`../${workflowRef.id}`, { replace: true });
    } catch (error) {
      setError(error);
    }
    setSubmitting(false);
  };

  return (
    <ColumnContainer>
      <WithHeaderContentColumn header="Create a new workflow" whiteBackground>
        <WorkflowForm onSubmit={save} submitting={submitting} />
        {error && <FormError>Something went wrong. Please try again later</FormError>}
      </WithHeaderContentColumn>
      <WithHeaderContentColumn header="What is a workflow?">
        <p className={themeClasses({ marginTop: 0 })}>
          Workflows helps you automate repeating tasks for your sessions, like sending reminders. You can create as many
          workflows as you want and choose which one to use for each service, series, or group session.
        </p>
        <p className={themeClasses({ marginBottom: 0 })}>
          <AnchorStyled href="https://introwise.com/help/workflows" target="_blank">
            Learn more about workflows
          </AnchorStyled>
        </p>
      </WithHeaderContentColumn>
    </ColumnContainer>
  );
};

export default WorkflowCreate;
