import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import BookingPageLink from 'web/components/BookingPageLink';
import ColumnContainer from 'web/components/ColumnContainer';
import { LinkButton, LinkStyled } from 'web/components/elements';
import ListUnstyled from 'web/components/elements/ListUnstyled';
import ScreenTracker from 'web/components/ScreenTracker';
import Services from 'web/components/Services/Services';
import { ServiceItemLink, ServicesList } from 'web/components/Services/ServicesCommon';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import themeClasses from 'web/styles/themeClasses.css';

const SettingsAvailabilityServices = () => {
  const { userData } = useContext(UserContext);

  if (!userData.bookingPageId) {
    return <p>Please create a booking page before setting up your personal sessions</p>;
  }

  return (
    <Services
      createTo={`/settings/scheduling/services/create`}
      reorderTo={`/settings/scheduling/services/reorder`}
      serviceTo={(serviceId) => `/settings/scheduling/services/${serviceId}`}
    />
  );
};

const SettingsAvailabilities = () => {
  const { userData } = useContext(UserContext);

  const availabilitiesSorted = userData.availabilities
    ? Object.values(userData.availabilities).sort((a, b) => a.order - b.order)
    : [];

  return availabilitiesSorted.length > 0 ? (
    <>
      <ServicesList inline>
        {availabilitiesSorted.map((availability) => (
          <li key={availability.id}>
            <ServiceItemLink to={`availabilities/${availability.id}`}>{availability.name}</ServiceItemLink>
          </li>
        ))}
      </ServicesList>
      <div className={themeClasses({ marginTop: 4 })}>
        <LinkButton secondary md to="availabilities/create">
          Add new availability
        </LinkButton>
      </div>
    </>
  ) : (
    <LinkButton secondary md to="/dashboard/scheduling/availabilities/availability">
      Manage availability
    </LinkButton>
  );
};

const SettingsSchedulingServicesIndex = () => {
  const { userData } = useContext(UserContext);

  return (
    <>
      <Helmet title="Manage services" />
      <ScreenTracker screenName="SettingsSchedulingServices" />
      <ColumnContainer>
        <div>
          <WithHeaderContentColumn whiteBackground header="Personal sessions">
            <h4 className={themeClasses({ marginTop: 0 })}>Your services</h4>
            <p>You can have multiple personal session types for various services you offer</p>
            <SettingsAvailabilityServices />
            <h4>Your availability</h4>
            <p>Clients can book personal sessions with you during your availability time</p>
            <SettingsAvailabilities />
            <h4>Scheduling rules</h4>
            <p>
              Set minimum notice time before a booking and how far in the future clients can book. Block time periods to
              have a time off.
            </p>
            <LinkButton secondary md to="rules">
              Manage rules
            </LinkButton>
            <LinkButton secondary md to="time-off" className={themeClasses({ marginLeft: 4 })}>
              Manage time off
            </LinkButton>
            <h4>Connected calendars</h4>
            {userData.settings?.calendarSync ? (
              <p>Your free/busy times and booked sessions are synced with your calendar accounts</p>
            ) : (
              <p>
                Connect your personal or work calendar accounts to share your free/busy times with Introwise and
                automaticaly add booked sessions to your calendar
              </p>
            )}
            <LinkButton secondary md to="connected-calendars">
              {userData.settings?.calendarSync ? 'Manage calendar accounts' : 'Connect calendar account'}
            </LinkButton>
            <h4>Cancellation policy</h4>
            <p>Set your cancellation policy to allow clients to cancel or reschedule their bookings</p>
            <LinkButton secondary md to="cancellation-policy">
              Manage cancellation policy
            </LinkButton>
          </WithHeaderContentColumn>
        </div>
        <div>
          <WithHeaderContentColumn header="View as a client">
            <BookingPageLink />
            <div style={{ marginTop: 16 }}>
              <small>View availabile personal and group sessions on your booking page</small>
            </div>
          </WithHeaderContentColumn>
          <WithHeaderContentColumn header="More settings">
            <ListUnstyled>
              <li>
                <LinkStyled to="/dashboard/booking">Edit booking page</LinkStyled>
              </li>
              <li>
                <LinkStyled to="/dashboard/payments/currency">Change currency</LinkStyled>
              </li>
              <li>
                <LinkStyled to="/dashboard/calls/locations">Manage conferencing</LinkStyled>
              </li>
            </ListUnstyled>
          </WithHeaderContentColumn>
        </div>
      </ColumnContainer>
    </>
  );
};

export default SettingsSchedulingServicesIndex;
