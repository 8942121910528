import React, { useMemo } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { Input } from 'web/components/elements';
import themeClasses from 'web/styles/themeClasses.css';
import themeConstants from 'web/styles/themeConstants';
import WithOptionalLabel from './WithOptionalLabel';

const StyledDateRangeField = styled.div<{ vertical?: boolean }>`
  display: grid;
  row-gap: 10px;
  column-gap: 20px;
  ${themeConstants.media.sm} {
    ${({ vertical }) => !vertical && 'grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);'}
  }

  .react-datepicker__tab-loop {
    display: inline-block;
  }

  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 110px;
  }

  .react-datepicker__time-container {
    width: 100px;
    .react-datepicker__time .react-datepicker__time-box {
      width: 100px;
    }
  }
`;

const DateTimeRangeSelector = ({
  vertical,
  disabled,
  isTimeOn,
  value,
  onChange,
  onBlur,
  errors,
  duration,
  startDateLabel,
  endDateLabel,
  startTimeLabel,
  endTimeLabel,
}: {
  isTimeOn: boolean;
  vertical?: boolean;
  disabled?: boolean;
  value: {
    start: Date;
    end: Date;
  };
  onChange: (value: { start: Date; end: Date }) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  errors?: {
    start?: boolean;
    end?: boolean;
  };
  duration?: number;
  startDateLabel?: string;
  endDateLabel?: string;
  startTimeLabel?: string;
  endTimeLabel?: string;
}) => {
  const minDate = useMemo(() => new Date(), []);

  const adjustEndDate = (newStartDate: Date) => {
    let newEndDate: Date;
    if (value.start && value.end && value.end.getTime() - value.start.getTime() > 0) {
      // Existing end date greater than existing start time - keep the diff
      newEndDate = new Date(newStartDate.getTime() + value.end.getTime() - value.start.getTime());
    } else if (value.end && value.end.getTime() - newStartDate.getTime() > 0) {
      // Existing end date greater than new start time - keep the end date intact
      newEndDate = new Date(value.end);
    } else {
      // Else make extend the end date in the future by duration or default duration
      newEndDate = new Date(newStartDate.getTime() + (duration ?? (isTimeOn ? 60 * 60 * 1000 : 24 * 60 * 60 * 1000)));
    }

    return newEndDate;
  };

  return (
    <StyledDateRangeField vertical={vertical}>
      <div className={themeClasses({ display: 'flex', gap: 1 })}>
        <WithOptionalLabel label={startDateLabel}>
          <DatePicker
            selected={value.start}
            startDate={value.start}
            endDate={value.end}
            minDate={minDate}
            selectsStart
            onChange={(date) => {
              let newStartDate;
              if (value.start) {
                newStartDate = new Date(value.start);
                newStartDate.setDate(date.getDate());
                newStartDate.setMonth(date.getMonth());
                newStartDate.setFullYear(date.getFullYear());
              } else {
                newStartDate = new Date(date);
                newStartDate.setHours(11);
                newStartDate.setMinutes(0);
              }
              const newEndDate = adjustEndDate(newStartDate);
              onChange({ start: newStartDate, end: newEndDate });
            }}
            customInput={<Input hasError={errors && errors.start} />}
            name={`start`}
            onBlur={onBlur}
            placeholderText="Start date"
            autoComplete="off"
            disabled={disabled}
          />
        </WithOptionalLabel>
        {isTimeOn && (
          <WithOptionalLabel label={startTimeLabel}>
            <DatePicker
              selected={value.start}
              startDate={value.start}
              endDate={value.end}
              selectsStart
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={30}
              dateFormat="h:mm aa"
              onChange={(date) => {
                const newStartDate = value.start ? new Date(value.start) : new Date();
                newStartDate.setHours(date.getHours());
                newStartDate.setMinutes(date.getMinutes());

                const newEndDate = adjustEndDate(newStartDate);
                onChange({ start: newStartDate, end: newEndDate });
              }}
              customInput={<Input hasError={errors && errors.start} />}
              name="start"
              onBlur={onBlur}
              placeholderText="12:00 AM"
              autoComplete="off"
              disabled={disabled}
            />
          </WithOptionalLabel>
        )}
      </div>
      <div className={themeClasses({ display: 'flex', gap: 1 })}>
        <WithOptionalLabel label={endDateLabel}>
          <DatePicker
            selected={value.end}
            startDate={value.start}
            endDate={value.end}
            minDate={value.start || minDate}
            selectsEnd
            onChange={(date) => {
              const newEndDate = new Date(date);
              newEndDate.setDate(date.getDate());
              newEndDate.setMonth(date.getMonth());
              newEndDate.setFullYear(date.getFullYear());

              onChange({ start: value.start, end: newEndDate });
            }}
            name="end"
            placeholderText="End date"
            customInput={<Input hasError={errors && errors.end} />}
            onBlur={onBlur}
            autoComplete="off"
            disabled={disabled}
          />
        </WithOptionalLabel>
        {isTimeOn && (
          <WithOptionalLabel label={endTimeLabel}>
            <DatePicker
              selected={value.end}
              startDate={value.start}
              endDate={value.end}
              minDate={value.start || minDate}
              selectsEnd
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={30}
              dateFormat="h:mm aa"
              onChange={(date) => {
                const newEndDate = date ? new Date(date) : new Date();
                newEndDate.setHours(date.getHours());
                newEndDate.setMinutes(date.getMinutes());
                onChange({ start: value.start, end: newEndDate });
              }}
              customInput={<Input hasError={errors && errors.end} />}
              name="end"
              onBlur={onBlur}
              placeholderText="12:00 AM"
              autoComplete="off"
              disabled={disabled}
            />
          </WithOptionalLabel>
        )}
      </div>
    </StyledDateRangeField>
  );
};

export default DateTimeRangeSelector;
