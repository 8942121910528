import { doc, runTransaction } from 'firebase/firestore';
import React, { useContext, useMemo, useState } from 'react';
import { Controller, FieldError, RegisterOptions, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import BackLink from 'web/components/BackLink';
import ColumnContainer from 'web/components/ColumnContainer';
import {
  Button,
  FormDescription,
  FormError,
  FormGroup,
  InlineButton,
  Input,
  Label,
  LabelText,
  LinkStyled,
} from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import DateTimeRangeSelector from 'web/components/form-fields/DateTimeRangeSelector';
import { NoticeCard } from 'web/components/NoticeCard';
import Spinner from 'web/components/Spinner';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import useFeatureOverrideCheck from 'web/hooks/useFeatureOverrideCheck';
import themeClasses from 'web/styles/themeClasses.css';
import SeriesContext from './SeriesContext';
import SeriesInfo from './SeriesInfo';
import SeriesSessionContext from './SeriesSessionContext';

const dateTimeValidationRules: RegisterOptions = {
  validate: {
    startRequired: (values: FormValues['dateTime']) => (!values.start ? 'Start date is required' : undefined),
    endRequired: (values: FormValues['dateTime']) => (!values.end ? 'End date is required' : undefined),
    startMin: (values: FormValues['dateTime']) =>
      values.start < new Date() ? 'Start date must be in the future' : undefined,
    endMin: (values: FormValues['dateTime']) =>
      values.start >= values.end ? 'End date must be greater than start' : undefined,
  },
};

const groupSizeLimit = 42;
const groupSizeLimitLarge = 500;

const makeGroupSizeValidationRules = (largeSessionLimits?: boolean): RegisterOptions => ({
  required: 'Required',
  validate: {
    min: (strValue: string) => {
      const value = parseInt(strValue);
      if (value <= 0) {
        return `Group size must be greater than 0`;
      }
    },
    max: (strValue: string) => {
      const value = parseInt(strValue);
      const limit = largeSessionLimits ? groupSizeLimitLarge : groupSizeLimit;
      if (value > limit) {
        return `Group sessions are limited to ${limit} participants`;
      }
    },
  },
});

type FormValues = {
  dateTime: {
    start: Date;
    end: Date;
  };
  groupSizeMax: string;
};

type SubmitValues = {
  start: Date;
  end: Date;
  groupSizeMax: number;
};

const sessionToFormValues = (session: introwise.GroupSession): FormValues =>
  session
    ? {
        dateTime: {
          start: session.start,
          end: session.end,
        },
        groupSizeMax: session.groupSize?.max?.toString(),
      }
    : {
        dateTime: {
          start: undefined,
          end: undefined,
        },
        groupSizeMax: '6',
      };

const formValuesToSubmitValues = (values: FormValues): SubmitValues => ({
  start: values.dateTime.start,
  end: values.dateTime.end,
  groupSizeMax: parseInt(values.groupSizeMax),
});

const TimezoneInfo = () => {
  const timezone = useMemo(() => Intl.DateTimeFormat().resolvedOptions().timeZone.replace('_', ' '), []);
  return <FormDescription>Your timezone: {timezone}</FormDescription>;
};

const SeriesSessionEditForm = ({
  session,
  onSubmit,
  onDelete,
  submitting,
  readOnly,
  largeSessionLimits,
}: {
  session: introwise.GroupSession;
  onSubmit: (session: SubmitValues) => void;
  onDelete?: () => void;
  submitting: boolean;
  readOnly?: boolean;
  largeSessionLimits?: boolean;
}) => {
  const groupSizeValidationRules = useMemo(
    () => makeGroupSizeValidationRules(largeSessionLimits),
    [largeSessionLimits],
  );

  const defaultValues = sessionToFormValues(session);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    defaultValues,
  });

  const isValid = Object.keys(errors).length === 0;

  return (
    <form
      onSubmit={handleSubmit((values) => {
        onSubmit(formValuesToSubmitValues(values));
      })}
    >
      <fieldset disabled={submitting}>
        {!readOnly && (
          <FormGroup>
            <div style={{ padding: '0 8px' }}>
              <Controller
                name="dateTime"
                control={control}
                rules={dateTimeValidationRules}
                render={({ field: { onChange, onBlur, value } }) => (
                  <DateTimeRangeSelector
                    isTimeOn
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    disabled={submitting || readOnly}
                    errors={{
                      start: (errors.dateTime as FieldError)?.type.startsWith('start'),
                      end: (errors.dateTime as FieldError)?.type.startsWith('end'),
                    }}
                  />
                )}
              />
            </div>
            <TimezoneInfo />
            {errors.dateTime && <FormError>{(errors.dateTime as FieldError).message}</FormError>}
          </FormGroup>
        )}
        <FormGroup>
          <Label>
            <LabelText>Group size</LabelText>
            <Input
              type="number"
              {...register('groupSizeMax', groupSizeValidationRules)}
              hasError={!!errors.groupSizeMax}
            />
          </Label>
          {errors.groupSizeMax && <FormError>{errors.groupSizeMax.message}</FormError>}
        </FormGroup>
      </fieldset>
      <FormGroup style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button primary type="submit" disabled={submitting}>
          {submitting && <Spinner />}
          <span>Save</span>
        </Button>
        {onDelete && (
          <InlineButton disabled={submitting} onClick={onDelete}>
            Delete
          </InlineButton>
        )}
      </FormGroup>
      {!isValid && <FormError>Please check your inputs</FormError>}
    </form>
  );
};

const SeriesSessionEdit = () => {
  const series = useContext(SeriesContext);
  const session = useContext(SeriesSessionContext);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useErrorStateHandler();
  const firestore = useFirestore();
  const navigate = useNavigate();
  const largeSessionLimits = useFeatureOverrideCheck('largeSessionLimits');

  const hasBookings = session.groupSize.current > 0;

  const save = async ({ start, end, groupSizeMax }: SubmitValues) => {
    setSubmitting(true);
    try {
      // We ensure that session update only happens for non-booked sessions
      const sessionRef = doc(firestore, 'sessions', session.id);
      await runTransaction(firestore, (t) =>
        t.get(sessionRef).then((sessionDoc) => {
          if (!sessionDoc.exists) {
            throw new Error(`Session no longer exist`);
          }

          const sessionExistingData = sessionDoc.data() as introwise.GroupSession;

          const sessionUpdate: introwise.FirestoreUpdateData<introwise.GroupSession> & {
            'groupSize.max': introwise.GroupSession['groupSize']['max'];
            'groupSize.remaining': introwise.GroupSession['groupSize']['remaining'];
          } = {
            ...(start && { start }),
            ...(end && { end }),
            ...(groupSizeMax && {
              'groupSize.max': groupSizeMax,
              'groupSize.remaining': Math.max(0, groupSizeMax - sessionExistingData.groupSize.current),
            }),
          };

          return t.update(sessionRef, sessionUpdate);
        }),
      );
      navigate('../..', { replace: true });
    } catch (err) {
      setError(err);
    }
    setSubmitting(false);
  };

  const del = async () => {
    if (hasBookings || submitting) {
      return;
    }
    const res = confirm('Are you sure you want to delete this session?');
    if (!res) {
      return;
    }
    setSubmitting(true);
    try {
      // We ensure that session update only happens for non-booked sessions
      const sessionRef = doc(firestore, 'sessions', session.id);
      await runTransaction(firestore, (t) =>
        t.get(sessionRef).then((sessionDoc) => {
          if (!sessionDoc.exists) {
            throw new Error(`Session no longer exist`);
          }
          if ((sessionDoc.data() as introwise.GroupSession).groupSize.current !== 0) {
            throw new Error(`Session already has bookings`);
          }
          return t.delete(sessionRef);
        }),
      );
      navigate(`/dashboard/scheduling${session.seriesId ? `/series/${session.seriesId}` : ''}`, { replace: true });
    } catch (err) {
      setError(err);
    }
    setSubmitting(false);
  };

  const cancel = () => {
    navigate('../..', { replace: true });
  };

  return (
    <>
      <BackLink to={`/dashboard/scheduling/series/${series.id}`} />
      <ColumnContainer>
        <div>
          <SeriesInfo series={series} />
          <WithHeaderContentColumn
            header="Edit a group session"
            whiteBackground
            extendedHeader={
              <InlineButton disabled={submitting} onClick={cancel} style={{ lineHeight: 'calc(20px*1.62)' }}>
                Cancel
              </InlineButton>
            }
          >
            {hasBookings && (
              <NoticeCard className={themeClasses({ marginBottom: 4 })}>
                <p className={themeClasses({ margin: 0 })}>
                  This session already has bookings, some details cannot be edited. To change the session time use the
                  rescheduling function on the{' '}
                  <LinkStyled to={`/dashboard/home/sessions/${session.id}`}>session dashboard</LinkStyled>.
                </p>
              </NoticeCard>
            )}
            <SeriesSessionEditForm
              session={session}
              onSubmit={save}
              onDelete={!hasBookings && del}
              submitting={submitting}
              largeSessionLimits={largeSessionLimits}
              readOnly={hasBookings}
            />
            {error && <FormError>{`${error}`}</FormError>}
          </WithHeaderContentColumn>
        </div>
        <div />
      </ColumnContainer>
    </>
  );
};

export default SeriesSessionEdit;
