import { To } from 'history';
import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import themeConstants from 'web/styles/themeConstants';
import themeVars from 'web/styles/themeVars.css';
import HeaderLogo from './header/Logo';
import UserIcon from './header/UserIcon';
import WhatsNewButton from './header/WhatsNewButton';
import WithNotificationContainer from './header/WithNotificationContainer';

type NavigationItem = {
  title: React.ReactNode | string;
  mobileTitle?: React.ReactNode | string;
  to: To;
  deepTo?: To;
  // isActive: boolean;
  // notification: boolean;
};

const StyledLogo = styled(HeaderLogo)`
  ${themeConstants.media.sm} {
    margin-right: 50px;
  }
`;

const NavContainer = styled.nav`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  grid-column-gap: 16px;
  align-items: center;
  height: 32px;

  overflow-x: auto;
  overflow-y: hidden;

  background-color: ${themeVars.backgrounds.mobileNavHeader};

  ${themeConstants.media.sm} {
    height: auto;
    background-color: transparent;
  }
`;

const UserContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 24px;
`;

const Container = styled.div`
  display: grid;
  grid-template-areas: 'logo . user' 'nav nav nav';
  grid-template-rows: 44px min-content;
  grid-template-columns: min-content minmax(0, 1fr) min-content;
  align-items: center;

  background-color: ${themeVars.backgrounds.header};
  border-bottom: 1px solid ${themeConstants.borders.light};

  ${StyledLogo} {
    grid-area: logo;
    margin-left: 12px;
  }

  ${NavContainer} {
    grid-area: nav;
    padding-left: 12px;
  }

  ${UserContainer} {
    grid-area: user;
    margin-right: 12px;
  }

  ${themeConstants.media.sm} {
    grid-template-areas: 'logo nav user';
    grid-template-rows: auto;
    grid-template-columns: min-content auto auto;
    padding: 0 12px;
  }

  ${themeConstants.media.lg} {
    height: 56px;
    column-gap: 20px;
    padding: 0 24px;
  }

  ${themeConstants.media.xl} {
    padding: 0 80px;
  }
`;

const StyledNavLink = styled(NavLink)`
  ${themeConstants.mixins.nonTextLink};

  margin: 0 2px;
  padding: 0;
  white-space: nowrap;
  font-size: 12px;

  > span {
    color: ${themeVars.color.navLink};
  }

  &.active {
    > span {
      color: ${themeVars.color.navLinkActive};
    }
  }

  ${themeConstants.media.sm} {
    margin: 0 8px;
  }

  ${themeConstants.media.md} {
    font-size: 16px;
    margin: 0 16px;
  }
`;

const SpanDesktop = styled.span`
  display: none;

  ${themeConstants.media.sm} {
    display: initial;
  }
`;

const SpanMobile = styled.span`
  ${themeConstants.media.sm} {
    display: none;
  }
`;

const NavBar = ({ items }: { items: Array<NavigationItem> }) => {
  const navigate = useNavigate();
  return (
    <NavContainer>
      {items.map((item, index) => (
        <WithNotificationContainer notification={false} key={index}>
          <StyledNavLink to={item.to} onClick={item.deepTo ? () => navigate(item.deepTo) : undefined} end={false}>
            <SpanDesktop>{item.title}</SpanDesktop>
            <SpanMobile>{item.mobileTitle || item.title}</SpanMobile>
          </StyledNavLink>
        </WithNotificationContainer>
      ))}
    </NavContainer>
  );
};

const AppHeaderDashboard = ({ navigationItems }: { navigationItems: Array<NavigationItem> }) => {
  return (
    <Container>
      <StyledLogo to="/dashboard/home" />
      <NavBar items={navigationItems} />
      <UserContainer>
        <div style={{ flex: 'none' }}>
          <WhatsNewButton />
        </div>
        <UserIcon />
      </UserContainer>
    </Container>
  );
};

export default AppHeaderDashboard;
