import { doc, updateDoc } from 'firebase/firestore';
import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet, Route, Routes, useParams } from 'react-router-dom';
import BackLink from 'web/components/BackLink';
import BookingPageContext from 'web/components/BookingPageContext';
import Bullet from 'web/components/Bullet';
import ColumnContainer from 'web/components/ColumnContainer';
import { FormError, InlineButton, LinkStyled } from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import Flare from 'web/components/Flare';
import Spinner from 'web/components/Spinner';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import { useDocumentData } from 'web/hooks/firebase';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import themeClasses from 'web/styles/themeClasses.css';
import { firestoreDiscountCodeConverter } from 'web/utils/convert';
import { formatDiscountValue, UprgadeColumn } from './common';
import useFeatureIncludedCheck from '../../../../hooks/useFeatureIncludedCheck';
import DiscountCodeContext from './DiscountCodeContext';

const SettingsPaymentsDiscountsContainer = () => {
  const {
    userData: { bookingPageId },
  } = useContext(UserContext);
  const { discountId } = useParams();
  const firestore = useFirestore();
  const discountCodeRef = doc(firestore, 'pages', bookingPageId, 'discountCodes', discountId);
  const [discountCode, loading, error] = useDocumentData(discountCodeRef.withConverter(firestoreDiscountCodeConverter));
  useErrorHandler(error);
  const [page, pageLoading, pageError] = useContext(BookingPageContext);
  useErrorHandler(pageError);
  const [submittingActive, setSubmittingActive] = useState(false);
  const [submitActiveError, setSubmitActiveError] = useErrorStateHandler();
  const featureIncluded = useFeatureIncludedCheck('discounts');

  const toggleActive = async () => {
    setSubmittingActive(true);
    setSubmitActiveError(null);
    try {
      await updateDoc(discountCodeRef, { active: !discountCode.active });
    } catch (err) {
      setSubmitActiveError(err);
    }
    setSubmittingActive(false);
  };

  return (
    <>
      <Helmet title="Discount code" />
      <BackLink to=".." />
      <ColumnContainer>
        <div>
          {pageLoading && <Spinner />}
          {!pageLoading && (pageError || !page) && <p>Something went wrong. Please try again later.</p>}
          {!pageLoading && !pageError && page && (
            <>
              <WithHeaderContentColumn header="Discount codes" whiteBackground>
                {loading && <Spinner />}
                {!loading && error && <p>Something went wrong. Please try again later.</p>}
                {!loading && !error && !discountCode && <p>Discount code not found.</p>}
                {!loading && !error && discountCode && (
                  <>
                    <Helmet title={`Discount code – ${discountCode.code}`} />
                    <div className={themeClasses({ justifyContent: 'space-between', display: 'flex' })}>
                      <h3 style={{ margin: 0 }}>{discountCode.code}</h3>
                      <div
                        className={themeClasses({
                          flex: 'none',
                          display: 'flex',
                          gap: 2,
                          alignItems: 'center',
                          flexWrap: 'wrap',
                        })}
                      >
                        <Flare variant={discountCode.active ? 'success' : 'disabled'}>
                          {discountCode.active ? 'active' : 'inactive'}
                        </Flare>
                        {discountCode.maxRedemptions && discountCode.maxRedemptions <= discountCode.timesRedeemed && (
                          <Flare variant="disabled">used&nbsp;up</Flare>
                        )}
                      </div>
                    </div>
                    <div className={themeClasses({ marginTop: 3 })}>
                      {formatDiscountValue(discountCode)}
                      <Bullet />
                      {discountCode.timesRedeemed}
                      {discountCode.maxRedemptions && <>/{discountCode.maxRedemptions}</>} used
                    </div>
                    {featureIncluded && (
                      <div className={themeClasses({ marginTop: 3, gap: 5, display: 'flex' })}>
                        <InlineButton onClick={toggleActive} disabled={submittingActive}>
                          {discountCode.active ? 'Disable' : 'Enable'}
                        </InlineButton>
                        <Routes>
                          <Route index element={<LinkStyled to="edit">Edit</LinkStyled>} />
                          <Route path="edit" element={<LinkStyled to="..">View usage</LinkStyled>} />
                        </Routes>
                      </div>
                    )}
                    {submitActiveError && <FormError>Something went wrong. Please try again later.</FormError>}
                  </>
                )}
              </WithHeaderContentColumn>
              {!loading && !error && discountCode && (
                <DiscountCodeContext.Provider value={discountCode}>
                  <Outlet />
                </DiscountCodeContext.Provider>
              )}
            </>
          )}
        </div>
        {featureIncluded ? <div /> : <UprgadeColumn />}
      </ColumnContainer>
    </>
  );
};

export default SettingsPaymentsDiscountsContainer;
