import { collection, Firestore, getDocs, getDocsFromServer, limit, query, where } from 'firebase/firestore';
import React, { useMemo } from 'react';
import { FieldError, RegisterOptions, useForm } from 'react-hook-form';
import { convertFromDecimal, convertToDecimal, isZeroDecimal } from 'web/utils/currency';
import {
  Button,
  Checkbox,
  FormDescription,
  FormError,
  FormGroup,
  InlineButton,
  Input,
  Label,
  LabelText,
  Radio,
} from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import PriceInput from 'web/components/form-fields/PriceInput';
import Spinner from 'web/components/Spinner';

const toFormValues = (
  val: introwise.DiscountCode,
  initialVal: DiscountCodeFormValues,
  currency: introwise.Currency,
): DiscountCodeFormValues =>
  !val
    ? initialVal
    : {
        code: val.code,
        ...(val.valueType === 'percentage'
          ? { valueType: 'percentage', value: { percentOff: val.value.percentOff } }
          : {
              valueType: 'fixed',
              value: { amountOff: convertToDecimal(val.value.amountOff, currency).toString() },
            }),
        checkedMaxRedemptions: !!val.maxRedemptions,
        maxRedemptions: val.maxRedemptions || 0,
      };

const toSubmitValues = (val: DiscountCodeFormValues, currency: introwise.Currency): DiscountCodeFormSubmitValues => ({
  code: val.code,
  ...(val.valueType === 'percentage'
    ? {
        valueType: 'percentage',
        value: { percentOff: val.value.percentOff },
      }
    : {
        valueType: 'fixed',
        value: { amountOff: convertFromDecimal(parseFloat(val.value.amountOff), currency), currency },
      }),
  maxRedemptions: val.checkedMaxRedemptions ? val.maxRedemptions : null,
});

type DiscountCodeFormValues = Pick<introwise.DiscountCode, 'code'> &
  (
    | {
        valueType: 'percentage';
        value: { percentOff: number };
      }
    | {
        valueType: 'fixed';
        value: { amountOff: string };
      }
  ) & {
    checkedMaxRedemptions: boolean;
    maxRedemptions?: number;
  };
export type DiscountCodeFormSubmitValues = Pick<
  introwise.DiscountCode,
  'code' | 'value' | 'valueType' | 'maxRedemptions'
>;

const initialValues: DiscountCodeFormValues = {
  code: '',
  valueType: 'percentage',
  value: {
    percentOff: 0,
  },
  checkedMaxRedemptions: false,
  maxRedemptions: 0,
};

type CodeValidator = (codeLower: string, useFirestoreCache?: boolean) => Promise<boolean>;

const makeCodeUniquenessValidator = (
  bookingPageId: string,
  initialCode: string | null,
  firestore: Firestore,
): CodeValidator => {
  return async (code: string, useFirestoreCache = true) => {
    const codeLower = code.toLowerCase();
    const initialCodeLower = initialCode?.toLowerCase();
    if (initialCodeLower && codeLower === initialCodeLower) {
      return true;
    }
    const pageQuery = query(
      collection(firestore, 'pages', bookingPageId, 'discountCodes'),
      where('codeLower', '==', codeLower),
      limit(1),
    );
    const res = await (useFirestoreCache ? getDocs(pageQuery) : getDocsFromServer(pageQuery)).then(
      (snapshot) => snapshot.empty,
    );
    return res;
  };
};

const codeValidationRules: RegisterOptions = {
  required: 'Required',
  maxLength: {
    value: 20,
    message: 'Must be 20 characters or less',
  },
  pattern: {
    value: /^[A-Za-z0-9]+$/,
    message: 'Code can only contain letters and digits without spaces',
  },
};
const percentageValidationRules: RegisterOptions = {
  required: 'Required',
  min: {
    value: 1,
    message: 'Must be at least 1%',
  },
  max: {
    value: 100,
    message: 'Must be at most 100%',
  },
  valueAsNumber: true,
};

const makeFixedDiscountValidationRules = (currency: introwise.Currency): RegisterOptions => {
  return {
    required: 'Required',
    validate: (strValue: string) => {
      const value = parseFloat(strValue);
      if (isNaN(value)) {
        return 'Must be a number';
      }
      if (value <= 0) {
        return 'Must be a positive number';
      }
      if (isZeroDecimal(currency) && !Number.isInteger(value)) {
        return `Must be a whole number, e.g. ${value.toFixed(0)}`;
      }
    },
  };
};

const maxRedemptionsMaxValue = 100;

const maxRedemptionsValidationRules: RegisterOptions = {
  min: { value: 1, message: 'Must be greater than 0' },
  max: { value: maxRedemptionsMaxValue, message: `Cannot be greater than ${maxRedemptionsMaxValue}` },
  valueAsNumber: true,
};

const DiscountCodeForm = ({
  onSubmit,
  submitting,
  bookingPageId,
  discountCode,
  initialCurrency,
  onDelete,
}: {
  bookingPageId: string;
  onSubmit: (values: DiscountCodeFormSubmitValues) => void;
  submitting: boolean;
  initialCurrency: introwise.Currency;
  discountCode?: introwise.DiscountCode;
  onDelete?: () => void;
}) => {
  const currency = discountCode?.valueType === 'fixed' ? discountCode?.value?.currency : initialCurrency;
  const fixedAmountValidationRules = useMemo(() => makeFixedDiscountValidationRules(currency), [currency]);
  const defaultValues = useMemo(() => toFormValues(discountCode, initialValues, currency), [currency, discountCode]);
  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm({ defaultValues });
  const firestore = useFirestore();
  const codeValidator = useMemo(
    () => makeCodeUniquenessValidator(bookingPageId, discountCode?.code, firestore),
    [bookingPageId, discountCode?.code, firestore],
  );

  const onSubmitInternal = async (values: DiscountCodeFormValues) => {
    const unique = await codeValidator(values.code, false);
    if (!unique) {
      setError('code', {
        type: 'manual',
        message: 'Code is already in use. Codes must be unique and are not case-sensitive.',
      });
      return;
    }
    onSubmit(toSubmitValues(values, currency));
  };

  const valueType = watch('valueType');
  const valueError =
    valueType === 'percentage'
      ? (errors.value as { percentOff?: FieldError })?.percentOff
      : (errors.value as { amountOff?: FieldError })?.amountOff;

  const checkedMaxRedemptions = watch('checkedMaxRedemptions');

  return (
    <form onSubmit={handleSubmit(onSubmitInternal)}>
      <fieldset>
        <FormGroup>
          <Label>
            <LabelText>Code</LabelText>
            <Input {...register('code', codeValidationRules)} hasError={!!errors.code} />
          </Label>
          <FormDescription>Unique code for redeeming this discount</FormDescription>
          {errors.code && <FormError>{errors.code.message}</FormError>}
        </FormGroup>
        <FormGroup>
          <h4 style={{ marginTop: 0 }}>Discount type</h4>
          <div style={{ display: 'flex', gap: 16 }}>
            <Radio {...register('valueType')} value="percentage">
              Percentage
            </Radio>
            <Radio {...register('valueType')} value="fixed">
              Fixed amount
            </Radio>
          </div>
        </FormGroup>
        <FormGroup>
          {valueType === 'percentage' ? (
            <>
              <Label>
                <LabelText>Percent off</LabelText>
                <Input
                  {...register('value.percentOff', percentageValidationRules)}
                  type="number"
                  min={0}
                  max={100}
                  hasError={!!valueError}
                />
              </Label>
              {valueError && <FormError>{valueError.message}</FormError>}
            </>
          ) : (
            <>
              <Label>
                <LabelText>Amount off</LabelText>
                <PriceInput
                  {...register('value.amountOff', fixedAmountValidationRules)}
                  currency={currency}
                  hasError={!!valueError}
                />
              </Label>
              {valueError && <FormError>{valueError.message}</FormError>}
            </>
          )}
        </FormGroup>
        <FormGroup>
          <Checkbox {...register('checkedMaxRedemptions')}>
            Limit maximim number of times this discount can be used
          </Checkbox>
        </FormGroup>
        {checkedMaxRedemptions && (
          <FormGroup>
            <Label>
              <LabelText>Maximum redemptions</LabelText>
              <Input
                type="number"
                {...register('maxRedemptions', maxRedemptionsValidationRules)}
                hasError={!!errors.maxRedemptions}
              />
            </Label>
            {errors.maxRedemptions && <FormError>{errors.maxRedemptions.message}</FormError>}
          </FormGroup>
        )}
        <FormGroup style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button type="submit">
            {submitting && <Spinner />}
            <span>Save</span>
          </Button>
          {onDelete && (
            <InlineButton disabled={submitting} onClick={onDelete}>
              Delete
            </InlineButton>
          )}
        </FormGroup>
      </fieldset>
    </form>
  );
};

export default DiscountCodeForm;
