// Fix for Cloudflare 'videodelivery.net' hostname being blocked by some ISPs
const fixCloudflareDownloadUrl = (url: string) => url.replace('videodelivery.net', 'cloudflarestream.com');

const getDownloadUrl = (resource: introwise.ResourceRecording) => {
  if (resource.hosting.downloads && resource.hosting.provider === 'cloudflare') {
    const videoUrl = fixCloudflareDownloadUrl(resource.hosting.downloads.default.url);
    const filenameQuery = resource.filename ? `?filename=${resource.filename}` : '';
    return `${videoUrl}${filenameQuery}`;
  }
};

export { getDownloadUrl };
