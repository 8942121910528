import {
  faBell,
  faCheckCircle,
  faEllipsisV,
  faPlayCircle,
  faPlusCircle,
  faStopCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { collection, deleteField, doc, increment, serverTimestamp, writeBatch } from 'firebase/firestore';
import React, { useContext, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import BackLink from 'web/components/BackLink';
import BookingPageContext from 'web/components/BookingPageContext';
import ColumnContainer from 'web/components/ColumnContainer';
import ContentColumn from 'web/components/ContentColumn';
import {
  Button,
  FormDescription,
  FormError,
  FormFootnote,
  FormGroup,
  InlineButton,
  Input,
  Label,
  LabelText,
  LinkStyled,
  Select,
  TextArea,
} from 'web/components/elements';
import Fake from 'web/components/elements/Fake';
import ListUnstyled from 'web/components/elements/ListUnstyled';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import Flare from 'web/components/Flare';
import FormValueLength from 'web/components/form-fields/FormValueLength';
import GenericReactModal from 'web/components/GenericReactModal';
import ScreenTracker from 'web/components/ScreenTracker';
import Spinner from 'web/components/Spinner';
import useTracking from 'web/components/TrackingContext/useTracking';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import useFeatureIncludedCheck from 'web/hooks/useFeatureIncludedCheck';
import useFirestoreCollectionData from 'web/hooks/useFirestoreCollectionData';
import themeClasses from 'web/styles/themeClasses.css';
import themeVars from 'web/styles/themeVars.css';
import { firestoreWorkflowActionConverter } from 'web/utils/convert';
import { featuresUpgradePath, pricingPlans } from 'web/utils/pricingPlans';
import WorkflowContext from './WorkflowContext';

type WorkflowActionFormValues = Pick<introwise.WorkflowAction['trigger'], 'offsetUnit' | 'offsetAmount'> & {
  triggerTypeWithDirection:
    | `${'session.started' | 'session.ended'}.${'before' | 'after'}`
    | `session.created.after`
    | `session.booking.created.after`;
  actionString: 'reminder.expert' | 'reminder.client' | 'email.expert' | 'email.client' | 'automation';
  message?: string;
  description?: string;
};

const actionStringReadable: { [key in WorkflowActionFormValues['actionString']]: string } = {
  'reminder.expert': 'Send a reminder to me',
  'reminder.client': 'Send a reminder to clients',
  'email.expert': 'Send an email note to me',
  'email.client': 'Send an email note to clients',
  automation: 'Trigger custom automation',
};

const actionDescription: { [key in WorkflowActionFormValues['actionString']]?: string } = {
  automation: 'Connect this workflow with a custom automation in Zapier',
  'email.client': 'Send a custom note or a follow-up email',
  'email.expert': 'Send a custom note or a follow-up email',
};

const actionsPerTriggerType: {
  [key in WorkflowActionFormValues['triggerTypeWithDirection']]: WorkflowActionFormValues['actionString'][];
} = {
  'session.created.after': ['automation', 'email.client', 'email.expert'],
  'session.started.before': ['reminder.expert', 'reminder.client', 'email.client', 'email.expert', 'automation'],
  'session.started.after': ['automation', 'email.client', 'email.expert'],
  'session.ended.before': ['automation', 'email.client', 'email.expert'],
  'session.ended.after': ['automation', 'email.client', 'email.expert'],
  'session.booking.created.after': [],
};

const triggerTypesWithDirection: WorkflowActionFormValues['triggerTypeWithDirection'][] = [
  'session.created.after',
  'session.started.before',
  'session.started.after',
  'session.ended.before',
  'session.ended.after',
];

const fieldsByActionString: {
  [key in WorkflowActionFormValues['actionString']]: Set<keyof WorkflowActionFormValues>;
} = {
  'reminder.expert': new Set(['message']),
  'reminder.client': new Set(['message']),
  'email.expert': new Set(['message']),
  'email.client': new Set(['message']),
  automation: new Set(['description']),
};

const triggerTypeWithDirectionReadable: {
  [key in WorkflowActionFormValues['triggerTypeWithDirection']]: string;
} = {
  'session.created.after': 'After session is scheduled',
  'session.started.before': 'Before session starts',
  'session.started.after': 'After session starts',
  'session.ended.before': 'Before session ends',
  'session.ended.after': 'After session ends',
  'session.booking.created.after': 'After session booking is created',
};

const triggerTypeOffsetDirection = (
  type: introwise.WorkflowAction['trigger']['type'],
  offset: introwise.WorkflowAction['trigger']['offset'],
): WorkflowActionFormValues['triggerTypeWithDirection'] => {
  switch (type) {
    case 'session.created':
      return 'session.created.after';
    case 'session.started':
      return offset < 0 ? 'session.started.before' : 'session.started.after';
    case 'session.ended':
      return offset < 0 ? 'session.ended.before' : 'session.ended.after';
    case 'session.booking.created':
      return 'session.booking.created.after';
  }
};

const actionToDefaultValues = (action?: introwise.WorkflowAction): WorkflowActionFormValues =>
  action
    ? action.type === 'reminder'
      ? {
          triggerTypeWithDirection: triggerTypeOffsetDirection(action.trigger.type, action.trigger.offset),
          offsetUnit: action.trigger.offsetUnit,
          offsetAmount: Math.abs(action.trigger.offsetAmount),
          actionString: action.properties.recipient === 'expert' ? 'reminder.expert' : 'reminder.client',
          message: action.properties.message,
        }
      : action.type === 'email'
      ? {
          triggerTypeWithDirection: triggerTypeOffsetDirection(action.trigger.type, action.trigger.offset),
          offsetUnit: action.trigger.offsetUnit,
          offsetAmount: Math.abs(action.trigger.offsetAmount),
          actionString: action.properties.recipient === 'expert' ? 'email.expert' : 'email.client',
          message: action.properties.message,
        }
      : {
          triggerTypeWithDirection: triggerTypeOffsetDirection(action.trigger.type, action.trigger.offset),
          offsetUnit: action.trigger.offsetUnit,
          offsetAmount: Math.abs(action.trigger.offsetAmount),
          actionString: 'automation',
          description: action.properties.description,
        }
    : {
        triggerTypeWithDirection: 'session.started.before',
        offsetUnit: 'hour',
        offsetAmount: 1,
        actionString: 'reminder.client',
        message: '',
      };

const maxEmailMessageLength = 2000;
const maxReminderMessageLength = 800;
const maxDescriptionLength = 60;

const WorkflowActionForm = ({
  action,
  onSubmit,
  onDelete,
  submitting,
  error,
}: {
  action?: introwise.WorkflowAction;
  onSubmit: (values: WorkflowActionFormValues) => void;
  onDelete?: () => void;
  submitting: boolean;
  error: Error;
}) => {
  const {
    register,
    watch,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<WorkflowActionFormValues>({
    defaultValues: actionToDefaultValues(action),
  });
  const offsetAmount = watch('offsetAmount');
  const offsetUnit = watch('offsetUnit');
  const actionString = watch('actionString');
  const fields = fieldsByActionString[actionString];

  const isEmailAction = actionString.startsWith('email');

  const triggerTypeWithDirection = watch('triggerTypeWithDirection');
  const direction = triggerTypeWithDirection.endsWith('before')
    ? 'before'
    : triggerTypeWithDirection.endsWith('after')
    ? 'after'
    : undefined;

  const triggerTypesWithDirectionEnabled = triggerTypesWithDirection;

  const actionsPerTriggerTypeEnabled = actionsPerTriggerType;

  const actions = actionsPerTriggerTypeEnabled[triggerTypeWithDirection];
  const onTriggerChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value as WorkflowActionFormValues['triggerTypeWithDirection'];
    const allowedActions = actionsPerTriggerTypeEnabled[value];
    if (!allowedActions.includes(actionString)) {
      setValue('actionString', allowedActions[0]);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset disabled={submitting}>
        <FormGroup>
          <h5 className={themeClasses({ margin: 0 })}>At</h5>
        </FormGroup>
        <FormGroup>
          <div className={themeClasses({ display: 'flex', gap: 2 })}>
            <Input
              {...register('offsetAmount', {
                valueAsNumber: true,
                min: {
                  value: 0,
                  message: 'Please use a positive number',
                },
                max: {
                  value: offsetUnit === 'minute' ? 30 * 24 * 60 : offsetUnit === 'hour' ? 30 * 24 : 30,
                  message: 'Cannot schedule actions more than 30 days in advance',
                },
                validate: (value) => {
                  if (value === 0 && direction === 'before') {
                    return 'Offset cannot be zero when scheduling before a trigger';
                  }
                },
              })}
              min={direction === 'before' ? 1 : 0}
              type="number"
              style={{ width: 100 }}
            />
            <Select {...register('offsetUnit')}>
              <option value="minute">minute{offsetAmount == 1 ? '' : 's'}</option>
              <option value="hour">hour{offsetAmount == 1 ? '' : 's'}</option>
              <option value="day">day{offsetAmount == 1 ? '' : 's'}</option>
            </Select>
          </div>
          {errors.offsetAmount && <FormError>{errors.offsetAmount.message}</FormError>}
        </FormGroup>
        <FormGroup>
          <Select {...register('triggerTypeWithDirection', { onChange: onTriggerChange })}>
            {triggerTypesWithDirectionEnabled.map((triggerTypeWithDirection) => (
              <option key={triggerTypeWithDirection} value={triggerTypeWithDirection}>
                {triggerTypeWithDirectionReadable[triggerTypeWithDirection]}
              </option>
            ))}
          </Select>
        </FormGroup>
        <FormGroup>
          <h5 className={themeClasses({ margin: 0 })}>Do</h5>
        </FormGroup>
        {actions.length > 0 && (
          <>
            <FormGroup>
              <Select {...register('actionString')}>
                {actions.map((actionString) => (
                  <option key={actionString} value={actionString}>
                    {actionStringReadable[actionString]}
                  </option>
                ))}
              </Select>
              {actionDescription[actionString] && <FormDescription>{actionDescription[actionString]}</FormDescription>}
            </FormGroup>
            {fields.has('message') && (
              <FormGroup>
                <Label>
                  <LabelText>Message{isEmailAction ? '' : ' (optional)'}</LabelText>
                  <TextArea
                    {...register('message', {
                      maxLength: {
                        value: isEmailAction ? maxEmailMessageLength : maxReminderMessageLength,
                        message: 'Message is too long',
                      },
                      required: isEmailAction ? 'Message is required' : false,
                    })}
                    hasError={!!errors.message}
                    rows={5}
                  />
                </Label>
                <FormFootnote>
                  <FormValueLength
                    name="message"
                    control={control}
                    maxLength={isEmailAction ? maxEmailMessageLength : maxReminderMessageLength}
                  />
                </FormFootnote>
                <FormDescription>
                  {isEmailAction ? 'A message to send in the email' : 'A short message to include with the reminder'}
                </FormDescription>
                {errors.message && <FormError>{errors.message.message}</FormError>}
              </FormGroup>
            )}
            {fields.has('description') && (
              <>
                <FormGroup>
                  <Label>
                    <LabelText>Description (optional)</LabelText>
                    <Input
                      {...register('description', {
                        maxLength: {
                          value: maxDescriptionLength,
                          message: 'Description is too long',
                        },
                      })}
                      placeholder="Create a custom page in Notion"
                    />
                  </Label>
                  <FormFootnote>
                    <FormValueLength name="description" control={control} maxLength={maxDescriptionLength} />
                  </FormFootnote>
                  <FormDescription>What this action does in Zapier</FormDescription>
                </FormGroup>
                <FormGroup>
                  <FormDescription>
                    Once the action is created, create a new automation in Zapier with a trigger &quot;Workflow Action
                    Triggered&quot; and connect this workflow to any app you want.
                  </FormDescription>
                </FormGroup>
              </>
            )}
          </>
        )}
        {actions.length === 0 && (
          <FormGroup>
            You have discovered a workflow feature that is not yet available on your account.{' '}
            <LinkStyled to="/contact" target="_blank">
              Let us know
            </LinkStyled>{' '}
            what you&apos;re building and we&apos;ll help you set it up.
          </FormGroup>
        )}
        <FormGroup className={themeClasses({ display: 'flex', justifyContent: 'space-between', alignItems: 'center' })}>
          <Button type="submit" size="md" disabled={actions.length === 0}>
            {submitting && <Spinner />}
            <span>Save</span>
          </Button>
          {onDelete && <InlineButton onClick={onDelete}>Delete</InlineButton>}
        </FormGroup>
        {error && <FormError>Something went wrong. Please try again</FormError>}
      </fieldset>
    </form>
  );
};

const offsetFromUnitAmount = (
  offsetUnit: introwise.WorkflowAction['trigger']['offsetUnit'],
  offsetAmount: introwise.WorkflowAction['trigger']['offsetAmount'],
) =>
  (offsetUnit === 'minute'
    ? offsetAmount
    : offsetUnit === 'hour'
    ? offsetAmount * 60
    : offsetUnit === 'day'
    ? offsetAmount * 60 * 24
    : 0) * 60;

const WorkflowActionCreateButton = ({ workflowId, pageId }: { workflowId: string; pageId: string }) => {
  const firestore = useFirestore();
  const [isOpen, setIsOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useErrorStateHandler();
  const tracking = useTracking();

  const onSubmit = async (values: WorkflowActionFormValues) => {
    setSubmitting(true);
    setError(null);
    try {
      const { offsetUnit, offsetAmount: offsetAmountAbs, actionString, triggerTypeWithDirection } = values;

      const triggerType = triggerTypeWithDirection.substring(
        0,
        triggerTypeWithDirection.lastIndexOf('.'),
      ) as introwise.WorkflowAction['trigger']['type'];
      const triggerDirection = triggerTypeWithDirection.substring(triggerTypeWithDirection.lastIndexOf('.') + 1) as
        | 'before'
        | 'after';
      const offsetAmount = triggerDirection === 'before' ? -offsetAmountAbs : offsetAmountAbs;
      const offset = offsetFromUnitAmount(offsetUnit, offsetAmount);

      const [actionType, actionRecipient] = actionString.split('.') as [
        introwise.WorkflowAction['type'],
        introwise.WorkflowActionReminder['properties']['recipient'],
      ];

      const commonValues: Pick<introwise.WorkflowAction, 'trigger' | 'type'> = {
        trigger: {
          type: triggerType,
          offset,
          offsetUnit,
          offsetAmount,
        },
        type: actionType,
      };

      const properties =
        actionType === 'reminder' || actionType === 'email'
          ? {
              recipient: actionRecipient,
              message: values.message,
            }
          : {
              description: values.description,
            };

      const action: introwise.FirestoreWriteData<introwise.WorkflowAction> = {
        createdAt: serverTimestamp(),
        properties,
        ...commonValues,
      };
      const workflowRef = doc(firestore, 'pages', pageId, 'workflows', workflowId);
      const actionRef = doc(collection(workflowRef, 'workflowActions'));
      const denormalizedAction: introwise.Workflow['actions'][0] = {
        id: actionRef.id,
        ...commonValues,
      };
      const batch = writeBatch(firestore);
      batch.set(actionRef, action);
      batch.update(workflowRef, { [`actions.${actionRef.id}`]: denormalizedAction });
      await batch.commit();
      tracking.trackEvent('Workflow Action Created', { actionType, triggerType, offset });
      setSubmitting(false);
      setIsOpen(false);
    } catch (error) {
      setError(error);
      setSubmitting(false);
    }
  };

  return (
    <>
      <InlineButton onClick={() => setIsOpen(true)}>
        <FontAwesomeIcon icon={faPlusCircle} fixedWidth /> Add an action
      </InlineButton>
      <GenericReactModal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
        <div style={{ maxWidth: '90vw', width: 450 }}>
          <h4 className={themeClasses({ margin: 0 })}>New action</h4>
          <WorkflowActionForm onSubmit={onSubmit} submitting={submitting} error={error} />
        </div>
      </GenericReactModal>
    </>
  );
};

const WorkflofActionsLimitReached = () => (
  <div style={{ color: themeVars.color.muted }}>
    <FontAwesomeIcon icon={faTimesCircle} fixedWidth /> You&apos;ve reached the limit of 20 actions per workflow
  </div>
);

const WorkflowActionEditButton = ({
  workflowId,
  pageId,
  action,
}: {
  workflowId: string;
  pageId: string;
  action: introwise.WorkflowAction;
}) => {
  const firestore = useFirestore();
  const [isOpen, setIsOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useErrorStateHandler();
  const tracking = useTracking();

  const onEdit = async (values: WorkflowActionFormValues) => {
    setSubmitting(true);
    setError(null);
    try {
      const { offsetUnit, offsetAmount: offsetAmountAbs, actionString, triggerTypeWithDirection } = values;

      const triggerType = triggerTypeWithDirection.substring(
        0,
        triggerTypeWithDirection.lastIndexOf('.'),
      ) as introwise.WorkflowAction['trigger']['type'];
      const triggerDirection = triggerTypeWithDirection.substring(triggerTypeWithDirection.lastIndexOf('.') + 1) as
        | 'before'
        | 'after';
      const offsetAmount = triggerDirection === 'before' ? -offsetAmountAbs : offsetAmountAbs;
      const offset = offsetFromUnitAmount(offsetUnit, offsetAmount);

      const [actionType, actionRecipient] = actionString.split('.') as [
        introwise.WorkflowAction['type'],
        introwise.WorkflowActionReminder['properties']['recipient'],
      ];

      const commonValues: Pick<introwise.WorkflowAction, 'trigger' | 'type'> = {
        trigger: {
          type: triggerType,
          offset,
          offsetUnit,
          offsetAmount,
        },
        type: actionType,
      };

      const properties =
        actionType === 'reminder' || actionType === 'email'
          ? {
              recipient: actionRecipient,
              message: values.message,
            }
          : {
              description: values.description,
            };

      const actionUpdate: introwise.FirestoreUpdateData<introwise.WorkflowAction> = {
        properties,
        ...commonValues,
      };
      const workflowRef = doc(firestore, 'pages', pageId, 'workflows', workflowId);
      const actionRef = doc(workflowRef, 'workflowActions', action.id);
      const denormalizedAction: introwise.Workflow['actions'][0] = {
        id: actionRef.id,
        ...commonValues,
      };
      const batch = writeBatch(firestore);
      batch.update(actionRef, actionUpdate);
      batch.update(workflowRef, { [`actions.${actionRef.id}`]: denormalizedAction });
      await batch.commit();
      tracking.trackEvent('Workflow Action Updated', { actionType, triggerType, offset });
      setSubmitting(false);
      setIsOpen(false);
    } catch (error) {
      setError(error);
      setSubmitting(false);
    }
  };

  const onDelete = async () => {
    setSubmitting(true);
    setError(null);
    try {
      const workflowRef = doc(firestore, 'pages', pageId, 'workflows', workflowId);
      const actionRef = doc(workflowRef, 'workflowActions', action.id);
      const batch = writeBatch(firestore);
      batch.delete(actionRef);
      batch.update(workflowRef, { [`actions.${actionRef.id}`]: deleteField() });
      await batch.commit();
      tracking.trackEvent('Workflow Action Removed');
      setSubmitting(false);
      setIsOpen(false);
    } catch (error) {
      setError(error);
      setSubmitting(false);
    }
  };

  return (
    <>
      <InlineButton onClick={() => setIsOpen(true)}>Edit</InlineButton>
      <GenericReactModal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
        <div style={{ maxWidth: '90vw', width: 450 }}>
          <h4 className={themeClasses({ margin: 0 })}>Edit action</h4>
          <WorkflowActionForm
            action={action}
            onSubmit={onEdit}
            onDelete={onDelete}
            submitting={submitting}
            error={error}
          />
        </div>
      </GenericReactModal>
    </>
  );
};

const recipientToStrign = (recipient: introwise.WorkflowActionReminder['properties']['recipient']) =>
  recipient === 'client' ? 'clients' : 'me';

const triggerOffsetToString = (trigger: introwise.WorkflowAction['trigger']) => {
  const { offset, offsetUnit, offsetAmount } = trigger;
  const offsetStr =
    offsetAmount === 0
      ? 'Immediately '
      : `${Math.abs(offsetAmount)} ${offsetUnit}${Math.abs(offsetAmount) === 1 ? '' : 's'} `;
  const direction = offset < 0 ? 'before' : 'after';
  return `${offsetStr} ${direction}`;
};

const WorkflowAction = ({
  pageId,
  workflowId,
  action,
}: {
  pageId: string;
  workflowId: string;
  action: introwise.WorkflowAction;
}) => {
  return (
    <div className={themeClasses({ marginY: 2 })}>
      <div>
        <FontAwesomeIcon icon={faBell} fixedWidth /> <b>{triggerOffsetToString(action.trigger)}</b>
      </div>
      {action.type === 'reminder' && (
        <>
          <div>Send a reminder to {recipientToStrign(action.properties.recipient)}</div>
          {action.properties.message && (
            <div className={themeClasses({ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' })}>
              <i>{action.properties.message}</i>
            </div>
          )}
        </>
      )}
      {action.type === 'email' && (
        <>
          <div>Send an email to {recipientToStrign(action.properties.recipient)}</div>
          {action.properties.message && (
            <div className={themeClasses({ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' })}>
              <i>{action.properties.message}</i>
            </div>
          )}
        </>
      )}
      {action.type === 'automation' && (
        <>
          <div>Trigger custom automation</div>
          <div className={themeClasses({ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' })}>
            {action.properties.description ? <i>{action.properties.description}</i> : <i>ID for Zapier: {action.id}</i>}
          </div>
        </>
      )}
      <WorkflowActionEditButton pageId={pageId} workflowId={workflowId} action={action} />
    </div>
  );
};

const workflowTriggers = [
  'session.created',
  // 'session.booking.created',
  'session.started',
  'session.ended',
] as const;

const WorkflowTrigger = ({ trigger }: { trigger: introwise.WorkflowTriggerType }) => {
  switch (trigger) {
    case 'session.created':
      return (
        <>
          <FontAwesomeIcon icon={faCheckCircle} fixedWidth /> Session is scheduled
        </>
      );
    case 'session.started':
      return (
        <>
          <FontAwesomeIcon icon={faPlayCircle} fixedWidth /> Session starts
        </>
      );
    case 'session.ended':
      return (
        <>
          <FontAwesomeIcon icon={faStopCircle} fixedWidth /> Session ends
        </>
      );
    default:
      return <>Unknown trigger</>;
  }
};

const WorkflowPauseButton = ({ pageId, workflow }: { pageId: string; workflow: introwise.Workflow }) => {
  const firestore = useFirestore();
  const [page] = useContext(BookingPageContext);
  const [submitting, setSubmitting] = useState(false);
  const [, setError] = useErrorStateHandler();
  const tracking = useTracking();

  const allowMultiplWorkflows = useFeatureIncludedCheck('multipleWorkflows');
  const hasReachedMaxWorkflows = !allowMultiplWorkflows && page.workflowsActiveCount >= 1;
  const upgradePath = featuresUpgradePath['multipleWorkflows'];
  const upgradePathName = pricingPlans[upgradePath].name;

  const onToggle = async () => {
    const newActive = !workflow.active;

    if (hasReachedMaxWorkflows && newActive) {
      window.alert(
        `You have reached the maximum number of active workflows for your plan. Please upgrade to the ${upgradePathName} plan to unpause this workflow.`,
      );
      return;
    }

    setSubmitting(true);
    setError(null);
    try {
      const pageRef = doc(firestore, 'pages', pageId);
      const workflowRef = doc(pageRef, 'workflows', workflow.id);
      const batch = writeBatch(firestore);
      const pageUpdate: introwise.FirestoreUpdateData<introwise.Page> = {
        workflowsActiveCount: increment(newActive ? 1 : -1),
      };
      batch.update(workflowRef, { active: newActive });
      batch.update(pageRef, pageUpdate);
      await batch.commit();
      tracking.trackEvent(newActive ? 'Workflow Unpaused' : 'Workflow Paused');
      setSubmitting(false);
    } catch (error) {
      setError(error);
      setSubmitting(false);
    }
  };

  return (
    <InlineButton onClick={onToggle} disabled={submitting}>
      {workflow.active ? <span>Pause</span> : <span>Unpause</span>}
    </InlineButton>
  );
};

const WorkflowUsage = ({ workflow }: { workflow: introwise.Workflow }) => {
  const [page, , error] = useContext(BookingPageContext);
  useErrorHandler(error);
  const [modalOpen, setModalOpen] = useState(false);

  const servicesUsed =
    page?.services && Object.values(page.services).filter((service) => service.workflowsIds?.includes(workflow.id));

  const seriesUsed =
    page?.series && Object.values(page.series).filter((series) => series.workflowsIds?.includes(workflow.id));

  const servicesCount = servicesUsed?.length || 0;
  const seriesCount = seriesUsed?.length || 0;

  return (
    <>
      <GenericReactModal isOpen={modalOpen} onRequestClose={() => setModalOpen(false)}>
        <div style={{ width: 300 }}>
          {servicesCount > 0 && (
            <>
              <h4 className={themeClasses({ marginTop: 0 })}>Used in these services:</h4>
              <ListUnstyled>
                {servicesUsed?.map((service) => (
                  <li key={service.id}>
                    <LinkStyled to={`/dashboard/scheduling/services/${service.id}`}>{service.title}</LinkStyled>
                  </li>
                ))}
              </ListUnstyled>
            </>
          )}
          {seriesCount > 0 && (
            <>
              <h4 className={themeClasses({ ...(servicesCount === 0 && { marginTop: 0 }) })}>Used in these series:</h4>
              <ListUnstyled>
                {seriesUsed?.map((series) => (
                  <li key={series.id}>
                    <LinkStyled to={`/dashboard/scheduling/series/${series.id}`}>{series.title}</LinkStyled>
                  </li>
                ))}
              </ListUnstyled>
            </>
          )}
        </div>
      </GenericReactModal>
      <div>
        {servicesCount + seriesCount > 0 && <>Used in </>}
        {servicesCount > 0 && (
          <>
            <InlineButton onClick={() => setModalOpen((val) => !val)}>
              {servicesCount} service{servicesCount === 1 ? '' : 's'}
            </InlineButton>
          </>
        )}
        {seriesCount > 0 && (
          <>
            {servicesCount > 0 && <> and </>}
            <InlineButton onClick={() => setModalOpen((val) => !val)}>{seriesCount} series</InlineButton>
          </>
        )}
        {servicesCount === 0 && seriesCount === 0 && <>Not used in any services or series</>}
      </div>
    </>
  );
};

const WorkflowEdit = () => {
  const { userData } = useContext(UserContext);
  const workflow = useContext(WorkflowContext);
  const { bookingPageId: pageId } = userData;
  const firestore = useFirestore();
  const [workflowActions, loading, error] = useFirestoreCollectionData(
    collection(firestore, 'pages', pageId, 'workflows', workflow.id, 'workflowActions').withConverter(
      firestoreWorkflowActionConverter,
    ),
  );

  const workflowActionsSorted = useMemo(
    () => workflowActions?.sort((a, b) => a.trigger.offset - b.trigger.offset) || [],
    [workflowActions],
  );

  return (
    <>
      <Helmet title={`Workflows – ${workflow.name}`} />
      <ScreenTracker screenName="SettingsSchedulingWorkflowEdit" />
      <BackLink to=".." />
      {workflow.deleted && (
        <ColumnContainer>
          <div>
            <WithHeaderContentColumn header="Workflow" whiteBackground>
              <div className={themeClasses({ display: 'flex', justifyContent: 'space-between' })}>
                <h3 className={themeClasses({ margin: 0 })}>{workflow.name}</h3>
                <div>
                  <Flare variant="error">Deleted</Flare>
                </div>
              </div>
              <div>This workflow has been deleted.</div>
            </WithHeaderContentColumn>
          </div>
        </ColumnContainer>
      )}
      {!workflow.deleted && (
        <ColumnContainer>
          <div>
            <WithHeaderContentColumn header="Workflow" whiteBackground>
              <div className={themeClasses({ display: 'flex', justifyContent: 'space-between' })}>
                <h3 className={themeClasses({ margin: 0 })}>{workflow.name}</h3>
                <div className={themeClasses({ display: 'flex', gap: 2 })}>
                  {workflow.default && (
                    <div>
                      <Flare variant="success">Default</Flare>
                    </div>
                  )}
                  {workflow.active ? (
                    <div>
                      <Flare variant="success">Active</Flare>
                    </div>
                  ) : (
                    <div>
                      <Flare variant="error">Paused</Flare>
                    </div>
                  )}
                </div>
              </div>
              <div
                className={themeClasses({
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'start',
                  flexWrap: 'wrap',
                })}
              >
                <div>
                  <WorkflowUsage workflow={workflow} />
                </div>
                <div className={themeClasses({ display: 'flex', gap: 3 })}>
                  <LinkStyled to="edit">Edit</LinkStyled>
                  <WorkflowPauseButton pageId={pageId} workflow={workflow} />
                </div>
              </div>
            </WithHeaderContentColumn>
            <div>
              {loading && (
                <Fake height={16} animated className={themeClasses({ marginTop: 10 })}>
                  Loading...
                </Fake>
              )}
              {error && <ContentColumn whiteBackground>Error: {error.message}</ContentColumn>}
              {!loading && !error && (
                <ContentColumn whiteBackground>
                  <div>
                    {workflowActionsSorted.length < 20 ? (
                      <WorkflowActionCreateButton workflowId={workflow.id} pageId={pageId} />
                    ) : (
                      <WorkflofActionsLimitReached />
                    )}
                  </div>
                  {workflowTriggers.map((triggerType, idx) => {
                    const triggersBefore = workflowActionsSorted.filter(
                      (action) => action.trigger.type === triggerType && action.trigger.offset < 0,
                    );
                    const triggersAfter = workflowActionsSorted.filter(
                      (action) => action.trigger.type === triggerType && action.trigger.offset >= 0,
                    );

                    return (
                      <div key={triggerType}>
                        {idx !== 0 && (
                          <div
                            style={{ marginLeft: 9, paddingLeft: 12, borderLeft: `2px solid ${themeVars.color.muted}` }}
                          >
                            {triggersBefore.map((action) => (
                              <WorkflowAction
                                key={action.id}
                                pageId={pageId}
                                workflowId={workflow.id}
                                action={action}
                              />
                            ))}
                          </div>
                        )}
                        <div className={themeClasses({ fontWeight: 'bold', marginY: 2 })}>
                          <WorkflowTrigger trigger={triggerType} />
                        </div>
                        <>
                          {triggersAfter.length > 0 && (
                            <div
                              style={{
                                marginLeft: 9,
                                paddingLeft: 12,
                                borderLeft: `2px solid ${themeVars.color.muted}`,
                              }}
                            >
                              {triggersAfter.map((action) => (
                                <WorkflowAction
                                  key={action.id}
                                  pageId={pageId}
                                  workflowId={workflow.id}
                                  action={action}
                                />
                              ))}
                            </div>
                          )}
                          {idx !== workflowTriggers.length - 1 && (
                            <div
                              className={themeClasses({ fontWeight: 'bold', marginY: 2 })}
                              style={{ color: themeVars.color.muted }}
                            >
                              <FontAwesomeIcon icon={faEllipsisV} fixedWidth />
                            </div>
                          )}
                        </>
                      </div>
                    );
                  })}
                  {workflowActionsSorted.length === 0 && <>This workflow doesn&apos;t have any actions yet</>}
                </ContentColumn>
              )}
            </div>
          </div>
          <div>
            <WithHeaderContentColumn header={'Built-in actions'}>
              <p>
                Booking confirmations, rescheduling and cancellation notifications are always sent automatically. You
                don&apos;t need to include them in your workflows.
              </p>
            </WithHeaderContentColumn>
            <WithHeaderContentColumn header="Monitoring">
              <LinkStyled to="monitor">View scheduled executions</LinkStyled>
            </WithHeaderContentColumn>
          </div>
        </ColumnContainer>
      )}
    </>
  );
};

export default WorkflowEdit;
