import React from 'react';
import { useNavigate, useParams } from 'react-router';
import BackLink from 'web/components/BackLink';
import BookingPageLink from 'web/components/BookingPageLink';
import ColumnContainer from 'web/components/ColumnContainer';
import Meta from 'web/components/Meta';
import ServiceEditImpl from 'web/components/Services/ServiceEdit';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';

const ServiceEdit = () => {
  const { serviceId } = useParams();

  const navigate = useNavigate();

  return (
    <>
      <Meta title="Edit a service" />
      <BackLink to="/dashboard/scheduling" />
      <ColumnContainer>
        <WithHeaderContentColumn header="Edit a service" whiteBackground>
          <ServiceEditImpl
            onSuccess={() => navigate('/dashboard/scheduling')}
            onClone={(service: introwise.Service) =>
              navigate('/dashboard/scheduling/services/create', { state: { service } })
            }
            serviceId={serviceId}
            withWorkflows
            withLocation
            withAvailability
          />
        </WithHeaderContentColumn>
        <WithHeaderContentColumn header="Link to this service">
          <BookingPageLink path={`/services/${serviceId}`} />
        </WithHeaderContentColumn>
      </ColumnContainer>
    </>
  );
};

export default ServiceEdit;
