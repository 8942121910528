import React from 'react';
import themeClasses from 'web/styles/themeClasses.css';
import BackButton from './BackButton';

const BackLink = ({ to }: { to: string }) => (
  <div className={themeClasses({ position: 'absolute' })}>
    <BackButton initialTo={to} className="" />
  </div>
);

export default BackLink;
