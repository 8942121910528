import React from 'react';
import { Accordion, LinkStyled } from '../elements';

const CalendarPrivacyNotice = () => (
  <Accordion.Details>
    <Accordion.Summary>How Introwise uses your calendar information</Accordion.Summary>

    <Accordion.Dl>
      <Accordion.Dt>Read calendars on your account</Accordion.Dt>
      <Accordion.Dd>
        To allow you to select the calendars to check for conflicts or add sessions to Introwise reads the list of
        calendars that you have access to on your account.
      </Accordion.Dd>

      <Accordion.Dt>Read your free/busy information</Accordion.Dt>
      <Accordion.Dd>
        To check for time conflicts Introwise reads start and end times of the periods that are shown as
        &quot;busy&quot; on the calendars you have selected. Introwise does not read or store your event details, such
        as title, attendees, etc.
      </Accordion.Dd>

      <Accordion.Dt>Write to the calendars you own</Accordion.Dt>
      <Accordion.Dd>
        Introwise automatically creates and updates events on the calendars you have selected for new sessions scheduled
        or booked. Introwise does not read, modify or store other events on your calendars.
      </Accordion.Dd>
    </Accordion.Dl>

    <LinkStyled to="/privacy-policy">Privacy Policy</LinkStyled>
  </Accordion.Details>
);

export default CalendarPrivacyNotice;
