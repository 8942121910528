import { faCheck, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { httpsCallable } from 'firebase/functions';
import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Button, FormError, InlineButton } from 'web/components/elements';
import {
  IntegrationBlock,
  IntegrationBlockTitle,
  IntegrationInlineMark,
  IntegrationTitle,
} from 'web/components/integrations/common';
import UserContext from 'web/components/UserContext';
import useErrorReporter from 'web/hooks/useErrorReporter';
import settings from 'web/utils/settings';
import useFunctions from 'web/components/FirebaseContext/useFunctions';
import Spinner from '../Spinner';
import themeConstants from 'web/styles/themeConstants';

const ConnectButtonsGroup = styled.div`
  ${themeConstants.media.sm} {
    text-align: center;
  }
`;

const ZoomIcon = (props: React.ImgHTMLAttributes<HTMLImageElement>) => (
  <img {...props} src="/integrations-zoom-icon.png" />
);

const makeZoomOAuthUrl = ({ clientId, redirectUri, state }: { clientId: string; redirectUri: string; state: string }) =>
  `https://zoom.us/oauth/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(
    redirectUri,
  )}&response_type=code&state=${state}`;

const ZoomIntegration = ({ isDefault, onClickDefault }: { isDefault: boolean; onClickDefault: () => void }) => {
  const functions = useFunctions();
  const { userData } = useContext(UserContext);
  const [disconnecting, setDisconnecting] = useState(false);
  const [error, setError] = useState(null);
  const errorReporter = useErrorReporter();
  const busy = disconnecting;

  const zoomAccount =
    userData.connectedAccounts &&
    (Object.values(userData.connectedAccounts).find(
      (account) => account.type === 'conferencing' && account.provider === 'zoom',
    ) as introwise.ZoomConferencingAccount);

  const onClickConnect = (reauthenticate?: boolean) => {
    const state = nanoid();
    window.localStorage.setItem('introwise-zoom-oauth-state', state);
    if (reauthenticate) {
      window.localStorage.setItem('introwise-zoom-oauth-reauthenticate', 'true');
    }
    const redirectUri = `${window.origin}/settings/scheduling/zoom-oauth`;
    const clientId = settings.zoom.clientId;
    window.location.href = makeZoomOAuthUrl({ clientId, redirectUri, state });
  };

  const onClickDisconnect = async (account: introwise.ZoomConferencingAccount) => {
    const res = window.confirm(`Are you sure you want to disconnect this Zoom account?`);
    if (!res) {
      return;
    }
    setDisconnecting(true);
    setError(null);
    try {
      await httpsCallable(functions, 'integrationsDisconnectAccount')({ accountId: account.id });
    } catch (err) {
      errorReporter.report(new Error(`Error on zoom account disconnect: ${err}`));
      setError('Something went wrong.');
    }
    setDisconnecting(false);
  };

  return (
    <>
      <IntegrationBlock>
        <IntegrationBlockTitle>
          <ZoomIcon width={40} style={{ margin: 4 }} />
          <div>
            <IntegrationTitle>
              Zoom{' '}
              {zoomAccount && zoomAccount.hasAuthError && (
                <span style={{ color: 'red' }}>
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                </span>
              )}
            </IntegrationTitle>
            {zoomAccount && <div>{zoomAccount.email}</div>}
            {zoomAccount && zoomAccount.hasAuthError && (
              <div>
                <p>There is a problem with your Zoom credentials. Please re-connect your Zoom account.</p>
              </div>
            )}
            <div>
              <small>Automatically create unique Zoom rooms for your sessions</small>
            </div>
          </div>
        </IntegrationBlockTitle>
        <ConnectButtonsGroup>
          {zoomAccount ? (
            <>
              {zoomAccount.hasAuthError ? (
                <div style={{ marginBottom: 8 }}>
                  <Button md primary onClick={() => onClickConnect(true)} disabled={busy}>
                    <span>Reconnect</span>
                  </Button>
                </div>
              ) : isDefault ? (
                <div style={{ marginBottom: 8 }}>
                  <IntegrationInlineMark>
                    <FontAwesomeIcon icon={faCheck} /> Default
                  </IntegrationInlineMark>
                </div>
              ) : (
                <div style={{ marginBottom: 8 }}>
                  <InlineButton onClick={onClickDefault} disabled={busy}>
                    {disconnecting && <Spinner />}
                    <span>Make default</span>
                  </InlineButton>
                </div>
              )}

              <InlineButton onClick={() => onClickDisconnect(zoomAccount)} disabled={busy}>
                {disconnecting && <Spinner />}
                <span>Disconnect</span>
              </InlineButton>
            </>
          ) : (
            <Button md secondary onClick={() => onClickConnect()}>
              Connect
            </Button>
          )}
        </ConnectButtonsGroup>
      </IntegrationBlock>
      {error && <FormError as="p">{error}</FormError>}
    </>
  );
};

export default ZoomIntegration;
