import { $isLinkNode } from '@lexical/link';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import { $getSelection, $isRangeSelection, SELECTION_CHANGE_COMMAND } from 'lexical';
import React, { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import FloatingLinkEditor from './ToolbarPlugin/FloatingLinkEditor';
import { getSelectedNode, LowPriority } from './ToolbarPlugin/utils';

const LinkEditPlugin = () => {
  const [editor] = useLexicalComposerContext();
  const [isLink, setIsLink] = useState(false);
  const [isLinkEditMode, setIsLinkEditMode] = useState(false);

  const updateEditMode = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
        setIsLinkEditMode(false);
      }
    }
  }, []);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateEditMode();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateEditMode();
          return false;
        },
        LowPriority,
      ),
    );
  }, [editor, updateEditMode]);

  return (
    <>
      {isLink && createPortal(<FloatingLinkEditor editor={editor} isInitialEditMode={isLinkEditMode} />, document.body)}
    </>
  );
};

export default LinkEditPlugin;
