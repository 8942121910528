import React, { useContext } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import BackLink from 'web/components/BackLink';
import ColumnContainer from 'web/components/ColumnContainer';
import AppleCalendarIntegrationForm from 'web/components/integrations/AppleCalendarIntegrationForm';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';

const ConnectedCalendarsConnectApple = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { userData } = useContext(UserContext);
  const accountId = searchParams.get('accountId');
  const reauthenticate = !!accountId;
  const account = userData.connectedAccounts?.[accountId] as introwise.AppleCalendarAccount | null;
  const defaultAppleId = account?.appleId || userData.email;

  const cannotReauthenticate = reauthenticate && (!account || account.provider !== 'apple');

  return cannotReauthenticate ? (
    <Navigate to=".." replace />
  ) : (
    <>
      <BackLink to=".." />
      <ColumnContainer>
        <WithHeaderContentColumn header="Apple Calendar account" whiteBackground>
          <AppleCalendarIntegrationForm
            defaultAppleId={defaultAppleId}
            reauthenticate={reauthenticate}
            onConnect={() => navigate('..', { replace: true })}
          />
        </WithHeaderContentColumn>
      </ColumnContainer>
    </>
  );
};

export default ConnectedCalendarsConnectApple;
