import { collection, doc, getDoc, writeBatch } from 'firebase/firestore';
import React, { useContext, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import BackLink from 'web/components/BackLink';
import ColumnContainer from 'web/components/ColumnContainer';
import { FormError, FormGroup, LinkStyled } from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import ScreenTracker from 'web/components/ScreenTracker';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import PersonalAvailabilityForm, { FormValues } from './PersonalAvailabilityForm';
import { firestoreAvailabilityRuleConverter } from 'web/utils/convert';
import useFeatureIncludedCheck from 'web/hooks/useFeatureIncludedCheck';
import themeClasses from 'web/styles/themeClasses.css';
import UpgradeButton from 'web/components/UpgradeButton';
import { featuresUpgradePath, pricingPlans } from 'web/utils/pricingPlans';
import useTracking from 'web/components/TrackingContext/useTracking';

const PersonalAvailabilityCreateImpl = () => {
  const { state } = useLocation();
  const { user } = useContext(UserContext);
  const firestore = useFirestore();
  const tracking = useTracking();
  const [error, setError] = useErrorStateHandler();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);

  const availabilityClone = (state as { availability: introwise.Availability } | undefined)?.availability;

  const timeZone = useMemo(() => Intl.DateTimeFormat().resolvedOptions().timeZone, []);

  const save = async (values: FormValues) => {
    setSubmitting(true);
    try {
      const batch = writeBatch(firestore);
      const userRef = doc(firestore, 'users', user.uid);
      const availabilityRef = doc(collection(userRef, 'cronofy'));
      const availabilityRulesRef = doc(availabilityRef, 'rules', 'default');
      const id = availabilityRef.id;

      const availability: introwise.FirestoreWriteData<introwise.Availability> = {
        name: values.name,
        order: Date.now(),
      };
      const update = {
        [`availabilities.${id}`]: { id, ...availability },
      };
      let availabilityRules: introwise.FirestoreWriteData<introwise.AvailabilityRules>;

      if (availabilityClone) {
        const rulesCloneDoc = await getDoc(
          doc(userRef, 'cronofy', availabilityClone.id, 'rules', 'default').withConverter(
            firestoreAvailabilityRuleConverter,
          ),
        );
        if (!rulesCloneDoc.exists) {
          throw new Error('Failed to clone availability recurring slots');
        }
        const { id, ...rulesClone } = rulesCloneDoc.data();
        availabilityRules = rulesClone;
      } else {
        availabilityRules = {
          timeZone,
          weeklyPeriods: [],
        };
      }

      batch.set(availabilityRef, availability);
      batch.set(availabilityRulesRef, availabilityRules);
      batch.update(userRef, update);

      await batch.commit();
      tracking.trackEvent('Availability Created', { clone: !!availabilityClone });
      navigate(`/dashboard/scheduling/availabilities/${id}`, { replace: true });
    } catch (err) {
      setError(err);
      setSubmitting(false);
    }
  };

  return (
    <>
      <Helmet title="Create a new availability" />
      <ScreenTracker screenName="SettingsAvailabilityPersonalCreate" />
      <BackLink to="/dashboard/scheduling" />
      <ColumnContainer>
        <WithHeaderContentColumn header="Create a new availability" whiteBackground>
          {availabilityClone && (
            <>
              Recurring availability periods will be cloned from{' '}
              <LinkStyled to={`/dashboard/scheduling/availabilities/${availabilityClone.id}`}>
                {availabilityClone.name}
              </LinkStyled>
            </>
          )}
          <PersonalAvailabilityForm
            onSubmit={save}
            submitting={submitting}
            defaultValues={availabilityClone ? { name: `${availabilityClone.name} (clone)` } : undefined}
          />
          {error && (
            <FormGroup>
              <FormError>Failed to create an availability. Please try again later.</FormError>
            </FormGroup>
          )}
        </WithHeaderContentColumn>
        <div></div>
      </ColumnContainer>
    </>
  );
};

const PersonalAvailabilityCreate = () => {
  const featureIncluded = useFeatureIncludedCheck('multipleAvailabilities');

  if (featureIncluded) {
    return <PersonalAvailabilityCreateImpl />;
  } else {
    const upgradePath = pricingPlans[featuresUpgradePath['multipleAvailabilities']];
    return (
      <>
        <Helmet title="Create a new availability" />
        <ScreenTracker screenName="SettingsAvailabilityPersonalCreate" />
        <BackLink to="/dashboard/scheduling" />
        <ColumnContainer equal>
          <WithHeaderContentColumn header="Create a new availability" whiteBackground>
            <p className={themeClasses({ marginTop: 0 })}>
              Create multiple availabilities for specific needs and customize your working hours for different services.
            </p>
            <p>
              Upgrade to the <b>{upgradePath.name}</b> plan to get access to the multiple availabilities feature and{' '}
              <b>{upgradePath.applicationFeePercentage * 100}%</b> Introwise commission.
            </p>
            <div>
              <UpgradeButton to="/dashboard/account/billing/subscription">Upgrade to {upgradePath.name}</UpgradeButton>
            </div>
          </WithHeaderContentColumn>
        </ColumnContainer>
      </>
    );
  }
};

export default PersonalAvailabilityCreate;
