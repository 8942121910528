import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { collection, documentId, getDocs, limit, orderBy, query, startAt, Timestamp, where } from 'firebase/firestore';
import Fuse from 'fuse.js';
import { debounce } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Button, Input, LinkButton, LinkStyled, Table } from 'web/components/elements';
import Fake from 'web/components/elements/Fake';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import Flare from 'web/components/Flare';
import ScreenTracker from 'web/components/ScreenTracker';
import sc from 'web/components/styled';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useErrorReporter from 'web/hooks/useErrorReporter';
import useFirestoreCollectionData from 'web/hooks/useFirestoreCollectionData';
import usePrevious from 'web/hooks/usePrevious';
import themeClasses from 'web/styles/themeClasses.css';
import { firestoreClientConverter } from 'web/utils/convert';
import { toCsvDateTime, toFilenameDateTime } from 'web/utils/csv';

const formatDate = (date: Date) => date.toLocaleString('en', { year: 'numeric', month: 'short', day: 'numeric' });

const sortOrderBy = {
  createdAtDesc: [orderBy('createdAt', 'desc'), orderBy(documentId(), 'desc')],
  createdAtAsc: [orderBy('createdAt', 'asc'), orderBy(documentId(), 'asc')],
  lastActiveAtDesc: [orderBy('lastActiveAt', 'desc'), orderBy(documentId(), 'desc')],
  lastActiveAtAsc: [orderBy('lastActiveAt', 'asc'), orderBy(documentId(), 'asc')],
  lastSessionAtDesc: [orderBy('lastSessionAt', 'desc'), orderBy(documentId(), 'desc')],
  lastSessionAtAsc: [orderBy('lastSessionAt', 'asc'), orderBy(documentId(), 'asc')],
  firstNameAsc: [orderBy('firstNameLower', 'asc'), orderBy('lastNameLower', 'asc'), orderBy(documentId(), 'asc')],
  firstNameDesc: [orderBy('firstNameLower', 'desc'), orderBy('lastNameLower', 'desc'), orderBy(documentId(), 'desc')],
  lastNameAsc: [orderBy('lastNameLower', 'asc'), orderBy('firstNameLower', 'asc'), orderBy(documentId(), 'asc')],
  lastNameDesc: [orderBy('lastNameLower', 'desc'), orderBy('firstNameLower', 'desc'), orderBy(documentId(), 'desc')],
  emailAsc: [orderBy('primaryEmail', 'asc'), orderBy(documentId(), 'asc')],
  emailDesc: [orderBy('primaryEmail', 'desc'), orderBy(documentId(), 'desc')],
};

type SortOrder = keyof typeof sortOrderBy;

const getNextPaginationBySortOder = (startFrom: introwise.Client, sortOrder: SortOrder) => {
  switch (sortOrder) {
    case 'createdAtDesc':
    case 'createdAtAsc':
      return [Timestamp.fromDate(startFrom.createdAt), startFrom.id] as const;
    case 'lastActiveAtDesc':
    case 'lastActiveAtAsc':
      return [Timestamp.fromDate(startFrom.lastActiveAt), startFrom.id] as const;
    case 'lastSessionAtDesc':
    case 'lastSessionAtAsc':
      return [Timestamp.fromDate(startFrom.lastSessionAt), startFrom.id] as const;
    case 'firstNameAsc':
    case 'firstNameDesc':
      return [startFrom.firstNameLower, startFrom.lastNameLower, startFrom.id] as const;
    case 'lastNameAsc':
    case 'lastNameDesc':
      return [startFrom.lastNameLower, startFrom.firstNameLower, startFrom.id] as const;
    case 'emailAsc':
    case 'emailDesc':
      return [startFrom.primaryEmail, startFrom.id] as const;
  }
};

const defaultSortOrder = 'createdAtDesc';

const SortButton = sc.button(
  themeClasses({
    border: 'none',
    cursor: 'pointer',
    background: 'none',
    padding: 0,
    transition: 'button',
    fontWeight: 'bold',
    color: 'inherit',
    width: '100%',
    textAlign: 'left',
  }),
);

const clientsListLimit = 25;

const ClientsList = ({ searchQuery }: { searchQuery: string }) => {
  const firestore = useFirestore();
  const { userData } = useContext(UserContext);

  const [sortOrder, setSortOrder] = React.useState<SortOrder>(() => {
    let storedSortOrder;
    try {
      storedSortOrder = window.localStorage.getItem('introwise.clientsSortOrder') as SortOrder;
    } catch (error) {
      // ignore
    }

    return storedSortOrder && sortOrderBy[storedSortOrder] ? storedSortOrder : defaultSortOrder;
  });
  const saveSortOrder = (sortOrder: SortOrder) => {
    setSortOrder(sortOrder);
    try {
      window.localStorage.setItem('introwise.clientsSortOrder', sortOrder);
    } catch (error) {
      // ignore
    }
  };
  const queryOrderBy = sortOrderBy[sortOrder];

  const hasQuery = searchQuery.length > 0;
  const searchPrefix = useMemo(() => searchQuery.slice(0, 2).toLowerCase(), [searchQuery]);
  const querySearchPrefix = hasQuery ? [where('searchPrefixes', 'array-contains', searchPrefix)] : [];

  const [paginationFirestoreStack, setPaginationFirestoreStack] = useState<
    (readonly [Timestamp, string] | readonly [string, string, string] | readonly [string, string])[]
  >([]);
  const paginationFirestoreStart =
    paginationFirestoreStack.length && paginationFirestoreStack[paginationFirestoreStack.length - 1];

  const sortOrderChanged = usePrevious(sortOrder) !== sortOrder;
  useEffect(() => {
    if (sortOrderChanged) {
      setPaginationFirestoreStack([]);
    }
  }, [sortOrderChanged]);

  const useFirestorePagination = searchQuery.length <= 2;

  const [clientsUnfiltered, loading, error] = useFirestoreCollectionData(
    query(
      collection(firestore, 'pages', userData.bookingPageId, 'clients'),
      ...querySearchPrefix,
      ...queryOrderBy,
      ...(useFirestorePagination
        ? [
            limit(clientsListLimit + 1),
            ...(paginationFirestoreStart && !sortOrderChanged ? [startAt(...paginationFirestoreStart)] : []),
          ]
        : []),
    ).withConverter(firestoreClientConverter),
  );
  useErrorHandler(error);

  const searchIndex = useMemo(
    () =>
      hasQuery && clientsUnfiltered
        ? new Fuse(clientsUnfiltered, { keys: ['firstName', 'lastName', 'primaryEmail'], threshold: 0.2 })
        : undefined,
    [clientsUnfiltered, hasQuery],
  );

  const clientsSearched = useMemo(() => {
    const shouldFuzzySearch = searchIndex && searchQuery.length > 2;
    if (shouldFuzzySearch) {
      return searchIndex.search(searchQuery).map((result) => result.item);
    } else {
      return clientsUnfiltered || [];
    }
  }, [clientsUnfiltered, searchIndex, searchQuery]);

  const hasNext = useFirestorePagination && clientsUnfiltered?.length > clientsListLimit;
  const hasPrev = useFirestorePagination && paginationFirestoreStack.length > 0;

  const clients = useMemo(
    () => (useFirestorePagination ? clientsSearched.slice(0, clientsListLimit) : clientsSearched),
    [clientsSearched, useFirestorePagination],
  );

  const onPrev = () => setPaginationFirestoreStack((stack) => stack.slice(0, stack.length - 1));

  const onNext = () =>
    setPaginationFirestoreStack((stack) => [
      ...stack,
      ...(hasNext ? [getNextPaginationBySortOder(clients[clients.length - 1], sortOrder)] : []),
    ]);

  return (
    <>
      {loading && (
        <Table>
          <thead>
            <tr>
              <th>
                <Fake animated>Loading...</Fake>
              </th>
              <th>
                <Fake animated>Loading...</Fake>
              </th>
              <th>
                <Fake animated>Loading...</Fake>
              </th>
              <th>
                <Fake animated>Loading...</Fake>
              </th>
            </tr>
          </thead>
        </Table>
      )}
      {!loading && error && <>Something went wrong. Please try again later.</>}
      {!loading && !error && clients && clients.length > 0 && (
        <div className={themeClasses({ overflowX: 'auto' })}>
          <Table>
            <thead>
              <tr>
                <th>
                  <SortButton
                    onClick={() =>
                      saveSortOrder(
                        sortOrder === 'firstNameAsc'
                          ? 'firstNameDesc'
                          : sortOrder === 'firstNameDesc'
                          ? 'lastNameAsc'
                          : sortOrder === 'lastNameAsc'
                          ? 'lastNameDesc'
                          : 'firstNameAsc',
                      )
                    }
                  >
                    Name
                    {sortOrder === 'firstNameDesc' && <FontAwesomeIcon icon={faAngleDown} fixedWidth />}
                    {sortOrder === 'firstNameAsc' && <FontAwesomeIcon icon={faAngleUp} fixedWidth />}
                    {sortOrder === 'lastNameDesc' && (
                      <>
                        <FontAwesomeIcon icon={faAngleDown} fixedWidth />
                        <Flare variant="disabled" style={{ fontWeight: 'normal' }}>
                          by last
                        </Flare>
                      </>
                    )}
                    {sortOrder === 'lastNameAsc' && (
                      <>
                        <FontAwesomeIcon icon={faAngleUp} fixedWidth />
                        <Flare variant="disabled" style={{ fontWeight: 'normal' }}>
                          by last
                        </Flare>
                      </>
                    )}
                  </SortButton>
                </th>
                <th>
                  <SortButton onClick={() => saveSortOrder(sortOrder === 'emailAsc' ? 'emailDesc' : 'emailAsc')}>
                    Email
                    {sortOrder === 'emailDesc' && <FontAwesomeIcon icon={faAngleDown} fixedWidth />}
                    {sortOrder === 'emailAsc' && <FontAwesomeIcon icon={faAngleUp} fixedWidth />}
                  </SortButton>
                </th>
                <th>
                  <SortButton
                    onClick={() => saveSortOrder(sortOrder === 'createdAtDesc' ? 'createdAtAsc' : 'createdAtDesc')}
                  >
                    Added
                    {sortOrder === 'createdAtDesc' && <FontAwesomeIcon icon={faAngleDown} fixedWidth />}
                    {sortOrder === 'createdAtAsc' && <FontAwesomeIcon icon={faAngleUp} fixedWidth />}
                  </SortButton>
                </th>
                <th>
                  <SortButton
                    onClick={() =>
                      saveSortOrder(sortOrder === 'lastSessionAtDesc' ? 'lastSessionAtAsc' : 'lastSessionAtDesc')
                    }
                  >
                    Last session
                    {sortOrder === 'lastSessionAtDesc' && <FontAwesomeIcon icon={faAngleDown} fixedWidth />}
                    {sortOrder === 'lastSessionAtAsc' && <FontAwesomeIcon icon={faAngleUp} fixedWidth />}
                  </SortButton>
                </th>
                {/* <th>Last active</th> */}
              </tr>
            </thead>
            <tbody>
              {clients.map((client) => (
                <tr key={client.id}>
                  <td>
                    <LinkStyled to={client.id}>
                      {client.firstName} {client.lastName}
                    </LinkStyled>
                  </td>
                  <td>{client.primaryEmail}</td>
                  <td>{formatDate(client.createdAt)}</td>
                  <td>{client.lastSessionAt ? formatDate(client.lastSessionAt) : ''}</td>
                  {/* <td>{client.lastActiveAt ? formatDate(client.lastActiveAt) : ''}</td> */}
                </tr>
              ))}
            </tbody>
          </Table>
          {(hasPrev || hasNext) && (
            <div className={themeClasses({ display: 'flex', justifyContent: 'space-between', marginTop: 5 })}>
              {hasPrev ? (
                <Button sm secondary onClick={onPrev}>
                  Previous
                </Button>
              ) : (
                <div />
              )}
              {hasNext ? (
                <Button sm secondary onClick={onNext}>
                  Next
                </Button>
              ) : (
                <div />
              )}
            </div>
          )}
        </div>
      )}
      {!loading && !error && clients && clients.length === 0 && <>No clients yet.</>}
    </>
  );
};

const ExportButton = () => {
  const { userData } = useContext(UserContext);
  const [downloading, setDownloading] = useState(false);
  const errorReporter = useErrorReporter();
  const firestore = useFirestore();

  const downloadCsv = async () => {
    setDownloading(true);
    try {
      const [{ download, generateCsv, mkConfig }, clientsDocs] = await Promise.all([
        import('export-to-csv'),
        getDocs(
          query(
            collection(firestore, 'pages', userData.bookingPageId, 'clients'),
            orderBy('createdAt', 'desc'),
          ).withConverter(firestoreClientConverter),
        ),
      ]);

      const clients = clientsDocs.docs.map((doc) => doc.data());

      const filename = `Clients_${toFilenameDateTime(new Date())}`;
      const config = mkConfig({
        filename: filename,
        useKeysAsHeaders: true,
      });

      const csv = generateCsv(config)(
        clients.map((client) => ({
          createdAt: toCsvDateTime(client.createdAt),
          firstName: client.firstName,
          lastName: client.lastName,
          email: client.primaryEmail,
          lastSessionAt: client.lastSessionAt ? toCsvDateTime(client.lastSessionAt) : '',
        })),
      );

      download(config)(csv);
    } catch (err) {
      errorReporter.report(`Failed to export clients: ${err.message}`);
    }
    setDownloading(false);
  };

  return (
    <Button variant="secondary" size="sm" onClick={downloadCsv} disabled={downloading}>
      Export
    </Button>
  );
};

const getSavedSearchQuery = () => {
  let query;
  try {
    query = window.sessionStorage.getItem('introwise.clientsSearchQuery') as string;
  } catch (error) {
    // ignore
  }

  return query || '';
};

const saveSearchQuery = (query: string) => {
  try {
    window.sessionStorage.setItem('introwise.clientsSearchQuery', query);
  } catch (error) {
    // ignore
  }
};

const ClientsDashboard = () => {
  const [searchQuery, setSearchPrefix] = React.useState(getSavedSearchQuery());

  const onChange = useMemo(
    () =>
      debounce((value: string) => {
        const newSearchPrefix = value.trim();
        setSearchPrefix(newSearchPrefix);
        saveSearchQuery(newSearchPrefix);
      }, 500),
    [],
  );
  return (
    <WithHeaderContentColumn
      header={
        <div className={themeClasses({ display: 'flex', gap: 4, alignItems: 'center' })}>
          <h3 className={themeClasses({ margin: 0 })}>Clients</h3>
          <Input
            autoComplete="off"
            type="search"
            placeholder="Search by name or email"
            defaultValue={searchQuery}
            onChange={(e) => onChange(e.target.value)}
          />
        </div>
      }
      whiteBackground
      extendedHeader={
        <div className={themeClasses({ display: 'flex', gap: 4, alignItems: 'center' })}>
          <ExportButton />
          <LinkButton variant="primary" size="sm" to="create">
            Add
          </LinkButton>
        </div>
      }
    >
      <ClientsList searchQuery={searchQuery} />
    </WithHeaderContentColumn>
  );
};

const DashboardClientsIndex = () => (
  <>
    <Helmet title="Clients" />
    <ScreenTracker screenName="DashbordClients" />
    <ClientsDashboard />
  </>
);

export default DashboardClientsIndex;
