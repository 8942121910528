/**
 * Formats a phone number by removing non-digit characters and applying a specific format.
 * Support for US phone numbers only.
 * @param phoneNumber - The phone number to format.
 * @returns The formatted phone number, or null if the input is invalid.
 */
export const formatPhoneNumber = (phoneNumber: string) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return ['(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};
