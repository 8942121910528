import React from 'react';
import { AnchorButton, LinkUnstyled } from 'web/components/elements';
import themeClasses from 'web/styles/themeClasses.css';
import { formatCurrencyAmount } from 'web/utils/currency';
import { isToday } from 'web/utils/dateFormat';
import Bullet from '../Bullet';
import SessionDateTime from './SessionDateTime';
import SessionTimeDiff from './SessionTimeDiff';

const isGroup = (session: introwise.Session): session is introwise.GroupSession => session.type === 'group';

const SessionCard = ({
  session,
  past,
  first,
  useTitle,
}: {
  session: introwise.Session;
  past: boolean;
  first?: boolean;
  useTitle?: boolean;
}) => {
  const title = isGroup(session) || useTitle ? session.title : `${session.client.firstName} ${session.client.lastName}`;
  const today = isToday(session.start);

  return (
    <div
      className={themeClasses({
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: { all: 'column', sm: 'row' },
        gap: 4,
      })}
    >
      <LinkUnstyled to={`/dashboard/home/sessions/${session.id}`} style={{ flex: 1 }}>
        <div className={themeClasses({ display: { all: 'grid', sm: 'flex' } })}>
          <b>{title}</b>
          <Bullet className={themeClasses({ display: { sm: 'initial', all: 'none' } })} />
          <SessionDateTime start={session.start} end={session.end} />
        </div>

        {!past && first && <SessionTimeDiff start={session.start} />}

        {!past && (
          <div style={{ marginTop: '10px' }}>
            <strong>Total: {formatCurrencyAmount(session.totalPaymentAmount, session.currency)}</strong>

            <span>
              <Bullet />
              {isGroup(session) ? (
                <>
                  {session.groupSize.current}/{session.groupSize.max} booked
                </>
              ) : (
                <>{session.title || 'Personal session'}</>
              )}
            </span>
          </div>
        )}
      </LinkUnstyled>
      <div>
        {!past && (
          <>
            <AnchorButton
              variant={first && today ? 'primary' : 'secondary'}
              md
              href={`/s/${session.id}`}
              target="_blank"
            >
              Join
            </AnchorButton>
          </>
        )}
        {past && <b>{formatCurrencyAmount(session.totalPaymentAmount, session.currency)}</b>}
      </div>
    </div>
  );
};

export default SessionCard;
