import { deleteDoc, doc, updateDoc } from 'firebase/firestore';
import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import BookingPageContext from 'web/components/BookingPageContext';
import ContentColumn from 'web/components/ContentColumn';
import DiscountCodeForm, { DiscountCodeFormSubmitValues } from 'web/components/DiscountCodeForm';
import { FormError, FormGroup } from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import UserContext from 'web/components/UserContext';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import useFeatureIncludedCheck from '../../../../hooks/useFeatureIncludedCheck';
import DiscountCodeContext from './DiscountCodeContext';

const SettingsPaymentsDiscountsEditImpl = () => {
  const {
    userData: { bookingPageId },
  } = useContext(UserContext);
  const { discountId } = useParams();
  const firestore = useFirestore();
  const discountCodeRef = doc(firestore, 'pages', bookingPageId, 'discountCodes', discountId);
  const discountCode = useContext(DiscountCodeContext);
  const [page] = useContext(BookingPageContext);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const [submitError, setSubmitError] = useErrorStateHandler();

  const edit = async (values: DiscountCodeFormSubmitValues) => {
    setSubmitting(true);
    setSubmitError(null);
    try {
      const codeLower = values.code.toLowerCase();
      const update: introwise.FirestoreUpdateData<introwise.DiscountCode> = {
        code: values.code,
        codeLower,
        valueType: values.valueType,
        value: values.value,
        maxRedemptions: values.maxRedemptions,
      };
      await updateDoc(discountCodeRef, update);
      navigate('../..', { replace: true });
    } catch (err) {
      setSubmitError(err);
      setSubmitting(false);
    }
  };

  const remove = async () => {
    const res = confirm('Are you sure you want to delete this discount code?');
    if (!res) {
      return;
    }
    setSubmitting(true);
    setSubmitError(null);
    try {
      await deleteDoc(discountCodeRef);
      navigate('../..', { replace: true });
    } catch (err) {
      setSubmitError(err);
      setSubmitting(false);
    }
  };

  return (
    <>
      <DiscountCodeForm
        onSubmit={edit}
        submitting={submitting}
        bookingPageId={bookingPageId}
        initialCurrency={page.currency}
        discountCode={discountCode}
        onDelete={remove}
      />
      {submitError && (
        <FormGroup>
          <FormError>Something went wrong. Please try again later.</FormError>
        </FormGroup>
      )}
    </>
  );
};

const SettingsPaymentsDiscountsEdit = () => {
  const featureIncluded = useFeatureIncludedCheck('discounts');
  return (
    <>
      <Helmet title="Edit a discount code" />
      <ContentColumn whiteBackground>
        {featureIncluded ? (
          <SettingsPaymentsDiscountsEditImpl />
        ) : (
          <div>Discount codes feature is not available on your plan.</div>
        )}
      </ContentColumn>
    </>
  );
};

export default SettingsPaymentsDiscountsEdit;
