import { httpsCallable } from 'firebase/functions';
import React, { useContext, useState } from 'react';
import useErrorReporter from 'web/hooks/useErrorReporter';
import themeClasses from 'web/styles/themeClasses.css';
import themeVars from 'web/styles/themeVars.css';
import { Button } from './elements';
import useFunctions from './FirebaseContext/useFunctions';
import Spinner from './Spinner';
import sc from './styled';
import UserContext from './UserContext';

const WarningStyled = sc.div(
  themeClasses({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: { all: 'column', md: 'row' },
    gap: { all: 2, md: 4 },
    paddingY: { all: 3, md: 1 },
    paddingX: { all: 3, md: 4 },
  }),
  { color: '#fff', backgroundColor: themeVars.color.accent },
);

const Warning = () => {
  const functions = useFunctions();
  const { user } = useContext(UserContext);
  const [sending, setSwitching] = useState(false);
  const [sent, setSent] = useState(false);
  const errorReporter = useErrorReporter();

  const resend = async () => {
    setSwitching(true);
    try {
      await httpsCallable<{ redirectUrl: string }, undefined>(
        functions,
        'authSendEmailVerificationLink',
      )({ redirectUrl: window.location.href });
      setSent(true);
    } catch (err) {
      errorReporter.report('Failed to send email verification link:', err);
    }
    setSwitching(false);
  };

  return (
    <WarningStyled>
      <div>
        <span>
          Please check your <b>{user.email}</b> inbox. We&apos;ve sent you a link to verify your email address.
        </span>
      </div>
      <div>
        <Button secondary xs onClick={resend} disabled={sending || sent} style={{ background: '#fff' }}>
          {sending && <Spinner />}
          <span>{sent ? 'Verification link sent' : 'Resend verification link'}</span>
        </Button>
      </div>
    </WarningStyled>
  );
};

const VerifyEmailWarning = () => {
  const { user, userData } = useContext(UserContext);

  // Auth and firestore data may lag behind, so we check for both verified statuses to avoid showing the warning when not needed
  if (userData && !userData.emailVerified && user && !user.emailVerified) {
    return <Warning />;
  }

  return <></>;
};

export default VerifyEmailWarning;
