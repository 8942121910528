import { doc, runTransaction } from 'firebase/firestore';
import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import BackLink from 'web/components/BackLink';
import BookingPageContext from 'web/components/BookingPageContext';
import BookingPageLink from 'web/components/BookingPageLink';
import ColumnContainer from 'web/components/ColumnContainer';
import { LinkStyled } from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import { NoticeCard } from 'web/components/NoticeCard';
import ScreenTracker from 'web/components/ScreenTracker';
import useTracking from 'web/components/TrackingContext/useTracking';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import { useDocumentData } from 'web/hooks/firebase';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import useFeatureOverrideCheck from 'web/hooks/useFeatureOverrideCheck';
import themeClasses from 'web/styles/themeClasses.css';
import themeConstants from 'web/styles/themeConstants';
import { firestoreGroupSessionConverter } from 'web/utils/convert';
import GroupSessionForm, { SubmitValues } from './GroupSessionForm';

const GroupSessionEditWithData = ({ session, page }: { session: introwise.GroupSession; page: introwise.Page }) => {
  const firestore = useFirestore();
  const tracking = useTracking();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useErrorStateHandler();
  const navigate = useNavigate();
  const increasedPriceLimit = useFeatureOverrideCheck('increasedPriceLimit');
  const largeSessionLimits = useFeatureOverrideCheck('largeSessionLimits');

  const hasBookings = session.groupSize.current > 0;

  const edit = async (values: SubmitValues) => {
    if (submitting) {
      return;
    }

    if (hasBookings) {
      const res = window.confirm(`This session already has bookings. Are you sure you want to edit it?`);
      if (!res) {
        return;
      }
    }

    setSubmitting(true);
    try {
      // We ensure that session update only happens for non-booked sessions
      const sessionRef = doc(firestore, 'sessions', session.id);
      await runTransaction(firestore, (t) =>
        t.get(sessionRef).then((sessionDoc) => {
          if (!sessionDoc.exists) {
            throw new Error(`Session no longer exist`);
          }

          const sessionExistingData = sessionDoc.data() as introwise.GroupSession;

          const sessionUpdate: Pick<
            introwise.GroupSession,
            'title' | 'description' | 'start' | 'end' | 'price' | 'hidden' | 'paymentAccountId'
          > & {
            'groupSize.max': introwise.GroupSession['groupSize']['max'];
            'groupSize.remaining': introwise.GroupSession['groupSize']['remaining'];
          } = {
            title: values.title,
            description: values.description,
            hidden: !!values.hidden,
            price: values.price,
            start: values.start,
            end: values.end,
            'groupSize.max': values.groupSizeMax,
            'groupSize.remaining': Math.max(0, values.groupSizeMax - sessionExistingData.groupSize.current),
            paymentAccountId: values.paymentAccountId,
          };

          return t.update(sessionRef, sessionUpdate);
        }),
      );
      tracking.trackEvent('Group Session Updated');
      navigate(`/dashboard/scheduling${session.seriesId ? `/series/${session.seriesId}` : ''}`, { replace: true });
    } catch (err) {
      setError(err);
    }
    setSubmitting(false);
  };

  const del = async () => {
    if (hasBookings || submitting) {
      return;
    }
    const res = confirm('Are you sure you want to delete this session?');
    if (!res) {
      return;
    }
    setSubmitting(true);
    try {
      // We ensure that session update only happens for non-booked sessions
      const sessionRef = doc(firestore, 'sessions', session.id);
      await runTransaction(firestore, (t) =>
        t.get(sessionRef).then((sessionDoc) => {
          if (!sessionDoc.exists) {
            throw new Error(`Session no longer exist`);
          }
          if ((sessionDoc.data() as introwise.GroupSession).groupSize.current !== 0) {
            throw new Error(`Session already has bookings`);
          }
          return t.delete(sessionRef);
        }),
      );
      tracking.trackEvent('Group Session Removed');
      navigate(`/dashboard/scheduling${session.seriesId ? `/series/${session.seriesId}` : ''}`, { replace: true });
    } catch (err) {
      setError(err);
    }
    setSubmitting(false);
  };

  return (
    <>
      {hasBookings && (
        <NoticeCard className={themeClasses({ marginBottom: 4 })}>
          <p className={themeClasses({ margin: 0 })}>
            This session already has bookings, some details cannot be edited. To change the session time use the
            rescheduling function on the{' '}
            <LinkStyled to={`/dashboard/home/sessions/${session.id}`}>session dashboard</LinkStyled>.
          </p>
        </NoticeCard>
      )}
      {session.seriesId && (
        <p className={themeClasses({ margin: 0 })}>
          Part of a series{' '}
          <LinkStyled to={`/dashboard/scheduling/series/${session.seriesId}`}>
            {page.series[session.seriesId].title}
          </LinkStyled>
        </p>
      )}
      <GroupSessionForm
        currency={session.currency}
        session={session}
        submitting={submitting}
        onSubmit={edit}
        readOnly={hasBookings}
        onDelete={hasBookings ? undefined : del}
        increasedPriceLimit={increasedPriceLimit}
        largeSessionLimits={largeSessionLimits}
        withWorkflows
      />
      {error && <p>{`${error}`}</p>}
    </>
  );
};

const GroupSessionEditLoading = styled.div`
  ${themeConstants.fake.loading}
  ${themeConstants.fake.animated}
  margin-bottom: 16px;
`;

const GroupSessionEdit = () => {
  const { sessionId } = useParams();
  const firestore = useFirestore();
  const [session, sessionLoading, sessionError] = useDocumentData(
    doc(firestore, 'sessions', sessionId).withConverter(firestoreGroupSessionConverter),
  );
  useErrorHandler(sessionError);
  const [page, pageLoading, pageError] = useContext(BookingPageContext);
  useErrorHandler(pageError);

  const loading = sessionLoading || pageLoading;

  return (
    <>
      <Helmet title="Edit a group session" />
      <ScreenTracker screenName="GroupSessionEdit" />
      <BackLink to="/dashboard/scheduling" />
      <ColumnContainer>
        <WithHeaderContentColumn header="Edit a group session" whiteBackground>
          {!sessionError && !pageError && loading && (
            <>
              <GroupSessionEditLoading as="p">Loading...</GroupSessionEditLoading>
              <GroupSessionEditLoading style={{ height: 64 }} />
              <GroupSessionEditLoading style={{ height: 90 }} />
            </>
          )}
          {!sessionError && !pageError && !loading && session && (
            <GroupSessionEditWithData session={session} page={page} />
          )}
          {!sessionError && !loading && !session && <p>Session doesn&apos;t exist</p>}
          {(sessionError || pageError) && <p>{`${sessionError || pageError}`}</p>}
        </WithHeaderContentColumn>
        {!sessionError && !loading && session && (
          <WithHeaderContentColumn header="Link to this group session">
            <BookingPageLink path={`/sessions/${sessionId}`} />
          </WithHeaderContentColumn>
        )}
      </ColumnContainer>
    </>
  );
};

export default GroupSessionEdit;
