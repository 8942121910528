import { deleteField, doc, writeBatch } from 'firebase/firestore';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BookingPageContext from 'web/components/BookingPageContext';
import { InlineButton, LinkStyled } from 'web/components/elements';
import ListUnstyled from 'web/components/elements/ListUnstyled';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import Flare from 'web/components/Flare';
import GenericReactModal from 'web/components/GenericReactModal';
import useTracking from 'web/components/TrackingContext/useTracking';
import UserContext from 'web/components/UserContext';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import themeClasses from 'web/styles/themeClasses.css';
import PersonalAvailabilityContext from './PersonalAvailabilityContext';

const DeleteButton = ({ servicesUsed, pageId }: { servicesUsed: introwise.Service[]; pageId: string }) => {
  const availability = useContext(PersonalAvailabilityContext);
  const { user } = useContext(UserContext);
  const firestore = useFirestore();
  const tracking = useTracking();
  const [, setError] = useErrorStateHandler();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);

  const servicesCount = servicesUsed?.length || 0;

  const del = async () => {
    setSubmitting(true);
    try {
      if (servicesCount > 0) {
        const res = window.confirm(
          `This availability is used in ${servicesCount} service${servicesCount === 1 ? '' : 's'}. ${
            servicesCount === 1 ? 'It' : 'They'
          } will be swicthed to the default availability. Are you sure you want to delete this availability?`,
        );
        if (!res) {
          setSubmitting(false);
          return;
        }
      }
      const batch = writeBatch(firestore);
      const id = availability.id;
      const userRef = doc(firestore, 'users', user.uid);
      const availabilityRef = doc(userRef, 'cronofy', id);
      const userUpdate = {
        [`availabilities.${id}`]: deleteField(),
      };
      batch.delete(availabilityRef);
      batch.update(userRef, userUpdate);
      if (servicesUsed.length > 0) {
        // TODO: might move this server-side for resiliency
        const pageRef = doc(firestore, 'pages', pageId);
        const pageUpdate: { [key: string]: string } = {};
        servicesUsed.forEach((service) => {
          const serviceRef = doc(pageRef, 'services', service.id);
          const serviceUpdate = {
            availabilityId: 'availability',
          };
          pageUpdate[`services.${service.id}.availabilityId`] = 'availability';
          batch.update(serviceRef, serviceUpdate);
        });
        batch.update(pageRef, pageUpdate);
      }
      await batch.commit();
      tracking.trackEvent('Availability Removed');
      navigate(`/dashboard/scheduling/`);
    } catch (err) {
      setError(err);
      setSubmitting(false);
    }
  };

  return (
    <InlineButton onClick={del} disabled={submitting}>
      Delete
    </InlineButton>
  );
};

const PersonalAvailabilityInfo = ({
  availability,
  withActions,
}: {
  availability: introwise.Availability;
  withActions?: boolean;
}) => {
  const [page, , error] = useContext(BookingPageContext);
  useErrorHandler(error);
  const [modalOpen, setModalOpen] = useState(false);

  const isDefault = availability.id === 'availability';

  const servicesUsed =
    page?.services &&
    Object.values(page.services).filter(
      (service) => service.availabilityId === availability.id || (isDefault && !service.availabilityId),
    );

  const servicesCount = servicesUsed?.length || 0;

  return (
    <>
      <GenericReactModal isOpen={modalOpen} onRequestClose={() => setModalOpen(false)}>
        <div style={{ width: 300 }}>
          <h4 className={themeClasses({ marginTop: 0 })}>Used in these services:</h4>
          <ListUnstyled>
            {servicesUsed?.map((service) => (
              <li key={service.id}>
                <LinkStyled to={`/dashboard/scheduling/services/${service.id}`}>{service.title}</LinkStyled>
              </li>
            ))}
          </ListUnstyled>
        </div>
      </GenericReactModal>
      <div className={themeClasses({ display: 'flex', justifyContent: 'space-between' })}>
        <h3 className={themeClasses({ marginY: 0 })}>{availability.name}</h3>
        {isDefault ? <Flare variant="success">Default</Flare> : undefined}
      </div>
      {withActions && (
        <div className={themeClasses({ marginTop: 4, display: 'flex', justifyContent: 'space-between' })}>
          <div>
            {servicesCount > 0 ? (
              <>
                Used in{' '}
                <InlineButton onClick={() => setModalOpen((val) => !val)}>
                  {servicesCount} service{servicesCount === 1 ? '' : 's'}
                </InlineButton>
              </>
            ) : (
              <>Not used in any services</>
            )}
          </div>
          <div className={themeClasses({ display: 'flex', alignItems: 'center', columnGap: 3 })}>
            <LinkStyled to="../../create" state={{ availability }}>
              Clone
            </LinkStyled>
            <LinkStyled to="rename">Rename</LinkStyled>
            {!isDefault && <DeleteButton servicesUsed={servicesUsed} pageId={page?.id} />}
          </div>
        </div>
      )}
    </>
  );
};

export default PersonalAvailabilityInfo;
