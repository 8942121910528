import { doc, writeBatch } from 'firebase/firestore';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { RegisterOptions, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import BackLink from 'web/components/BackLink';
import BookingPageContext from 'web/components/BookingPageContext';
import ColumnContainer from 'web/components/ColumnContainer';
import { Button, FormError, FormGroup, Label, LabelText, Select } from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import PriceInput from 'web/components/form-fields/PriceInput';
import ScreenTracker from 'web/components/ScreenTracker';
import Spinner from 'web/components/Spinner';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import themeClasses from 'web/styles/themeClasses.css';
import {
  convertFromDecimal,
  convertToDecimal,
  exchangeRateToUsd,
  formatCurrencyDecimal,
  formatCurrencyName,
  formatCurrencySymbol,
  isZeroDecimal,
  minChargeAmount,
  supportedCurrencies,
} from 'web/utils/currency';

// TODO: should this be in a single place for all service forms?
const maxPriceValueUsdDefault = 5000;

const makePriceValidationRules = (currency: introwise.Currency): RegisterOptions => {
  const minPriceValue = convertToDecimal(minChargeAmount[currency], currency);
  const maxPriceValue = maxPriceValueUsdDefault * exchangeRateToUsd[currency];
  return {
    required: 'Required',
    validate: (strValue: string) => {
      const value = parseFloat(strValue);
      if (isNaN(value)) {
        return 'Must be a number';
      }
      if (value > maxPriceValue) {
        return `That's too much! Our sessions price is limited to ${formatCurrencyDecimal(maxPriceValue, currency)}`;
      }
      if (value < minPriceValue) {
        return `Must be at least ${formatCurrencyDecimal(minPriceValue, currency)}`;
      }
      if (isZeroDecimal(currency) && !Number.isInteger(value)) {
        return `Must be a whole number, e.g. ${value.toFixed(0)}`;
      }
    },
  };
};

type Prices = {
  services: {
    [serviceId: string]: number;
  };
  series: {
    [seriesId: string]: number;
  };
};

const makeDefaultValues = (
  services: introwise.Page['services'],
  series: introwise.Page['series'],
  currency: introwise.Currency,
) =>
  ({
    services: services
      ? Object.keys(services).reduce(
          (acc, serviceId) => ({ ...acc, [serviceId]: convertToDecimal(services[serviceId].price, currency) }),
          {},
        )
      : {},
    series: series
      ? Object.keys(series).reduce(
          (acc, seriesId) => ({
            ...acc,
            [seriesId]: convertToDecimal(series[seriesId].price, series[seriesId].currency),
          }),
          {},
        )
      : {},
  } as Prices);

const SettingsPaymentsCurrencyForm = ({
  initialCurrency,
  services,
  series,
  onSubmit,
  submitting,
}: {
  initialCurrency: introwise.Currency;
  services: introwise.Page['services'];
  series: introwise.Page['series'];
  onSubmit: (newCurrency: introwise.Currency, newPrices: Prices) => void;
  submitting: boolean;
}) => {
  const [newCurrency, setNewCurrency] = useState<introwise.Currency>();
  const currency = newCurrency || initialCurrency;

  const priceValidationRules = useMemo(() => makePriceValidationRules(currency), [currency]);

  const defaultValues = useMemo<Prices>(
    () => makeDefaultValues(services, series, initialCurrency),
    [services, series, initialCurrency],
  );

  const {
    register,
    formState: { errors },
    handleSubmit,
    clearErrors,
  } = useForm({ defaultValues });

  useEffect(() => {
    if (newCurrency) {
      clearErrors();
    }
  }, [clearErrors, newCurrency]);

  const servicesIds = useMemo(() => (services ? Object.keys(services) : []), [services]);
  const seriesIds = useMemo(() => (series ? Object.keys(series) : []), [series]);
  const pricedServicesIds = useMemo(() => servicesIds.filter((id) => services[id].price), [servicesIds, services]);
  const pricedSeriesIds = useMemo(() => seriesIds.filter((id) => series[id].price), [seriesIds, series]);

  const convertAndSubmit = (values: Prices) => {
    const convertedPrices = {
      services: servicesIds.length
        ? servicesIds.reduce(
            (acc, serviceId) => ({
              ...acc,
              [serviceId]: values.services?.[serviceId]
                ? convertFromDecimal(values.services[serviceId], currency)
                : services[serviceId].price,
            }),
            {},
          )
        : {},
      series: seriesIds.length
        ? seriesIds.reduce(
            (acc, seriesId) => ({
              ...acc,
              [seriesId]: values.series?.[seriesId]
                ? convertFromDecimal(values.series[seriesId], currency)
                : series[seriesId].price,
            }),
            {},
          )
        : {},
    } as Prices;
    onSubmit(currency, convertedPrices);
  };

  const pricedItems = { services, series };

  const PricedItem = ({ itemName, itemId }: { itemName: 'services' | 'series'; itemId: string }) => (
    <FormGroup>
      <h4 className={themeClasses({ marginTop: 0 })}>{pricedItems[itemName][itemId].title}</h4>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, minmax(0, 1fr))', columnGap: 20 }}>
        <Label>
          <LabelText>Old price</LabelText>
          <PriceInput
            readOnly
            disabled
            defaultValue={convertToDecimal(pricedItems[itemName][itemId].price, initialCurrency)}
            currency={initialCurrency}
          />
        </Label>
        <div>
          <Label>
            <LabelText>New price</LabelText>
            <PriceInput
              {...register(`${itemName}.${itemId}`, priceValidationRules)}
              currency={currency}
              hasError={!!errors[itemName]?.[itemId]}
            />
          </Label>
          {errors[itemName]?.[itemId] && <FormError>{errors[itemName]?.[itemId].message}</FormError>}
        </div>
      </div>
    </FormGroup>
  );

  return (
    <>
      <Label>
        <LabelText>New currency</LabelText>
        <Select defaultValue={initialCurrency} onChange={(e) => setNewCurrency(e.target.value as introwise.Currency)}>
          {supportedCurrencies.map((supportedCurrency) => {
            const symbol = formatCurrencySymbol(supportedCurrency);
            const name = formatCurrencyName(supportedCurrency);
            const nameCapitalized = name.charAt(0).toUpperCase() + name.slice(1);
            return (
              <option key={supportedCurrency} value={supportedCurrency}>
                {nameCapitalized} {symbol}
              </option>
            );
          })}
        </Select>
      </Label>

      <form onSubmit={handleSubmit(convertAndSubmit)}>
        {(pricedServicesIds.length > 0 || pricedSeriesIds.length > 0) && (
          <fieldset disabled={submitting}>
            <p>Please check the new prices after the currency change:</p>
            {pricedServicesIds.map((serviceId) => (
              <PricedItem key={serviceId} itemName="services" itemId={serviceId} />
            ))}
            {pricedSeriesIds.map((seriesId) => (
              <PricedItem key={seriesId} itemName="series" itemId={seriesId} />
            ))}
          </fieldset>
        )}
        <FormGroup>
          <p>
            <b>Important:</b> already booked personal sessions, scheduled group sessions and existing packages will not
            change the price or the currency - your clients will see the old prices. If you want to avoid confusion we
            suggest to wait and change the currency after the scheduled sessions are over, and re-create your packages.
          </p>
        </FormGroup>
        <FormGroup>
          <Button primary type="submit" disabled={submitting}>
            {submitting && <Spinner />}
            <span>Save</span>
          </Button>
        </FormGroup>
      </form>
    </>
  );
};

const SettingsPaymentsCurrencyImpl = ({ page }: { page: introwise.Page }) => {
  const firestore = useFirestore();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useErrorStateHandler();
  const navigate = useNavigate();

  const paidServices = page.services
    ? Object.keys(page.services).reduce(
        (acc, serviceId) =>
          page.services[serviceId].price === 0 ? acc : { ...acc, [serviceId]: page.services[serviceId] },
        {} as introwise.Page['services'],
      )
    : {};
  const onSubmit = async (newCurrency: introwise.Currency, newPrices: Prices) => {
    setSubmitting(true);
    try {
      const batch = writeBatch(firestore);
      const pageRef = doc(firestore, 'pages', page.id);
      const pageUpdate: introwise.FirestoreUpdateData<introwise.Page> = {
        currency: newCurrency,
      };
      for (const serviceId in newPrices.services) {
        batch.update(doc(pageRef, 'services', serviceId), { price: newPrices.services[serviceId] });
        pageUpdate[`services.${serviceId}.price`] = newPrices.services[serviceId];
      }
      for (const seriesId in newPrices.series) {
        batch.update(doc(pageRef, 'series', seriesId), { price: newPrices.series[seriesId], currency: newCurrency });
        pageUpdate[`series.${seriesId}.price`] = newPrices.series[seriesId];
        pageUpdate[`series.${seriesId}.currency`] = newCurrency;
      }
      batch.update(pageRef, pageUpdate);
      await batch.commit();
      setSubmitting(false);
      navigate('/dashboard/payments');
    } catch (err) {
      setError(err);
      setSubmitting(false);
    }
  };

  return (
    <>
      <SettingsPaymentsCurrencyForm
        initialCurrency={page.currency}
        services={paidServices}
        series={page.series}
        onSubmit={onSubmit}
        submitting={submitting}
      />
      {error && <FormError>{`${error}`}</FormError>}
    </>
  );
};

const SettingsPaymentsCurrency = () => {
  const [page, pageLoading, pageError] = useContext(BookingPageContext);
  useErrorHandler(pageError);

  return (
    <>
      <Helmet title="Currency" />
      <ScreenTracker screenName="SettingsPaymentsCurrency" />
      <BackLink to="/dashboard/payments" />
      <ColumnContainer equal>
        <WithHeaderContentColumn header="Change currency" whiteBackground>
          {!page && pageLoading && <Spinner />}
          {((!page && !pageLoading) || pageError) && (
            <p>Cannot change the currency at this time. Please try again later</p>
          )}
          {page && <SettingsPaymentsCurrencyImpl page={page} />}
        </WithHeaderContentColumn>
      </ColumnContainer>
    </>
  );
};

export default SettingsPaymentsCurrency;
