import { faCheck, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { collection, doc, updateDoc } from 'firebase/firestore';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import ColumnContainer from 'web/components/ColumnContainer';
import ContentColumn from 'web/components/ContentColumn';
import ContentColumnHeader from 'web/components/ContentColumnHeader';
import { InlineButton, LinkStyled } from 'web/components/elements';
import Fake from 'web/components/elements/Fake';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import {
  IntegrationBlock,
  IntegrationBlockTitle,
  IntegrationInlineMark,
  IntegrationTitle,
} from 'web/components/integrations/common';
import ZoomIntegration from 'web/components/integrations/ZoomIntegration';
import ScreenTracker from 'web/components/ScreenTracker';
import UserContext from 'web/components/UserContext';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useFirestoreCollectionData from 'web/hooks/useFirestoreCollectionData';
import themeClasses from 'web/styles/themeClasses.css';
import themeVars from 'web/styles/themeVars.css';
import { firestoreLocationConverter } from 'web/utils/convert';

const IntrowiseIcon = (props: React.ImgHTMLAttributes<HTMLImageElement>) => (
  <img {...props} src="/integrations-introwise-icon.png" />
);

const IntrowiseIntegration = ({ isDefault, onClickDefault }: { isDefault: boolean; onClickDefault: () => void }) => (
  <IntegrationBlock>
    <IntegrationBlockTitle>
      <IntrowiseIcon width={40} style={{ margin: 4 }} />
      <div>
        <IntegrationTitle>Introwise calls</IntegrationTitle>
        <div>
          <small>Built-in HD video calls, no apps requried</small>
        </div>
        <div className={themeClasses({ marginTop: 3 })}></div>
      </div>
    </IntegrationBlockTitle>
    <div className={themeClasses({ textAlign: { sm: 'center' } })}>
      <div>
        {isDefault ? (
          <IntegrationInlineMark>
            <FontAwesomeIcon icon={faCheck} /> Default
          </IntegrationInlineMark>
        ) : (
          <InlineButton onClick={onClickDefault}>Make default</InlineButton>
        )}
      </div>
      <div className={themeClasses({ marginTop: 2 })}>
        <LinkStyled to="/dashboard/calls">View usage</LinkStyled>
      </div>
    </div>
  </IntegrationBlock>
);

const SettingsConferencingProviders = () => {
  const { user, userData } = useContext(UserContext);
  const currentProvider = userData.settings?.conferencing?.provider || 'daily';
  const firestore = useFirestore();
  const setDefault = async (provider: 'daily' | 'zoom') => {
    await updateDoc(doc(firestore, 'users', user.uid), {
      'settings.conferencing.provider': provider,
    });
  };
  return (
    <>
      <ContentColumn whiteBackground>
        <IntrowiseIntegration isDefault={currentProvider === 'daily'} onClickDefault={() => setDefault('daily')} />
      </ContentColumn>
      <ContentColumn whiteBackground>
        <ZoomIntegration isDefault={currentProvider === 'zoom'} onClickDefault={() => setDefault('zoom')} />
      </ContentColumn>
    </>
  );
};

const SettingsConferencingLocations = () => {
  const { user, userData } = useContext(UserContext);
  const currentProvider = userData.settings?.conferencing?.provider;
  const firestore = useFirestore();

  const setDefault = async (locationId: string) => {
    await updateDoc(doc(firestore, 'users', user.uid), {
      'settings.conferencing.provider': locationId,
    });
  };

  const [locations, loading, error] = useFirestoreCollectionData(
    collection(firestore, 'users', user.uid, 'locations').withConverter(firestoreLocationConverter),
  );
  useErrorHandler(error);

  return (
    <>
      {loading && <Fake>Loading...</Fake>}
      {error && <ContentColumn whiteBackground>Error: {error.message}</ContentColumn>}
      {!loading && !error && (
        <>
          {locations.map((location) => (
            <ContentColumn whiteBackground key={location.id}>
              <IntegrationBlock>
                <IntegrationBlockTitle>
                  <FontAwesomeIcon
                    style={{
                      margin: 4,
                      marginTop: 8,
                      width: 40,
                      fontSize: 36,
                      color: themeVars.color.muted,
                    }}
                    icon={faMapMarkerAlt}
                  />
                  <div>
                    <IntegrationTitle>{location.name}</IntegrationTitle>
                    <div>
                      <small>{location.location}</small>
                    </div>
                  </div>
                </IntegrationBlockTitle>
                <div className={themeClasses({ textAlign: { sm: 'right' } })}>
                  <div>
                    {currentProvider === location.id ? (
                      <IntegrationInlineMark>
                        <FontAwesomeIcon icon={faCheck} /> Default
                      </IntegrationInlineMark>
                    ) : (
                      <InlineButton onClick={() => setDefault(location.id)}>Make default</InlineButton>
                    )}
                  </div>
                  <div className={themeClasses({ marginTop: 2 })}>
                    <LinkStyled to={`${location.id}`}>Edit</LinkStyled>
                  </div>
                </div>
              </IntegrationBlock>
            </ContentColumn>
          ))}
          <ContentColumn whiteBackground>
            <LinkStyled to="create">Add new location</LinkStyled>
          </ContentColumn>
        </>
      )}
    </>
  );
};

const SettingsCallsLocations = () => {
  return (
    <>
      <Helmet title="Locations" />
      <ScreenTracker screenName="SettingsCallsLocations" />
      <ColumnContainer>
        <div>
          <ContentColumnHeader header="Conferencing accounts" />
          <SettingsConferencingProviders />
        </div>
      </ColumnContainer>
      <ColumnContainer>
        <div>
          <ContentColumnHeader header="Custom locations" />
          <SettingsConferencingLocations />
        </div>
      </ColumnContainer>
    </>
  );
};

export default SettingsCallsLocations;
