import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  Firestore,
  getDocs,
  getDocsFromServer,
  limit,
  query,
  runTransaction,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import BookingPageContext from 'web/components/BookingPageContext';
import BookingPageFormSlug from 'web/components/booking-page-form/BookingPageFormSlug';
import BookingPageLink from 'web/components/BookingPageLink';
import ColumnContainer from 'web/components/ColumnContainer';
import {
  AnchorStyled,
  Button,
  FormDescription,
  FormError,
  FormGroup,
  InlineButton,
  Input,
  Label,
  LabelText,
} from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import ScreenTracker from 'web/components/ScreenTracker';
import Spinner from 'web/components/Spinner';
import useTracking from 'web/components/TrackingContext/useTracking';
import UpgradeButton from 'web/components/UpgradeButton';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorReporter from 'web/hooks/useErrorReporter';
import useFeatureIncludedCheck from 'web/hooks/useFeatureIncludedCheck';
import themeClasses from 'web/styles/themeClasses.css';
import { firestorePageConverter } from 'web/utils/convert';
import { featuresUpgradePath, pricingPlans } from 'web/utils/pricingPlans';
import settings from 'web/utils/settings';
import toPunycode from 'web/utils/toPunycode';

const checkDomainUnique = async (domainPunycode: string, firestore: Firestore, useFirestoreCache: boolean) => {
  const pageQuery = query(
    collection(firestore, 'pages'),
    where('customDomainsPunycodes', 'array-contains', domainPunycode),
    limit(1),
  );
  const res = await (useFirestoreCache ? getDocs(pageQuery) : getDocsFromServer(pageQuery)).then(
    (snapshot) => snapshot.empty,
  );
  return res;
};

const checkDomainSetup = async (domainPunycode: string) => {
  const res = (await (await fetch(`https://dns.google/resolve?name=${domainPunycode}`)).json()) as {
    Status: number;
    Answer?: {
      data: string;
      type: number;
      name: string;
    }[];
  };
  if (res.Status === 0 && res.Answer) {
    const hasIp = res.Answer.some((answer) => answer.type === 1 && answer.data === settings.customDomain.ipAddress);
    const hasCname = res.Answer.some(
      (answer) =>
        answer.type === 5 &&
        (answer.data === settings.customDomain.cname || answer.data === `${settings.customDomain.cname}.`),
    );
    if ((hasIp && res.Answer.length === 1) || (hasCname && hasIp && res.Answer.length === 2)) {
      return true;
    }
  }
  return false;
};

const DomainForm = ({
  defaultValue,
  exampleValue,
  onCancel,
  onSuccess,
}: {
  defaultValue: string;
  exampleValue: string;
  onSuccess: (domain: string) => void;
  onCancel: () => void;
}) => {
  const firestore = useFirestore();
  const { handleSubmit, setError, register, formState } = useForm({
    defaultValues: {
      domain: defaultValue,
    },
  });

  const { isSubmitting, errors } = formState;

  const onSubmit = async (data: { domain: string }) => {
    const { domain } = data;
    const domainPunycode = toPunycode(domain);
    if (!(await checkDomainUnique(domainPunycode, firestore, true))) {
      setError('domain', {
        type: 'manual',
        message: 'Domain already in use',
      });
      return;
    }
    onSuccess(domain);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset disabled={isSubmitting}>
        <FormGroup>
          <Label>
            <LabelText>Domain</LabelText>
            <Input
              {...register('domain', {
                required: true,
                pattern: {
                  value: /\b((?=[a-z0-9-]{1,63}\.)(xn--)?[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}\b/,
                  message: 'Invalid domain',
                },
                setValueAs: (v) => v.trim(),
              })}
              hasError={!!errors.domain}
            />
          </Label>
          <FormDescription>
            Your domain, e.g. <b>{exampleValue}.com</b>, or subdomain, e.g. <b>book.{exampleValue}.com</b>
          </FormDescription>
        </FormGroup>
        <FormGroup className={buttonGroup}>
          <Button type="submit">
            {isSubmitting && <Spinner />}
            <span>Continue</span>
          </Button>
          <Button secondary onClick={onCancel}>
            Cancel
          </Button>
        </FormGroup>
        {errors.domain && (
          <FormGroup>
            <FormError>{errors.domain.message}</FormError>
          </FormGroup>
        )}
      </fieldset>
    </form>
  );
};

const DomainSetup = ({
  domain,
  pageId,
  onSuccess,
  onCancel,
}: {
  domain: string;
  pageId: string;
  onSuccess: () => void;
  onCancel: () => void;
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string>();
  const errorReporter = useErrorReporter();
  const firestore = useFirestore();
  const tracking = useTracking();

  const verifyDomain = async () => {
    const domainPunycode = toPunycode(domain);
    setSubmitting(true);
    try {
      if (!(await checkDomainSetup(domainPunycode))) {
        setError(
          'Domain is not setup. Please check your DNS settings.\nIt may take up to 48 hours for your settings to propagate.',
        );
        setSubmitting(false);
        return;
      }
      if (!(await checkDomainUnique(domainPunycode, firestore, false))) {
        setError('Domain already in use');
        setSubmitting(false);
        return;
      }
      const update: Pick<introwise.FirestoreUpdateData<introwise.Page>, 'customDomains' | 'customDomainsPunycodes'> = {
        customDomains: arrayUnion(domain),
        customDomainsPunycodes: arrayUnion(domainPunycode),
      };
      const pageRef = doc(collection(firestore, 'pages'), pageId);
      await updateDoc(pageRef, update);
      tracking.trackEvent('Booking Page Domain Connected');
      onSuccess();
    } catch (err) {
      setError(`Something went wrong. Please try verifying your domain setup later.`);
      errorReporter.report(err);
      setSubmitting(false);
    }
  };

  const isSubdomain = domain.split('.').length > 2;
  const rootDoman = domain.split('.').slice(-2).join('.');
  const subdomain = isSubdomain ? domain.split('.').slice(0, -2).join('.') : '';

  return (
    <>
      <p>You need to setup your DNS settings at your domain provider</p>
      <ol>
        <li>
          Log in to your domain provider&apos;s website
          <br />
          <small>
            E.g.{' '}
            <AnchorStyled href="https://domains.google" target="_blank" rel="noreferrer">
              Google Domains
            </AnchorStyled>
            ,{' '}
            <AnchorStyled href="https://www.namecheap.com/" target="_blank" rel="noreferrer">
              Namecheap
            </AnchorStyled>
            ,{' '}
            <AnchorStyled href="https://godaddy.com/" target="_blank" rel="noreferrer">
              GoDaddy
            </AnchorStyled>
          </small>
        </li>
        <li>
          Find DNS settings page for your domain <b>{rootDoman}</b>
        </li>
        <li>
          Add the following records:
          <ol type="a">
            {isSubdomain ? (
              <>
                <li>
                  <b>CNAME</b> record for <b>{subdomain}</b> pointing to <u>{settings.customDomain.cname}</u>
                </li>
              </>
            ) : (
              <>
                <li>
                  <b>A</b> record pointing to Introwise IP address <u>{settings.customDomain.ipAddress}</u>
                  <br />
                  <small>
                    You might need to use <b>@</b> symbol in the host name
                  </small>
                </li>
                <li>Delete other A records if there are any.</li>
              </>
            )}
          </ol>
        </li>
        <li>Save your changes</li>
      </ol>
      <p>Once these DNS settings are added, go back to this page and finish your setup.</p>
      <FormGroup className={buttonGroup}>
        <Button onClick={verifyDomain} disabled={submitting}>
          {submitting && <Spinner />}
          <span>Verify &amp; Save</span>
        </Button>
        <Button secondary onClick={onCancel} disabled={submitting}>
          Back
        </Button>
      </FormGroup>
      {error && (
        <FormGroup>
          <FormError>{error}</FormError>
        </FormGroup>
      )}
    </>
  );
};

const DomainRemove = ({
  domain,
  pageId,
  onSuccess,
  onCancel,
}: {
  domain: string;
  pageId: string;
  onSuccess: () => void;
  onCancel: () => void;
}) => {
  const firestore = useFirestore();
  const tracking = useTracking();
  const [error, setError] = useState<string>();
  // const [showSavedMark, setShowSavedMark] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const errorReporter = useErrorReporter();

  const disconnect = async () => {
    setSubmitting(true);
    const pageRef = doc(collection(firestore, 'pages'), pageId);
    try {
      const domainPunycode = toPunycode(domain);
      const updateData: Pick<
        introwise.FirestoreUpdateData<introwise.Page>,
        'customDomains' | 'customDomainsPunycodes'
      > = {
        customDomains: arrayRemove(domain),
        customDomainsPunycodes: arrayRemove(domainPunycode),
      };
      await updateDoc(pageRef, updateData);
      tracking.trackEvent('Booking Page Domain Removed');
      onSuccess();
    } catch (err) {
      setError("Can't disconnect the domain at the moment. Please try again later.");
      errorReporter.report(new Error(`Failed to disconnect a custom domain: ${err}`));
    }
    setSubmitting(false);
  };

  return (
    <>
      <p>
        You&apos;re about to disconnect your custom domain <b>{domain}</b>. After you remove the custom domain your
        booking page will remain reachable by your Introwise page URL.
      </p>
      <p>Are you sure?</p>
      <FormGroup className={buttonGroup}>
        <Button onClick={disconnect} disabled={submitting}>
          {submitting && <Spinner />}
          <span>Disconnect</span>
        </Button>
        <Button secondary onClick={onCancel} disabled={submitting}>
          Cancel
        </Button>
      </FormGroup>
      {error && (
        <FormGroup>
          <FormError>{error}</FormError>
        </FormGroup>
      )}
    </>
  );
};

const DomainSetupSuccess = ({
  domain,
  additionalDomain,
  onDone,
  onContinue,
}: {
  domain: string;
  additionalDomain?: string;
  onDone: () => void;
  onContinue: (additionalDomain: string) => void;
}) => {
  return (
    <>
      <p>
        You&apos;ve successfully connected your custom domain <b>{domain}</b> to your booking page.
      </p>
      {additionalDomain && (
        <p>
          As the next step we recommend to connect <b>{additionalDomain}</b> as well. Your clients will be able to use
          both domains to access your booking page.
        </p>
      )}
      <FormGroup className={buttonGroup}>
        {additionalDomain && (
          <>
            <Button onClick={() => onContinue(additionalDomain)}>Continue</Button>
            <Button onClick={onDone} variant="secondary">
              Cancel
            </Button>
          </>
        )}
        {!additionalDomain && <Button onClick={onDone}>Done</Button>}
      </FormGroup>
    </>
  );
};

const useMakePrimary = (pageId: string) => {
  const firestore = useFirestore();
  const tracking = useTracking();
  const errorReporter = useErrorReporter();
  const [submitting, setSubmitting] = useState(false);

  const makePrimary = useCallback(
    async (domain: string) => {
      setSubmitting(true);
      const pageRef = doc(collection(firestore, 'pages'), pageId).withConverter(firestorePageConverter);
      try {
        const domainPunycode = toPunycode(domain);
        await runTransaction(firestore, async (t) => {
          const page = await (await t.get(pageRef)).data();
          const customDomains = page.customDomains ?? [];
          const customDomainsPunycodes = page.customDomainsPunycodes ?? [];
          t.update(pageRef, {
            customDomains: [domain, ...customDomains.filter((d) => d !== domain)],
            customDomainsPunycodes: [domainPunycode, ...customDomainsPunycodes.filter((d) => d !== domainPunycode)],
          });
        });
        tracking.trackEvent('Booking Page Domain Updated');
      } catch (err) {
        errorReporter.report(new Error(`Failed to make a custom domain primary: ${err}`));
      }
      setSubmitting(false);
    },
    [errorReporter, firestore, pageId, tracking],
  );

  return { makePrimary, submitting };
};

const buttonGroup = themeClasses({ display: 'flex', justifyContent: 'space-between' });

const SettingsBookingPageDomainImpl = () => {
  const [domain, setDomain] = useState<string>();
  const [pageData] = useContext(BookingPageContext);
  const [domainForRemoval, setDomainForRemoval] = useState<string>();
  const { makePrimary, submitting } = useMakePrimary(pageData.id);

  const page = pageData;

  const hasDomain = page.customDomains && page.customDomains.length > 0;
  const hasMultipleDomains = page.customDomains && page.customDomains.length > 1;
  const hasMaxDomains = page.customDomains && page.customDomains.length >= 10;
  const additionalDomain = useMemo(
    () =>
      domain
        ? ((split) =>
            split.length === 2
              ? `www.${domain}`
              : split.length === 3 && split[0] === 'www'
              ? domain.substring(4)
              : null)(domain.split('.'))
        : null,
    [domain],
  );

  const [step, setStep] = useState<'view' | 'input' | 'setup' | 'setup-success' | 'confirm-removal'>('view');

  return (
    <>
      <WithHeaderContentColumn header="Custom domain" whiteBackground>
        {step === 'view' && !hasDomain && (
          <>
            <p>You currently don&apos;t have a custom domain setup.</p>
            <p>
              Use your own domain, or subdomain, e.g. <b>book.{page.slug}.com</b> instead of your Introwise page URL.
            </p>
            <p>You can connect up to 10 custom domains to your booking page.</p>
            <p>
              <InlineButton
                onClick={() => {
                  setDomain('');
                  setStep('input');
                }}
              >
                Setup the domain
              </InlineButton>
            </p>
          </>
        )}
        {step === 'view' && hasDomain && (
          <>
            <p>
              Your custom domain: <b>{page.customDomains[0]}</b>{' '}
              <InlineButton
                className={themeClasses({ marginLeft: 2 })}
                onClick={() => {
                  setDomainForRemoval(page.customDomains[0]);
                  setStep('confirm-removal');
                }}
                disabled={submitting}
              >
                Remove
              </InlineButton>
            </p>
            <p>You can use this address instead of your Introwse booking page URL</p>
          </>
        )}
        {step === 'input' && (
          <DomainForm
            defaultValue={domain}
            exampleValue={page.slug}
            onCancel={() => setStep('view')}
            onSuccess={(domain) => {
              setDomain(domain);
              setStep('setup');
            }}
          />
        )}
        {step === 'setup' && (
          <DomainSetup
            domain={domain}
            pageId={page.id}
            onSuccess={() => setStep('setup-success')}
            onCancel={() => setStep('input')}
          />
        )}
        {step === 'setup-success' && (
          <DomainSetupSuccess
            domain={domain}
            additionalDomain={
              !additionalDomain || page.customDomains?.includes(additionalDomain) ? null : additionalDomain
            }
            onDone={() => setStep('view')}
            onContinue={(additionalDomain: string) => {
              setDomain(additionalDomain);
              setStep('setup');
            }}
          />
        )}
        {step === 'confirm-removal' && (
          <DomainRemove
            domain={domainForRemoval}
            pageId={page.id}
            onSuccess={() => {
              setStep('view');
              setDomainForRemoval(null);
            }}
            onCancel={() => {
              setStep('view');
              setDomainForRemoval(null);
            }}
          />
        )}
      </WithHeaderContentColumn>
      {step === 'view' && hasDomain && (
        <WithHeaderContentColumn header="Additional domains" whiteBackground>
          {hasMultipleDomains &&
            page.customDomains.slice(1).map((domain) => (
              <p key={domain}>
                <b>{domain}</b>
                <InlineButton
                  className={themeClasses({ marginLeft: 2 })}
                  onClick={() => {
                    setDomainForRemoval(domain);
                    setStep('confirm-removal');
                  }}
                  disabled={submitting}
                >
                  Remove
                </InlineButton>
                <InlineButton
                  className={themeClasses({ marginLeft: 2 })}
                  onClick={() => makePrimary(domain)}
                  disabled={submitting}
                >
                  Make primary
                </InlineButton>
              </p>
            ))}
          {hasMaxDomains ? (
            <p>
              You have reached the maximum number of connected custom domains. You can remove one of your existing
              domains to add a new one.
            </p>
          ) : (
            <>
              <p>
                You can connect up to 9 additional custom domains to your booking page. These domains will be
                automatically redirected to your primary custom domain.
              </p>
              <p>
                <InlineButton
                  onClick={() => {
                    setDomain('');
                    setStep('input');
                  }}
                >
                  Add a domain
                </InlineButton>
              </p>
            </>
          )}
        </WithHeaderContentColumn>
      )}
    </>
  );
};

const SettingsBookingPageAddress = () => {
  const { user } = useContext(UserContext);
  const firestore = useFirestore();
  const tracking = useTracking();
  const [error, setError] = useState<string>();
  const [pageData] = useContext(BookingPageContext);
  const [submitting, setSubmitting] = useState(false);
  const errorReporter = useErrorReporter();

  const edit = async ({ slug }: introwise.Page) => {
    setSubmitting(true);
    try {
      const pageUpdate: introwise.FirestoreUpdateData<introwise.Page> = {
        slug,
      };
      const userUpdate: introwise.FirestoreUpdateData<introwise.User> = {
        bookingPageSlug: slug,
      };
      const batch = writeBatch(firestore);
      const pageRef = doc(collection(firestore, 'pages'), pageData.id);
      const userRef = doc(collection(firestore, 'users'), user.uid);
      batch.update(pageRef, pageUpdate);
      batch.update(userRef, userUpdate);
      await batch.commit();
      tracking.trackEvent('Booking Page Slug Updated');
    } catch (err) {
      setError("Can't save the booking page URL at the moment. Please try again later.");
      errorReporter.report(new Error(`Failed to update booking page slug: ${err}`));
    }
    setSubmitting(false);
  };

  const page = pageData;

  return (
    <WithHeaderContentColumn header="Introwise URL" whiteBackground>
      <BookingPageFormSlug page={page} onSubmit={edit} submitting={submitting} />
      {error && <FormError>{`${error}`}</FormError>}
    </WithHeaderContentColumn>
  );
};

const SettingsBookingPageDomain = () => {
  const featureIncluded = useFeatureIncludedCheck('customDomain');
  const upgradePath = pricingPlans[featuresUpgradePath['customDomain']];

  return (
    <>
      <Helmet title="Domain" />
      <ScreenTracker screenName="SettingsBookingDomain" />
      <ColumnContainer>
        <div>
          <SettingsBookingPageAddress />
          {featureIncluded ? (
            <SettingsBookingPageDomainImpl />
          ) : (
            <WithHeaderContentColumn header="Custom domain" whiteBackground>
              <p className={themeClasses({ marginTop: 0 })}>
                Make your booking page consistent with your brand by using your own custom domain. Easy to setup, with
                Introwise taking care of hosting and securing your page.
              </p>
              <p>
                Upgrade to the <b>{upgradePath.name}</b> plan to get access to the custom domain feature and{' '}
                <b>{upgradePath.applicationFeePercentage * 100}%</b> Introwise commission.
              </p>
              <div>
                <UpgradeButton to="/dashboard/account/billing/subscription">
                  Upgrade to {upgradePath.name}
                </UpgradeButton>
              </div>
            </WithHeaderContentColumn>
          )}
        </div>
        <WithHeaderContentColumn header="View as a client">
          <BookingPageLink />
          <div style={{ marginTop: 16 }}>
            <small>View your booking page exactly as your clients see it</small>
          </div>
        </WithHeaderContentColumn>
      </ColumnContainer>
    </>
  );
};

export default SettingsBookingPageDomain;
