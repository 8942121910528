import { collection, doc, updateDoc } from 'firebase/firestore';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import BackLink from 'web/components/BackLink';
import BookingPageContext from 'web/components/BookingPageContext';
import ColumnContainer from 'web/components/ColumnContainer';
import { Button, Checkbox, FormDescription, FormError, FormGroup } from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import Spinner from 'web/components/Spinner';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorReporter from 'web/hooks/useErrorReporter';
import themeClasses from 'web/styles/themeClasses.css';

export type FormValues = {
  noIndex: boolean;
};

const BookingPageSeoForm = ({
  submitting,
  onSubmit,
  defaultValues,
}: {
  defaultValues?: FormValues;
  submitting: boolean;
  onSubmit: (values: FormValues) => void;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({ defaultValues });
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset disabled={submitting}>
        <FormGroup>
          <Checkbox {...register('noIndex')}>Hide from search engines</Checkbox>
          {errors.noIndex && <FormError>{errors.noIndex.message}</FormError>}
          <FormDescription>Tell Google and other well-behaved search engines not to index your page</FormDescription>
        </FormGroup>
        <FormGroup className={themeClasses({ display: 'flex', justifyContent: 'space-between', alignItems: 'center' })}>
          <Button type="submit" variant="primary">
            {submitting && <Spinner />}
            <span>Save</span>
          </Button>
        </FormGroup>
      </fieldset>
    </form>
  );
};

const SettingsBookingPageSeo = () => {
  const firestore = useFirestore();
  const [error, setError] = useState<string>();
  // const [showSavedMark, setShowSavedMark] = useState(false);
  const [page] = useContext(BookingPageContext);
  const [submitting, setSubmitting] = useState(false);
  const errorReporter = useErrorReporter();

  const edit = async (values: FormValues) => {
    setSubmitting(true);
    const pageRef = doc(collection(firestore, 'pages'), page.id);
    const updateData: introwise.FirestoreUpdateData<introwise.Page> = {
      noIndex: values.noIndex,
    };
    try {
      await updateDoc(pageRef, updateData);
    } catch (err) {
      setError("Can't save the booking page at the moment. Please try again later.");
      errorReporter.report(new Error(`Failed to update a booking page: ${err}`));
    }
    setSubmitting(false);
  };

  return (
    <>
      <BackLink to=".." />
      <ColumnContainer>
        <div>
          <WithHeaderContentColumn header="SEO settings" whiteBackground>
            <BookingPageSeoForm defaultValues={{ noIndex: !!page.noIndex }} submitting={submitting} onSubmit={edit} />
            {error && <FormError>{`${error}`}</FormError>}
          </WithHeaderContentColumn>
        </div>
        <div />
      </ColumnContainer>
    </>
  );
};

export default SettingsBookingPageSeo;
