import { collection, documentId, query, Timestamp, where } from 'firebase/firestore';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { AnchorStyled, Button } from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import { SlotContainer, SlotDescription, SlotTitle } from 'web/components/packages/common';
import UserContext from 'web/components/UserContext';
import { useCollectionData } from 'web/hooks/firebase';
import { firestoreGroupSessionConverter } from 'web/utils/convert';
import { formatSessionDateTimeString } from 'web/utils/dateFormat';

const Container = styled.div``;

export const SessionSlot = ({
  session,
  onSelect,
  onRemove,
  readOnly,
  showPrice,
}: {
  session: introwise.GroupSession;
  onSelect?: (id: string) => void;
  onRemove?: (id: string) => void;
  readOnly?: boolean;
  showPrice?: boolean;
}) => (
  <SlotContainer readOnly={readOnly}>
    <div style={{ flex: 1 }}>
      <SlotTitle>{session.title}</SlotTitle>
      <SlotDescription>{session.description}</SlotDescription>
      <div>{formatSessionDateTimeString(session.start, session.end)}</div>
      {showPrice && (
        <div>
          <small>
            <b>${(session.price / 100).toFixed(2)}</b>
          </small>{' '}
          per person
        </div>
      )}
    </div>
    {!readOnly && (
      <div>
        {onRemove && (
          <Button secondary onClick={() => onRemove(session.id)}>
            Unselect
          </Button>
        )}
        {onSelect && (
          <Button primary onClick={() => onSelect(session.id)}>
            Select
          </Button>
        )}
      </div>
    )}
  </SlotContainer>
);

const SessionSelectorPart = ({
  title,
  items,
  onSelect,
  onRemove,
  readOnly,
  showPrice,
}: {
  title: string;
  items: introwise.GroupSession[];
  readOnly?: boolean;
  showPrice?: boolean;
  onSelect?: (id: string) => void;
  onRemove?: (id: string) => void;
}) => (
  <>
    {items.length > 0 && (
      <>
        {!readOnly && <h4>{title}</h4>}
        {items.map((session, i) => (
          <SessionSlot
            session={session}
            key={i}
            onSelect={onSelect}
            onRemove={onRemove}
            readOnly={readOnly}
            showPrice={showPrice}
          />
        ))}
      </>
    )}
  </>
);

const GroupSessionSelector = ({
  selectedIdList,
  idList,
  readOnly,
  onSelect,
  onRemove,
  showPrice,
}: {
  selectedIdList: string[];
  idList?: string[];
  readOnly?: boolean;
  onSelect: (id: string) => void;
  onRemove: (id: string) => void;
  showPrice?: boolean;
}) => {
  const firestore = useFirestore();
  const { user } = useContext(UserContext);
  const now = useMemo(() => Timestamp.now(), []);
  const [groupSessions, loading, error] = useCollectionData(
    readOnly
      ? idList &&
          idList.length > 0 &&
          query(
            collection(firestore, 'sessions'),
            where('type', '==', 'group'),
            where(documentId(), 'in', idList),
          ).withConverter(firestoreGroupSessionConverter)
      : query(
          collection(firestore, 'sessions'),
          where('expert.id', '==', user.uid),
          where('type', '==', 'group'),
          where('start', '>', now),
        ).withConverter(firestoreGroupSessionConverter),
  );

  if (error) return <p>{error.message}</p>;

  if (loading) return <p>Loading...</p>;

  if (!groupSessions || groupSessions.length === 0) {
    return readOnly ? <p>Group sessions not included</p> : <p>No available group sessions</p>;
  }

  const selectedList = selectedIdList
    ? selectedIdList.map((id) => groupSessions.find((k) => k.id === id)).filter((val) => !!val)
    : [];

  const optionListFiltered = groupSessions.filter((t) => selectedIdList.findIndex((i) => i === t.id) < 0);

  return (
    <Container>
      <SessionSelectorPart
        items={selectedList}
        title={'Selected sessions'}
        onRemove={onRemove}
        readOnly={readOnly}
        showPrice={showPrice}
      />
      {!readOnly && (
        <>
          <SessionSelectorPart
            title={'Available sessions'}
            items={optionListFiltered}
            onSelect={onSelect}
            showPrice={showPrice}
          />
          {optionListFiltered.length === 0 && selectedList.length === 0 && (
            <small>
              On the above dates you don&apos;t have any group sessions. If you want add group sessions in package you
              can change the dates or{' '}
              <AnchorStyled href="/settings/scheduling/group/create">schedule new session.</AnchorStyled>
            </small>
          )}
        </>
      )}
    </Container>
  );
};

export default GroupSessionSelector;
