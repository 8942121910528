import { httpsCallable } from 'firebase/functions';
import React, { useContext } from 'react';
import { Button } from 'web/components/elements';
import useFunctions from 'web/components/FirebaseContext/useFunctions';
import UserContext from 'web/components/UserContext';
import useErrorReporter from 'web/hooks/useErrorReporter';

const StripeDashboardButton = ({ text, size }: { text: string; size?: 'md' | 'sm' }) => {
  const { userData } = useContext(UserContext);
  const functions = useFunctions();
  const standard = userData.stripeAccountType === 'standard';
  const errorReporter = useErrorReporter();

  const openStripeDashboard = async () => {
    const newTab = window.open('/redirect.html');
    if (standard) {
      newTab.location.href = 'https://dashboard.stripe.com';
    } else {
      try {
        const res = await httpsCallable<unknown, { url: string }>(functions, 'stripeCreateLoginLink')({});
        newTab.location.href = res.data.url;
      } catch (err) {
        errorReporter.report(new Error(`Failed to create Stripe login link: ${err}`));
      }
    }
  };

  return (
    <Button secondary size={size} onClick={openStripeDashboard}>
      {text}
    </Button>
  );
};

export default StripeDashboardButton;
