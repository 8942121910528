import type { DateSelectArg, EventChangeArg, EventClickArg } from '@fullcalendar/react';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { DateRange, EventType, ExtendedEventPropType } from 'web/components/calendar/common/types';
import useTimeOffCalendar from 'web/hooks/useTimeOffCalendar';
import AvailabilityCalendar from '../common/AvailabilityCalendar';
import timeSlotContent from '../common/timeSlotContent';
import TimeOffEditModal from './TimeOffEditModal';
import timeOffEventContent from './timeOffEventContent';

const TimeOffCalendar = () => {
  const [selectedEvent, setSelectedEvent] = useState<{ event: EventType; onCreate: boolean }>();
  const [dateRange, setDateRange] = useState<DateRange>(() => {
    const start = DateTime.now().startOf('week').toJSDate();
    const end = DateTime.now().endOf('week').toJSDate();
    return { start, end };
  });
  const { events, loading, deleteEvent, updateEvent, submitting } = useTimeOffCalendar({
    dateRange,
  });

  const onEventSelect = ({ event, jsEvent }: EventClickArg) => {
    jsEvent.preventDefault();
    setSelectedEvent({
      event: events.find((t) => t.id === event.id),
      onCreate: false, // flag for conditionaly change modal window
    });
  };

  const onDateSelect = ({ start, end, jsEvent }: DateSelectArg) => {
    jsEvent.preventDefault();

    // create new event with id: undefined
    setSelectedEvent(() => {
      const recurring = true;
      const ev = {
        id: undefined as string,
        start,
        end,
        title: start.toLocaleTimeString('en'),
        extendedProps: {
          mobileTitle: start.toLocaleTimeString('en'),
          recurring,
        },
      };
      return {
        event: ev,
        onCreate: true,
      };
    });
  };

  const onEventsChanged = ({ event }: EventChangeArg) => {
    const newEvent: EventType = {
      id: event.id,
      start: event.start,
      end: event.end,
      title: event.start.toLocaleTimeString('en'),
      extendedProps: event.extendedProps as ExtendedEventPropType,
    };
    updateEvent(newEvent);
  };

  const onDateRangeChange = (newDateRange: DateRange) => {
    setDateRange((oldDateRange) =>
      oldDateRange.start.getTime() !== newDateRange.start.getTime() &&
      oldDateRange.end.getTime() !== newDateRange.end.getTime()
        ? newDateRange
        : oldDateRange,
    );
  };

  const onModalClose = () => {
    setSelectedEvent(null);
  };

  const onModalDelete = () => {
    deleteEvent(selectedEvent.event);
    onModalClose();
  };

  const onModalSave = (start: Date, end: Date) => {
    const { id: selectedEventId, extendedProps } = selectedEvent.event;
    const id = selectedEventId;
    const newEvent: EventType = {
      id,
      start,
      end,
      title: start.toLocaleTimeString('en'),
      extendedProps,
    };
    updateEvent(newEvent);
    onModalClose();
  };

  return (
    <div>
      <AvailabilityCalendar
        events={events}
        loading={loading}
        saving={submitting}
        dateRange={dateRange}
        onDateRangeChange={onDateRangeChange}
        onEventSelect={onEventSelect}
        onEventsChanged={onEventsChanged}
        onDateSelect={onDateSelect}
        slotLabelContent={timeSlotContent}
        eventContent={timeOffEventContent}
        allowMultiDaySelect
      />
      {selectedEvent && (
        <TimeOffEditModal
          period={selectedEvent.event}
          create={selectedEvent.onCreate}
          onClose={onModalClose}
          isOpen={!!selectedEvent}
          onDelete={onModalDelete}
          onSave={onModalSave}
        />
      )}
    </div>
  );
};

export default TimeOffCalendar;
