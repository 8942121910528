import { httpsCallable } from 'firebase/functions';
import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import BackLink from 'web/components/BackLink';
import ColumnContainer from 'web/components/ColumnContainer';
import { Button, FormError, FormGroup, LinkStyled } from 'web/components/elements';
import useFunctions from 'web/components/FirebaseContext/useFunctions';
import Spinner from 'web/components/Spinner';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import {
  PricingPlanId,
  pricingPlans,
  subscriptionPricingAnnualPriceId,
  subscriptionPricingPlanId,
  subscriptionPricingPriceId,
} from 'web/utils/pricingPlans';

const formatPriceAmount = (amount: number) => `$${Math.round((amount / 100 + Number.EPSILON) * 100) / 100}`;

type SubscriptionRequest = { stripeSubscriptionId: string; planId: string; priceId: string };

type UpcomingInvoiceResult = {
  invoice?: {
    currency: string;
    amount_due: number;
  };
};

type ChangeSubscriptionPriceResult = {
  subscription?: {
    status: string;
  };
};

const SubscriptionDowngrade = () => {
  const functions = useFunctions();
  const { userData } = useContext(UserContext);
  const { stripeSubscription, pricingPriceId } = userData;
  const [initialPriceId] = useState(pricingPriceId);
  const [proratedAmount, setProratedAmount] = useState<number>(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [downgraded, setDowngraded] = useState(stripeSubscription?.cancelAtPeriodEnd);
  const [downgradedPastDue, setDowngradedPastDue] = useState(false);
  const [error, setError] = useErrorStateHandler();

  const stripeSubscriptionId = stripeSubscription?.id;
  const downgradable = stripeSubscriptionId && initialPriceId === subscriptionPricingAnnualPriceId;
  const ready = !loading && proratedAmount !== null;

  useEffect(() => {
    const retrieveProratedAmount = async () => {
      setLoading(true);
      try {
        const result = await httpsCallable<SubscriptionRequest, UpcomingInvoiceResult>(
          functions,
          'stripeRetrieveUpcomingInvoice',
        )({
          stripeSubscriptionId,
          planId: subscriptionPricingPlanId,
          priceId: subscriptionPricingPriceId,
        });
        const { invoice } = result.data as UpcomingInvoiceResult;
        if (!invoice) {
          throw new Error(`Response has no invoice data`);
        }
        setProratedAmount(invoice.amount_due);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    if (downgradable) {
      void retrieveProratedAmount();
    }
  }, [stripeSubscriptionId, functions, setError, downgradable]);

  if (!downgradable) {
    return <Navigate to="/dashboard/account/billing" />;
  }
  const pricingPlan = pricingPlans[userData.pricingPlanId as PricingPlanId];

  const cancel = async () => {
    setSubmitting(true);
    try {
      const result = await httpsCallable<SubscriptionRequest, ChangeSubscriptionPriceResult>(
        functions,
        'stripeChangeSubscriptionPrice',
      )({
        stripeSubscriptionId,
        planId: subscriptionPricingPlanId,
        priceId: subscriptionPricingPriceId,
      });
      const { subscription } = result.data;
      if (!subscription) {
        throw new Error(`Response has no subscription data`);
      }
      setDowngraded(true);
      setDowngradedPastDue(subscription.status === 'past_due');
    } catch (err) {
      setError(err);
    }
    setSubmitting(false);
  };

  return (
    <>
      <BackLink to="/dashboard/account/billing" />
      <ColumnContainer equal>
        <WithHeaderContentColumn header="Switch to monthly" whiteBackground>
          {downgraded && !downgradedPastDue && (
            <>
              <p>
                Your subscription was successfully switched to a <b>monthly billing</b>.
              </p>
            </>
          )}
          {downgraded && downgradedPastDue && (
            <>
              <p>
                <b>Action needed:</b> please check your payment method
              </p>
              <p>
                Your subscription was switched to a monthly billing, but there was a problem charging your payment
                method. Please <LinkStyled to="/dashboard/account/billing">update your payment method</LinkStyled> to
                avoid cancellation.
              </p>
            </>
          )}
          {!downgraded && (
            <>
              <p>
                You&apos;re about to switch your <b>{pricingPlan.name}</b> plan to a monthly billing.
              </p>
              <p>
                Your new billing cycle will be activated immediately and any amount left of your current annual fee will
                be credited to your new bill.
              </p>
              <p>
                Total due today: {!ready && <Spinner />}
                {ready && <b>{formatPriceAmount(proratedAmount)}</b>}
              </p>
              <FormGroup>
                <Button primary onClick={cancel} disabled={submitting || !ready}>
                  {submitting && <Spinner />}
                  <span>Change to monthly</span>
                </Button>
              </FormGroup>
              {error && (
                <FormGroup>
                  <FormError>{`${error}`}</FormError>
                </FormGroup>
              )}
            </>
          )}
        </WithHeaderContentColumn>
      </ColumnContainer>
    </>
  );
};

export default SubscriptionDowngrade;
