import React, { useMemo } from 'react';
import { Select } from 'web/components/elements';
import useErrorReporter from 'web/hooks/useErrorReporter';
import getTimeZoneOptions, { TimezoneOption } from 'web/components/timezone/getTimeZoneOptions';

const ExpertTimeZoneSelect = React.forwardRef<HTMLSelectElement, React.SelectHTMLAttributes<HTMLSelectElement>>(
  (props: React.HtmlHTMLAttributes<HTMLSelectElement>, ref) => {
    const errorReporter = useErrorReporter();
    const options = useMemo<TimezoneOption[]>(() => getTimeZoneOptions(errorReporter), [errorReporter]);

    return (
      <Select {...props} ref={ref}>
        {options.map((opt) => (
          <option key={opt.value} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </Select>
    );
  },
);

ExpertTimeZoneSelect.displayName = 'ExpertTimeZoneSelect';

export default ExpertTimeZoneSelect;
