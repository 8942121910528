import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Logo from 'web/components/Logo';
import { To } from 'history';
import themeConstants from 'web/styles/themeConstants';
import themeVars from 'web/styles/themeVars.css';

const LogoStyled = styled(Logo)`
  font-size: 16px;

  ${themeConstants.media.sm} {
    font-size: 19px;
  }
`;

const NavLinkStyled = styled(Link)`
  ${themeConstants.mixins.nonTextLink};
  > * {
    color: ${themeVars.color.accentOutline};
  }
`;

const HeaderLogo = styled(({ className, to }: { className?: string; to: To }) => (
  <NavLinkStyled to={to} className={className}>
    <LogoStyled />
  </NavLinkStyled>
))``;

export default HeaderLogo;
