import { collection, doc, serverTimestamp, writeBatch } from 'firebase/firestore';
import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import BackLink from 'web/components/BackLink';
import ColumnContainer from 'web/components/ColumnContainer';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import PackageForm, { SubmitValues } from 'web/components/packages/PackageForm';
import ScreenTracker from 'web/components/ScreenTracker';
import useTracking from 'web/components/TrackingContext/useTracking';
import UpgradeButton from 'web/components/UpgradeButton';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import { useDocumentData } from 'web/hooks/firebase';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import useFeatureExperimentalCheck from 'web/hooks/useFeatureExperimentalCheck';
import useFeatureIncludedCheck from 'web/hooks/useFeatureIncludedCheck';
import useFeatureOverrideCheck from 'web/hooks/useFeatureOverrideCheck';
import themeClasses from 'web/styles/themeClasses.css';
import { firestorePageConverter } from 'web/utils/convert';
import { convertToDecimal } from 'web/utils/currency';
import { featuresUpgradePath, pricingPlans } from 'web/utils/pricingPlans';

const upgradePath = pricingPlans[featuresUpgradePath['timeLimitedPackages']];

const PackageCreate = () => {
  const firestore = useFirestore();
  const tracking = useTracking();
  const { userData } = useContext(UserContext);
  const increasedPriceLimit = useFeatureOverrideCheck('increasedPriceLimit');
  const timeLimitingEnabled = useFeatureIncludedCheck('timeLimitedPackages');
  const richTextDescriptionEnabled = useFeatureExperimentalCheck('richTextDescription');
  const paymentPlanEnabled = useFeatureIncludedCheck('paymentPlans');

  const { state } = useLocation();

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useErrorStateHandler();
  const navigate = useNavigate();

  const [page, loadingPage, errorPage] = useDocumentData(
    doc(firestore, 'pages', userData.bookingPageId).withConverter(firestorePageConverter),
  );
  useErrorHandler(errorPage);

  const packToClone = (state as { package: introwise.Package } | undefined)?.package;

  if (loadingPage) return <p>Loading...</p>;

  const create = async (values: SubmitValues) => {
    const pageRef = doc(firestore, 'pages', userData.bookingPageId);
    const packageRef = doc(collection(pageRef, 'packages'));
    const packageId = packageRef.id;

    const newPackage: introwise.FirestoreWriteData<introwise.Package> = {
      ...values,
      order: Date.now(), // current time will always be bigger than any other stored value
      hidden: !!values.hidden,
      currency: page.currency,
      pageId: userData.bookingPageId,
      createdAt: serverTimestamp(),
      isDeleted: false,
    };

    const pageUpdate: introwise.FirestoreUpdateData<introwise.Page> = {
      [`packages.${packageId}`]: {
        ...newPackage,
        id: packageId,
      },
    };

    const batch = writeBatch(firestore);
    batch.update(pageRef, pageUpdate);
    batch.set(packageRef, newPackage);

    setSubmitting(true);
    try {
      await batch.commit();
      tracking.trackEvent('Package Created', {
        price: convertToDecimal(values.price, page.currency),
        currency: page.currency,
        clone: !!packToClone,
      });
      navigate('/dashboard/scheduling/packages', { replace: true });
    } catch (err) {
      setError(err);
      setSubmitting(false);
    }
  };

  return (
    <>
      <Helmet title="Create a new package" />
      <ScreenTracker screenName="PackageCreate" />
      <BackLink to="/dashboard/scheduling" />
      <ColumnContainer>
        <WithHeaderContentColumn header="Create a new package" whiteBackground>
          <PackageForm
            currency={page.currency}
            pack={packToClone ? { ...packToClone, title: `${packToClone.title} (clone)` } : undefined}
            onSubmit={create}
            submitting={submitting}
            showGroupSessionPrice={true}
            services={page.services}
            series={page.series}
            increasedPriceLimit={increasedPriceLimit}
            timeLimitingEnabled={timeLimitingEnabled}
            richTextDescriptionEnabled={richTextDescriptionEnabled}
            paymentPlanEnabled={paymentPlanEnabled}
          />
          {error && <p>{error.message}</p>}
        </WithHeaderContentColumn>
        {!timeLimitingEnabled && (
          <WithHeaderContentColumn header="Time-limited packages" whiteBackground>
            <p className={themeClasses({ marginTop: 0 })}>
              Upgrade to the <b>{upgradePath.name}</b> plan to set package expiration rules and get access to lower{' '}
              <b>{upgradePath.applicationFeePercentage * 100}%</b> Introwise commission.
            </p>
            <div>
              <UpgradeButton to="/dashboard/payments/subscription">Upgrade to {upgradePath.name}</UpgradeButton>
            </div>
          </WithHeaderContentColumn>
        )}
      </ColumnContainer>
    </>
  );
};

export default PackageCreate;
