import { deleteDoc, doc, increment, updateDoc, writeBatch } from 'firebase/firestore';
import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import BackLink from 'web/components/BackLink';
import ColumnContainer from 'web/components/ColumnContainer';
import { FormError } from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import ScreenTracker from 'web/components/ScreenTracker';
import useTracking from 'web/components/TrackingContext/useTracking';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import WorkflowContext from './WorkflowContext';
import WorkflowForm, { WorkflowFormValues } from './WorkflowForm';

const WorkflowEditSelf = () => {
  const workflow = useContext(WorkflowContext);
  const firestore = useFirestore();
  const { userData } = useContext(UserContext);
  const { bookingPageId: pageId } = userData;
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useErrorStateHandler();
  const navigate = useNavigate();
  const tracking = useTracking();

  const save = async (values: WorkflowFormValues) => {
    setSubmitting(true);
    try {
      const workflowUpdate: introwise.FirestoreUpdateData<introwise.Workflow> = {
        name: values.name,
        default: values.default,
      };
      await updateDoc(doc(firestore, 'pages', pageId, 'workflows', workflow.id), workflowUpdate);
      tracking.trackEvent('Workflow Updated');
      navigate(`..`, { replace: true });
    } catch (error) {
      setError(error);
    }
    setSubmitting(false);
  };

  const remove = async () => {
    const res = window.confirm(`Are you sure you want to delete the workflow "${workflow.name}"?`);
    if (!res) {
      return;
    }
    setSubmitting(true);
    try {
      const batch = writeBatch(firestore);
      const pageRef = doc(firestore, 'pages', pageId);
      const workflowRef = doc(pageRef, 'workflows', workflow.id);

      const workflowUpdate: introwise.FirestoreUpdateData<introwise.Workflow> = {
        deleted: true,
        default: false,
        active: false,
        actions: {},
      };
      for (const actionId of Object.keys(workflow.actions)) {
        const actionRef = doc(workflowRef, 'workflowActions', actionId);
        batch.delete(actionRef);
      }
      batch.update(workflowRef, workflowUpdate);

      const pageUpdate: introwise.FirestoreUpdateData<introwise.Page> = {
        ...(workflow.active && { workflowsActiveCount: increment(-1) }),
        workflowsCount: increment(-1),
      };
      batch.update(pageRef, pageUpdate);

      await batch.commit();

      const hardDelete = !workflow.scheduled;
      if (hardDelete) {
        await deleteDoc(workflowRef);
      }

      tracking.trackEvent('Workflow Removed', {
        hardDelete,
      });

      navigate(`../..`, { replace: true });
    } catch (error) {
      setError(error);
    }
    setSubmitting(false);
  };

  return (
    <>
      <Helmet title={`Workflows – ${workflow.name}`} />
      <ScreenTracker screenName="SettingsSchedulingWorkflowEditSelf" />
      <BackLink to={`/dashboard/scheduling/workflows/${workflow.id}`} />
      <ColumnContainer>
        <WithHeaderContentColumn header="Edit a workflow" whiteBackground>
          <WorkflowForm initialValues={workflow} onSubmit={save} onDelete={remove} submitting={submitting} />
          {error && <FormError>Something went wrong. Please try again later</FormError>}
        </WithHeaderContentColumn>
        <div />
      </ColumnContainer>
    </>
  );
};

export default WorkflowEditSelf;
