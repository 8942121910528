import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import ColumnContainer from 'web/components/ColumnContainer';
import { AnchorStyled, LinkButton, LinkStyled } from 'web/components/elements';
import ListUnstyled from 'web/components/elements/ListUnstyled';
import ScreenTracker from 'web/components/ScreenTracker';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorReporter from 'web/hooks/useErrorReporter';
import themeClasses from 'web/styles/themeClasses.css';
import {
  defaultPricingPlanId,
  paidFeaturesNames,
  PricingPlanId,
  PricingPlanLifetime,
  pricingPlans,
  subscriptionPricingAnnualPriceId,
  SubscriptionPricingPriceId,
  subscriptionPricingPriceId,
} from 'web/utils/pricingPlans';
import { formatBillDate, formatCard } from './common';

const formatBillingInterval = (subscription: introwise.User['stripeSubscription']) => {
  return subscription.priceIntervalCount === 1
    ? `${subscription.priceInterval}ly`
    : `every ${subscription.priceIntervalCount} ${subscription.priceInterval}s`;
};

const formatPricingPlanName = (planId: PricingPlanId, priceId?: SubscriptionPricingPriceId) => {
  let suffix = '';
  if (planId === 'subscription') {
    if (priceId === subscriptionPricingPriceId) {
      suffix = ' (Monthly)';
    } else if (priceId === subscriptionPricingAnnualPriceId) {
      suffix = ' (Yearly)';
    }
  }

  return `${pricingPlans[planId].name}${suffix}`;
};

const SettingsPricingPlanSubscription = () => {
  const {
    userData: { pricingPlanId, stripeSubscription, stripeCard, pricingPriceId },
  } = useContext(UserContext);

  const sanitizedPlanId = (pricingPlanId as PricingPlanId) || defaultPricingPlanId;
  const upgradable = sanitizedPlanId === 'subscription' && pricingPriceId === subscriptionPricingPriceId;
  const downgradable = sanitizedPlanId === 'subscription' && pricingPriceId === subscriptionPricingAnnualPriceId;

  return (
    <>
      <p>
        <b>Current {formatBillingInterval(stripeSubscription)} payment:</b> ${stripeSubscription.unitAmount / 100}
        {stripeSubscription.discount && (
          <>
            ({stripeSubscription.discount.duration === 'once' && 'One-time '}
            {stripeSubscription.discount.percentOff
              ? `${stripeSubscription.discount.percentOff}%`
              : `$${stripeSubscription.discount.amountOff / 100}`}
            {' discount'}
            {stripeSubscription.discount.end && ` until ${formatBillDate(stripeSubscription.discount.end)}`})
          </>
        )}
      </p>
      {stripeSubscription?.status !== 'incomplete' && stripeCard && (
        <p>
          <b>Payment method:</b> {formatCard(stripeCard)}
        </p>
      )}
      {stripeSubscription.currentPeriodEnd &&
        !stripeSubscription.cancelAtPeriodEnd &&
        (stripeSubscription.status === 'active' || stripeSubscription.status === 'trialing') && (
          <p>
            <b>Next bill:</b> {formatBillDate(stripeSubscription.currentPeriodEnd)}
          </p>
        )}
      {stripeSubscription.currentPeriodEnd && stripeSubscription.cancelAtPeriodEnd && (
        <p>
          <b>Will be cancelled:</b> {formatBillDate(stripeSubscription.currentPeriodEnd)}
        </p>
      )}
      {stripeSubscription.status === 'incomplete' && (
        <p>
          <b>Action needed:</b> We weren&apos;t able to activate your subscription due to the failed credit card charge.
          Please update your payment details to activate the subscription.
        </p>
      )}
      {stripeSubscription?.status !== 'incomplete' &&
        (stripeSubscription?.paymentStatus === 'requires_payment_method' ||
          stripeSubscription?.paymentStatus === 'requires_action') && (
          <p>
            <b>Action needed:</b> We weren&apos;t able to charge your credit card. Update your payment details to avoid
            subscription cancellation.
          </p>
        )}

      {!stripeSubscription.cancelAtPeriodEnd && (
        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <LinkButton variant="secondary" size="md" to="/dashboard/account/billing/subscription/update">
            Update payment method
          </LinkButton>
        </div>
      )}
      {stripeSubscription.cancelAtPeriodEnd && (
        <LinkButton variant="secondary" size="md" to="/dashboard/account/billing/subscription/reactivate">
          Reactivate subscription
        </LinkButton>
      )}
      <div style={{ marginTop: 48 }}>
        <p>
          {upgradable && (
            <LinkStyled style={{ marginRight: 12 }} to="/dashboard/account/billing/subscription/upgrade">
              Upgrade to yearly
            </LinkStyled>
          )}
          {downgradable && (
            <LinkStyled style={{ marginRight: 12 }} to="/dashboard/account/billing/subscription/downgrade">
              Change to monthly
            </LinkStyled>
          )}
          {!stripeSubscription.cancelAtPeriodEnd && (
            <LinkStyled to="/dashboard/account/billing/subscription/cancel">Cancel subscription</LinkStyled>
          )}
        </p>
      </div>
    </>
  );
};

const SettingsPricingPlanCommission = () => {
  return (
    <p>
      <LinkButton variant="secondary" size="md" to="/dashboard/account/billing/subscription">
        Switch to subscription
      </LinkButton>
    </p>
  );
};

const BillingInfoLifetime = ({
  pricingPlan,
  appSumoInvoiceItemUuid,
}: {
  pricingPlan: PricingPlanLifetime;
  appSumoInvoiceItemUuid?: string;
}) => {
  return (
    <>
      <p>Included in your plan:</p>
      <ul>
        <li>All core features</li>
        <li>{pricingPlan.applicationFeePercentage * 100}% Introwise commission</li>
        <li>
          {Math.round(pricingPlan.monthlyCallsParticipantSeconds / 60)} free minutes of Introwise calls each month
        </li>
        {Object.keys(pricingPlan.features).map((feature) => (
          <li key={feature}>{paidFeaturesNames[feature as keyof introwise.PaidFeaturesFlags]}</li>
        ))}
      </ul>
      {appSumoInvoiceItemUuid && (
        <p>
          <AnchorStyled
            href={`https://appsumo.com/account/redemption/${appSumoInvoiceItemUuid}#change-plan`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Manage your plan
          </AnchorStyled>
        </p>
      )}
    </>
  );
};

const BillingInfo = () => {
  const {
    userData: { pricingPlanId, pricingPriceId, appSumoInvoiceItemUuid },
  } = useContext(UserContext);

  const errorReporter = useErrorReporter();

  const sanitizedPlanId = (pricingPlanId as PricingPlanId) || defaultPricingPlanId;
  const pricingPlan = pricingPlans[sanitizedPlanId];

  // Extra check in case we accidentally delete a pricing plan from the code, but it's still used by some of the users
  const isDataValid = !!pricingPlan;
  useEffect(() => {
    if (!isDataValid) {
      errorReporter.report(new Error(`Invalid pricing plan or price id`));
    }
  }, [isDataValid, errorReporter]);

  if (!isDataValid) {
    return <p>Something went wrong. Please come back later.</p>;
  }

  return (
    <>
      <p>
        <b>Your current plan:</b> {formatPricingPlanName(sanitizedPlanId, pricingPriceId as SubscriptionPricingPriceId)}
      </p>
      <p>{pricingPlan.description}</p>
      {pricingPlan.type === 'subscription' && <SettingsPricingPlanSubscription />}
      {pricingPlan.type === 'commission' && <SettingsPricingPlanCommission />}
      {pricingPlan.type === 'lifetime' && (
        <BillingInfoLifetime pricingPlan={pricingPlan} appSumoInvoiceItemUuid={appSumoInvoiceItemUuid} />
      )}
      <div className={themeClasses({ marginTop: 3 })}>
        Questions about billing? <LinkStyled to="/contact">Contact us</LinkStyled>{' '}
      </div>
    </>
  );
};

const SettingsAccountBilling = () => (
  <>
    <Helmet title="Edit profile" />
    <ScreenTracker screenName="SettingsProfile" />
    <ColumnContainer>
      <WithHeaderContentColumn header="Pricing plan" whiteBackground>
        <BillingInfo />
      </WithHeaderContentColumn>
      <div>
        <WithHeaderContentColumn header="Support">
          <ListUnstyled>
            <li>
              <LinkStyled to="/faq">FAQs</LinkStyled>
            </li>
            <li>
              <LinkStyled to="/help">Help center</LinkStyled>
            </li>
            <li>
              <LinkStyled to="/pricing">Pricing &amp; Features</LinkStyled>
            </li>
            <li>
              <LinkStyled to="/contact">Contact us</LinkStyled>
            </li>
          </ListUnstyled>
        </WithHeaderContentColumn>
      </div>
    </ColumnContainer>
  </>
);

export default SettingsAccountBilling;
