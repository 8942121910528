import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import BackLink from 'web/components/BackLink';
import ColumnContainer from 'web/components/ColumnContainer';
import ContentColumn from 'web/components/ContentColumn';
import ContentColumnHeader from 'web/components/ContentColumnHeader';
import { LinkStyled } from 'web/components/elements';
import { AppleCalendarIntegrationConnected } from 'web/components/integrations/AppleCalendarIntegration';
import CalendarSyncSettings from 'web/components/integrations/CalendarSyncSettings';
import { GoogleCalendarIntegrationConnected } from 'web/components/integrations/GoogleCalendarIntegration';
import { MicrosoftCalendarIntegrationConnected } from 'web/components/integrations/MicrosoftCalendarIntegration';
import ScreenTracker from 'web/components/ScreenTracker';
import UserContext from 'web/components/UserContext';
import { ConnectedCalendarsConnectNew } from './ConnectedCalendarsConnect';

const ConnectedCalendars = () => {
  const { userData } = useContext(UserContext);
  const connectedCalendars = userData.connectedAccounts
    ? Object.values(userData.connectedAccounts)
        .filter((account) => account.type === 'calendar')
        .sort((a, b) => a.connectedAt.getTime() - b.connectedAt.getTime())
    : [];
  return (
    <>
      <Helmet title="Connected calendars" />
      <ScreenTracker screenName="ConnectedCalendars" />
      <BackLink to="/dashboard/scheduling" />
      <ColumnContainer>
        <div>
          {connectedCalendars.length === 0 && <ConnectedCalendarsConnectNew prefixTo={'connect/'} />}
          {connectedCalendars.length > 0 && (
            <>
              <ContentColumnHeader header="Connected calendars" />
              {connectedCalendars.map((account) => (
                <ContentColumn whiteBackground key={account.id}>
                  {account.provider === 'google' && <GoogleCalendarIntegrationConnected accountId={account.id} />}
                  {account.provider === 'microsoft' && <MicrosoftCalendarIntegrationConnected accountId={account.id} />}
                  {account.provider === 'apple' && (
                    <AppleCalendarIntegrationConnected
                      accountId={account.id}
                      reconnectTo={`connect/apple?accountId=${account.id}`}
                    />
                  )}
                </ContentColumn>
              ))}
              <ContentColumn whiteBackground>
                <CalendarSyncSettings />
              </ContentColumn>
              <ContentColumn whiteBackground>
                <LinkStyled to="connect">Connect additional calendar accounts</LinkStyled>
              </ContentColumn>
            </>
          )}
        </div>
      </ColumnContainer>
    </>
  );
};

export default ConnectedCalendars;
