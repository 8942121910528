import React, { useState } from 'react';
import { DateRange } from 'web/components/calendar/common/types';
import { Button, FormError, FormGroup } from 'web/components/elements';
import DateTimeSelector from 'web/components/form-fields/DateTimeSelector';
import GenericReactModal from 'web/components/GenericReactModal';
import sc from 'web/components/styled';
import themeClasses from 'web/styles/themeClasses.css';

const Container = sc.div(themeClasses({ display: 'flex', flexDirection: 'column' }), { width: 320, maxWidth: '95vw' });

const ButtonGroup = sc.div(themeClasses({ display: 'flex', justifyContent: 'flex-end', marginTop: 5, width: '100%' }));

const StyledButton = sc(Button)(themeClasses({ marginLeft: 5 }));

const TimeOffForm = ({
  period,
  create,
  onDelete,
  onSave,
}: {
  period: DateRange;
  create: boolean;
  onDelete: () => void;
  onSave: (start: Date, end: Date) => void;
}) => {
  const [dateRange, setDateRange] = useState<DateRange>(() => {
    if (period) {
      return period;
    }
    const tomorrow = new Date(Date.now() + 24 * 60 * 60 * 1000);
    return {
      start: new Date(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate(), 8, 0),
      end: new Date(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate(), 18, 0),
    };
  });

  const { start, end } = dateRange;

  const invalidStartEnd = start.getTime() >= end.getTime();
  const invalidEnd = end.getTime() <= Date.now();

  return (
    <>
      <h3 className={themeClasses({ margin: 0 })}>{create ? 'Add' : 'Edit'} time off</h3>
      <FormGroup>
        <label>From</label>
        <DateTimeSelector
          value={start}
          startDate={start}
          endDate={end}
          selectsStart
          onChange={(value) => setDateRange({ ...dateRange, start: value })}
          isTimeOn
        />
      </FormGroup>
      <FormGroup>
        <label>To</label>
        <DateTimeSelector
          value={end}
          startDate={start}
          endDate={end}
          selectsEnd
          onChange={(value) => setDateRange({ ...dateRange, end: value })}
          isTimeOn
        />
      </FormGroup>
      {invalidStartEnd && (
        <FormGroup>
          <FormError>End date must be after start date</FormError>
        </FormGroup>
      )}
      {invalidEnd && (
        <FormGroup>
          <FormError>End date must be in the future</FormError>
        </FormGroup>
      )}
      <ButtonGroup>
        {!create && (
          <StyledButton secondary md onClick={onDelete}>
            Delete
          </StyledButton>
        )}
        <StyledButton primary md onClick={() => onSave(start, end)} disabled={invalidStartEnd || invalidEnd}>
          {create ? 'Add' : 'Save'}
        </StyledButton>
      </ButtonGroup>
    </>
  );
};

const TimeOffEditModal = ({
  period,
  create,
  isOpen,
  onClose,
  onDelete,
  onSave,
}: {
  period: DateRange;
  create: boolean;
  onClose: () => void;
  onDelete: () => void;
  onSave: (start: Date, end: Date) => void;
  isOpen: boolean;
}) => {
  return (
    <GenericReactModal isOpen={isOpen} onRequestClose={onClose} allowOverflow>
      <Container>
        <TimeOffForm period={period} create={create} onDelete={onDelete} onSave={onSave} />
      </Container>
    </GenericReactModal>
  );
};

export default TimeOffEditModal;
