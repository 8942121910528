import { collection, orderBy, query, Timestamp, where } from 'firebase/firestore';
import React, { useContext, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import BookingPageLink from 'web/components/BookingPageLink';
import ColumnContainer from 'web/components/ColumnContainer';
import { LinkButton, LinkStyled } from 'web/components/elements';
import ListUnstyled from 'web/components/elements/ListUnstyled';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import ScreenTracker from 'web/components/ScreenTracker';
import { ServicesButtonsGroup } from 'web/components/Services/ServicesCommon';
import SessionSlot, { SessionSlotLoading } from 'web/components/SessionSlot';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import { useCollectionData } from 'web/hooks/firebase';
import useErrorHandler from 'web/hooks/useErrorHandler';
import themeClasses from 'web/styles/themeClasses.css';
import { firestoreGroupSessionConverter } from 'web/utils/convert';
import SchedulingSeries from './SchedulingSeries/Series';

const SettingsAvailabilityGroup = () => {
  const firestore = useFirestore();
  const now = useMemo(() => Timestamp.now(), []);
  const {
    user: { uid: userId },
  } = useContext(UserContext);

  const [sessions, loading, error] = useCollectionData(
    query(
      collection(firestore, 'sessions'),
      where('seriesId', '==', null),
      where('status', '==', 'confirmed'),
      where('expert.id', '==', userId),
      where('type', '==', 'group'),
      where('start', '>', now),
      orderBy('start', 'asc'),
    ).withConverter(firestoreGroupSessionConverter),
  );
  useErrorHandler(error);
  if (loading) {
    return (
      <div style={{ maxWidth: 450 }}>
        <SessionSlotLoading />
      </div>
    );
  }
  if (error) {
    return <p>Failed to load group sessions</p>;
  }

  return (
    <>
      {sessions && sessions.length > 0 && (
        <ListUnstyled style={{ maxWidth: 450 }} className={themeClasses({ display: 'grid', rowGap: 2 })}>
          {sessions.map((session) => (
            <li key={session.id}>
              <SessionSlot
                slot={{
                  start: session.start,
                  end: session.end,
                  price: session.price,
                  currency: session.currency,
                  title: session.title,
                  units: 'per person',
                  hidden: session.hidden,
                }}
                linkTo={`sessions/${session.id}`}
              />
            </li>
          ))}
        </ListUnstyled>
      )}
      <ServicesButtonsGroup>
        <LinkButton secondary md to="sessions/create">
          Schedule group session
        </LinkButton>
      </ServicesButtonsGroup>
    </>
  );
};

const SettingsSchedulingGroupIndex = () => {
  return (
    <>
      <Helmet title="Manage group sessions" />
      <ScreenTracker screenName="SettingsSchedulingGroup" />
      <ColumnContainer>
        <div>
          <WithHeaderContentColumn whiteBackground header="Group sessions">
            <h4 className={themeClasses({ marginTop: 0 })}>Series</h4>
            <p className={themeClasses({ marginTop: 0 })}>
              Offer sessions of the same type on specific dates: like regular office hours or group workshops. Multiple
              people can book slots in the same session from a series.
            </p>
            <SchedulingSeries />
            <h4>One-time sessions</h4>
            <p className={themeClasses({ marginTop: 0 })}>
              Offer a one-time session on a specific date: perfect for one-off and irregular events. Multiple people can
              book slots in the same one-time session.
            </p>
            <SettingsAvailabilityGroup />
          </WithHeaderContentColumn>
        </div>
        <div>
          <WithHeaderContentColumn header="View as a client">
            <BookingPageLink />
            <div style={{ marginTop: 16 }}>
              <small>View availabile personal and group sessions on your booking page</small>
            </div>
          </WithHeaderContentColumn>
          <WithHeaderContentColumn header="More settings">
            <ListUnstyled>
              <li>
                <LinkStyled to="/dashboard/booking">Edit booking page</LinkStyled>
              </li>
              <li>
                <LinkStyled to="/dashboard/payments/currency">Change currency</LinkStyled>
              </li>
              <li>
                <LinkStyled to="/dashboard/calls/locations">Manage conferencing</LinkStyled>
              </li>
            </ListUnstyled>
          </WithHeaderContentColumn>
        </div>
      </ColumnContainer>
    </>
  );
};

export default SettingsSchedulingGroupIndex;
