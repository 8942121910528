import { To } from 'history';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import themeConstants from 'web/styles/themeConstants';
import themeVars from 'web/styles/themeVars.css';

const DashboardNavContainer = styled.nav`
  ${themeConstants.typography.h1};

  display: flex;
  flex-wrap: wrap;

  margin-top: 52px;
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: bold;

  ${themeConstants.media.sm} {
    margin-bottom: 15px;
  }

  ${themeConstants.media.lg} {
    font-size: 32px;
    line-height: 140%;
  }

  ${themeConstants.media.xl} {
    font-size: 36px;
  }

  > :not(:last-child) {
    margin-right: 20px;
  }
`;

const DashboardNavLink = styled(NavLink)`
  ${themeConstants.mixins.nonTextLink};

  white-space: nowrap;

  color: ${themeVars.color.halfMuted};
  text-decoration: underline;
  text-decoration-style: dotted;

  &.active {
    color: unset;
    text-decoration: unset;
  }
`;

const DashboardNav = ({ items }: { items: { text: string; to: To; end?: boolean }[] }) => (
  <DashboardNavContainer>
    {items.length > 1 &&
      items.map((item, idx) => (
        <DashboardNavLink to={item.to} end={item.end} key={idx}>
          {item.text}
        </DashboardNavLink>
      ))}
    {items.length === 1 && <span>{items[0].text}</span>}
  </DashboardNavContainer>
);

export default DashboardNav;
