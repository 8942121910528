import styled from 'styled-components';
import React from 'react';
import themeConstants from 'web/styles/themeConstants';
import themeVars from 'web/styles/themeVars.css';

const ShareContainer = styled.div<{ sm?: boolean; color?: string }>`
  display: flex;

  a {
    transition: ${themeConstants.transition.button};
    margin-right: ${({ sm }) => (sm ? '4px' : '16px')};
    width: ${({ sm }) => (sm ? '28px' : '56px')};
    height: ${({ sm }) => (sm ? '28px' : '56px')};

    fill: ${({ color }) => (color ? color : themeVars.button.borderColor.secondary)};
    :visited {
      fill: ${({ color }) => (color ? color : themeVars.button.borderColor.secondary)};
    }
    :hover {
      fill: ${({ color }) => (color ? color : themeVars.button.borderColor.secondaryHover)};
    }
  }
`;

const Facebook = () => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" role="img">
    <title>Facebook</title>
    <path d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM13.2508 12.5271V19.0557H10.5496V12.5274H9.20002V10.2776H10.5496V8.92678C10.5496 7.0914 11.3116 6 13.4766 6H15.2791V8.25006H14.1524C13.3096 8.25006 13.2539 8.56447 13.2539 9.15125L13.2508 10.2773H15.2918L15.053 12.5271H13.2508Z" />
  </svg>
);

const Twitter = () => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" role="img">
    <title>Twitter</title>
    <path d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM11.6658 10.169L11.6406 9.75375C11.5651 8.67755 12.2282 7.69456 13.2774 7.31323C13.6635 7.17765 14.3182 7.1607 14.7463 7.27934C14.9142 7.33018 15.2331 7.49966 15.4598 7.65219L15.8711 7.93184L16.3243 7.78778C16.5761 7.71151 16.9119 7.5844 17.063 7.49966C17.2057 7.42339 17.3316 7.38102 17.3316 7.40645C17.3316 7.5505 17.021 8.042 16.7608 8.31317C16.4083 8.6945 16.509 8.72839 17.2224 8.47417C17.6505 8.33011 17.6589 8.33011 17.575 8.49112C17.5246 8.57586 17.2644 8.87245 16.9874 9.14362C16.5174 9.60969 16.4922 9.66054 16.4922 10.0503C16.4922 10.652 16.2068 11.9062 15.9214 12.5925C15.3926 13.8806 14.2595 15.211 13.1263 15.8805C11.5315 16.8211 9.40786 17.0584 7.61999 16.5075C7.02404 16.3211 6 15.8466 6 15.7618C6 15.7364 6.31057 15.7025 6.68829 15.694C7.4773 15.6771 8.26631 15.4568 8.93781 15.067L9.39108 14.7958L8.87066 14.6178C8.13201 14.3636 7.46891 13.7789 7.30103 13.2281C7.25067 13.0501 7.26746 13.0417 7.73751 13.0417L8.22434 13.0332L7.81305 12.8383C7.32621 12.5925 6.88134 12.1773 6.66311 11.7536C6.50362 11.4486 6.30218 10.6774 6.36093 10.6181C6.37772 10.5927 6.55399 10.6435 6.75544 10.7113C7.33461 10.9232 7.41015 10.8723 7.0744 10.5164C6.44487 9.87239 6.25181 8.91482 6.55399 8.0081L6.69668 7.60135L7.25067 8.15216C8.38383 9.26226 9.71843 9.92323 11.2461 10.1181L11.6658 10.169Z" />
  </svg>
);

const LinkedIn = () => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" role="img">
    <title>LinkedIn</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM5.7609 9.93867H8.48036V18.1095H5.7609V9.93867ZM8.65941 7.41135C8.64176 6.6102 8.06885 6 7.13854 6C6.20823 6 5.60002 6.6102 5.60002 7.41135C5.60002 8.1959 6.19025 8.82367 7.10324 8.82367H7.12062C8.06885 8.82367 8.65941 8.1959 8.65941 7.41135ZM15.1569 9.74766C16.9465 9.74766 18.2881 10.9157 18.2881 13.4255L18.2879 18.1104H15.5686V13.739C15.5686 12.641 15.1751 11.8917 14.1906 11.8917C13.4393 11.8917 12.9918 12.3968 12.7953 12.8847C12.7234 13.0595 12.7057 13.3031 12.7057 13.5473V18.1106H9.98591C9.98591 18.1106 10.0218 10.7065 9.98591 9.93974H12.7057V11.0971C13.0666 10.5407 13.7131 9.74766 15.1569 9.74766Z"
    />
  </svg>
);

export { ShareContainer, Facebook, Twitter, LinkedIn };
