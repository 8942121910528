import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, FormDescription, FormError, FormGroup, Input, Label, LabelText } from 'web/components/elements';
import themeClasses from 'web/styles/themeClasses.css';

export type FormValues = {
  name: string;
};

const PersonalAvailabilityForm = ({
  submitting,
  onSubmit,
  defaultValues,
}: {
  defaultValues?: FormValues;
  submitting: boolean;
  onSubmit: (values: FormValues) => void;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({ defaultValues: defaultValues || { name: 'New Hours' } });
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset disabled={submitting}>
        <FormGroup>
          <Label>
            <Input
              {...register('name', {
                required: 'Required',
                maxLength: { value: 40, message: 'Too long, please limit the name to 40 characters' },
              })}
              hasError={!!errors.name}
              placeholder="Working Hours"
            />
            <LabelText>Name</LabelText>
          </Label>
          {errors.name && <FormError>{errors.name.message}</FormError>}
          <FormDescription>A descriptive name to help you manage availabilities</FormDescription>
        </FormGroup>
        <FormGroup className={themeClasses({ display: 'flex', justifyContent: 'space-between', alignItems: 'center' })}>
          <Button type="submit" variant="primary">
            Save
          </Button>
        </FormGroup>
      </fieldset>
    </form>
  );
};

export default PersonalAvailabilityForm;
