import { doc, runTransaction } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import useErrorReporter from 'web/hooks/useErrorReporter';
import { firestoreUserConverter } from 'web/utils/convert';
import { Button, Checkbox, InlineButton, Radio, Select } from '../elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import useFunctions from 'web/components/FirebaseContext/useFunctions';
import GenericReactModal from '../GenericReactModal';
import UserContext from '../UserContext';
import GoogleCalendarIcon from './GoogleCalendarIcon';
import themeConstants from 'web/styles/themeConstants';
import MicrosoftOutlookIcon from './MicrosoftOutlookIcon';
import AppleCalendarIcon from './AppleCalendarIcon';

const ModalContentContainer = styled.div`
  width: 360px;
  max-width: 100%;
`;

const Group = styled.fieldset`
  display: grid;
  row-gap: 8px;
  > * {
    display: block;
  }
`;

const ModalButtonGroup = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  > *:not(:first-child) {
    margin-left: 16px;
  }
`;

const connectedAccountTitle = (connectedAccount: introwise.ConnectedAccount) =>
  connectedAccount.provider === 'microsoft'
    ? connectedAccount.email || connectedAccount.principalName
    : connectedAccount.provider === 'google'
    ? connectedAccount.email
    : connectedAccount.provider === 'apple'
    ? connectedAccount.appleId
    : 'Unknown account';

const connectedAccountIcon = (connectedAccount: introwise.ConnectedAccount) => {
  switch (connectedAccount.provider) {
    case 'google':
      return GoogleCalendarIcon;
    case 'microsoft':
      return MicrosoftOutlookIcon;
    case 'apple':
      return AppleCalendarIcon;
    default:
      return React.Fragment;
  }
};

const CalendarsSyncToEditImpl = ({ onDone }: { onDone: () => void }) => {
  const { userData } = useContext(UserContext);
  const calendarAccounts = Object.values(userData.connectedAccounts).filter(
    (account) => account.type === 'calendar' && account.enabled,
  );
  const currentAccountRef = userData.settings.calendarSync.to && Object.values(userData.settings.calendarSync.to)[0];
  const [accountId, setAccountId] = useState(currentAccountRef?.id);
  const [calendarId, setCalendarId] = useState(currentAccountRef?.calendarIds[0]);
  const firestore = useFirestore();
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = async () => {
    setSubmitting(true);
    try {
      await runTransaction(firestore, async (t) => {
        const userRef = doc(firestore, 'users', userData.id).withConverter(firestoreUserConverter);
        const userDoc = await t.get(userRef);
        if (!userDoc.exists) {
          // TODO: signal error
          return;
        }
        const freshUserData = userDoc.data()!;
        let update;
        if (accountId) {
          const freshAccount = freshUserData.connectedAccounts?.[accountId];
          if (!freshAccount || freshAccount.type !== 'calendar') {
            // TODO: signal error
            return;
          }
          const { calendars } = freshAccount;
          if (!calendars.find((calendar) => calendar.id === calendarId)) {
            // TODO: signal error
            return;
          }
          update = {
            [accountId]: {
              id: accountId,
              calendarIds: [calendarId],
            },
          };
        } else {
          update = {};
        }
        t.update(userRef, {
          [`settings.calendarSync.to`]: update,
        });
      });
      onDone();
    } catch (err) {
      // TODO: signal error
      setSubmitting(false);
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newAccountId = event.target.value;
    const newCalendarId =
      newAccountId === currentAccountRef?.id
        ? currentAccountRef.calendarIds[0]
        : newAccountId
        ? (userData.connectedAccounts[newAccountId] as introwise.CalendarAccount).calendars.filter(
            (c) => c.writeable,
          )[0].id
        : undefined;
    setAccountId(newAccountId);
    setCalendarId(newCalendarId);
  };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit();
      }}
    >
      <h3 style={{ marginTop: 0 }}>Add new sessions to</h3>
      <Group disabled={submitting}>
        {calendarAccounts.map((account) => {
          const title = connectedAccountTitle(account);
          const Icon = connectedAccountIcon(account);
          return (
            <Radio
              key={account.id}
              name="accountId"
              value={account.id}
              onChange={onChange}
              defaultChecked={account.id === accountId}
            >
              <Icon width={16} style={{ marginTop: -3, marginRight: 4, verticalAlign: 'middle' }} />
              {title}
            </Radio>
          );
        })}
        <Radio name="accountId" value="" onChange={onChange} defaultChecked={!accountId}>
          Do not add sessions
        </Radio>
      </Group>
      {accountId && userData.connectedAccounts[accountId].type === 'calendar' && (
        <>
          <h4>Which calendar?</h4>
          <Select defaultValue={calendarId} onChange={(event) => setCalendarId(event.target.value)} key={accountId}>
            {(userData.connectedAccounts[accountId] as introwise.CalendarAccount).calendars
              .filter((calendar) => calendar.writeable)
              .map((calendar) => (
                <option value={calendar.id} key={calendar.id}>
                  {calendar.name}
                </option>
              ))}
          </Select>
        </>
      )}
      <ModalButtonGroup>
        <Button md secondary onClick={onDone} disabled={submitting}>
          Cancel
        </Button>
        <Button md primary type="submit" disabled={submitting}>
          Save
        </Button>
      </ModalButtonGroup>
    </form>
  );
};

const CalendarsSyncFromEditImpl = ({ onDone, accountId }: { onDone: () => void; accountId: string }) => {
  const { userData } = useContext(UserContext);
  const account = userData.connectedAccounts[accountId];
  if (account.type !== 'calendar') {
    throw new Error(`Invalid account type. Account ${accountId} is not a calendar account.`);
  }
  const calendars = account.calendars;
  const [calendarIds, setCalendarIds] = useState(
    () => new Set(userData.settings.calendarSync.from[accountId].calendarIds),
  );
  const initialCalendarIds = useRef(calendarIds).current;
  const firestore = useFirestore();
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = async () => {
    setSubmitting(true);
    try {
      await runTransaction(firestore, async (t) => {
        const userRef = doc(firestore, 'users', userData.id);
        const userDoc = await t.get(userRef.withConverter(firestoreUserConverter));
        if (!userDoc.exists) {
          // TODO: signal error
          return;
        }
        const freshUserData = userDoc.data()!;
        const freshAccount = freshUserData.connectedAccounts?.[accountId];
        if (!freshAccount || freshAccount.type !== 'calendar') {
          // TODO: signal error
          return;
        }
        const { calendars } = freshAccount;
        const newCalendarIds = calendars.filter((calendar) => calendarIds.has(calendar.id)).map(({ id }) => id);
        t.update(userRef, {
          [`settings.calendarSync.from.${accountId}.calendarIds`]: newCalendarIds,
        });
      });
      onDone();
    } catch (err) {
      // TODO: signal error
      setSubmitting(false);
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const calendarId = event.target.value;
    const checked = event.target.checked;
    if (checked) {
      calendarIds.add(calendarId);
    } else {
      calendarIds.delete(calendarId);
    }
    setCalendarIds(new Set(calendarIds));
  };

  const Icon = connectedAccountIcon(account);
  const title = connectedAccountTitle(account);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit();
      }}
    >
      <h3 style={{ marginTop: 0 }}>Checking for conflicts</h3>

      <Icon width={16} style={{ marginTop: -3, marginRight: 4, verticalAlign: 'middle' }} />
      {title}

      <h4>Which calendars?</h4>
      <Group disabled={submitting}>
        {calendars.map((calendar) => (
          <Checkbox
            key={calendar.id}
            value={calendar.id}
            defaultChecked={initialCalendarIds.has(calendar.id)}
            onChange={onChange}
          >
            {calendar.name}
          </Checkbox>
        ))}
      </Group>

      <ModalButtonGroup>
        <Button md secondary onClick={onDone} disabled={submitting}>
          Cancel
        </Button>
        <Button md primary type="submit" disabled={submitting}>
          Save
        </Button>
      </ModalButtonGroup>
    </form>
  );
};

const CalendarsSyncModalButton = ({ type, accountId }: { type: 'to' | 'from'; accountId?: string }) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <GenericReactModal isOpen={modalOpen} onRequestClose={() => setModalOpen(false)}>
        <ModalContentContainer>
          {type === 'to' && <CalendarsSyncToEditImpl onDone={() => setModalOpen(false)} />}
          {type === 'from' && <CalendarsSyncFromEditImpl onDone={() => setModalOpen(false)} accountId={accountId} />}
        </ModalContentContainer>
      </GenericReactModal>
      <InlineButton onClick={() => setModalOpen(true)}>Edit</InlineButton>
    </>
  );
};

const CalendarsAccountsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const CalendarsAccountsListItem = styled.li`
  padding: 16px 26px;
  border-radius: ${themeConstants.borderRadius.sm};
  border: 1px solid ${themeConstants.borders.light};

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  > div {
    ${themeConstants.media.sm} {
      display: grid;
      grid-template-columns: auto min-content;
      column-gap: 20px;
    }
  }
`;

const CalendarsList = ({
  type,
  calendarRefs,
  connectedAccounts,
}: {
  type: 'to' | 'from';
  calendarRefs: introwise.CalendarSyncAccountRefMap;
  connectedAccounts: introwise.User['connectedAccounts'];
}) => {
  const list =
    (calendarRefs &&
      Object.values(calendarRefs).sort(
        (a, b) => connectedAccounts[a.id]?.connectedAt.getTime() - connectedAccounts[b.id]?.connectedAt.getTime(),
      )) ||
    [];
  return (
    <CalendarsAccountsList>
      {list.map((syncRef) => {
        const account = connectedAccounts[syncRef.id];
        if (account.type !== 'calendar') {
          throw new Error(`Invalid account type. Account ${syncRef.id} is not a calendar account.`);
        }
        const title = connectedAccountTitle(account);
        const Icon = connectedAccountIcon(account);
        return (
          <CalendarsAccountsListItem key={`${syncRef.id}${syncRef.calendarIds?.join()}`}>
            <div>
              <div>
                <Icon width={16} style={{ marginTop: -3, marginRight: 4, verticalAlign: 'middle' }} />
                Calendar{type === 'from' ? 's' : ''} on <b>{title}</b>
                {syncRef.calendarIds?.length ? (
                  <ul>
                    {syncRef.calendarIds.map((calendarId) => (
                      <li key={calendarId}>{account.calendars.find(({ id }) => id === calendarId)?.name}</li>
                    ))}
                  </ul>
                ) : (
                  type === 'from' && (
                    <ul>
                      <li>Do not check this calendar</li>
                    </ul>
                  )
                )}
              </div>
              <div>
                <CalendarsSyncModalButton type={type} accountId={type === 'from' && syncRef.id} />
              </div>
            </div>
          </CalendarsAccountsListItem>
        );
      })}
      {list.length === 0 && type === 'to' && (
        <CalendarsAccountsListItem>
          <div>
            <div>Do not add sessions to any calendar</div>
            <div>
              <CalendarsSyncModalButton type="to" />
            </div>
          </div>
        </CalendarsAccountsListItem>
      )}
    </CalendarsAccountsList>
  );
};

const SyncSettingsBlock = styled.div`
  display: grid;

  ${themeConstants.media.sm} {
    grid-template-columns: 1fr minmax(auto, 60%);
    column-gap: 40px;
  }
`;

const SyncSettingsBlockTitle = styled.div`
  > h4 {
    margin: 0;
  }

  > p {
    margin-top: 8px;
  }
`;

const CalendarSyncSettings = () => {
  const { userData } = useContext(UserContext);
  const functions = useFunctions();
  const errorReporter = useErrorReporter();

  useEffect(() => {
    const refreshAccounts = async () => {
      try {
        await httpsCallable(functions, 'integrationsRefreshCalendarAccounts')({});
      } catch (err) {
        errorReporter.report(`Failed to refresh calendar accounts: ${err}`);
      }
    };
    void refreshAccounts();
  }, [errorReporter, functions]);

  return (
    <>
      <SyncSettingsBlock>
        <SyncSettingsBlockTitle>
          <h4>Check for conflicts</h4>
          <p>
            <small>Introwise will check your free/busy times on these calendars when showing your availability</small>
          </p>
        </SyncSettingsBlockTitle>
        <div>
          <CalendarsList
            type="from"
            calendarRefs={userData.settings.calendarSync.from}
            connectedAccounts={userData.connectedAccounts}
          />
        </div>
      </SyncSettingsBlock>
      <SyncSettingsBlock style={{ marginTop: 32 }}>
        <SyncSettingsBlockTitle>
          <h4>Add sessions to</h4>
          <p>
            <small>Introwise will automatically add new session bookings to this calendar</small>
          </p>
        </SyncSettingsBlockTitle>
        <div>
          <CalendarsList
            type="to"
            calendarRefs={userData.settings.calendarSync.to}
            connectedAccounts={userData.connectedAccounts}
          />
        </div>
      </SyncSettingsBlock>
    </>
  );
};

export default CalendarSyncSettings;
