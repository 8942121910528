import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import React, { useState } from 'react';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import useTracking from 'web/components/TrackingContext/useTracking';
import useErrorReporter from 'web/hooks/useErrorReporter';
import useMountedRef from 'web/hooks/useMountedRef';
import NoteModal from './NoteModal';

const NoteAddModalButton = ({
  pageId,
  clientId,
  children,
}: {
  pageId: string;
  clientId: string;
  children: (onClick: () => void) => React.ReactNode;
}) => {
  const [open, setOpen] = useState(false);
  const firestore = useFirestore();
  const tracking = useTracking();
  const errorReporter = useErrorReporter();
  const [submitting, setSubmitting] = useState(false);
  const mounted = useMountedRef();

  const onSubmit = async (content: introwise.RichText) => {
    setSubmitting(true);
    try {
      const note: introwise.FirestoreWriteData<introwise.Note> = {
        content,
        updatedAt: null,
        createdAt: serverTimestamp(),
      };
      await addDoc(collection(firestore, 'pages', pageId, 'clients', clientId, 'notes'), note);
      tracking.trackEvent('Client Note Created');
      if (mounted.current) {
        setOpen(false);
      }
    } catch (err) {
      errorReporter.report(err);
    }
    if (mounted.current) {
      setSubmitting(false);
    }
  };

  return (
    <>
      {children(() => setOpen(true))}
      <NoteModal isOpen={open} onRequestClose={() => setOpen(false)} onSubmit={onSubmit} submitting={submitting} />
    </>
  );
};

export default NoteAddModalButton;
