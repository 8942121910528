import React, { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { formatDurationMins } from 'web/App/BookingPage/common';
import { Button, FormDescription, InlineButton, Input, Label, LabelText, Select } from 'web/components/elements';
import themeConstants from 'web/styles/themeConstants';
import { formatCurrencyAmount } from 'web/utils/currency';

const SeriesCard = styled.div`
  padding: 14px 20px;

  border: 1px solid ${themeConstants.borders.light};
  border-radius: ${themeConstants.borderRadius.sm};

  display: grid;
  grid-template: auto auto / auto;
  gap: 20px;
  ${themeConstants.media.sm} {
    grid-template: auto / 1fr 90px;
  }
`;

const SeriesInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SeriesCount = styled.div``;

const SeriesEdit = ({
  series,
  defaultValue,
  onRemove,
  onChange,
}: {
  series: introwise.Series;
  defaultValue: number;
  onRemove: () => void;
  onChange: (count: number) => void;
}) => {
  return (
    <SeriesCard>
      <SeriesInfo>
        <div>
          <b>{series.title}</b>
        </div>
        <div>
          {formatCurrencyAmount(series.price, series.currency)}
          <span>&nbsp;· </span>
          {formatDurationMins(series.duration)}
          <span>&nbsp;· </span>
          <InlineButton onClick={onRemove}>Remove</InlineButton>
        </div>
      </SeriesInfo>
      <SeriesCount>
        <Label>
          <LabelText>Sessions</LabelText>
          <Input defaultValue={defaultValue} type="number" min={0} onChange={(e) => onChange(Number(e.target.value))} />
        </Label>
      </SeriesCount>
    </SeriesCard>
  );
};

const SeriesAdd = ({
  personalSessions,
  series,
  onAdd,
}: {
  personalSessions: introwise.PackagePersonalSessions;
  series: { [id: string]: introwise.Series };
  onAdd: (seriesId: string) => void;
}) => {
  const availableSeriesIds = useMemo(
    () => (series ? Object.keys(series).filter((seriesId) => !personalSessions[seriesId]) : []),
    [series, personalSessions],
  );
  const [adding, setAdding] = useState(false);
  // const [selectedSeriesId, setSelectedSeriesId] = useState(() => availableSeriesIds[0]);
  const selectRef = useRef<HTMLSelectElement>();
  return (
    <>
      {adding && (
        <SeriesCard>
          <div>
            <Label>
              <LabelText>Series</LabelText>
              <Select ref={selectRef}>
                {availableSeriesIds.map((seriesId) => (
                  <option value={seriesId} key={seriesId}>
                    {series[seriesId].title}
                  </option>
                ))}
              </Select>
            </Label>
          </div>
          <div style={{ display: 'grid' }}>
            <Button
              secondary
              onClick={() => {
                onAdd(selectRef.current.value);
                setAdding(false);
              }}
            >
              Add
            </Button>
          </div>
        </SeriesCard>
      )}
      <div>
        {availableSeriesIds.length > 0 && (
          <InlineButton onClick={() => setAdding((prev) => !prev)}>{adding ? 'Cancel' : 'Add a series'}</InlineButton>
        )}
        {availableSeriesIds.length === 0 && (
          <FormDescription>You don&apos;t have any more group sessions series</FormDescription>
        )}
      </div>
    </>
  );
};

const GroupSeriesSelector = ({
  groupSessionSeries,
  series,
  onChange,
}: {
  groupSessionSeries: introwise.PackageGroupSessionSeries;
  series: { [id: string]: introwise.Series };
  onChange?: (value: introwise.PackageGroupSessionSeries) => void;
}) => {
  const onAdd = (id: string) => {
    onChange({
      ...groupSessionSeries,
      [id]: {
        id,
        title: series[id].title,
        count: 0,
      },
    });
  };

  const onRemove = (id: string) => {
    const { [id]: remove, ...rest } = groupSessionSeries;
    onChange(rest);
  };

  const onEdit = (id: string, value: number) => {
    const { [id]: edited, ...rest } = groupSessionSeries;
    onChange({
      ...rest,
      [id]: {
        ...edited,
        count: value,
      },
    });
  };

  return (
    <div>
      <div style={{ display: 'grid', gap: 12 }}>
        {Object.keys(groupSessionSeries).map((seriesId) => (
          <SeriesEdit
            series={series[seriesId]}
            defaultValue={groupSessionSeries[seriesId].count}
            key={seriesId}
            onRemove={() => onRemove(seriesId)}
            onChange={(value) => onEdit(seriesId, value)}
          />
        ))}
        <SeriesAdd personalSessions={groupSessionSeries} series={series} onAdd={onAdd} />
      </div>
    </div>
  );
};

export default GroupSeriesSelector;
