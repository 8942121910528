import React, { useMemo } from 'react';
import { Controller, FieldError, RegisterOptions, useForm } from 'react-hook-form';
import {
  Button,
  Checkbox,
  FormDescription,
  FormError,
  FormFootnote,
  FormGroup,
  InlineButton,
  Input,
  Label,
  LabelText,
  LinkStyled,
  TextArea,
} from 'web/components/elements';
import DateTimeRangeSelector from 'web/components/form-fields/DateTimeRangeSelector';
import FormValueLength from 'web/components/form-fields/FormValueLength';
import LocationField from 'web/components/form-fields/LocationField';
import PaymentAccountField from 'web/components/form-fields/PaymentAccountField';
import PriceInput from 'web/components/form-fields/PriceInput';
import WorkflowsField from 'web/components/form-fields/WorkflowsField';
import Spinner from 'web/components/Spinner';
import {
  convertFromDecimal,
  convertToDecimal,
  exchangeRateToUsd,
  formatCurrencyDecimal,
  isZeroDecimal,
  minChargeAmount,
} from 'web/utils/currency';

const maxTitleLength = 60;
const maxTitleLengthLarge = 120;

const makeTitleValidationRules = (largeSessionLimits?: boolean): RegisterOptions => ({
  maxLength: {
    value: largeSessionLimits ? maxTitleLengthLarge : maxTitleLength,
    message: `Too long, please limit the title to ${maxTitleLength} characters`,
  },
  required: 'Required',
  setValueAs: (v) => v.trim(),
});

const descriptionLengthMax = 1250;

const descriptionValidationRules: RegisterOptions = {
  maxLength: {
    value: descriptionLengthMax,
    message: `Too long, please limit the description to ${descriptionLengthMax} characters`,
  },
  setValueAs: (v) => v.trim(),
};

const dateTimeValidationRules: RegisterOptions = {
  validate: {
    startRequired: (values: FormValues['dateTime']) => (!values.start ? 'Start date is required' : undefined),
    endRequired: (values: FormValues['dateTime']) => (!values.end ? 'End date is required' : undefined),
    startMin: (values: FormValues['dateTime']) =>
      values.start < new Date() ? 'Start date must be in the future' : undefined,
    endMin: (values: FormValues['dateTime']) =>
      values.start >= values.end ? 'End date must be greater than start' : undefined,
  },
};

const groupSizeLimit = 42;
const groupSizeLimitLarge = 500;

const makeGroupSizeValidationRules = (largeSessionLimits?: boolean): RegisterOptions => ({
  required: 'Required',
  valueAsNumber: true,
  validate: {
    min: (value: number) => {
      if (value <= 0) {
        return `Group size must be greater than 0`;
      }
    },
    max: (value: number) => {
      const limit = largeSessionLimits ? groupSizeLimitLarge : groupSizeLimit;
      if (value > limit) {
        return `Group sessions are limited to ${limit} participants`;
      }
    },
  },
});

const maxPriceUsdDefault = 1000;
const increasedPriceLimitMultiplier = 5;

const makePriceValidationRules = (currency: introwise.Currency, increasedPriceLimit?: boolean): RegisterOptions => {
  const minPriceValue = convertToDecimal(minChargeAmount[currency], currency);
  const maxPriceValue =
    maxPriceUsdDefault * exchangeRateToUsd[currency] * (increasedPriceLimit ? increasedPriceLimitMultiplier : 1);
  return {
    required: 'Required',
    validate: (strValue: string) => {
      const value = parseFloat(strValue);
      if (isNaN(value)) {
        return 'Must be a number';
      }
      if (value > maxPriceValue) {
        return `That's too much! Our sessions price is limited to ${formatCurrencyDecimal(
          maxPriceValue,
          currency,
        )}. Contact us if you need this limit increased.`;
      }

      if (value < minPriceValue && value !== 0) {
        return `Must be at least ${formatCurrencyDecimal(minPriceValue, currency)}. Use ${formatCurrencyDecimal(
          0,
          currency,
        )} for a free session.`;
      }

      if (isZeroDecimal(currency) && !Number.isInteger(value)) {
        return `Must be a whole number, e.g. ${value.toFixed(0)}`;
      }
    },
  };
};

type FormValues = {
  title: string;
  description: string;
  dateTime: {
    start: Date;
    end: Date;
  };
  groupSizeMax: string;
  price: string;
  hidden?: boolean;
  workflowsIds?: string[];
  locationId?: string;
  paymentAccountId?: introwise.GroupSession['paymentAccountId'];
};

type SubmitValues = {
  title: string;
  description: string;
  start: Date;
  end: Date;
  groupSizeMax: number;
  price: number;
  hidden?: boolean;
  workflowsIds?: string[];
  locationId?: string;
  paymentAccountId?: introwise.GroupSession['paymentAccountId'];
};

const sessionToFormValues = (session: introwise.GroupSession): FormValues =>
  session
    ? {
        title: session.title,
        description: session.description,
        dateTime: {
          start: session.start,
          end: session.end,
        },
        groupSizeMax: session.groupSize?.max?.toString(),
        price: convertToDecimal(session.price, session.currency).toString(),
        hidden: !!session.hidden,
      }
    : {
        title: '',
        description: '',
        dateTime: {
          start: undefined,
          end: undefined,
        },
        groupSizeMax: '6',
        price: '',
        hidden: false,
      };

const formValuesToSubmitValues = (values: FormValues, currency: introwise.Currency): SubmitValues => ({
  title: values.title,
  description: values.description,
  start: values.dateTime.start,
  end: values.dateTime.end,
  groupSizeMax: parseInt(values.groupSizeMax),
  price: convertFromDecimal(parseFloat(values.price), currency),
  hidden: !!values.hidden,
  workflowsIds: values.workflowsIds || [],
  locationId: values.locationId,
});

const TimezoneInfo = () => {
  const timezone = useMemo(() => Intl.DateTimeFormat().resolvedOptions().timeZone.replace('_', ' '), []);
  return <FormDescription>Your timezone: {timezone}</FormDescription>;
};

const GroupSessionForm = ({
  session,
  currency,
  onSubmit,
  onDelete,
  onCancel,
  submitting,
  readOnly,
  increasedPriceLimit,
  largeSessionLimits,
  withWorkflows,
  locationSelectable,
  workflowsSelectable,
}: {
  session: introwise.GroupSession;
  currency: introwise.Currency;
  onSubmit: (session: SubmitValues) => void;
  onDelete?: () => void;
  onCancel?: () => void;
  submitting: boolean;
  readOnly?: boolean;
  increasedPriceLimit?: boolean;
  largeSessionLimits?: boolean;
  withWorkflows?: boolean;
  locationSelectable?: boolean;
  workflowsSelectable?: boolean;
}) => {
  const priceValidationRules = useMemo(
    () => makePriceValidationRules(session?.currency || currency, increasedPriceLimit),
    [currency, session?.currency, increasedPriceLimit],
  );
  const titleValidationRules = useMemo(() => makeTitleValidationRules(largeSessionLimits), [largeSessionLimits]);
  const groupSizeValidationRules = useMemo(
    () => makeGroupSizeValidationRules(largeSessionLimits),
    [largeSessionLimits],
  );

  const defaultValues = sessionToFormValues(session);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    defaultValues,
  });

  const isValid = Object.keys(errors).length === 0;

  return (
    <form
      onSubmit={handleSubmit((values) => {
        onSubmit(formValuesToSubmitValues(values, session?.currency || currency));
      })}
    >
      <fieldset disabled={submitting}>
        <FormGroup>
          <Label>
            <LabelText>Session title</LabelText>
            <Input {...register('title', titleValidationRules)} hasError={!!errors.title} />
          </Label>
          <FormFootnote>
            <FormValueLength
              control={control}
              name="title"
              maxLength={largeSessionLimits ? maxTitleLengthLarge : maxTitleLength}
            />
          </FormFootnote>
          <FormError>{errors.title ? errors.title.message : <>&nbsp;</>}</FormError>
          <div style={{ marginBottom: '-0.75em' }} />
        </FormGroup>
        <FormGroup>
          <Label>
            <LabelText>Short description (optional)</LabelText>
            <TextArea
              {...register('description', descriptionValidationRules)}
              rows={4}
              hasError={!!errors.description}
            />
          </Label>
          <FormFootnote>
            <FormValueLength name="description" maxLength={descriptionLengthMax} control={control} />
          </FormFootnote>
          <FormDescription>Extra information and need-to-know details</FormDescription>
          {errors?.description && <FormError>{errors.description.message}</FormError>}
        </FormGroup>
        {!readOnly && (
          <FormGroup>
            <Controller
              name="dateTime"
              control={control}
              rules={dateTimeValidationRules}
              render={({ field: { onChange, onBlur, value } }) => (
                <DateTimeRangeSelector
                  isTimeOn
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  disabled={submitting || readOnly}
                  errors={{
                    start: (errors.dateTime as FieldError)?.type.startsWith('start'),
                    end: (errors.dateTime as FieldError)?.type.startsWith('end'),
                  }}
                  startDateLabel="From"
                  startTimeLabel={'\u00A0'}
                  endDateLabel="To"
                  endTimeLabel={'\u00A0'}
                />
              )}
            />
            <TimezoneInfo />
            {errors.dateTime && <FormError>{(errors.dateTime as FieldError).message}</FormError>}
          </FormGroup>
        )}
        <FormGroup>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, minmax(0, 1fr))', columnGap: 20 }}>
            <div>
              <Label>
                <LabelText>Group size</LabelText>
                <Input
                  type="number"
                  {...register('groupSizeMax', groupSizeValidationRules)}
                  hasError={!!errors.groupSizeMax}
                />
              </Label>
              {errors.groupSizeMax && <FormError>{errors.groupSizeMax.message}</FormError>}
            </div>
            <div>
              <Label>
                <LabelText>Price per person</LabelText>
                <PriceInput
                  {...register('price', priceValidationRules)}
                  currency={currency}
                  hasError={!!errors.price}
                />
              </Label>
              {errors.price && <FormError>{errors.price.message}</FormError>}
            </div>
          </div>
        </FormGroup>
        <FormGroup>
          <Label>
            <LabelText>Location</LabelText>
            <LocationField
              initialValue={session ? session.locationId : null}
              control={control}
              name="locationId"
              disabled={submitting || !locationSelectable}
            />
          </Label>
          {errors.locationId && <FormError>{errors.locationId.message}</FormError>}
          <FormDescription>
            Where the session will be held.
            {locationSelectable ? (
              <span>
                You can edit and add additional locations in the{' '}
                <LinkStyled to="/dashboard/calls/locations" target="_blank">
                  calls settings
                </LinkStyled>
              </span>
            ) : (
              <span>Location cannot be edited after the group session is scheduled</span>
            )}
          </FormDescription>
        </FormGroup>
        <FormGroup>
          <Label>
            <LabelText>Payment processing</LabelText>
            <PaymentAccountField
              initialValue={session ? session.paymentAccountId : null}
              control={control}
              name="paymentAccountId"
              disabled={submitting}
            />
          </Label>
          {errors.paymentAccountId && <FormError>{errors.paymentAccountId.message}</FormError>}
          <FormDescription>
            How the payments should be processed. You can add payment accounts in the{' '}
            <LinkStyled to="/dashboard/payments/processing/accounts" target="_blank">
              payments settings
            </LinkStyled>
          </FormDescription>
        </FormGroup>
        <FormGroup style={{ ...(!withWorkflows && { display: 'none' }) }}>
          <Label>
            <LabelText>Workflows</LabelText>
            <WorkflowsField
              initialValue={session ? session.workflowsIds : null}
              control={control}
              name="workflowsIds"
              workflowType="session"
              disabled={submitting || !workflowsSelectable}
            />
          </Label>
          <FormDescription>
            {workflowsSelectable ? (
              <span>Use workflows to automate repeating tasks, like reminders and follow-ups.</span>
            ) : (
              <span>Workflows cannot be edited after the group session is scheduled</span>
            )}
          </FormDescription>
        </FormGroup>
        <FormGroup>
          <Checkbox {...register('hidden')}>
            Hide on the booking page and only show to clients with a direct link
          </Checkbox>
        </FormGroup>
      </fieldset>
      <FormGroup style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button primary type="submit" disabled={submitting}>
          {submitting && <Spinner />}
          <span>Save</span>
        </Button>
        {onDelete && (
          <InlineButton disabled={submitting} onClick={onDelete}>
            Delete
          </InlineButton>
        )}
        {onCancel && (
          <InlineButton disabled={submitting} onClick={onCancel}>
            Cancel
          </InlineButton>
        )}
      </FormGroup>
      {!isValid && <FormError>Please check your inputs</FormError>}
    </form>
  );
};

export { SubmitValues };
export default GroupSessionForm;
