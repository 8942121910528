import React, { useCallback, useState } from 'react';
import GenericReactModal from 'web/components/GenericReactModal';
import themeClasses from 'web/styles/themeClasses.css';
import NoteForm from './NoteForm';

const NoteModal = ({
  initialContent,
  submitting,
  onSubmit,
  isOpen,
  onRequestClose,
}: {
  initialContent?: introwise.RichText;
  submitting: boolean;
  onSubmit: (content: introwise.RichText) => void;
  isOpen: boolean;
  onRequestClose: () => void;
}) => {
  const [isFormDirty, setIsFormDirty] = useState(false);
  const onDirty = useCallback((dirty: boolean) => setIsFormDirty(dirty), []);

  const onRequestCloseConfirm = useCallback(() => {
    const confirmed = !isFormDirty || window.confirm(`You haven't saved your note. Discard unsaved changes?`);
    if (confirmed) {
      onRequestClose();
    }
  }, [isFormDirty, onRequestClose]);

  return (
    <GenericReactModal isOpen={isOpen} onRequestClose={onRequestCloseConfirm}>
      <div style={{ width: 500, maxWidth: '85vw' }}>
        <h4 className={themeClasses({ marginY: 0 })}>Add a note</h4>
        <NoteForm initialContent={initialContent} submitting={submitting} onSubmit={onSubmit} onDirty={onDirty} />
      </div>
    </GenericReactModal>
  );
};

export default NoteModal;
