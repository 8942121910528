import { collection, query, where } from 'firebase/firestore';
import React, { useContext, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { CardsList, CardsListItem } from 'web/components/CardsList';
import ColumnContainer from 'web/components/ColumnContainer';
import Fake from 'web/components/elements/Fake';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import PackageCard from 'web/components/packages/PackageCard';
import ScreenTracker from 'web/components/ScreenTracker';
import BookingPageLinkBlock from 'web/components/session-page/BookingPageLinkBlock';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useFirestoreCollectionData from 'web/hooks/useFirestoreCollectionData';
import { firestoreBookedPackageConverter } from 'web/utils/convert';

const DashboardPackagesIndex = () => {
  const { userData } = useContext(UserContext);
  const firestore = useFirestore();
  const [packages, loading, error] = useFirestoreCollectionData(
    userData &&
      query(
        collection(firestore, 'bookedPackages'),
        where('package.pageId', '==', userData.bookingPageId),
      ).withConverter(firestoreBookedPackageConverter),
  );
  useErrorHandler(error);

  const sortedPackages = useMemo(
    () => packages?.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime()),
    [packages],
  );

  return (
    <>
      <Helmet title="Packages" />
      <ScreenTracker screenName="DashbordPackages" />
      <ColumnContainer>
        <div>
          <WithHeaderContentColumn header="Booked packages">
            {loading && <Fake height={20} animated />}
            {!loading && error && <>Something went wrong. Please try again later.</>}
            {!loading &&
              !error &&
              (sortedPackages.length === 0 ? (
                <>
                  <p>Packages booked with you will appear here.</p>
                  <p>There are no booked packages yet.</p>
                </>
              ) : (
                <CardsList>
                  {sortedPackages.map((pack) => (
                    <CardsListItem key={pack.id}>
                      <PackageCard bookedPackage={pack} />
                    </CardsListItem>
                  ))}
                </CardsList>
              ))}
          </WithHeaderContentColumn>
        </div>
        <BookingPageLinkBlock />
      </ColumnContainer>
    </>
  );
};

export default DashboardPackagesIndex;
