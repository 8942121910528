import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import BackLink from 'web/components/BackLink';
import BookingPageContext from 'web/components/BookingPageContext';
import ColumnContainer from 'web/components/ColumnContainer';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import ScreenTracker from 'web/components/ScreenTracker';
import Spinner from 'web/components/Spinner';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import useFeatureOverrideCheck from 'web/hooks/useFeatureOverrideCheck';
import useTracking from 'web/components/TrackingContext/useTracking';
import { convertToDecimal } from 'web/utils/currency';
import GroupSessionForm, { SubmitValues } from './GroupSessionForm';

const GroupSessionCreate = () => {
  const firestore = useFirestore();
  const tracking = useTracking();
  const { userData } = useContext(UserContext);
  const increasedPriceLimit = useFeatureOverrideCheck('increasedPriceLimit');
  const largeSessionLimits = useFeatureOverrideCheck('largeSessionLimits');

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useErrorStateHandler();
  const navigate = useNavigate();
  const [page, pageLoading, pageError] = useContext(BookingPageContext);
  useErrorHandler(pageError);

  const create = async (values: SubmitValues) => {
    const session: introwise.FirestoreWriteData<introwise.GroupSession> = {
      status: 'confirmed',
      type: 'group',
      seriesId: null,
      title: values.title,
      description: values.description,
      start: values.start,
      end: values.end,
      createdAt: serverTimestamp(),
      price: values.price,
      currency: page.currency,
      totalPaymentAmount: 0,
      paid: false,
      groupSize: {
        max: values.groupSizeMax,
        current: 0,
        remaining: values.groupSizeMax,
      },
      expert: {
        id: userData.id,
        firstName: userData.firstName,
        lastName: userData.lastName,
        displayName: userData.displayName,
        pageId: userData.bookingPageId,
      },
      hidden: !!values.hidden,
      workflowsIds: values.workflowsIds || [],
      ...(values.locationId && { locationId: values.locationId }),
      ...(values.paymentAccountId && { paymentAccountId: values.paymentAccountId }),
    };

    setSubmitting(true);
    try {
      await addDoc(collection(firestore, 'sessions'), session);
      tracking.trackEvent('Group Session Created', {
        price: convertToDecimal(values.price, page.currency),
        currency: page.currency,
      });
      navigate('/dashboard/scheduling', { replace: true });
    } catch (err) {
      setError(err);
    }
    setSubmitting(false);
  };

  return (
    <>
      <Helmet title="Schedule a group session" />
      <ScreenTracker screenName="GroupSessionCreate" />
      <BackLink to="/dashboard/scheduling" />
      <ColumnContainer>
        <WithHeaderContentColumn header="Schedule a group session" whiteBackground>
          {!page && pageLoading && <Spinner />}
          {((!page && !pageLoading) || pageError) && <p>Something went wrong. Please try again later.</p>}
          {page && (
            <>
              <GroupSessionForm
                currency={page.currency}
                session={null}
                submitting={submitting}
                onSubmit={create}
                increasedPriceLimit={increasedPriceLimit}
                largeSessionLimits={largeSessionLimits}
                withWorkflows
                workflowsSelectable
                locationSelectable
              />
              {error && <p>{`${error}`}</p>}
            </>
          )}
        </WithHeaderContentColumn>
        <div />
      </ColumnContainer>
    </>
  );
};

export default GroupSessionCreate;
