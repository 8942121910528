import { doc, updateDoc, writeBatch } from 'firebase/firestore';
import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import BackLink from 'web/components/BackLink';
import ColumnContainer from 'web/components/ColumnContainer';
import ContentColumnHeader from 'web/components/ContentColumnHeader';
import { FormError } from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import ScreenTracker from 'web/components/ScreenTracker';
import useTracking from 'web/components/TrackingContext/useTracking';
import UserContext from 'web/components/UserContext';
import useErrorReporter from 'web/hooks/useErrorReporter';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import LocationContext from './LocationContext';
import LocationForm from './LocationForm';

const SettingsCallsLocationsEdit = () => {
  const { user, userData } = useContext(UserContext);
  const location = useContext(LocationContext);
  const firestore = useFirestore();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useErrorStateHandler();
  const errorReporter = useErrorReporter();
  const tracking = useTracking();

  const currentProvider = userData.settings?.conferencing?.provider;

  const onSave = async (data: { name: string; location: string }) => {
    setSubmitting(true);
    try {
      const locationUpdate: introwise.FirestoreUpdateData<introwise.Location> = {
        name: data.name,
        location: data.location,
      };
      await updateDoc(doc(firestore, 'users', user.uid, 'locations', location.id), locationUpdate);
      tracking.trackEvent('Location Updated');
      navigate('..', { replace: true });
    } catch (error) {
      setError(error);
      setSubmitting(false);
    }
  };

  const onDelete = async () => {
    const res = await window.confirm(`Are you sure you want to remove this location?`);

    if (!res) {
      return;
    }

    try {
      const batch = writeBatch(firestore);
      batch.delete(doc(firestore, 'users', user.uid, 'locations', location.id));
      if (currentProvider === location.id) {
        batch.update(doc(firestore, 'users', user.uid), {
          'settings.conferencing.provider': 'daily',
        });
      }
      await batch.commit();
      tracking.trackEvent('Location Removed');
      navigate('..', { replace: true });
    } catch (e) {
      errorReporter.report(e);
    }
  };

  return (
    <>
      <Helmet title="Edit a location" />
      <ScreenTracker screenName="SettingsCallsLocationsEdit" />
      <BackLink to=".." />
      <ColumnContainer>
        <div>
          <ContentColumnHeader header="Edit a location" />
          <LocationForm initialValues={location} onSubmit={onSave} onDelete={onDelete} submitting={submitting} />
          {error && <FormError>Something went wrong. Please try again later</FormError>}
        </div>
      </ColumnContainer>
    </>
  );
};

export default SettingsCallsLocationsEdit;
