/// <reference types="google.accounts" />
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FunctionsError, httpsCallable } from 'firebase/functions';
import React, { useContext, useEffect, useState } from 'react';
import { Button, FormError, InlineButton } from 'web/components/elements';
import useFunctions from 'web/components/FirebaseContext/useFunctions';
import Spinner from 'web/components/Spinner';
import UserContext from 'web/components/UserContext';
import useErrorReporter from 'web/hooks/useErrorReporter';
import useMountedRef from 'web/hooks/useMountedRef';
import useTracking from 'web/components/TrackingContext/useTracking';
import settings from 'web/utils/settings';
import { ConnectButtonsGroup, IntegrationBlock, IntegrationBlockTitle, IntegrationTitle } from './common';
import GoogleCalendarIcon from './GoogleCalendarIcon';

let gsiClientPromise: Promise<void>;

const calendarScopeArray = [
  'email',
  'https://www.googleapis.com/auth/calendar.calendarlist.readonly',
  'https://www.googleapis.com/auth/calendar.freebusy',
  'https://www.googleapis.com/auth/calendar.events.owned',
];
const calendarScope = calendarScopeArray.join(' ');

const useGapiScriptLoading = () => {
  useEffect(() => {
    if (!gsiClientPromise) {
      gsiClientPromise = new Promise<void>((resolve, reject) => {
        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.onload = () => resolve();
        script.onerror = () => reject(new Error(`Failed to load gapi script`));
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
      });
    }
  }, []);

  return gsiClientPromise;
};

const useGoogleCalendarConnect = () => {
  const functions = useFunctions();
  const scriptLoading = useGapiScriptLoading();
  const errorReporter = useErrorReporter();

  const handleAuthClick = async (reauthenticate?: boolean, googleAccountUid?: string) => {
    await scriptLoading;
    const code = await new Promise<string>((resolve, reject) => {
      const client = google.accounts.oauth2.initCodeClient({
        client_id: settings.google.clientId,
        scope: calendarScope,
        ux_mode: 'popup',
        ...(reauthenticate && googleAccountUid && { login_hint: googleAccountUid }),
        callback: (authResp) => {
          const { code, error } = authResp;
          if (error) {
            switch (error) {
              case 'access_denied':
                reject(new Error('Required permissions were not granted. Please try again and allow calendar access.'));
                break;
              default:
                errorReporter.report(new Error(`Unknown gsi oauth response: ${JSON.stringify(authResp)}`));
                reject(new Error(`Something went wrong. Please try again later.`));
                break;
            }
          } else {
            const scopesSet = new Set(authResp.scope.split(' '));
            if (!calendarScopeArray.every((scope) => scopesSet.has(scope))) {
              reject(
                new Error(
                  `Required permissions were not granted. Please make sure to allow Introwise access to your calendar account.`,
                ),
              );
            } else {
              resolve(code);
            }
          }
        },
        error_callback: (err) => {
          switch (err.type) {
            case 'popup_closed':
              reject(new Error('Google Calendar connection was interrupted. Please try again.'));
              break;
            case 'popup_failed_to_open':
              reject(
                new Error('The Google Calendar connection window failed to open. Please try again and allow popups.'),
              );
              break;
            default:
              errorReporter.report(new Error(`Unknown gsi client response: ${JSON.stringify(err)}`));
              reject(new Error(`Something went wrong. Please try again later.`));
              break;
          }
        },
      });
      client.requestCode();
    });

    try {
      await httpsCallable(
        functions,
        'integrationsConnectAccount',
      )({ type: 'calendar', provider: 'google', code, reauthenticate });
    } catch (err) {
      errorReporter.report(new Error(`Error on google account connect: ${err}`));
      // TODO: err.code reports the error as 'functions-exp/permission-denied', this seems like a bug and .includes() is a workaround
      if ((err as FunctionsError).code?.includes?.('permission-denied')) {
        throw new Error(
          `Required permissions were not granted. Please make sure to allow Introwise access to your calendar account.`,
        );
      } else {
        throw new Error(`Something went wrong.`);
      }
    }
  };

  return handleAuthClick;
};

const GoogleCalendarIntegrationConnected = ({ accountId }: { accountId: string }) => {
  const functions = useFunctions();
  const { userData } = useContext(UserContext);
  const [error, setError] = useState(null);
  const errorReporter = useErrorReporter();
  const [connecting, setConnecting] = useState(false);
  const [disconnecting, setDisconnecting] = useState(false);
  const googleCalendarConnect = useGoogleCalendarConnect();
  const busy = connecting || disconnecting;

  const connectedAccounts = userData.connectedAccounts;
  const googleAccount = connectedAccounts?.[accountId];

  const handleAuthClick = async () => {
    setConnecting(true);
    setError(null);
    try {
      await googleCalendarConnect(true, (googleAccount as introwise.GoogleCalendarAccount)?.uid);
    } catch (err) {
      errorReporter.report(new Error(`Error on Google Account connection: ${err}`));
      setError(err.message);
    }
    setConnecting(false);
  };

  const handleDisconnectClick = async (account: introwise.GoogleCalendarAccount) => {
    const res = window.confirm(`Are you sure you want to disconnect '${account.email}' calendar?`);
    if (!res) {
      return;
    }
    setDisconnecting(true);
    setError(null);
    try {
      await httpsCallable(functions, 'integrationsDisconnectAccount')({ accountId: account.id });
    } catch (err) {
      errorReporter.report(new Error(`Error on google account disconnect: ${err}`));
      setError('Something went wrong.');
    }
    setDisconnecting(false);
  };

  if (!googleAccount || googleAccount.type !== 'calendar' || googleAccount.provider !== 'google') {
    // This should never happen
    return (
      <>
        <IntegrationBlock>
          Something went wrong. Connected calendar cannot be shown. Please try again later.
        </IntegrationBlock>
      </>
    );
  }

  return (
    <>
      <IntegrationBlock>
        <IntegrationBlockTitle>
          <GoogleCalendarIcon width={40} style={{ margin: 4 }} />
          <div>
            <>Google Calendar</>{' '}
            <>
              <IntegrationTitle>
                {googleAccount.email}{' '}
                {googleAccount.hasAuthError && (
                  <span style={{ color: 'red' }}>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                  </span>
                )}
              </IntegrationTitle>
              {googleAccount.hasAuthError && (
                <div>
                  <p>There is a problem with your calendar credentials. Please re-connect your calendar account.</p>
                </div>
              )}
            </>
          </div>
        </IntegrationBlockTitle>
        <ConnectButtonsGroup>
          {googleAccount.hasAuthError && (
            <div style={{ marginBottom: 16 }}>
              <Button primary onClick={() => handleAuthClick()} disabled={busy}>
                {connecting && <Spinner />}
                <span>Reconnect</span>
              </Button>
            </div>
          )}
          <InlineButton onClick={() => handleDisconnectClick(googleAccount)} disabled={busy}>
            {disconnecting && <Spinner />}
            <span>Disconnect</span>
          </InlineButton>
        </ConnectButtonsGroup>
      </IntegrationBlock>

      {error && (
        <FormError>
          <p>{error}</p>
        </FormError>
      )}
    </>
  );
};

const GoogleCalendarIntegrationNew = ({ onConnect }: { onConnect?: () => void }) => {
  const tracking = useTracking();
  const [error, setError] = useState(null);
  const errorReporter = useErrorReporter();
  const [connecting, setConnecting] = useState(false);
  const mounted = useMountedRef();

  const googleCalendarConnect = useGoogleCalendarConnect();

  const handleAuthClick = async () => {
    setConnecting(true);
    setError(null);
    try {
      await googleCalendarConnect();
      tracking.trackEvent('Calendar Connected', { provider: 'google' });
      onConnect?.();
    } catch (err) {
      errorReporter.report(new Error(`Error on Google Account connection: ${err}`));
      setError(err.message);
    }
    if (mounted.current) {
      setConnecting(false);
    }
  };

  return (
    <>
      <div>
        <IntegrationBlock>
          <IntegrationBlockTitle>
            <GoogleCalendarIcon width={40} style={{ margin: 4 }} />
            <div>
              <>Google Calendar</>{' '}
              <div>
                <small>GMail, G Suite and Google Workspace</small>
              </div>
            </div>
          </IntegrationBlockTitle>
          <ConnectButtonsGroup>
            <Button primary onClick={() => handleAuthClick()} disabled={connecting}>
              {connecting && <Spinner />}
              <span>Connect</span>
            </Button>
          </ConnectButtonsGroup>
        </IntegrationBlock>
      </div>
      {error && (
        <FormError>
          <p>{error}</p>
        </FormError>
      )}
    </>
  );
};

export { GoogleCalendarIntegrationConnected, GoogleCalendarIntegrationNew };
