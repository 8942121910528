const formatCard = (card: introwise.User['stripeCard']) =>
  card
    ? `${card.brand.slice(0, 1).toUpperCase()}${card.brand.slice(1)}-${card.last4}, Expires ${new Date(
        card.expYear,
        card.expMonth - 1,
      ).toLocaleDateString('en', { month: '2-digit', year: 'numeric' })}`
    : 'No payment method saved';

const formatBillDate = (timestamp: number) =>
  new Date(timestamp * 1000).toLocaleDateString('en', { day: 'numeric', month: 'long', year: 'numeric' });

export { formatCard, formatBillDate };
