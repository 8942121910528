import { deleteField, doc, updateDoc } from 'firebase/firestore';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import BackLink from 'web/components/BackLink';
import ColumnContainer from 'web/components/ColumnContainer';
import { Button, Checkbox, FormError, FormGroup, Label, LabelText, Select } from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import ScreenTracker from 'web/components/ScreenTracker';
import Spinner from 'web/components/Spinner';
import useTracking from 'web/components/TrackingContext/useTracking';
import UpgradeButton from 'web/components/UpgradeButton';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import useFeatureIncludedCheck from 'web/hooks/useFeatureIncludedCheck';
import themeClasses from 'web/styles/themeClasses.css';
import { featuresUpgradePath, pricingPlans } from 'web/utils/pricingPlans';

const minNoticeOptions = {
  0: '0 minutes',
  5: '5 minutes',
  10: '10 minutes',
  15: '15 minutes',
  30: '30 minutes',
  45: '45 minutes',
  60: '1 hour',
  90: '1.5 hours',
  120: '2 hours',
  180: '3 hours',
  360: '6 hours',
  720: '12 hours',
  1440: '24 hours',
  2880: '2 days',
  4320: '3 days',
  10080: '1 week',
};

type FormValues = {
  cancellationEnabled: boolean;
  cancellationMinNotice: number;
  reschedulingEnabled: boolean;
  reschedulingMinNotice: number;
};

const makeDefaultValues = (initialValues?: introwise.User['settings']['cancellationPolicy']): FormValues => ({
  cancellationEnabled: !initialValues?.cancellationDisabled,
  cancellationMinNotice: initialValues?.cancellationMinNotice || 0,
  reschedulingEnabled: !initialValues?.reschedulingDisabled,
  reschedulingMinNotice: initialValues?.reschedulingMinNotice || 0,
});

const toSubmitValues = (formValues: FormValues) => ({
  cancellationDisabled: !formValues.cancellationEnabled,
  cancellationMinNotice: formValues.cancellationEnabled ? formValues.cancellationMinNotice : undefined,
  reschedulingDisabled: !formValues.reschedulingEnabled,
  reschedulingMinNotice: formValues.reschedulingEnabled ? formValues.reschedulingMinNotice : undefined,
});

const CancellationPolicyForm = ({
  initialValues,
  onSubmit,
  submitting,
  minNoticeEnabled,
}: {
  initialValues: introwise.User['settings']['cancellationPolicy'];
  onSubmit: (values: introwise.User['settings']['cancellationPolicy']) => void;
  submitting: boolean;
  minNoticeEnabled?: boolean;
}) => {
  const defaultValues = useMemo(() => makeDefaultValues(initialValues), [initialValues]);
  const { register, handleSubmit, watch, setValue, formState } = useForm<FormValues>({
    defaultValues,
  });

  const onSubmitInternal = useCallback((values: FormValues) => onSubmit(toSubmitValues(values)), [onSubmit]);

  const cancellationEnabled = watch('cancellationEnabled');
  const reschedulingEnabled = watch('reschedulingEnabled');
  const { errors } = formState;

  return (
    <form onSubmit={handleSubmit(onSubmitInternal)}>
      <fieldset disabled={submitting}>
        <FormGroup>
          <Checkbox
            {...register('cancellationEnabled')}
            onChange={(e) => setValue('cancellationEnabled', e.target.checked)}
          >
            Allow clients to cancel sessions
          </Checkbox>
        </FormGroup>
        {cancellationEnabled && (
          <FormGroup>
            <Label>
              <LabelText>Minimum cancellation notice</LabelText>
              <Select {...register('cancellationMinNotice', { valueAsNumber: true })} disabled={!minNoticeEnabled}>
                {Object.entries(minNoticeOptions).map(([value, label]) => (
                  <option value={value} key={value}>
                    {label}
                  </option>
                ))}
              </Select>
            </Label>
            {errors.cancellationMinNotice && <FormError>{errors.cancellationMinNotice.message}</FormError>}
          </FormGroup>
        )}
        <FormGroup>
          <Checkbox
            {...register('reschedulingEnabled')}
            onChange={(e) => setValue('reschedulingEnabled', e.target.checked)}
          >
            Allow clients to reschedule sessions
          </Checkbox>
        </FormGroup>
        {reschedulingEnabled && (
          <FormGroup>
            <Label>
              <LabelText>Minimum rescheduling notice</LabelText>
              <Select {...register('reschedulingMinNotice', { valueAsNumber: true })} disabled={!minNoticeEnabled}>
                {Object.entries(minNoticeOptions).map(([value, label]) => (
                  <option value={value} key={value}>
                    {label}
                  </option>
                ))}
              </Select>
            </Label>
            {errors.reschedulingMinNotice && <FormError>{errors.reschedulingMinNotice.message}</FormError>}
          </FormGroup>
        )}
        <FormGroup>
          <Button primary type="submit">
            {submitting && <Spinner />}
            <span>Save</span>
          </Button>
        </FormGroup>
      </fieldset>
    </form>
  );
};

const upgradePath = pricingPlans[featuresUpgradePath['cancellationRules']];

const SchedulingCancellationPolicy = () => {
  const { user, userData } = useContext(UserContext);
  const firestore = useFirestore();
  const tracking = useTracking();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useErrorStateHandler();
  const navigate = useNavigate();
  const minNoticeEnabled = useFeatureIncludedCheck('cancellationRules');

  const savePolicy = useCallback(
    async (values: introwise.User['settings']['cancellationPolicy']) => {
      setSubmitting(true);
      setError(undefined);
      try {
        const { cancellationDisabled, cancellationMinNotice, reschedulingDisabled, reschedulingMinNotice } = values;
        const userRef = doc(firestore, 'users', user.uid);
        await updateDoc(userRef, {
          'settings.cancellationPolicy.cancellationDisabled': cancellationDisabled,
          'settings.cancellationPolicy.reschedulingDisabled': reschedulingDisabled,
          'settings.cancellationPolicy.cancellationMinNotice': !cancellationDisabled
            ? cancellationMinNotice
            : deleteField(),
          'settings.cancellationPolicy.reschedulingMinNotice': !reschedulingDisabled
            ? reschedulingMinNotice
            : deleteField(),
        });
        tracking.trackEvent('Cancellation Policy Updated');
        navigate('/dashboard/scheduling');
      } catch (err) {
        setError(error);
        setSubmitting(false);
      }
    },
    [error, firestore, navigate, setError, user.uid, tracking],
  );

  return (
    <>
      <Helmet title="Cancellation Policy" />
      <ScreenTracker screenName="SettingsCancellationPolicy" />
      <BackLink to="/dashboard/scheduling" />
      <ColumnContainer>
        <WithHeaderContentColumn header="Cancellation policy" whiteBackground>
          <CancellationPolicyForm
            initialValues={userData.settings?.cancellationPolicy}
            onSubmit={savePolicy}
            submitting={submitting}
            minNoticeEnabled={minNoticeEnabled}
          />
          {error && <FormError>Something went wrong. Please try again later.</FormError>}
        </WithHeaderContentColumn>
        {!minNoticeEnabled && (
          <WithHeaderContentColumn header="Cancellation rules">
            <p className={themeClasses({ marginTop: 0 })}>
              Upgrade to the <b>{upgradePath.name}</b> plan to set minimum notice rules for cancellations and
              rescheduling.
            </p>
            <div>
              <UpgradeButton to="/dashboard/payments/subscription">Upgrade to {upgradePath.name}</UpgradeButton>
            </div>
          </WithHeaderContentColumn>
        )}
      </ColumnContainer>
    </>
  );
};

export default SchedulingCancellationPolicy;
