import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import BackLink from 'web/components/BackLink';
import ColumnContainer from 'web/components/ColumnContainer';
import ContentColumn from 'web/components/ContentColumn';
import { LinkStyled } from 'web/components/elements';
import Flare from 'web/components/Flare';
import ScreenTracker from 'web/components/ScreenTracker';
import sc from 'web/components/styled';
import UpgradeButton from 'web/components/UpgradeButton';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import themeClasses from 'web/styles/themeClasses.css';
import { featuresUpgradePath, isValidPricingPlan, pricingPlans } from 'web/utils/pricingPlans';

export const formatPaymentMethod = (paymentMethodType: string) => {
  switch (paymentMethodType) {
    case 'card':
      return 'Cards';
    case 'apple_pay':
      return 'Apple Pay';
    case 'google_pay':
      return 'Google Pay';
    case 'giropay':
      return paymentMethodType;
    case 'alipay':
    case 'p24':
    case 'bancontact':
    case 'sofort':
      return `${paymentMethodType[0].toUpperCase()}${paymentMethodType.slice(1)}`;
    case 'wechat_pay':
      return 'WeChat Pay';
    case 'sepa_debit':
      return 'SEPA Direct Debit';
    case 'ideal':
      return 'iDEAL';
    case 'eps':
      return 'EPS';
    default:
      return paymentMethodType;
  }
};

const defaultPaymentMethods = ['card'];
const localPaymentMethods = ['bancontact', 'ideal', 'giropay', 'p24', 'eps', 'alipay', 'wechat_pay'];

const PaymentMethodsContainer = sc.div(themeClasses({ display: 'flex', flexWrap: 'wrap', columnGap: 8, rowGap: 4 }));

const PaymentMethodItem = ({ paymentMethodType }: { paymentMethodType: string }) => (
  <div className={themeClasses({ display: 'flex', gap: 1 })}>
    <span>{formatPaymentMethod(paymentMethodType)}</span>
    <Flare
      variant="success"
      title="Enabled"
      style={{ fontSize: 12, paddingLeft: 6, paddingRight: 6, alignSelf: 'center' }}
    >
      <FontAwesomeIcon icon={faCheck} className={themeClasses({})} />
    </Flare>
  </div>
);

const SettingPaymentsStripePaymentMethods = () => {
  const { userData } = useContext(UserContext);

  const featureEnabled =
    isValidPricingPlan(userData.pricingPlanId) && pricingPlans[userData.pricingPlanId].features.stripePaymentMethods;

  const upgradePath = !featureEnabled && pricingPlans[featuresUpgradePath['stripePaymentMethods']];

  const paymentMethods = userData.stripePaymentMethods || defaultPaymentMethods;

  return (
    <>
      <Helmet title="Stripe Payment Methods" />
      <ScreenTracker screenName="SettingsPaymentsStripePaymentMethods" />
      <BackLink to="/dashboard/payments/accounts" />
      <ColumnContainer>
        <div>
          <WithHeaderContentColumn whiteBackground header="Stripe payment methods">
            <h4 className={themeClasses({ marginTop: 0 })}>Enabled payment methods</h4>
            <PaymentMethodsContainer>
              {paymentMethods.map((paymentMethodType) => (
                <React.Fragment key={paymentMethodType}>
                  <PaymentMethodItem paymentMethodType={paymentMethodType} />
                  {paymentMethodType === 'card' && (
                    <>
                      <PaymentMethodItem paymentMethodType="google_pay" />
                      <PaymentMethodItem paymentMethodType="apple_pay" />
                    </>
                  )}
                </React.Fragment>
              ))}
            </PaymentMethodsContainer>
          </WithHeaderContentColumn>
          <ContentColumn whiteBackground>
            <div className={themeClasses({ display: 'flex' })}>
              <h4 className={themeClasses({ marginTop: 0 })}>Local payment methods</h4>
              {!featureEnabled && (
                <div className={themeClasses({ marginLeft: 2 })}>
                  <Flare variant="warning">{upgradePath.name} plan feature</Flare>
                </div>
              )}
            </div>
            <PaymentMethodsContainer>
              {localPaymentMethods.map((paymentMethodType) => (
                <div key={paymentMethodType}>
                  <span>{formatPaymentMethod(paymentMethodType)}</span>
                </div>
              ))}
            </PaymentMethodsContainer>
            {featureEnabled ? (
              <p className={themeClasses({ marginTop: 6 })}>
                Payment methods availability depends on the country of your Stripe account and the currency of your
                services. Please <LinkStyled to="/contact">contact us</LinkStyled> to enable local payment methods.
              </p>
            ) : (
              <>
                <p className={themeClasses({ marginTop: 6 })}>
                  Give your clients more ways to pay and save on Stripe commissions by enabling local payment methods.
                  Please <LinkStyled to="/contact">contact us</LinkStyled> to check availability of specific payment
                  methods on your account.
                </p>
                <p>
                  Upgrade to the <b>{upgradePath.name}</b> plan to get access to local payment methods and reduced{' '}
                  <b>{upgradePath.applicationFeePercentage * 100}%</b> Introwise commission.
                </p>
                <div>
                  <UpgradeButton to="/dashboard/account/billing/subscription">
                    Upgrade to {upgradePath.name}
                  </UpgradeButton>
                </div>
              </>
            )}
          </ContentColumn>
        </div>
      </ColumnContainer>
    </>
  );
};

export default SettingPaymentsStripePaymentMethods;
