import { doc, collection, writeBatch, query, where } from 'firebase/firestore';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import BackLink from 'web/components/BackLink';
import ColumnContainer from 'web/components/ColumnContainer';
import { Button, FormError } from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import Meta from 'web/components/Meta';
import { useOrderableItems, ReorderingList } from 'web/components/ReorderingList';
import { ServicesButtonsGroup, ServicesLoading } from 'web/components/Services/ServicesCommon';
import Spinner from 'web/components/Spinner';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import useFirestoreCollectionData from 'web/hooks/useFirestoreCollectionData';
import { firestorePackageConverter } from 'web/utils/convert';
import { formatCurrencyAmount } from 'web/utils/currency';

const PackagesReorderImpl = ({
  packages,
  onSuccess,
  pageId,
}: {
  packages: introwise.Package[];
  onSuccess: () => void;
  pageId: string;
}) => {
  const firestore = useFirestore();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useErrorStateHandler();

  const [packagesSorted, setPackagesSorted, initPackagesOrder] = useOrderableItems(packages);

  const save = async () => {
    const bookingPageId = pageId;
    const pageRef = doc(firestore, 'pages', bookingPageId);
    const packagesRef = collection(pageRef, 'packages');
    const batch = writeBatch(firestore);
    const pageUpdate: introwise.FirestoreUpdateData<introwise.Page> = {};

    for (const pack of packagesSorted) {
      if (initPackagesOrder[pack.id] !== pack.order) {
        pageUpdate[`packages.${pack.id}.order`] = pack.order;
        batch.update(doc(packagesRef, pack.id), { order: pack.order });
      }
    }

    batch.update(pageRef, pageUpdate);

    setSubmitting(true);
    try {
      await batch.commit();
      onSuccess();
    } catch (err) {
      setError(err);
      setSubmitting(false);
    }
  };

  if (!packagesSorted.length) {
    return <p>There are no packages to reorder on your booking page.</p>;
  }

  return (
    <>
      <p style={{ marginTop: 0 }}>
        Use the arrows or drag packages to change the order that they appear on your booking page.
      </p>
      <ReorderingList
        items={packagesSorted}
        onItemsChange={setPackagesSorted}
        itemRender={(pack) => (
          <>
            {pack.title} &mdash; {pack.price ? formatCurrencyAmount(pack.price, pack.currency) : 'Free'}
          </>
        )}
      ></ReorderingList>
      <ServicesButtonsGroup>
        <Button primary onClick={save} disabled={submitting}>
          {submitting && <Spinner />}
          <span>Save</span>
        </Button>
      </ServicesButtonsGroup>
      {error && <FormError>{`${error}`}</FormError>}
    </>
  );
};

const PackagesReorder = () => {
  const firestore = useFirestore();
  const { userData } = useContext(UserContext);
  const [packages, loadingPackages, errorPackages] = useFirestoreCollectionData(
    query(
      collection(firestore, 'pages', userData.bookingPageId, 'packages'),
      where('isDeleted', '==', false),
    ).withConverter(firestorePackageConverter),
  );
  useErrorHandler(errorPackages);
  const navigate = useNavigate();

  return (
    <>
      <Meta title="Reorder packages" />
      <BackLink to="/dashboard/scheduling" />
      <ColumnContainer>
        <WithHeaderContentColumn header="Reorder packages" whiteBackground>
          {!packages && loadingPackages && <ServicesLoading />}
          {!packages && !loadingPackages && <p>Something went wrong. Please try again later.</p>}
          {packages && (
            <PackagesReorderImpl
              packages={packages}
              onSuccess={() => navigate('/dashboard/scheduling')}
              pageId={userData.bookingPageId}
            />
          )}
        </WithHeaderContentColumn>
      </ColumnContainer>
    </>
  );
};

export default PackagesReorder;
