import { doc, writeBatch, deleteField, query, collection, where, getDocsFromServer, orderBy } from 'firebase/firestore';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BackLink from 'web/components/BackLink';
import BookingPageContext from 'web/components/BookingPageContext';
import ColumnContainer from 'web/components/ColumnContainer';
import { FormError } from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import Meta from 'web/components/Meta';
import useTracking from 'web/components/TrackingContext/useTracking';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import useFeatureOverrideCheck from 'web/hooks/useFeatureOverrideCheck';
import { firestorePackageConverter } from 'web/utils/convert';
import SeriesContext from './SeriesContext';
import SeriesForm, { FormValues } from './SeriesForm';

const SeriesEdit = () => {
  const series = useContext(SeriesContext);
  const firestore = useFirestore();
  const tracking = useTracking();
  const [page, ,] = useContext(BookingPageContext);
  const { user } = useContext(UserContext);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useErrorStateHandler();
  const increasedPriceLimit = useFeatureOverrideCheck('increasedPriceLimit');
  const largeSessionLimits = useFeatureOverrideCheck('largeSessionLimits');
  const navigate = useNavigate();

  const bookingPageId = page.id;
  const seriesId = series.id;

  const edit = async (values: FormValues) => {
    const seriesUpdate: introwise.FirestoreUpdateData<introwise.Series> = {
      ...values,
      order: series.order,
    };

    const update: introwise.FirestoreUpdateData<introwise.Page> = {
      [`series.${seriesId}`]: {
        ...seriesUpdate,
        id: seriesId,
      },
    };

    const pageRef = doc(firestore, 'pages', bookingPageId);
    const seriesRef = doc(pageRef, 'series', seriesId);
    const batch = writeBatch(firestore);
    batch.update(pageRef, update);
    batch.update(seriesRef, seriesUpdate);

    setSubmitting(true);
    try {
      await batch.commit();
      tracking.trackEvent('Series Updated');
      navigate('..', { replace: true });
    } catch (err) {
      setError(err);
      setSubmitting(false);
    }
  };

  const remove = async () => {
    const update: introwise.FirestoreUpdateData<introwise.Page> = {
      [`series.${seriesId}`]: deleteField(),
    };

    const pageRef = doc(firestore, 'pages', bookingPageId);
    const seriesRef = doc(pageRef, 'series', seriesId);
    const batch = writeBatch(firestore);
    batch.update(pageRef, update);
    batch.delete(seriesRef);

    setSubmitting(true);
    try {
      const sessionsQuery = query(
        collection(firestore, 'sessions'),
        where('seriesId', '==', seriesId),
        where('status', '==', 'confirmed'),
        where('expert.id', '==', user.uid),
        where('type', '==', 'group'),
        where('start', '>', new Date()),
        // TODO: bring back the limit
        // limit(sessionsLimit || defaultLimit),
        orderBy('start', 'asc'),
      ).withConverter(firestorePackageConverter);
      const upcomingSessions = await getDocsFromServer(sessionsQuery);
      if (!upcomingSessions.empty) {
        const res = window.confirm(
          `This series has ${upcomingSessions.size} upcoming sessions scheduled.\nThey will be removed from this series. Do you want to continue?`,
        );
        if (!res) {
          setSubmitting(false);
          return;
        }
      }

      const packagesQuery = query(
        collection(pageRef, 'packages'),
        where(`groupSessionSeries.${seriesId}`, '!=', null),
      ).withConverter(firestorePackageConverter);
      const existingPackages = await getDocsFromServer(packagesQuery);
      if (!existingPackages.empty) {
        const res = window.confirm(
          `This series is included in the following packages:\n  ${existingPackages.docs
            .map((packDoc) => packDoc.data().title)
            .join(
              ', ',
            )}\nClients who already bought a package will loose access to this series. Do you want to continue?`,
        );
        if (!res) {
          setSubmitting(false);
          return;
        }
      }
      await batch.commit();
      tracking.trackEvent('Series Removed');
      navigate('../../..', { replace: true });
    } catch (err) {
      setError(err);
      setSubmitting(false);
    }
  };

  const clone = () => navigate('/dashboard/scheduling/series/create', { state: { series } });

  return (
    <>
      <Meta title="Edit a series" />
      <BackLink to=".." />
      <ColumnContainer>
        <WithHeaderContentColumn header="Edit a series" whiteBackground>
          <SeriesForm
            currency={page.currency}
            initialValues={series}
            onSubmit={edit}
            onClone={clone}
            onDelete={remove}
            submitting={submitting}
            increasedPriceLimit={increasedPriceLimit}
            largeSessionLimits={largeSessionLimits}
            withWorkflows={true}
          />
          {error && <FormError>Failed to save the changes. Please try again later.</FormError>}
        </WithHeaderContentColumn>
        <div />
      </ColumnContainer>
    </>
  );
};

export default SeriesEdit;
