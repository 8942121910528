import React, { useContext, useState } from 'react';
import { Navigate } from 'react-router';
import BackLink from 'web/components/BackLink';
import ColumnContainer from 'web/components/ColumnContainer';
import PlanChooser from 'web/components/subscription/PlanChooser';
import SubscriptionCreateForm from 'web/components/subscription/SubscriptionCreateForm';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import { isValidPricingPlan, pricingPlans, SubscriptionPricingPlanId } from 'web/utils/pricingPlans';

const SubscriptionCreate = () => {
  const { userData } = useContext(UserContext);
  const [planId, setPlanId] = useState<SubscriptionPricingPlanId>(null);

  const canSubscribe = isValidPricingPlan(userData.pricingPlanId)
    ? pricingPlans[userData.pricingPlanId].type === 'commission'
    : true;

  if (!canSubscribe) {
    return <Navigate to="/dashboard/account/billing" />;
  }

  return (
    <>
      <BackLink to="/dashboard/account/billing" />
      {!planId && <PlanChooser onPlanIdSelect={setPlanId} />}
      {planId && (
        <ColumnContainer equal>
          <WithHeaderContentColumn header={`Switch to ${pricingPlans[planId].name} plan`} whiteBackground>
            <SubscriptionCreateForm
              planId={planId}
              activatedView={
                <ColumnContainer equal>
                  <WithHeaderContentColumn header="Your plan is active" whiteBackground>
                    <p>You subscription has been activated</p>
                  </WithHeaderContentColumn>
                </ColumnContainer>
              }
              alreadyActivatedView={
                <ColumnContainer equal>
                  <WithHeaderContentColumn header="Your plan is already active" whiteBackground>
                    <p>You already have a subscription</p>
                  </WithHeaderContentColumn>
                </ColumnContainer>
              }
            />
          </WithHeaderContentColumn>
        </ColumnContainer>
      )}
    </>
  );
};

export default SubscriptionCreate;
