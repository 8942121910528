import React, { useContext } from 'react';
import UserContext from '../UserContext';
import { Facebook, LinkedIn, ShareContainer, Twitter } from 'web/components/share/common';

const ExpertShare = ({ sm }: { sm?: boolean }) => {
  const { userData } = useContext(UserContext);

  const pageUrl = `${window.origin}/${userData.bookingPageSlug}`;
  const shareTitle = `${userData.firstName} ${userData.lastName} – Introwise`;
  const shareText = `Book a session with me on Introwise!`;

  return (
    <ShareContainer sm={sm}>
      <a
        href={`https://www.facebook.com/sharer/sharer.php?u=${pageUrl}&amp;src=sdkpreparse`}
        target="_blank"
        rel="noreferrer"
      >
        <Facebook />
      </a>

      <a href={`https://twitter.com/share?url=${pageUrl}&text=${shareText}`} target="_blank" rel="noreferrer">
        <Twitter />
      </a>

      <a href={`https://linkedin.com/shareArticle?url=${pageUrl}&title=${shareTitle}`} target="_blank" rel="noreferrer">
        <LinkedIn />
      </a>
    </ShareContainer>
  );
};

export default ExpertShare;
