import { doc, runTransaction, serverTimestamp } from 'firebase/firestore';
import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { To, useNavigate } from 'react-router-dom';
import ContentColumn from 'web/components/ContentColumn';
import {
  Accordion,
  AnchorStyled,
  Button,
  FormError,
  FormFootnote,
  FormGroup,
  Label,
  LabelText,
  LinkButton,
  TextArea,
} from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import FormValueLength from 'web/components/form-fields/FormValueLength';
import ScreenTracker from 'web/components/ScreenTracker';
import Spinner from 'web/components/Spinner';
import useTracking from 'web/components/TrackingContext/useTracking';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import themeClasses from 'web/styles/themeClasses.css';
import { firestoreSessionConverter } from 'web/utils/convert';
import { formatCurrencyAmount } from 'web/utils/currency';
import SessionContext from './SessionContex';

const maxMessageLength = 800;

const SessionApproveCancellationForm = ({
  onSubmit,
  error,
  submitting,
  onCancelTo,
  bookingsCount,
  hasPayments,
}: {
  onSubmit: (message: string) => void;
  error: string | Error;
  submitting: boolean;
  onCancelTo: To;
  bookingsCount: number;
  hasPayments: boolean;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<{ message: string }>({ defaultValues: { message: '' } });

  return (
    <>
      <form onSubmit={handleSubmit(({ message }) => onSubmit(message))}>
        {bookingsCount > 0 && (
          <>
            <p style={{ margin: 0 }}>
              You can include a cancellation message for your client{bookingsCount > 1 ? 's' : ''}
            </p>
            <FormGroup>
              <Label>
                <LabelText>Message (optional)</LabelText>
                <TextArea
                  name="message"
                  rows={4}
                  {...register('message', {
                    setValueAs: (v) => v.trim(),
                    maxLength: {
                      value: maxMessageLength,
                      message: `Too long, please limit the message to ${maxMessageLength} characters`,
                    },
                  })}
                />
              </Label>
              <FormFootnote>
                <FormValueLength control={control} name="message" maxLength={maxMessageLength} />
              </FormFootnote>
              <FormError>{errors.message ? errors.message.message : <>&nbsp;</>}</FormError>
              <div style={{ marginBottom: '-0.75em' }} />
            </FormGroup>

            {hasPayments && (
              <FormGroup>
                <Accordion.Details>
                  <Accordion.Summary>How refunds are processed</Accordion.Summary>
                  <Accordion.Dl>
                    <Accordion.Dt>Stripe and PayPal payments</Accordion.Dt>
                    <Accordion.Dd>
                      Clients&apos; payments made through Stripe or PayPal will <u>not</u> be automatically refunded.
                      You will need to use your{' '}
                      <AnchorStyled href="https://dashboard.stripe.com" target="_blank" rel="noopener noreferrer">
                        Stripe account dashboard
                      </AnchorStyled>
                      {' or '}
                      <AnchorStyled
                        href="https://www.paypal.com/mep/dashboard"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        PayPal account dashboard
                      </AnchorStyled>{' '}
                      to issue refunds. Stripe and PayPal payment processing fees, and Introwise comissions are
                      non-refundable.
                    </Accordion.Dd>
                  </Accordion.Dl>
                </Accordion.Details>
              </FormGroup>
            )}
          </>
        )}
        {bookingsCount === 0 && (
          <p>No one has booked this session yet. It will be permanently removed on cancellation.</p>
        )}
        <FormGroup
          style={{ gridAutoColumns: '1fr' }}
          className={themeClasses({
            display: 'grid',
            gridAutoFlow: { all: 'row', sm: 'column' },
            gap: 5,
            textAlign: 'center',
          })}
        >
          <LinkButton secondary to={onCancelTo}>
            I&apos;ve changed my mind
          </LinkButton>
          <Button primary type="submit" disabled={submitting}>
            {submitting && <Spinner />}
            <span>Confirm cancellation</span>
          </Button>
        </FormGroup>
      </form>
      {error && <FormError>{`${error}`}</FormError>}
    </>
  );
};

const SessionCancelSuccess = ({ message, bookingsCount }: { message: string; bookingsCount: number }) => (
  <>
    <b>Session has been cancelled.</b>

    {bookingsCount > 0 && (
      <>
        {message && (
          <>
            <> {bookingsCount === 1 ? 'Your client' : 'Clients'} will receive a cancellation email with your message:</>
            <p className={themeClasses({ wordBreak: 'break-word', whiteSpace: 'pre-line' })}>
              <i>{message}</i>
            </p>
          </>
        )}
        {!message && <> {bookingsCount === 1 ? 'Your client' : 'Clients'} will receive a cancellation email.</>}
      </>
    )}
    <div className={themeClasses({ marginTop: 9 })}>
      <LinkButton to="/dashboard/home/sessions">Go to dashboard</LinkButton>
    </div>
  </>
);

const DashboardSessionsSessionCancel = () => {
  const firestore = useFirestore();
  const navigate = useNavigate();
  const tracking = useTracking();

  const [error, setError] = useErrorStateHandler();

  const [message, setMessage] = useState('');
  const [isCancelled, setIsCancelled] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const session = useContext(SessionContext);

  const onSubmit = async (msg: string) => {
    setSubmitting(true);

    setMessage(msg);

    try {
      const sessionRef = doc(firestore, 'sessions', session.id).withConverter(firestoreSessionConverter);
      const shouldDelete = session.type === 'group' && session.groupSize.current === 0;
      await runTransaction(firestore, async (t) => {
        const sessionDoc = await t.get(sessionRef);
        if (!sessionDoc.exists) {
          throw new Error(`Session doesn't exist`);
        }
        if (shouldDelete) {
          if ((sessionDoc.data() as introwise.GroupSession).groupSize?.current !== 0) {
            throw new Error(`Session has bookings`);
          }
          return t.delete(sessionRef);
        } else {
          if (sessionDoc.data().status === 'cancelled') {
            throw new Error(`Session is already cancelled`);
          }
          return t.update(sessionRef, {
            status: 'cancelled',
            cancelledAt: serverTimestamp(),
            cancelledBy: null,
            cancellationMessage: msg,
          });
        }
      });

      tracking.trackEvent('Session Cancelled');

      if (shouldDelete) {
        navigate('/home');
      } else {
        setIsCancelled(true);
      }
    } catch (err) {
      setError(err);
    }

    setSubmitting(false);
  };

  const { totalPaymentAmount, currency } = session;
  const isGroup = session.type === 'group';
  const bookingsCount = isGroup ? session.groupSize.current : 1;

  return (
    <>
      <Helmet title="Session cancellation" />
      <ScreenTracker screenName="SessionCancellation" />
      <WithHeaderContentColumn header="Cancellation" whiteBackground>
        {!isCancelled ? (
          <>
            <b>Are you sure you want to cancel the session?</b>
            {bookingsCount > 0 && (
              <>
                {bookingsCount > 0 && totalPaymentAmount !== 0 && (
                  <p className={themeClasses({ marginBottom: 0 })}>
                    You will miss <b>{formatCurrencyAmount(totalPaymentAmount, currency)}</b> in earnings.
                  </p>
                )}
              </>
            )}
          </>
        ) : (
          <SessionCancelSuccess message={message} bookingsCount={bookingsCount} />
        )}
      </WithHeaderContentColumn>
      {!isCancelled && (
        <ContentColumn whiteBackground>
          <SessionApproveCancellationForm
            onSubmit={onSubmit}
            submitting={submitting}
            error={error}
            bookingsCount={bookingsCount}
            hasPayments={session.totalPaymentAmount > 0}
            onCancelTo=".."
          />
        </ContentColumn>
      )}
    </>
  );
};

export default DashboardSessionsSessionCancel;
