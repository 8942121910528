import React from 'react';
import styled, { keyframes } from 'styled-components';

const fade = keyframes`
  0% { opacity: 1; }
  66% { opacity: 1; }
  100% { opacity: 0; }
`;

const Container = styled.div`
  animation: ${fade} 2s linear;
  transform-origin: center;
  width: min-content;
  margin-left: 10px;
  display: flex;
  align-items: center;
  cursor: default;
`;

const SavedMark = ({ animate, onAnimationEnd }: { animate?: boolean; onAnimationEnd?: () => void }) => {
  if (!animate) return <></>;

  return <Container onAnimationEnd={onAnimationEnd}>Saved</Container>;
};

export default SavedMark;
