import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, FormError, FormGroup, Input, Label, LabelText } from 'web/components/elements';
import Spinner from 'web/components/Spinner';
import { emailValidationRules, nameValidationRules } from 'web/utils/formValidation';

export type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
};

const clientToFormValues = (client: introwise.Client): FormValues => ({
  firstName: client.firstName,
  lastName: client.lastName,
  email: client.primaryEmail,
});

const ClientForm = ({
  client,
  onSubmit,
  submitting,
}: {
  client?: introwise.Client;
  onSubmit: (values: FormValues) => void;
  submitting: boolean;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({ defaultValues: client ? clientToFormValues(client) : undefined });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset disabled={submitting}>
        <FormGroup>
          <Label>
            <LabelText>First name</LabelText>
            <Input {...register('firstName', nameValidationRules)} hasError={!!errors.firstName} autoComplete="off" />
          </Label>
          {errors.firstName && <FormError>{errors.firstName.message}</FormError>}
        </FormGroup>
        <FormGroup>
          <Label>
            <LabelText>Last name</LabelText>
            <Input {...register('lastName', nameValidationRules)} hasError={!!errors.lastName} autoComplete="off" />
          </Label>
          {errors.lastName && <FormError>{errors.lastName.message}</FormError>}
        </FormGroup>
        <FormGroup>
          <Label>
            <LabelText>Email</LabelText>
            <Input {...register('email', emailValidationRules)} hasError={!!errors.email} autoComplete="off" />
          </Label>
          {errors.email && <FormError>{errors.email.message}</FormError>}
        </FormGroup>
        <FormGroup>
          <Button type="submit" disabled={submitting}>
            {submitting && <Spinner />}
            <span>Save</span>
          </Button>
        </FormGroup>
      </fieldset>
    </form>
  );
};

export default ClientForm;
