import React from 'react';
import { Helmet } from 'react-helmet-async';
import ScreenTracker from 'web/components/ScreenTracker';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import ZapierEmbed from './ZapierEmbed';

const SettingsAutomationIntegrations = () => {
  return (
    <>
      <Helmet title="Integrations" />
      <ScreenTracker screenName="IntegrationsIndex" />
      <WithHeaderContentColumn header="Integrations" extendedHeader={<div></div>} whiteBackground>
        <ZapierEmbed />
      </WithHeaderContentColumn>
    </>
  );
};

export default SettingsAutomationIntegrations;
