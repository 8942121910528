import { addDoc, collection, deleteDoc, doc, orderBy, query, updateDoc, where } from 'firebase/firestore';
import { useCallback, useContext, useMemo, useState } from 'react';
import { DateRange, EventType } from 'web/components/calendar/common/types';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import useTracking from 'web/components/TrackingContext/useTracking';
import UserContext from 'web/components/UserContext';
import { firestoreAvailabilityPeriodConverter } from 'web/utils/convert';
import { formatSessionTime } from 'web/utils/dateFormat';
import useErrorHandler from './useErrorHandler';
import useErrorReporter from './useErrorReporter';
import useFirestoreCollectionData from './useFirestoreCollectionData';

const useTimeOffPeriods = (dateRange: DateRange) => {
  const { user } = useContext(UserContext);
  const firestore = useFirestore();

  const res = useFirestoreCollectionData(
    query(
      collection(firestore, 'users', user.uid, 'timeOffPeriods').withConverter(firestoreAvailabilityPeriodConverter),
      where('end', '>', dateRange.start),
      orderBy('end', 'asc'),
    ),
  );
  const [, , periodsError] = res;
  useErrorHandler(periodsError);

  return res;
};

const useTimeOffPeriodsMutations = () => {
  const { user } = useContext(UserContext);
  const firestore = useFirestore();
  const tracking = useTracking();
  const [submitting, setSubmitting] = useState(false);
  const errorReporter = useErrorReporter();

  const periodsRef = collection(firestore, 'users', user.uid, 'timeOffPeriods');

  const savePeriod = useCallback(
    async (periodId: string | undefined, period: Omit<introwise.AvailabilityPeriod, 'id'>) => {
      setSubmitting(true);
      try {
        const updateData: introwise.FirestoreUpdateData<introwise.AvailabilityPeriod> = {
          start: period.start,
          end: period.end,
        };
        if (periodId) {
          await updateDoc(doc(periodsRef, periodId), updateData);
        } else {
          await addDoc(periodsRef, updateData);
        }
        tracking.trackEvent('Scheduling Time Off Updated');
      } catch (err) {
        errorReporter.report(err);
      }
      setSubmitting(false);
    },
    [errorReporter, periodsRef, tracking],
  );

  const deletePeriod = useCallback(
    async (periodId: string) => {
      setSubmitting(true);
      try {
        await deleteDoc(doc(periodsRef, periodId));
        tracking.trackEvent('Scheduling Time Off Updated', { deleted: true });
      } catch (err) {
        errorReporter.report(err);
      }
      setSubmitting(false);
    },
    [errorReporter, periodsRef, tracking],
  );

  return useMemo(() => ({ savePeriod, deletePeriod, submitting }), [savePeriod, deletePeriod, submitting]);
};

const convertPeriodsToEvents = (periods: introwise.AvailabilityPeriod[]) => {
  let result: Array<EventType> = [];
  if (periods) {
    result = periods.map((period) => {
      const { start, end, id } = period;
      return {
        id,
        start: new Date(start),
        end: new Date(end),
        title: formatSessionTime(new Date(start)),
        extendedProps: {
          mobileTitle: formatSessionTime(new Date(start)),
          recurring: false,
        },
      };
    });
  }
  return result;
};

const useTimeOffCalendar = ({ dateRange }: { dateRange: DateRange }) => {
  const [periods, loading] = useTimeOffPeriods(dateRange);
  const { deletePeriod, savePeriod, submitting } = useTimeOffPeriodsMutations();
  const events = useMemo(() => convertPeriodsToEvents(periods), [periods]);
  const deleteEvent = useCallback(
    (event: EventType) => {
      deletePeriod(event.id);
    },
    [deletePeriod],
  );
  const updateEvent = useCallback(
    (event: EventType) => {
      savePeriod(event.id, {
        start: event.start,
        end: event.end,
      });
    },
    [savePeriod],
  );

  return useMemo(
    () => ({ events, deleteEvent, updateEvent, loading, submitting }),
    [deleteEvent, events, loading, submitting, updateEvent],
  );
};

export { useTimeOffPeriods, useTimeOffPeriodsMutations };
export default useTimeOffCalendar;
