import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo, useState } from 'react';
import { FormProvider, RegisterOptions, useForm } from 'react-hook-form';
import {
  Button,
  Checkbox,
  FormDescription,
  FormError,
  FormFootnote,
  FormGroup,
  InlineButton,
  Input,
  Label,
  LabelText,
  LinkStyled,
  LinkUnstyled,
  Radio,
} from 'web/components/elements';
import Flare from 'web/components/Flare';
import PaymentAccountField from 'web/components/form-fields/PaymentAccountField';
import PriceInput from 'web/components/form-fields/PriceInput';
import GroupSessionSelector from 'web/components/packages/GroupSessionSelector';
import PersonalSessionSelector from 'web/components/packages/PersonalSessionSelector';
import Spinner from 'web/components/Spinner';
import themeClasses from 'web/styles/themeClasses.css';
import {
  convertFromDecimal,
  convertToDecimal,
  exchangeRateToUsd,
  formatCurrencyDecimal,
  isZeroDecimal,
  minChargeAmount,
} from 'web/utils/currency';
import DesriptionField, { stringifyDescription } from '../form-fields/DescriptionField';
import FormValueLength from '../form-fields/FormValueLength';
import GroupSeriesSelector from './GroupSeriesSelector';

const titleLengthMax = 60;
const descriptionLengthMax = 1250;
const personalSessionsLimit = 10;
const groupSessionsLimit = 5;

type FormValues = {
  title: string;
  description: string;
  descriptionRich: null;
  price: number;
  hidden?: boolean;
  checkedExpiryDays?: boolean;
  expiryDays?: number;
  priceType?: 'onetime' | 'monthly';
  pricePerMonth?: number;
  priceMonthsCount?: number;
  paymentAccountId?: introwise.Package['paymentAccountId'];
};

export type SubmitValues = {
  title: string;
  description: string;
  price: number;
  personalSessions: introwise.PackagePersonalSessions;
  groupSessionIds: string[];
  groupSessionSeries: introwise.PackageGroupSessionSeries;
  hidden?: boolean;
  expiryDays?: number;
  paymentPlan?: introwise.Package['paymentPlan'];
  paymentAccountId?: introwise.Package['paymentAccountId'];
};

const validationRules = {
  title: {
    required: 'Required',
    minLength: {
      value: 5,
      message: 'Too short, please use at least 5 characters',
    },
    maxLength: {
      value: titleLengthMax,
      message: `Too long, please limit the name to ${titleLengthMax} characters`,
    },
  } as RegisterOptions,
  description: {
    maxLength: {
      value: descriptionLengthMax,
      message: `Too long, please limit the name to ${descriptionLengthMax} characters`,
    },
  } as RegisterOptions,
  personalSessions: {
    validate: (data) => {
      const value = parseFloat(data);
      if (isNaN(value)) return 'Should be a number';
      if (value > personalSessionsLimit)
        return `That's too much! Personal sessions is limited to ${personalSessionsLimit}`;
    },
  } as RegisterOptions,
  groupSessionIds: {
    validate: (data: string[]) => {
      if (data && data.length > groupSessionsLimit)
        return `That's too much! Group sessions is limited to ${groupSessionsLimit}`;

      return true;
    },
  } as RegisterOptions,
};

const maxPriceUsdDefault = 3000;
const increasedPriceLimitMultiplier = 10;

const makePriceValidationRulesCommon = (
  currency: introwise.Currency,
  increasedPriceLimit: boolean,
  canBeFree: boolean,
): RegisterOptions => {
  const minPriceValue = convertToDecimal(minChargeAmount[currency], currency);
  const maxPriceValue =
    maxPriceUsdDefault * exchangeRateToUsd[currency] * (increasedPriceLimit ? increasedPriceLimitMultiplier : 1);
  return {
    required: 'Required',
    validate: (strValue: string) => {
      const value = parseFloat(strValue);
      if (isNaN(value)) {
        return 'Must be a number';
      }
      if (value > maxPriceValue) {
        return `That's too much! The package price is limited to ${formatCurrencyDecimal(
          maxPriceValue,
          currency,
        )}. Contact us if you need this limit increased.`;
      }

      if (value === 0 && !canBeFree) {
        return `Must be at least ${formatCurrencyDecimal(minPriceValue, currency)}.`;
      }

      if (value < minPriceValue && value !== 0) {
        return `Must be at least ${formatCurrencyDecimal(minPriceValue, currency)}.${
          canBeFree ? ` Use ${formatCurrencyDecimal(0, currency)} for a free package.` : ''
        }`;
      }

      if (isZeroDecimal(currency) && !Number.isInteger(value)) {
        return `Must be a whole number, e.g. ${value.toFixed(0)}`;
      }
    },
  };
};

const makePriceValidationRules = (currency: introwise.Currency, increasedPriceLimit?: boolean): RegisterOptions =>
  makePriceValidationRulesCommon(currency, !!increasedPriceLimit, true);

const makePricePerMonthValidationRules = (
  currency: introwise.Currency,
  increasedPriceLimit?: boolean,
): RegisterOptions => makePriceValidationRulesCommon(currency, !!increasedPriceLimit, false);

const priceMonthsCountValidationRules: RegisterOptions = {
  required: {
    value: true,
    message: 'Please enter the number of months',
  },
  min: {
    value: 2,
    message: 'Minimum number of months is 2',
  },
  max: {
    value: 12,
    message: 'Maximum number of months is 12',
  },
};

const toDefaultValues = (pack: introwise.Package | null): Omit<FormValues, 'description' | 'descriptionRich'> =>
  pack
    ? {
        title: pack.title,
        price: convertToDecimal(pack.price, pack.currency),
        hidden: !!pack.hidden,
        checkedExpiryDays: !!pack.expiryDays,
        expiryDays: pack.expiryDays || undefined,
        priceType: pack.paymentPlan?.type || 'onetime',
        pricePerMonth: convertToDecimal(pack.price, pack.currency),
        priceMonthsCount: pack.paymentPlan?.count || 2,
      }
    : {
        title: '',
        price: undefined,
        hidden: false,
        checkedExpiryDays: false,
        expiryDays: undefined,
        priceType: 'onetime',
        pricePerMonth: undefined,
        priceMonthsCount: 2,
      };

const PackageForm = ({
  pack,
  currency,
  onSubmit,
  onClone,
  onDelete,
  services,
  series,
  submitting,
  showGroupSessionPrice,
  increasedPriceLimit,
  timeLimitingEnabled,
  richTextDescriptionEnabled,
  paymentPlanEnabled,
}: {
  pack: introwise.Package | null;
  currency: introwise.Currency;
  onSubmit?: (values: SubmitValues) => void;
  onClone?: () => void;
  onDelete?: () => void;
  services: { [id: string]: introwise.Service };
  series: { [id: string]: introwise.Series };
  submitting?: boolean;
  showGroupSessionPrice?: boolean;
  increasedPriceLimit?: boolean;
  timeLimitingEnabled?: boolean;
  richTextDescriptionEnabled?: boolean;
  paymentPlanEnabled?: boolean;
}) => {
  const packageCurrency = pack?.currency || currency;
  const servicesCurrency = currency;
  const priceValidationRules = useMemo(
    () => makePriceValidationRules(packageCurrency, increasedPriceLimit),
    [packageCurrency, increasedPriceLimit],
  );
  const pricePerMonthValidationRules = useMemo(
    () => makePricePerMonthValidationRules(packageCurrency, increasedPriceLimit),
    [packageCurrency, increasedPriceLimit],
  );
  const [selectedGroupSessions, setSelectedGroupSessions] = useState<string[]>(pack?.groupSessionIds || []);
  const [personalSessions, setPersonalSessions] = useState<introwise.PackagePersonalSessions>(
    pack?.personalSessions || {},
  );
  const [groupSessionSeries, setGroupSessionSeries] = useState<introwise.PackagePersonalSessions>(
    pack?.groupSessionSeries || {},
  );
  const [error, setError] = useState<string>(null);
  const [toggleGroupSessionsFilter, setToggleGroupSessionsFilter] = useState(true);
  const defaultValues = toDefaultValues(pack);
  const methods = useForm<FormValues>({
    defaultValues,
    mode: 'all',
  });
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = methods;

  const submitDependentFields = (values: FormValues) => {
    const {
      price: priceOneTime,
      pricePerMonth,
      priceType,
      checkedExpiryDays,
      expiryDays,
      description: descriptionPlain,
      descriptionRich,
      ...restValues
    } = values;

    const price = priceType === 'onetime' ? priceOneTime : pricePerMonth;
    const priceAmount = convertFromDecimal(price, packageCurrency);

    const description = stringifyDescription(descriptionPlain, descriptionRich);

    const submitValues: SubmitValues = {
      ...restValues,
      description,
      price: priceAmount,
      groupSessionIds: selectedGroupSessions,
      personalSessions: personalSessions,
      groupSessionSeries: groupSessionSeries,
      ...(expiryDays && { expiryDays }),
      ...(priceType === 'monthly' && {
        paymentPlan: { type: 'monthly', count: values.priceMonthsCount, amount: priceAmount },
      }),
    };

    const hasNoServicesIncluded = !Object.keys(submitValues.personalSessions).some(
      (k) => submitValues.personalSessions[k].count > 0,
    );

    const hasNoGroupSessionsIncluded = submitValues.groupSessionIds.length === 0;

    const hasNoGroupSessionSeriesIncluded = !Object.keys(submitValues.groupSessionSeries).some(
      (k) => submitValues.groupSessionSeries[k].count > 0,
    );

    if (hasNoServicesIncluded && hasNoGroupSessionsIncluded && hasNoGroupSessionSeriesIncluded) {
      const res = window.confirm(
        `Are you sure you want to save a package without any services or group sessions included?`,
      );
      if (!res) {
        setError('Please add services or group sessions to the package');
        return;
      }
    }

    onSubmit(submitValues);
  };

  const onSessionRemove = (id: string, list: string[]) => {
    return [...list.filter((i) => i !== id)];
  };

  const onSessionSelect = (id: string, list: string[]) => {
    return [...list, id];
  };

  const checkedExpiryDays = watch('checkedExpiryDays');
  const priceType = watch('priceType');

  return (
    <form onSubmit={handleSubmit(submitDependentFields)}>
      <FormGroup>
        <Label>
          <LabelText>Package title</LabelText>
          <Input {...register('title', validationRules.title)} hasError={!!errors.title} />
        </Label>
        <FormFootnote>
          <FormValueLength control={control} name="title" maxLength={titleLengthMax} />
        </FormFootnote>
        <FormError>{errors.title ? errors.title.message : <>&nbsp;</>}</FormError>
        <div style={{ marginBottom: '-0.75em' }} />
      </FormGroup>
      <FormProvider {...methods}>
        <DesriptionField
          initialStringifiedValue={pack?.description || ''}
          richTextDescriptionEnabled={richTextDescriptionEnabled}
          fieldDescription="Extra information about the package"
          disabled={submitting}
        />
      </FormProvider>
      <h4 className={themeClasses({ marginBottom: 0 })}>Included services</h4>
      <FormGroup>
        <PersonalSessionSelector
          personalSessions={personalSessions}
          onChange={setPersonalSessions}
          services={services}
          currency={servicesCurrency}
        />
      </FormGroup>
      <h4 className={themeClasses({ marginBottom: 0 })}>Included group sessions series</h4>
      <FormGroup>
        <GroupSeriesSelector groupSessionSeries={groupSessionSeries} onChange={setGroupSessionSeries} series={series} />
      </FormGroup>
      {false && (
        <FormGroup>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
            <h3>Group sessions</h3>
            <InlineButton style={{ marginBottom: 16 }} onClick={() => setToggleGroupSessionsFilter((t) => !t)}>
              {toggleGroupSessionsFilter ? 'show all ' : 'hide not in date'} sessions
            </InlineButton>
          </div>
          <GroupSessionSelector
            selectedIdList={selectedGroupSessions}
            idList={pack?.groupSessionIds}
            onSelect={(id) => setSelectedGroupSessions((prev) => onSessionSelect(id, prev))}
            onRemove={(id) => setSelectedGroupSessions((prev) => onSessionRemove(id, prev))}
            showPrice={showGroupSessionPrice}
          />
        </FormGroup>
      )}
      <h4 className={themeClasses({ marginBottom: 0 })}>Payment</h4>

      <FormGroup>
        <Radio {...register('priceType')} value="onetime">
          One-time payment
        </Radio>
      </FormGroup>

      {priceType === 'onetime' && (
        <FormGroup>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, minmax(0, 1fr))', columnGap: 20 }}>
            <Label>
              <LabelText>Full price</LabelText>
              <PriceInput
                {...register('price', { ...priceValidationRules, shouldUnregister: true })}
                defaultValue={defaultValues.price}
                currency={packageCurrency}
                hasError={!!errors.price}
              />
            </Label>
          </div>
          <FormDescription>Price for the whole package that is paid on booking</FormDescription>
          {errors.price && <FormError>{errors.price.message}</FormError>}
        </FormGroup>
      )}
      <FormGroup>
        <Radio {...register('priceType')} value="monthly" disabled={!paymentPlanEnabled}>
          Monthly payment plan{' '}
          {!paymentPlanEnabled && (
            <LinkUnstyled to="/dashboard/account/billing" target="_blank" className={themeClasses({ marginLeft: 2 })}>
              <Flare variant="warning">
                <FontAwesomeIcon icon={faStar} fixedWidth /> Pro
              </Flare>
            </LinkUnstyled>
          )}
        </Radio>
      </FormGroup>
      {priceType === 'monthly' && (
        <FormGroup>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, minmax(0, 1fr))', columnGap: 20 }}>
            <Label>
              <LabelText>Price per month</LabelText>
              <PriceInput
                {...register('pricePerMonth', { ...pricePerMonthValidationRules, shouldUnregister: true })}
                defaultValue={defaultValues.pricePerMonth}
                currency={packageCurrency}
                hasError={!!errors.price}
              />
            </Label>
            <Label>
              <LabelText>Total months</LabelText>
              <Input
                {...register('priceMonthsCount', { ...priceMonthsCountValidationRules, shouldUnregister: true })}
                type="number"
                min={2}
                max={12}
                defaultValue={defaultValues.priceMonthsCount}
                hasError={!!errors.price}
              />
            </Label>
          </div>
          <FormDescription>Split the full package price over several monthly payments</FormDescription>
          {errors.price && <FormError>{errors.price.message}</FormError>}
          {errors.priceMonthsCount && <FormError>{errors.priceMonthsCount.message}</FormError>}
        </FormGroup>
      )}
      <FormGroup>
        <Label>
          <LabelText>Payment processing</LabelText>
          <PaymentAccountField
            initialValue={pack ? pack.paymentAccountId : null}
            control={control}
            name="paymentAccountId"
            disabled={submitting}
            supportPaymentPlans={paymentPlanEnabled && priceType === 'monthly'}
          />
        </Label>
        <FormDescription>
          How the payments should be processed. You can add payment accounts in the{' '}
          <LinkStyled to="/dashboard/payments/processing/accounts" target="_blank">
            payments settings
          </LinkStyled>
        </FormDescription>
        {errors.paymentAccountId && <FormError>{errors.paymentAccountId.message}</FormError>}
      </FormGroup>
      <h4 className={themeClasses({ marginBottom: 0 })}>Advanced settings</h4>
      <FormGroup>
        <Checkbox {...register('hidden')}>
          Hide on the booking page and only show to clients with a direct link
        </Checkbox>
      </FormGroup>
      <FormGroup>
        <Checkbox {...register('checkedExpiryDays')} disabled={!timeLimitingEnabled}>
          Limit for how long the package can be used
          {!timeLimitingEnabled && (
            <LinkUnstyled to="/dashboard/account/billing" target="_blank" className={themeClasses({ marginLeft: 2 })}>
              <Flare variant="warning">
                <FontAwesomeIcon icon={faStar} fixedWidth /> Growth
              </Flare>
            </LinkUnstyled>
          )}
        </Checkbox>
      </FormGroup>
      {checkedExpiryDays && (
        <FormGroup>
          <Label>
            <LabelText>Number of days after purchasing</LabelText>
            <Input
              type="number"
              defaultValue={''}
              {...register('expiryDays', {
                required: 'Required',
                min: { value: 1, message: 'Must be greater than 0 days' },
                max: { value: 36500, message: 'Cannot be greater than 36500 days' },
                valueAsNumber: true,
                shouldUnregister: true,
              })}
              hasError={!!errors.expiryDays}
              disabled={!timeLimitingEnabled}
            />
          </Label>
          <FormDescription>All sessions must be booked for no later than that day after purchasing</FormDescription>
          {errors.expiryDays && <FormError>{errors.expiryDays.message}</FormError>}
        </FormGroup>
      )}
      <FormGroup className={themeClasses({ display: 'flex', justifyContent: 'space-between' })}>
        <Button type="submit" primary disabled={submitting}>
          {submitting && <Spinner />}
          <span>Save</span>
        </Button>
        {(onDelete || onClone) && (
          <div className={themeClasses({ display: 'flex', gap: 4 })}>
            {onClone && (
              <InlineButton disabled={submitting} onClick={onClone}>
                Clone
              </InlineButton>
            )}
            {onDelete && (
              <InlineButton disabled={submitting} onClick={onDelete}>
                Delete
              </InlineButton>
            )}
          </div>
        )}
      </FormGroup>

      {error && (
        <FormGroup>
          <FormError>{error}</FormError>
        </FormGroup>
      )}
    </form>
  );
};

export default PackageForm;
