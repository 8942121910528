import React from 'react';
import Container from 'web/components/Container';
import themeVars from 'web/styles/themeVars.css';
import AppHeaderDashboard from './AppHeaderDashboard';

const expertLinks = [
  {
    title: 'Dashboard',
    to: '/dashboard/home',
  },
  {
    title: 'Booking page',
    mobileTitle: 'Booking',
    to: '/dashboard/booking',
  },
  {
    title: 'Scheduling',
    to: '/dashboard/scheduling',
  },
  {
    title: 'Payments',
    to: '/dashboard/payments',
  },
  {
    title: 'Calls',
    to: '/dashboard/calls',
  },
  {
    title: 'Automation',
    to: '/dashboard/automation',
  },
];

const HomeFullPageLayout = ({ children, backgroundColor }: { children: React.ReactNode; backgroundColor?: string }) => {
  return (
    <>
      <div
        style={{
          backgroundColor: backgroundColor || themeVars.backgrounds.content,
          minHeight: '100vh',
          paddingBottom: 56,
        }}
      >
        <AppHeaderDashboard navigationItems={expertLinks} />
        <Container>{children}</Container>
      </div>
    </>
  );
};

export default HomeFullPageLayout;
