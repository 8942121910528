import React from 'react';
import styled from 'styled-components';
import type { EventContentArg, EventApi } from '@fullcalendar/react';
import { formatSessionDateTimeString } from 'web/utils/dateFormat';
import themeConstants from 'web/styles/themeConstants';
import themeVars from 'web/styles/themeVars.css';

const Container = styled.div<{ title: string; along?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  transition: ${themeConstants.transition.button};
  box-shadow: none;
  position: relative;

  color: ${themeVars.calendar.blockedSlotBackground};
  background: repeating-linear-gradient(
    45deg,
    ${themeVars.calendar.blockedSlotBackground},
    ${themeVars.calendar.blockedSlotBackground} 2px,
    #fff 2px,
    #fff 10px
  );

  border: 2px solid ${themeVars.calendar.blockedSlotBackground};

  &:hover {
    :before {
      content: ${({ title }) => `'${title}'`};
      ${themeConstants.typography.regular};
      width: 100px;
      font-size: 12px;
      text-align: center;
      position: absolute;
      background-color: #777;
      color: #fff;
      border-radius: 2px;
      top: -56px;
      left: ${({ along }) => (along ? '-38px' : '-8px')};
      right: ${({ along }) => (along ? '-38px' : '-8px')};
      padding: 4px 6px;
    }
    :after {
      content: '';
      position: absolute;
      top: -10px;
      left: calc(50% - 10px);
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #777;
    }
  }
`;

const generateTitle = (event: EventApi) => {
  return formatSessionDateTimeString(event.start, event.end);
};

const timeOffEventContent = (eventInfo: EventContentArg) => (
  <Container title={generateTitle(eventInfo.event)} along={eventInfo.event.extendedProps.along} />
);

export default timeOffEventContent;
