import { httpsCallable } from 'firebase/functions';
import React, { useContext, useState } from 'react';
import { Navigate } from 'react-router-dom';
import BackLink from 'web/components/BackLink';
import ColumnContainer from 'web/components/ColumnContainer';
import { Button, FormError, FormGroup } from 'web/components/elements';
import useFunctions from 'web/components/FirebaseContext/useFunctions';
import Spinner from 'web/components/Spinner';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import { PricingPlanId, pricingPlans } from 'web/utils/pricingPlans';
import { formatBillDate } from '../common';

const SubscriptionReactivate = () => {
  const functions = useFunctions();
  const { userData } = useContext(UserContext);
  const { stripeSubscription } = userData;
  // We save initial state as the subscription may change after cancellation
  const [initialStripeSubscription] = useState(stripeSubscription);
  const [submitting, setSubmitting] = useState(false);
  const [reactivated, setReactivated] = useState(false);
  const [error, setError] = useErrorStateHandler();

  const stripeSubscriptionId = initialStripeSubscription?.id;
  const cantReactivate =
    !initialStripeSubscription?.cancelAtPeriodEnd ||
    (initialStripeSubscription?.status !== 'active' && initialStripeSubscription?.status !== 'trialing');

  if (!stripeSubscriptionId || cantReactivate) {
    return <Navigate to="/dashboard/account/billing" />;
  }

  const pricingPlan = pricingPlans[userData.pricingPlanId as PricingPlanId];

  const reactivate = async () => {
    setSubmitting(true);
    try {
      await httpsCallable(
        functions,
        'stripeReactivateSubscription',
      )({
        stripeSubscriptionId,
      });
      setReactivated(true);
    } catch (err) {
      setError(err);
    }
    setSubmitting(false);
  };

  return (
    <>
      <BackLink to="/dashboard/account/billing" />
      <ColumnContainer equal>
        <WithHeaderContentColumn header="Reactivate subscription" whiteBackground>
          {reactivated && (
            <p>
              Your subscription has been reactivated. The next bill is on{' '}
              {formatBillDate(initialStripeSubscription.currentPeriodEnd)}
            </p>
          )}
          {!reactivated && (
            <>
              <p>
                You&apos;re about to reactivate your subscription <b>{pricingPlan.name}</b>
              </p>
              <p>
                Your regular billing cycle will be resumed starting{' '}
                {formatBillDate(initialStripeSubscription.currentPeriodEnd)}
              </p>
              <FormGroup>
                <Button primary onClick={reactivate} disabled={submitting}>
                  {submitting && <Spinner />}
                  <span>Reactivate</span>
                </Button>
              </FormGroup>
              {error && (
                <FormGroup>
                  <FormError>{`${error}`}</FormError>
                </FormGroup>
              )}
            </>
          )}
        </WithHeaderContentColumn>
      </ColumnContainer>
    </>
  );
};

export default SubscriptionReactivate;
