import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import ColumnContainer from 'web/components/ColumnContainer';
import RecordingsTable from 'web/components/RecordingsTable';
import ScreenTracker from 'web/components/ScreenTracker';
import UpgradeButton from 'web/components/UpgradeButton';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useFeatureIncludedCheck from 'web/hooks/useFeatureIncludedCheck';
import themeClasses from 'web/styles/themeClasses.css';
import { featuresUpgradePath, pricingPlans } from 'web/utils/pricingPlans';

const SettingsCallsRecordingsImpl = () => {
  const { user } = useContext(UserContext);
  return (
    <>
      <RecordingsTable userId={user.uid} />
    </>
  );
};

const SettingsCallsRecordings = () => {
  const featureIncluded = useFeatureIncludedCheck('recording');
  const upgradePath = pricingPlans[featuresUpgradePath['recording']];

  return (
    <>
      <Helmet title="Recordings" />
      <ScreenTracker screenName="SettingsCallsRecordings" />
      <ColumnContainer equal={!featureIncluded}>
        <WithHeaderContentColumn header="Recordings" whiteBackground>
          {featureIncluded ? (
            <SettingsCallsRecordingsImpl />
          ) : (
            <>
              <p className={themeClasses({ marginTop: 0 })}>
                Upgrade to the <b>{upgradePath.name}</b> plan to record your Introwise calls in 1080p HD directly to the
                cloud on any device and get reduced <b>{upgradePath.applicationFeePercentage * 100}%</b> Introwise
                commission.
              </p>
              <div>
                <UpgradeButton to="/dashboard/account/billing/subscription">
                  Upgrade to {upgradePath.name}
                </UpgradeButton>
              </div>
            </>
          )}
        </WithHeaderContentColumn>
      </ColumnContainer>
    </>
  );
};

export default SettingsCallsRecordings;
