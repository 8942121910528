import { collection, doc, writeBatch } from 'firebase/firestore';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BackLink from 'web/components/BackLink';
import BookingPageContext from 'web/components/BookingPageContext';
import ColumnContainer from 'web/components/ColumnContainer';
import { Button, FormError } from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import Meta from 'web/components/Meta';
import { ReorderingList, useOrderableItems } from 'web/components/ReorderingList';
import { ServicesButtonsGroup, ServicesLoading } from 'web/components/Services/ServicesCommon';
import Spinner from 'web/components/Spinner';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import { formatCurrencyAmount } from 'web/utils/currency';

const SeriesReorderImpl = ({
  series,
  onSuccess,
  pageId,
}: {
  series: introwise.Series[];
  onSuccess: () => void;
  pageId: string;
}) => {
  const firestore = useFirestore();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useErrorStateHandler();

  const [seriesSorted, setSeriesSorted, initSeriesOrder] = useOrderableItems(series);

  const save = async () => {
    const bookingPageId = pageId;
    const pageRef = doc(firestore, 'pages', bookingPageId);
    const seriesRef = collection(pageRef, 'series');
    const batch = writeBatch(firestore);
    const pageUpdate: introwise.FirestoreUpdateData<introwise.Page> = {};

    for (const series of seriesSorted) {
      if (initSeriesOrder[series.id] !== series.order) {
        pageUpdate[`series.${series.id}.order`] = series.order;
        batch.update(doc(seriesRef, series.id), { order: series.order });
      }
    }

    batch.update(pageRef, pageUpdate);

    setSubmitting(true);
    try {
      await batch.commit();
      onSuccess();
    } catch (err) {
      setError(err);
      setSubmitting(false);
    }
  };

  if (!seriesSorted.length) {
    return <p>There are no series to reorder on your booking page.</p>;
  }

  return (
    <>
      <p style={{ marginTop: 0 }}>
        Use the arrows or drag series to change the order that they appear on your booking page.
      </p>
      <ReorderingList
        items={seriesSorted}
        onItemsChange={setSeriesSorted}
        itemRender={(pack) => (
          <>
            {pack.title} &mdash; {pack.price ? formatCurrencyAmount(pack.price, pack.currency) : 'Free'}
          </>
        )}
      ></ReorderingList>
      <ServicesButtonsGroup>
        <Button primary onClick={save} disabled={submitting}>
          {submitting && <Spinner />}
          <span>Save</span>
        </Button>
      </ServicesButtonsGroup>
      {error && <FormError>{`${error}`}</FormError>}
    </>
  );
};

const SeriesReorder = () => {
  const [page, pageLoading, pageError] = useContext(BookingPageContext);
  useErrorHandler(pageError);
  const navigate = useNavigate();

  const series = page?.series ? Object.values(page.series) : undefined;

  return (
    <>
      <Meta title="Reorder series" />
      <BackLink to="/dashboard/scheduling" />
      <ColumnContainer>
        <WithHeaderContentColumn header="Reorder series" whiteBackground>
          {!page && pageLoading && <ServicesLoading />}
          {!page && !pageLoading && <p>Something went wrong. Please try again later.</p>}
          {page && (
            <SeriesReorderImpl series={series} onSuccess={() => navigate('/dashboard/scheduling')} pageId={page.id} />
          )}
        </WithHeaderContentColumn>
      </ColumnContainer>
    </>
  );
};

export default SeriesReorder;
