import React from 'react';
import { CardsList, CardsListItem } from '../CardsList';
import SessionCard from './SessionCard';
import { SessionLoading } from './SessionLoading';

export const SessionsList = ({
  sessions,
  loading,
  past,
  useTitle,
  noSessionsElement,
}: {
  sessions: introwise.Session[];
  loading: boolean;
  past?: boolean;
  useTitle?: boolean;
  noSessionsElement?: React.ReactElement;
}) => {
  if (loading) {
    return <SessionLoading extended={past} />;
  }
  if (!sessions || sessions.length === 0) {
    return noSessionsElement || <p>No sessions</p>;
  }
  return (
    <CardsList>
      {sessions.map((session, idx) => (
        <CardsListItem key={session.id}>
          <SessionCard past={past} first={idx === 0} useTitle={useTitle} session={session} />
        </CardsListItem>
      ))}
    </CardsList>
  );
};
