import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { BookingPageProvider } from 'web/components/BookingPageContext';
import catchAllRouteElement from 'web/components/catchAllRouteElement';
import HomeFullPageLayout from 'web/components/HomeFullPageLayout';
import MintThemeProvider from 'web/components/MintThemeProvider';
import Rewrite from 'web/components/Rewrite';
import VerifyEmailWarning from 'web/components/VerifyEmailWarning';
import DashboardHome from './DashboardHome';
import DashboardClientsClient from './DashboardHome/DashboardClients/DashboardClientsClient';
import DashboardClientsClientContainer from './DashboardHome/DashboardClients/DashboardClientsClientContainer';
import DashboardClientsClientEdit from './DashboardHome/DashboardClients/DashboardClientsClientEdit';
import DashboardClientsCreate from './DashboardHome/DashboardClients/DashboardClientsCreate';
import DashboardClientsIndex from './DashboardHome/DashboardClients/DashboardClientsIndex';
import DashboardPackagesIndex from './DashboardHome/DashboardPackages/DashboardPackagesIndex';
import DashboardPackagesPackage from './DashboardHome/DashboardPackages/DashboardPackagesPackage';
import DashboardPackagesPackageContainer from './DashboardHome/DashboardPackages/DashboardPackagesPackageContainer';
import DashboardPackagesPackageExpiration from './DashboardHome/DashboardPackages/DashboardPackagesPackageExpiration';
import DashboardSessionsCancelled from './DashboardHome/DashboardSessions/DashboardSessionsCancelled';
import DashboardSessionsIndex from './DashboardHome/DashboardSessions/DashboardSessionsIndex';
import DashboardSessionsSession from './DashboardHome/DashboardSessions/DashboardSessionsSession';
import DashboardSessionsSessionCancel from './DashboardHome/DashboardSessions/DashboardSessionsSessionCancel';
import DashboardSessionsSessionContainer from './DashboardHome/DashboardSessions/DashboardSessionsSessionContainer';
import DashboardSessionsSessionRecordings from './DashboardHome/DashboardSessions/DashboardSessionsSessionRecordings';
import DashboardSessionsSessionReschedule from './DashboardHome/DashboardSessions/DashboardSessionsSessionReschedule';
import SettingsAccount from './SettingsAccount';
import SettingsAccountBilling from './SettingsAccount/SettingsAccountBilling';
import SettingsPaymentsSubscription from './SettingsAccount/SettingsAccountBilling/Subscription';
import SubscriptionCancel from './SettingsAccount/SettingsAccountBilling/Subscription/SubscriptionCancel';
import SubscriptionCreate from './SettingsAccount/SettingsAccountBilling/Subscription/SubscriptionCreate';
import SubscriptionDowngrade from './SettingsAccount/SettingsAccountBilling/Subscription/SubscriptionDowngrade';
import SubscriptionReactivate from './SettingsAccount/SettingsAccountBilling/Subscription/SubscriptionReactivate';
import SubscriptionUpdate from './SettingsAccount/SettingsAccountBilling/Subscription/SubscriptionUpdate';
import SubscriptionUpgrade from './SettingsAccount/SettingsAccountBilling/Subscription/SubscriptionUpgrade';
import SettingsProfile from './SettingsAccount/SettingsProfile';
import SettingsAutomation from './SettingsAutomation';
import SettingsAutomationIntegrations from './SettingsAutomation/SettingsAutomationIntegrations';
import WorkflowContainer from './SettingsAutomation/SettingsAutomationWorkflows/WorkflowContainer';
import WorkflowEdit from './SettingsAutomation/SettingsAutomationWorkflows/WorkflowEdit';
import WorkflowMonitoringIndex from './SettingsAutomation/SettingsAutomationWorkflows/WorkflowMonitoringIndex';
import WorkflowCreate from './SettingsAutomation/SettingsAutomationWorkflows/WorkflowsCreate';
import WorkflowEditSelf from './SettingsAutomation/SettingsAutomationWorkflows/WorkflowsEditSelf';
import WorkflowsIndex from './SettingsAutomation/SettingsAutomationWorkflows/WorkflowsIndex';
import SettingsBookingPageEdit from './SettingsBookingPage/SettingsBookingPageEdit';
import SettingsBookingPageBranding from './SettingsBookingPage/SettingsBookingPageBranding';
import SettingsBookingPageDomain from './SettingsBookingPage/SettingsBookingPageDomain';
import SettingsBookingPageSeo from './SettingsBookingPage/SettingsBookingPageSeo';
import SettingsCalls from './SettingsCalls';
import SettingsCallsLocations from './SettingsCalls/SettingsCallsLocations/SettingsCallsLocations';
import SettingsCallsRecordings from './SettingsCalls/SettingsCallsRecordings';
import SettingsCallsUsage from './SettingsCalls/SettingsCallsUsage';
import SettingsPayments from './SettingsPayments';
import SettingsPaymentsAccounts from './SettingsPayments/SettingsPaymentsAccounts';
import SettingsPaymentsAccountsPayPal from './SettingsPayments/SettingsPaymentsAccountsPayPal';
import SettingsPaymentsAccountsPayPalVerify from './SettingsPayments/SettingsPaymentsAccountsPayPalVerify';
import SettingsPaymentsCurrency from './SettingsPayments/SettingsPaymentsCurrency';
import SettingsPaymentsDiscountsContainer from './SettingsPayments/SettingsPaymentsDiscounts/SettingsPaymentsDiscountsContainer';
import SettingsPaymentsDiscountsCreate from './SettingsPayments/SettingsPaymentsDiscounts/SettingsPaymentsDiscountsCreate';
import SettingsPaymentsDiscountsEdit from './SettingsPayments/SettingsPaymentsDiscounts/SettingsPaymentsDiscountsEdit';
import SettingsPaymentsDiscountsIndex from './SettingsPayments/SettingsPaymentsDiscounts/SettingsPaymentsDiscountsIndex';
import SettingsPaymentsDiscountsUsage from './SettingsPayments/SettingsPaymentsDiscounts/SettingsPaymentsDiscountsUsage';
import SettingsPaymentsOverview from './SettingsPayments/SettingsPaymentsOverview';
import SettingPaymentsStripeBalance from './SettingsPayments/SettingsPaymentsStripeBalance';
import SettingPaymentsStripePaymentMethods from './SettingsPayments/SettingsPaymentsStripePaymentMethods';
import SettingsScheduling from './SettingsScheduling';
import ConnectedCalendars from './SettingsScheduling/ConnectedCalendars';
import ConnectedCalendarsConnect from './SettingsScheduling/ConnectedCalendarsConnect';
import ConnectedCalendarsConnectApple from './SettingsScheduling/ConnectedCalendarsConnectApple';
import PersonalAvailabilityContainer from './SettingsScheduling/PersonalAvailability';
import PersonalAvailabilityCreate from './SettingsScheduling/PersonalAvailability/PersonalAvailabilityCreate';
import PersonalAvailabilityEdit from './SettingsScheduling/PersonalAvailability/PersonalAvailabilityEdit';
import PersonalAvailabilityRename from './SettingsScheduling/PersonalAvailability/PersonalAvailabilityRename';
import SchedulingCancellationPolicy from './SettingsScheduling/SchedulingCancellationPolicy';
import GroupSessionCreate from './SettingsScheduling/SchedulingGroup/GroupSessionCreate';
import GroupSessionEdit from './SettingsScheduling/SchedulingGroup/GroupSessionEdit';
import SettingsSchedulingGroupIndex from './SettingsScheduling/SchedulingGroupIndex';
import SettingsSchedulingPackagesIndex from './SettingsScheduling/SchedulingPackages';
import PackageCreate from './SettingsScheduling/SchedulingPackages/PackageCreate';
import PackageEdit from './SettingsScheduling/SchedulingPackages/PackageEdit';
import PackagesReorder from './SettingsScheduling/SchedulingPackages/PackagesReorder';
import SchedulingRules from './SettingsScheduling/SchedulingRules';
import SeriesContainer from './SettingsScheduling/SchedulingSeries/SeriesContainer';
import SeriesCreate from './SettingsScheduling/SchedulingSeries/SeriesCreate';
import SeriesEdit from './SettingsScheduling/SchedulingSeries/SeriesEdit';
import SeriesReorder from './SettingsScheduling/SchedulingSeries/SeriesReorder';
import SeriesSchedule from './SettingsScheduling/SchedulingSeries/SeriesSchedule';
import SeriesSessionContainer from './SettingsScheduling/SchedulingSeries/SeriesSessionContainer';
import SeriesSessionEdit from './SettingsScheduling/SchedulingSeries/SeriesSessionEdit';
import SeriesView from './SettingsScheduling/SchedulingSeries/SeriesView';
import SettingsSchedulingServicesIndex from './SettingsScheduling/SchedulingServices';
import ServiceCreate from './SettingsScheduling/SchedulingServices/ServiceCreate';
import ServiceEdit from './SettingsScheduling/SchedulingServices/ServiceEdit';
import ServicesReorder from './SettingsScheduling/SchedulingServices/ServicesReorder';
import SchedulingTimeOff from './SettingsScheduling/SchedulingTimeOff';
import BookingPageLoadingScreen from 'web/components/BookingPageLoadingScreen';
import SettingsBookingPage from './SettingsBookingPage';
import SettingsCallsLocationsCreate from './SettingsCalls/SettingsCallsLocations/SettingsCallsLocationsCreate';
import SettingsCallsLocationsContainer from './SettingsCalls/SettingsCallsLocations/SettingsCallsLocationsContainer';
import SettingsCallsLocationsEdit from './SettingsCalls/SettingsCallsLocations/SettingsCallsLocationsEdit';
import DashboardPackagesPackageEdit from 'web/App/Dashboard/DashboardHome/DashboardPackages/DashboardPackagesPackageEdit';

const HomeFullPage = () => {
  return (
    <>
      <BookingPageProvider>
        <MintThemeProvider>
          <VerifyEmailWarning />
          <HomeFullPageLayout>
            <BookingPageLoadingScreen>
              <Routes>
                <Route index element={<Navigate to="home" replace />} />
                <Route path="home/*" element={<DashboardHome />}>
                  <Route index element={<Navigate to="sessions" replace />} />
                  <Route path="sessions/*">
                    <Route index element={<DashboardSessionsIndex />} />
                    <Route path="cancelled" element={<DashboardSessionsCancelled />} />
                    <Route path=":sessionId/*" element={<DashboardSessionsSessionContainer />}>
                      <Route index element={<DashboardSessionsSession />} />
                      <Route path="cancel" element={<DashboardSessionsSessionCancel />} />
                      <Route path="reschedule" element={<DashboardSessionsSessionReschedule />} />
                      <Route path="recordings" element={<DashboardSessionsSessionRecordings />} />
                      {catchAllRouteElement}
                    </Route>
                    {catchAllRouteElement}
                  </Route>
                  <Route path="packages/*">
                    <Route index element={<DashboardPackagesIndex />} />
                    <Route path=":packageId" element={<DashboardPackagesPackageContainer />}>
                      <Route index element={<DashboardPackagesPackage />} />
                      <Route path="expiration" element={<DashboardPackagesPackageExpiration />} />
                      <Route path="edit" element={<DashboardPackagesPackageEdit />} />
                    </Route>
                    {catchAllRouteElement}
                  </Route>
                  <Route path="clients/*">
                    <Route index element={<DashboardClientsIndex />} />
                    <Route path="create" element={<DashboardClientsCreate />} />
                    <Route path=":clientId" element={<DashboardClientsClientContainer />}>
                      <Route index element={<DashboardClientsClient />} />
                      <Route path="edit" element={<DashboardClientsClientEdit />} />
                    </Route>
                  </Route>
                  {catchAllRouteElement}
                </Route>
                <Route path="account" element={<SettingsAccount />}>
                  <Route index element={<SettingsProfile />} />
                  <Route path="billing">
                    <Route index element={<SettingsAccountBilling />} />
                    <Route path="subscription" element={<SettingsPaymentsSubscription />}>
                      <Route index element={<SubscriptionCreate />} />
                      <Route path="update" element={<SubscriptionUpdate />} />
                      <Route path="cancel" element={<SubscriptionCancel />} />
                      <Route path="reactivate" element={<SubscriptionReactivate />} />
                      <Route path="upgrade" element={<SubscriptionUpgrade />} />
                      <Route path="downgrade" element={<SubscriptionDowngrade />} />
                      {catchAllRouteElement}
                    </Route>
                  </Route>
                </Route>
                <Route path="profile" element={<Rewrite to={(from) => from.replace('account', 'profile')} />} />
                <Route path="booking" element={<SettingsBookingPage />}>
                  <Route index element={<SettingsBookingPageEdit />} />
                  <Route path="branding" element={<SettingsBookingPageBranding />} />
                  <Route path="domain" element={<SettingsBookingPageDomain />} />
                  <Route path="seo" element={<SettingsBookingPageSeo />} />
                  {catchAllRouteElement}
                </Route>
                <Route path="scheduling" element={<SettingsScheduling />}>
                  <Route index element={<Navigate to="services" replace />} />
                  <Route path="services">
                    <Route index element={<SettingsSchedulingServicesIndex />} />
                    <Route path="create" element={<ServiceCreate />} />
                    <Route path="reorder" element={<ServicesReorder />} />
                    <Route path="availabilities/*">
                      <Route path="create" element={<PersonalAvailabilityCreate />} />
                      <Route path=":availabilityId/*" element={<PersonalAvailabilityContainer />}>
                        <Route index element={<PersonalAvailabilityEdit />} />
                        <Route path="rename" element={<PersonalAvailabilityRename />} />
                      </Route>
                    </Route>
                    <Route path="connected-calendars/*">
                      <Route index element={<ConnectedCalendars />} />
                      <Route path="connect" element={<ConnectedCalendarsConnect />} />
                      <Route path="connect/apple" element={<ConnectedCalendarsConnectApple />} />
                      {catchAllRouteElement}
                    </Route>
                    <Route path="rules" element={<SchedulingRules />} />
                    <Route path="time-off" element={<SchedulingTimeOff />} />
                    <Route path="cancellation-policy" element={<SchedulingCancellationPolicy />} />
                    <Route path=":serviceId" element={<ServiceEdit />} />
                  </Route>
                  <Route path="group">
                    <Route index element={<SettingsSchedulingGroupIndex />} />
                    <Route path="sessions">
                      <Route path="create" element={<GroupSessionCreate />} />
                      <Route path=":sessionId" element={<GroupSessionEdit />} />
                    </Route>
                    <Route path="series">
                      <Route path="create" element={<SeriesCreate />} />
                      <Route path="reorder" element={<SeriesReorder />} />
                      <Route path=":seriesId" element={<SeriesContainer />}>
                        <Route index element={<SeriesView />} />
                        <Route path="edit" element={<SeriesEdit />} />
                        <Route path="sessions">
                          <Route index element={<Navigate to="../.." replace />} />
                          <Route path="create" element={<SeriesSchedule />} />
                          <Route path=":sessionId" element={<SeriesSessionContainer />}>
                            <Route index element={<SeriesSessionEdit />} />
                          </Route>
                        </Route>
                      </Route>
                    </Route>
                  </Route>
                  <Route path="packages">
                    <Route index element={<SettingsSchedulingPackagesIndex />} />
                    <Route path="create" element={<PackageCreate />} />
                    <Route path="reorder" element={<PackagesReorder />} />
                    <Route path=":packageId" element={<PackageEdit />} />
                  </Route>
                  {catchAllRouteElement}
                </Route>
                <Route path="payments/*" element={<SettingsPayments />}>
                  <Route index element={<Navigate to="processing" />} />
                  <Route path="processing">
                    <Route index element={<SettingsPaymentsOverview />} />
                    <Route path="accounts/*">
                      <Route index element={<SettingsPaymentsAccounts />} />
                      <Route path="stripe">
                        <Route path="balance" element={<SettingPaymentsStripeBalance />} />
                        <Route path="payment-methods" element={<SettingPaymentsStripePaymentMethods />} />
                      </Route>
                      <Route path="paypal/*">
                        <Route index element={<SettingsPaymentsAccountsPayPal />} />
                        <Route path="verify" element={<SettingsPaymentsAccountsPayPalVerify />} />
                        {catchAllRouteElement}
                      </Route>
                      {catchAllRouteElement}
                    </Route>
                    <Route path="currency" element={<SettingsPaymentsCurrency />} />
                  </Route>
                  <Route path="discounts">
                    <Route index element={<SettingsPaymentsDiscountsIndex />} />
                    <Route path="create" element={<SettingsPaymentsDiscountsCreate />} />
                    <Route path=":discountId/*" element={<SettingsPaymentsDiscountsContainer />}>
                      <Route index element={<SettingsPaymentsDiscountsUsage />} />
                      <Route path="edit" element={<SettingsPaymentsDiscountsEdit />} />
                      {catchAllRouteElement}
                    </Route>
                    {catchAllRouteElement}
                  </Route>
                  <Route
                    path="subscription/*"
                    element={
                      <Rewrite to={(from) => from.replace('payments/subscription', 'account/billing/subscription')} />
                    }
                  />
                  {catchAllRouteElement}
                </Route>
                <Route path="calls/*" element={<SettingsCalls />}>
                  <Route index element={<SettingsCallsUsage />} />
                  <Route path="locations">
                    <Route index element={<SettingsCallsLocations />} />
                    <Route path="create" element={<SettingsCallsLocationsCreate />} />
                    <Route path=":locationId" element={<SettingsCallsLocationsContainer />}>
                      <Route index element={<SettingsCallsLocationsEdit />} />
                    </Route>
                  </Route>
                  <Route path="recordings" element={<SettingsCallsRecordings />} />
                  {catchAllRouteElement}
                </Route>
                <Route path="automation/*" element={<SettingsAutomation />}>
                  <Route index element={<Navigate to="workflows" replace />} />
                  <Route path="workflows/*">
                    <Route index element={<WorkflowsIndex />} />
                    <Route path="create" element={<WorkflowCreate />} />
                    <Route path=":workflowId" element={<WorkflowContainer />}>
                      <Route index element={<WorkflowEdit />} />
                      <Route path="edit" element={<WorkflowEditSelf />} />
                      <Route path="monitor" element={<WorkflowMonitoringIndex />} />
                    </Route>
                  </Route>
                  <Route path="integrations" element={<SettingsAutomationIntegrations />} />
                  {catchAllRouteElement}
                </Route>
                {catchAllRouteElement}
              </Routes>
            </BookingPageLoadingScreen>
          </HomeFullPageLayout>
        </MintThemeProvider>
      </BookingPageProvider>
    </>
  );
};

export default HomeFullPage;
