import { faMugHot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { collection, documentId, limit, orderBy, query, startAt, Timestamp, where } from 'firebase/firestore';
import React, { useContext, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import ColumnContainer from 'web/components/ColumnContainer';
import ContentColumn from 'web/components/ContentColumn';
import { Button, LinkStyled } from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import ScreenTracker from 'web/components/ScreenTracker';
import BookingPageLinkBlock from 'web/components/session-page/BookingPageLinkBlock';
import { SessionsList } from 'web/components/session-page/SessionsList';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useFirestoreCollectionData from 'web/hooks/useFirestoreCollectionData';
import themeClasses from 'web/styles/themeClasses.css';
import themeVars from 'web/styles/themeVars.css';
import { firestoreSessionConverter } from 'web/utils/convert';
import { isValidPricingPlan, pricingPlans } from 'web/utils/pricingPlans';

const nowMinute = () => Math.floor(Date.now() / (60 * 1000)) * 60 * 1000;

const UpcomingSessionsList = () => {
  const { user } = useContext(UserContext);
  const firestore = useFirestore();
  const now = useMemo(() => Timestamp.fromMillis(nowMinute()), []);
  const [sessions, loading, error] = useFirestoreCollectionData(
    query(
      collection(firestore, 'sessions'),
      where('status', '==', 'confirmed'),
      where('expert.id', '==', user.uid),
      where('end', '>=', now),
    ).withConverter(firestoreSessionConverter),
  );

  useErrorHandler(error);

  const sortedSessions = sessions?.sort((a, b) => a.start.getTime() - b.start.getTime());

  return (
    <SessionsList
      sessions={sortedSessions}
      loading={loading}
      past={false}
      noSessionsElement={
        <ContentColumn whiteBackground>
          No upcoming sessions
          <FontAwesomeIcon
            icon={faMugHot}
            style={{ color: themeVars.color.halfMuted }}
            className={themeClasses({ marginLeft: 1.5 })}
          />
        </ContentColumn>
      }
    />
  );
};

const pastSessionsLimit = 10;

const PastSessionsList = () => {
  const { user } = useContext(UserContext);
  const firestore = useFirestore();
  const now = useMemo(() => Timestamp.fromMillis(nowMinute()), []);
  const [paginationStack, setPaginationStack] = useState<(readonly [Timestamp, string])[]>([]);
  const paginationStart = paginationStack.length && paginationStack[paginationStack.length - 1];
  const [sessions, loading, error] = useFirestoreCollectionData(
    query(
      collection(firestore, 'sessions'),
      where('status', '==', 'confirmed'),
      where('expert.id', '==', user.uid),
      where('end', '<', now),
      orderBy('end', 'desc'),
      orderBy(documentId(), 'desc'),
      limit(pastSessionsLimit + 1),
      ...(paginationStart ? [startAt(...paginationStart)] : []),
    ).withConverter(firestoreSessionConverter),
  );

  useErrorHandler(error);

  const hasNext = sessions?.length > pastSessionsLimit;
  const hasPrev = paginationStack.length > 0;

  const paginationNext = hasNext
    ? ([Timestamp.fromDate(sessions[sessions.length - 1].end), sessions[sessions.length - 1].id] as const)
    : undefined;

  const sortedSessions = sessions?.slice(0, pastSessionsLimit).sort((a, b) => b.end.getTime() - a.end.getTime());

  return (
    <>
      <SessionsList
        sessions={sortedSessions}
        loading={loading}
        past
        noSessionsElement={<ContentColumn whiteBackground>No past sessions</ContentColumn>}
      />
      {(hasPrev || hasNext) && (
        <div className={themeClasses({ display: 'flex', justifyContent: 'space-between', marginTop: 5 })}>
          {hasPrev ? (
            <Button sm secondary onClick={() => setPaginationStack((stack) => stack.slice(0, stack.length - 1))}>
              Previous
            </Button>
          ) : (
            <div />
          )}
          {hasNext ? (
            <Button sm secondary onClick={() => setPaginationStack((stack) => [...stack, paginationNext])}>
              Next
            </Button>
          ) : (
            <div />
          )}
        </div>
      )}
    </>
  );
};

const LifetimeDealBlock = () => {
  const { userData } = useContext(UserContext);
  const isLifetime =
    isValidPricingPlan(userData?.pricingPlanId) && pricingPlans[userData?.pricingPlanId].type === 'lifetime';

  return isLifetime ? (
    <WithHeaderContentColumn header="Your plan">
      Your current plan: <b>{pricingPlans[userData?.pricingPlanId].name}</b>
      <br />
      View included features and manage your plan in the{' '}
      <LinkStyled to="/dashboard/account/billing">payments settings</LinkStyled>.
    </WithHeaderContentColumn>
  ) : (
    <></>
  );
};

const DashboardSessionsIndex = () => (
  <>
    <Helmet title="Home" />
    <ScreenTracker screenName="DashbordSessions" />
    <ColumnContainer>
      <div>
        <WithHeaderContentColumn header="Upcoming sessions">
          <UpcomingSessionsList />
        </WithHeaderContentColumn>
        <div className={themeClasses({ marginBottom: 14 })} />
        <WithHeaderContentColumn
          header="Past sessions"
          extendedHeader={
            <LinkStyled to="cancelled" style={{ lineHeight: 'calc(20px*1.62)' }}>
              View cancelled
            </LinkStyled>
          }
        >
          <PastSessionsList />
        </WithHeaderContentColumn>
        <div className={themeClasses({ marginBottom: 14 })} />
      </div>
      <div>
        <BookingPageLinkBlock />
        <LifetimeDealBlock />
      </div>
    </ColumnContainer>
  </>
);

export default DashboardSessionsIndex;
