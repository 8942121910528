import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import React, { useContext, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import BackLink from 'web/components/BackLink';
import ColumnContainer from 'web/components/ColumnContainer';
import { Button, FormDescription, FormError, FormGroup, InlineButton } from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import DateTimeSelector from 'web/components/form-fields/DateTimeSelector';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import useTracking from 'web/components/TrackingContext/useTracking';
import themeClasses from 'web/styles/themeClasses.css';
import { convertToDecimal } from 'web/utils/currency';
import SeriesContext from './SeriesContext';
import SeriesInfo from './SeriesInfo';

type FormValues = { start: Date };

const TimezoneInfo = () => {
  const timezone = useMemo(() => Intl.DateTimeFormat().resolvedOptions().timeZone.replace('_', ' '), []);
  return <FormDescription>Your timezone: {timezone}</FormDescription>;
};

const SeriesSessionForm = ({
  onSubmit,
  submitting,
}: {
  onSubmit: (values: FormValues) => void;
  submitting: boolean;
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({ defaultValues: { start: undefined } });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p style={{ margin: 0 }}>Choose the start time for your session</p>
      <FormGroup style={{ maxWidth: 300 }}>
        <Controller
          name="start"
          control={control}
          rules={{
            required: 'Required',
            validate: {
              startMin: (value) => (value < new Date() ? 'Start date must be in the future' : undefined),
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <DateTimeSelector
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              isTimeOn
              disabled={submitting}
              hasError={!!errors.start}
            />
          )}
        />
        <TimezoneInfo />
        {errors.start && <FormError>{errors.start.message}</FormError>}
      </FormGroup>
      <FormGroup className={themeClasses({ display: 'flex', justifyContent: 'space-between' })}>
        <Button type="submit">Save</Button>
      </FormGroup>
    </form>
  );
};

const SeriesSchedule = () => {
  const series = useContext(SeriesContext);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useErrorStateHandler();
  const { userData } = useContext(UserContext);
  const firestore = useFirestore();
  const tracking = useTracking();
  const navigate = useNavigate();

  const save = async ({ start }: FormValues) => {
    const session: introwise.FirestoreWriteData<introwise.GroupSession> = {
      status: 'confirmed',
      type: 'group',
      seriesId: series.id,
      title: series.title,
      description: series.description,
      start: start,
      end: new Date(start.getTime() + series.duration * 60 * 1000),
      createdAt: serverTimestamp(),
      price: series.price,
      currency: series.currency,
      totalPaymentAmount: 0,
      paid: false,
      groupSize: {
        max: series.groupSizeMax,
        current: 0,
        remaining: series.groupSizeMax,
      },
      expert: {
        id: userData.id,
        firstName: userData.firstName,
        lastName: userData.lastName,
        displayName: userData.displayName,
        pageId: userData.bookingPageId,
      },
      hidden: false,
    };

    setSubmitting(true);
    try {
      await addDoc(collection(firestore, 'sessions'), session);
      tracking.trackEvent('Series Group Session Created', {
        price: convertToDecimal(series.price, series.currency),
        currency: series.currency,
      });
      navigate('../..', { replace: true });
    } catch (err) {
      setError(err);
    }
    setSubmitting(false);
  };

  const cancel = () => {
    navigate('../..', { replace: true });
  };

  return (
    <>
      <BackLink to="/dashboard/scheduling" />
      <ColumnContainer>
        <div>
          <SeriesInfo series={series} />
          <WithHeaderContentColumn
            header="Schedule a group session"
            whiteBackground
            extendedHeader={
              <InlineButton disabled={submitting} onClick={cancel} style={{ lineHeight: 'calc(20px*1.62)' }}>
                Cancel
              </InlineButton>
            }
          >
            <SeriesSessionForm onSubmit={save} submitting={submitting} />
            {error && <FormError>{`${error}`}</FormError>}
          </WithHeaderContentColumn>
        </div>
        <div />
      </ColumnContainer>
    </>
  );
};

export default SeriesSchedule;
