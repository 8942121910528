import { EditorState } from 'lexical';
import { debounce } from 'lodash';
import React, { useRef } from 'react';
import { Button, FormError, FormFootnote, FormGroup, InlineButton } from 'web/components/elements';
import FormattedTextEditor from 'web/components/lexical/FormattedTextEditor';
import { fromPortableText, toPlainText, toPortableText } from 'web/components/lexical/serializers';
import Spinner from 'web/components/Spinner';
import themeClasses from 'web/styles/themeClasses.css';

const maxLength = 50000;

const calculateLength = (content?: EditorState) => {
  if (!content) {
    return 0;
  }
  const plainText = toPlainText(content);
  return plainText.length;
};

const NoteForm = ({
  initialContent,
  submitting,
  onSubmit,
  onDelete,
  onDirty,
}: {
  initialContent?: introwise.RichText;
  submitting: boolean;
  onSubmit: (content: introwise.RichText) => void;
  onDelete?: () => void;
  onDirty?: (dirty: boolean) => void;
}) => {
  const editorStateRef = useRef<EditorState>();
  const [currentLength, setCurrentLength] = React.useState(() => calculateLength(editorStateRef.current));
  const isOverLength = currentLength > maxLength;
  const isCloseToLength = currentLength > maxLength - 1000;
  const updateLength = debounce(() => {
    const length = calculateLength(editorStateRef.current);
    setCurrentLength(length);
  }, 500);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (calculateLength(editorStateRef.current) > maxLength) {
          // TODO: show error
        } else {
          onDirty?.(false);
          onSubmit(toPortableText(editorStateRef.current));
        }
      }}
    >
      <fieldset disabled={submitting}>
        <FormGroup>
          <FormattedTextEditor
            initialState={() => {
              fromPortableText(initialContent ? initialContent : []);
            }}
            onChange={(editorState) => {
              const firstChange = !editorStateRef.current;
              editorStateRef.current = editorState;
              updateLength();
              onDirty?.(!firstChange);
            }}
            placeholder="Information about the client, things to remember, todos, etc."
            rows={6}
            disabled={submitting}
          />
          <FormFootnote>
            {isCloseToLength && (
              <span>
                {currentLength}/{maxLength} characters
              </span>
            )}
          </FormFootnote>
          {isOverLength && <FormError>Too long, please write a shorter note</FormError>}
        </FormGroup>
        <FormGroup className={themeClasses({ display: 'flex', justifyContent: 'space-between', alignItems: 'center' })}>
          <Button type="submit" size="md">
            {submitting && <Spinner />}
            <span>Save</span>
          </Button>
          {onDelete && (
            <InlineButton onClick={onDelete} disabled={submitting}>
              <span>Delete</span>
            </InlineButton>
          )}
        </FormGroup>
      </fieldset>
    </form>
  );
};

export default NoteForm;
