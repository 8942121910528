import React from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Checkbox,
  FormDescription,
  FormError,
  FormGroup,
  InlineButton,
  Input,
  Label,
  LabelText,
} from 'web/components/elements';
import Spinner from 'web/components/Spinner';
import themeClasses from 'web/styles/themeClasses.css';

export type WorkflowFormValues = Pick<introwise.Workflow, 'name' | 'default'>;

const WorkflowForm = ({
  initialValues,
  onSubmit,
  onDelete,
  submitting,
}: {
  initialValues?: WorkflowFormValues;
  onSubmit: (values: WorkflowFormValues) => void;
  onDelete?: () => void;
  submitting: boolean;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<WorkflowFormValues>({
    defaultValues: initialValues
      ? {
          name: initialValues.name,
          default: initialValues.default,
        }
      : { name: '', default: false },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset disabled={submitting}>
        <FormGroup>
          <Label>
            <LabelText>Name</LabelText>
            <Input
              {...register('name', {
                required: {
                  value: true,
                  message: 'Please give this workflow a name',
                },
                minLength: {
                  value: 3,
                  message: 'Please use at least 3 characters',
                },
                maxLength: {
                  value: 80,
                  message: 'Please use at most 80 characters',
                },
              })}
              placeholder="Client reminders, New client onboarding, etc."
              hasError={!!errors.name}
            />
          </Label>
          <FormDescription>Descriptive name for this workflow</FormDescription>
          {errors.name && <FormError>{errors.name.message}</FormError>}
        </FormGroup>
        <FormGroup>
          <Checkbox {...register('default')}>Add by default to new services and series</Checkbox>
        </FormGroup>
        <FormGroup className={themeClasses({ display: 'flex', justifyContent: 'space-between', alignItems: 'center' })}>
          <Button type="submit">
            {submitting && <Spinner />}
            <span>Save</span>
          </Button>
          {onDelete && <InlineButton onClick={onDelete}>Delete</InlineButton>}
        </FormGroup>
      </fieldset>
    </form>
  );
};

export default WorkflowForm;
