import { FunctionsErrorCode, httpsCallable } from 'firebase/functions';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { AnchorStyled, Button, FormError, FormGroup, Input, Label, LabelText } from 'web/components/elements';
import useFunctions from 'web/components/FirebaseContext/useFunctions';
import Spinner from 'web/components/Spinner';
import useErrorReporter from 'web/hooks/useErrorReporter';
import useTracking from 'web/components/TrackingContext/useTracking';
import themeClasses from 'web/styles/themeClasses.css';

const AppleCalendarIntegrationForm = ({
  onConnect,
  defaultAppleId,
  reauthenticate,
}: {
  onConnect: () => void;
  defaultAppleId: string;
  reauthenticate: boolean;
}) => {
  const functions = useFunctions();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const errorReporter = useErrorReporter();
  const tracking = useTracking();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { username: defaultAppleId, password: '' } });
  const connect = async ({ username, password }: { username: string; password: string }) => {
    setSubmitting(true);
    setError(null);
    try {
      await httpsCallable(
        functions,
        'integrationsConnectAccount',
      )({ type: 'calendar', provider: 'apple', username, password, reauthenticate });
      tracking.trackEvent('Calendar Connected', { provider: 'apple' });
      onConnect();
    } catch (err) {
      errorReporter.report(`Failed to connect Apple account: ${err.message}`);
      const code = err.code as `functions/${FunctionsErrorCode}`;
      if (code === 'functions/failed-precondition') {
        setError(`Failed to connect Apple account: ${err.message}`);
      } else {
        setError(`Something went wrong. Please try again later.`);
      }
      setSubmitting(false);
    }
  };

  return (
    <div>
      <p className={themeClasses({ marginY: 0 })}>
        To connect an Apple Calendar account you need to generate an app-specific password. Follow{' '}
        <AnchorStyled href="https://support.apple.com/en-us/HT204397" target="_blank" rel="noopener noreferrer">
          this guide from Apple
        </AnchorStyled>{' '}
        to create an app-specific password.
      </p>
      {reauthenticate && <p>Please provide the updated app-specific password for your Apple Calendar account below.</p>}
      <form onSubmit={handleSubmit(connect)}>
        <fieldset disabled={submitting}>
          <FormGroup>
            <Label>
              <LabelText>Apple ID</LabelText>
              <Input
                readOnly={reauthenticate}
                placeholder="example@example.com"
                {...register('username', {
                  required: 'Please enter an email address',
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: 'Please enter an email address',
                  },
                })}
              />
            </Label>
            {errors.username && <FormError>{errors.username.message}</FormError>}
          </FormGroup>
          <FormGroup>
            <Label>
              <LabelText>App-specific password</LabelText>
              <Input
                type="password"
                autoComplete="off"
                {...register('password', {
                  required: 'Please enter a password',
                })}
              />
            </Label>
            {errors.password && <FormError>{errors.password.message}</FormError>}
          </FormGroup>
          <FormGroup>
            <Button type="submit">
              {submitting && <Spinner />}
              <span>{reauthenticate ? 'Reconnect' : 'Connect'}</span>
            </Button>
          </FormGroup>
          {error && (
            <FormGroup>
              <FormError>{error}</FormError>
            </FormGroup>
          )}
        </fieldset>
      </form>
    </div>
  );
};

export default AppleCalendarIntegrationForm;
