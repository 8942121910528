import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import {
  BOLD_ITALIC_STAR,
  BOLD_ITALIC_UNDERSCORE,
  BOLD_STAR,
  BOLD_UNDERSCORE,
  ITALIC_STAR,
  ITALIC_UNDERSCORE,
  LINK,
  ORDERED_LIST,
  UNORDERED_LIST,
} from '@lexical/markdown';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { EditorState, EditorThemeClasses, LexicalEditor } from 'lexical';
import React from 'react';
import useErrorReporter from 'web/hooks/useErrorReporter';
import themeClasses from 'web/styles/themeClasses.css';
import joinClassNames from 'web/utils/joinClassNames';
import textArea from '../elements/textArea.css';
import { HeadingNode } from './nodes/HeadingNode';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import LinkEditPlugin from './plugins/LinkEditPlugin';
import LinkNormalizationPlugin from './plugins/LinkNormalizationPlugin';
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';
import OnInitPlugin from './plugins/OnInitPlugin';
import SetEditablePlugin from './plugins/SetEditablePlugin';
import { editorLabel, editorLink, editorNestedList, editorTextUnderline } from './richEditor.css';
import { placeholder as editorPlaceholder, contentEditableInset as contentEditablePadding } from './textEditor.css';

const theme: EditorThemeClasses = {
  link: editorLink,
  list: {
    nested: {
      listitem: editorNestedList,
    },
  },
  text: {
    underline: editorTextUnderline,
  },
};

const markdownTransformers = [
  UNORDERED_LIST,
  ORDERED_LIST,
  BOLD_ITALIC_STAR,
  BOLD_ITALIC_UNDERSCORE,
  BOLD_STAR,
  BOLD_UNDERSCORE,
  ITALIC_STAR,
  ITALIC_UNDERSCORE,
  LINK,
];

const FormattedTextEditor = ({
  placeholder,
  rows,
  initialState,
  onInit,
  onChange,
  label,
  additionalPlugins,
  disabled,
}: {
  placeholder?: string;
  rows?: number;
  initialState?: () => void;
  onInit?: (editor: LexicalEditor) => void;
  onChange?: (editorState: EditorState) => void;
  label?: string;
  additionalPlugins?: React.ReactElement[];
  disabled?: boolean;
}) => {
  const errorReporter = useErrorReporter();
  const editorConfig = {
    namespace: 'FormattedTextEditor',
    theme,
    onError: (error: Error) => errorReporter.report(error),
    editorState: initialState,
    editable: !disabled,
    nodes: [HeadingNode, ListNode, ListItemNode, AutoLinkNode, LinkNode],
  };

  return (
    <LexicalComposer initialConfig={editorConfig}>
      {label && <label className={editorLabel}>{label}</label>}
      <div className={themeClasses({ position: 'relative' })}>
        <RichTextPlugin
          contentEditable={
            <ContentEditable
              {...(label ? { ariaLabelledBy: label } : {})}
              className={joinClassNames(textArea, themeClasses({ paddingX: 4 }))}
              style={{
                minHeight: rows ? 21.6 * rows + contentEditablePadding[0] + contentEditablePadding[2] : undefined,
              }}
            />
          }
          placeholder={placeholder ? <p className={editorPlaceholder}>{placeholder}</p> : undefined}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <HistoryPlugin />
        <ListPlugin />
        <LinkPlugin />
        <AutoLinkPlugin />
        <LinkEditPlugin />
        <LinkNormalizationPlugin />
        <ListMaxIndentLevelPlugin maxDepth={3} />
        <MarkdownShortcutPlugin transformers={markdownTransformers} />
        <OnInitPlugin onInit={onInit} />
        {onChange && <OnChangePlugin onChange={onChange} ignoreHistoryMergeTagChange={false} ignoreSelectionChange />}
        <SetEditablePlugin editable={!disabled} />
        {additionalPlugins}
      </div>
    </LexicalComposer>
  );
};

export default FormattedTextEditor;
