import React, { useContext } from 'react';
import PageLoadingFallback from './PageLoadingFallback';
import { BookingPageContext } from './BookingPageContext';

const BookingPageLoadingScreen = ({ children }: { children: React.ReactNode }) => {
  const [, loading, error] = useContext(BookingPageContext);
  if (loading) {
    return <PageLoadingFallback />;
  }
  if (error) {
    throw error;
  }
  return <>{children}</>;
};

export default BookingPageLoadingScreen;
