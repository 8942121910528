import React from 'react';
import { useEffect, useState } from 'react';
import Fake from 'web/components/elements/Fake';
import settings from 'web/utils/settings';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'zapier-full-experience': {
        'client-id': string;
        theme?: 'light' | 'dark';
        'intro-copy-display'?: 'hide' | 'show';
        'app-selection-display'?: 'hide' | 'show';
        'zap-limit'?: string;
        'zap-create-from-scratch-display'?: 'hide' | 'show';
      };
    }
  }
}

const zapierScriptId = 'zapier-embed-script';

const ZapierEmbed = () => {
  const [loaded, setLoaded] = useState(() => document.getElementById(zapierScriptId) !== null);
  useEffect(() => {
    if (loaded) {
      return;
    }

    if (document.getElementById(zapierScriptId) !== null) {
      return;
    }
    const script = document.createElement('script');
    script.id = zapierScriptId;
    script.async = true;
    script.type = 'module';
    script.src = 'https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js';
    script.onload = () => setLoaded(true);
    document.body.appendChild(script);

    const stylesheet = document.createElement('link');
    stylesheet.rel = 'stylesheet';
    stylesheet.href = 'https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css';
    document.head.appendChild(stylesheet);
  }, [loaded]);

  return (
    <div>
      {!loaded && <Fake>Loading...</Fake>}
      {loaded && (
        <zapier-full-experience
          client-id={settings.zapier.clientId}
          theme="light"
          intro-copy-display="show"
          app-selection-display="hide"
          zap-limit="0"
          zap-create-from-scratch-display="hide"
        />
      )}
    </div>
  );
};

export default ZapierEmbed;
