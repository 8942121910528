import { deleteDoc, doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import React, { useState } from 'react';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import useTracking from 'web/components/TrackingContext/useTracking';
import useErrorReporter from 'web/hooks/useErrorReporter';
import NoteForm from './NoteForm';

const NoteEdit = ({
  note,
  pageId,
  clientId,
  onDone,
}: {
  note: introwise.Note;
  pageId: string;
  clientId: string;
  onDone: () => void;
}) => {
  const firestore = useFirestore();
  const tracking = useTracking();
  const errorReporter = useErrorReporter();
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = async (content: introwise.RichText) => {
    setSubmitting(true);
    try {
      const noteUpdate: introwise.FirestoreUpdateData<introwise.Note> = {
        content,
        updatedAt: serverTimestamp(),
      };
      await updateDoc(doc(firestore, 'pages', pageId, 'clients', clientId, 'notes', note.id), noteUpdate);
      tracking.trackEvent('Client Note Updated');
      onDone();
    } catch (err) {
      errorReporter.report(err);
      setSubmitting(false);
    }
  };

  const onDelete = async () => {
    setSubmitting(true);
    try {
      await deleteDoc(doc(firestore, 'pages', pageId, 'clients', clientId, 'notes', note.id));
      tracking.trackEvent('Client Note Removed');
      onDone();
    } catch (err) {
      errorReporter.report(err);
      setSubmitting(false);
    }
  };

  return (
    <>
      <NoteForm initialContent={note.content} onSubmit={onSubmit} submitting={submitting} onDelete={onDelete} />
    </>
  );
};

export default NoteEdit;
