import Tooltip from '@reach/tooltip';
import '@reach/tooltip/styles.css';
import { collection } from 'firebase/firestore';
import React, { useContext, useMemo, useState } from 'react';
import ContentColumn from 'web/components/ContentColumn';
import { AnchorButton, AnchorStyled, InlineButton, LinkButton, LinkStyled, Table } from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import Flare from 'web/components/Flare';
import SessionTimeDiff from 'web/components/session-page/SessionTimeDiff';
import Spinner from 'web/components/Spinner';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import { useCollectionData } from 'web/hooks/firebase';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useErrorReporter from 'web/hooks/useErrorReporter';
import themeClasses from 'web/styles/themeClasses.css';
import { firestoreBookingConverter } from 'web/utils/convert';
import { toCsvDateTime, toFilenameDateTime, toFilenameSafeString } from 'web/utils/csv';
import { formatCurrencyAmount } from 'web/utils/currency';
import { isToday } from 'web/utils/dateFormat';
import { formatPhoneNumber } from 'web/utils/phoneFormat';
import SessionContext from './SessionContex';

const formatDiscount = (discount: introwise.DiscountCodeFixed | introwise.DiscountCodePercentage) =>
  discount.valueType === 'percentage'
    ? `${discount.value.percentOff}% off`
    : `${formatCurrencyAmount(discount.value.amountOff, discount.value.currency)} off`;

const BookingsTable = ({ bookings }: { bookings: introwise.Booking[] }) => {
  const hasBookingsWithPhoneNumbers = bookings.some((booking) => !!booking.client.phoneNumber);

  return (
    <div style={{ overflowX: 'auto' }}>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            {hasBookingsWithPhoneNumbers && <th>Phone</th>}
            <th>Amount</th>
            <th></th>
          </tr>
        </thead>
        <colgroup>
          <col />
          <col />
          {hasBookingsWithPhoneNumbers && <col />}
          <col />
          <col style={{ width: 0 }} />
        </colgroup>
        <tbody>
          {bookings.map((booking) => (
            <>
              <tr key={booking.id}>
                <td>
                  {booking.client.id ? (
                    <LinkStyled to={`/dashboard/home/clients/${booking.client.id}`}>
                      {booking.client.firstName} {booking.client.lastName}{' '}
                    </LinkStyled>
                  ) : (
                    <b>
                      {booking.client.firstName} {booking.client.lastName}{' '}
                    </b>
                  )}
                </td>
                <td>
                  <AnchorStyled href={`mailto:${booking.client.email}`}>{booking.client.email}</AnchorStyled>
                </td>
                {hasBookingsWithPhoneNumbers && (
                  <td>
                    <AnchorStyled href={`tel:${booking.client.phoneNumber}`}>
                      {formatPhoneNumber(booking.client.phoneNumber)}
                    </AnchorStyled>
                  </td>
                )}
                <td>
                  {formatCurrencyAmount(booking.paymentAmount, booking.currency)}
                  {booking.bookedPackageId && (
                    <>
                      {' '}
                      (<LinkStyled to={`/dashboard/home/packages/${booking.bookedPackageId}`}>100% off</LinkStyled>)
                    </>
                  )}
                  {booking.discount && (
                    <>
                      {' '}
                      (
                      <>
                        {booking.discountCodeId ? (
                          <LinkStyled to={`/dashboard/payments/discounts/${booking.discountCodeId}`}>
                            {formatDiscount(booking.discount)}
                          </LinkStyled>
                        ) : (
                          formatDiscount(booking.discount)
                        )}
                      </>
                      )
                    </>
                  )}
                </td>
                <td>
                  {booking.bookedPackageId && <Flare variant="success">Package</Flare>}
                  {booking.partnerId && <Flare variant="success">Partner</Flare>}
                  {!booking.partnerId && booking.paymentId && !booking.bookedPackageId && (
                    <Flare variant="success">Paid</Flare>
                  )}
                  {!booking.partnerId && booking.paymentAmount > 0 && !booking.paymentId && !booking.bookedPackageId && (
                    <Tooltip label="Payment was not collected on booking">
                      <Flare variant="warning" style={{ margin: 0 }}>
                        Unpaid
                      </Flare>
                    </Tooltip>
                  )}
                </td>
              </tr>
              <tr>
                <td colSpan={3} style={{ borderTop: 'none' }}>
                  {booking.client.message ? <i>{booking.client.message}</i> : <i>No message</i>}
                </td>
              </tr>
            </>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

const BookingsExport = ({ session, bookings }: { session: introwise.Session; bookings: introwise.Booking[] }) => {
  const [downloading, setDownloading] = useState(false);
  const errorReporter = useErrorReporter();

  const downloadCsv = async () => {
    setDownloading(true);
    try {
      const { download, generateCsv, mkConfig } = await import('export-to-csv');
      const filename = `${toFilenameSafeString(session.title)}_${toFilenameDateTime(session.start)}`;
      const config = mkConfig({
        filename: filename,
        useKeysAsHeaders: true,
      });

      const csv = generateCsv(config)(
        bookings.map((booking) => ({
          createdAt: toCsvDateTime(booking.createdAt),
          firstName: booking.client.firstName,
          lastName: booking.client.lastName,
          email: booking.client.email,
          message: booking.client.message,
          paymentAmount: booking.paymentAmount,
          currency: booking.currency,
          discountCode: booking.discountCode || '',
          discountAmountOff: booking.discountAmountOff || '',
        })),
      );

      download(config)(csv);
    } catch (err) {
      errorReporter.report(`Failed to export bookings: ${err.message}`);
    }
    setDownloading(false);
  };
  return (
    <InlineButton onClick={downloadCsv} disabled={downloading} style={{ lineHeight: 'calc(20px*1.62)' }}>
      Export
    </InlineButton>
  );
};

const SessionBookings = ({ session }: { session: introwise.Session }) => {
  const sessionId = session.id;
  const firestore = useFirestore();
  const [bookings, loading, error] = useCollectionData(
    collection(firestore, 'sessions', sessionId, 'bookings').withConverter(firestoreBookingConverter),
  );
  useErrorHandler(error);

  return (
    <WithHeaderContentColumn
      header="Bookings"
      whiteBackground
      extendedHeader={bookings && bookings.length > 0 && <BookingsExport session={session} bookings={bookings} />}
    >
      {error && <p>Failed to load bookings</p>}
      {loading && <Spinner />}
      {!bookings || (bookings.length === 0 && <p>No bookings</p>)}
      {bookings && bookings.length > 0 && <BookingsTable bookings={bookings} />}
    </WithHeaderContentColumn>
  );
};

const DashboardSessionsSession = () => {
  const session = useContext(SessionContext);

  const now = useMemo(() => new Date(), []);

  const isIntrowiseCall = !!session.dailyCoRoomName;

  return (
    <>
      {session.end > now && (
        <ContentColumn whiteBackground>
          <div className={themeClasses({ display: 'flex', alignItems: 'center' })}>
            <div className={themeClasses({ flex: 'auto' })}>
              <SessionTimeDiff start={session.start} />
            </div>
            <div>
              <AnchorButton
                sm
                variant={isToday(session.start) ? 'primary' : 'secondary'}
                href={`/s/${session.id}`}
                target="_blank"
              >
                Join now
              </AnchorButton>
            </div>
          </div>
        </ContentColumn>
      )}
      <SessionBookings session={session} />
      {session.status === 'confirmed' && (
        <WithHeaderContentColumn header="Manage">
          <LinkButton variant="secondary" size="md" to="cancel">
            Cancel
          </LinkButton>
          <LinkButton style={{ marginLeft: 24 }} variant="secondary" size="md" to="reschedule">
            Reschedule
          </LinkButton>
          {isIntrowiseCall && (
            <LinkButton style={{ marginLeft: 24 }} variant="secondary" size="md" to="recordings">
              View recordings
            </LinkButton>
          )}
        </WithHeaderContentColumn>
      )}
    </>
  );
};

export default DashboardSessionsSession;
