import React from 'react';
import Bullet from 'web/components/Bullet';
import { LinkStyled } from 'web/components/elements';
import Meta from 'web/components/Meta';
import sc from 'web/components/styled';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import themeClasses from 'web/styles/themeClasses.css';
import themeConstants from 'web/styles/themeConstants';
import { formatCurrencyAmount } from 'web/utils/currency';
import { formatDurationMins } from 'web/utils/dateFormat';

const SeriesPrice = sc.div(undefined, themeConstants.typography.h3);

const SeriesInfo = ({ series }: { series: introwise.Series }) => (
  <WithHeaderContentColumn header="Group sessions series" whiteBackground>
    <Meta title={series.title} />
    <div className={themeClasses({ justifyContent: 'space-between', display: 'flex', flexWrap: 'wrap' })}>
      <div>
        <h3 style={{ margin: 0 }}>{series.title}</h3>
        <LinkStyled to={`/dashboard/scheduling/group/series/${series.id}/edit`}>Edit</LinkStyled>
      </div>
      <div className={themeClasses({ flex: 'none' })} style={{ textAlign: 'right' }}>
        <SeriesPrice>{formatCurrencyAmount(series.price, series.currency)}</SeriesPrice>
        <div>
          {formatDurationMins(series.duration)}
          <Bullet />
          {series.groupSizeMax} participant{series.groupSizeMax === 1 ? '' : 's'}
        </div>
      </div>
    </div>
  </WithHeaderContentColumn>
);

export default SeriesInfo;
