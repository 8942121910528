const pad = (num: number) => (num < 10 ? `0${num}` : num);

const toCsvDateTime = (date: Date, withSeconds = true) => {
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());
  return `${year}-${month}-${day} ${hours}:${minutes}${withSeconds ? `:${seconds}` : ''}`;
};

const toFilenameSafeString = (str: string) => str.replace(/[^a-zA-Z0-9]/g, '_');

const toFilenameDateTime = (date: Date) => toCsvDateTime(date, false).replace(/:/g, '').replace(/ /g, '-');

export { toCsvDateTime, toFilenameSafeString, toFilenameDateTime };
