import { collection } from 'firebase/firestore';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import ColumnContainer from 'web/components/ColumnContainer';
import { LinkButton, LinkStyled, Table } from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import Flare from 'web/components/Flare';
import ScreenTracker from 'web/components/ScreenTracker';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useFirestoreCollectionData from 'web/hooks/useFirestoreCollectionData';
import { firestoreDiscountCodeConverter } from 'web/utils/convert';
import useFeatureIncludedCheck from '../../../../hooks/useFeatureIncludedCheck';
import { formatDiscountValue, UpgradePrompt } from './common';

const DiscountsList = () => {
  const {
    userData: { bookingPageId },
  } = useContext(UserContext);
  const firestore = useFirestore();
  const [discountCodes, loading, error] = useFirestoreCollectionData(
    collection(firestore, 'pages', bookingPageId, 'discountCodes').withConverter(firestoreDiscountCodeConverter),
  );
  useErrorHandler(error);

  return (
    <>
      {loading && <p>Loading...</p>}
      {!loading && error && <p>Something went wrong. Please try again later.</p>}
      {!loading &&
        !error &&
        discountCodes &&
        (discountCodes.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <th>Code</th>
                <th>Value</th>
                <th>Usage</th>
                <th></th>
              </tr>
            </thead>
            <colgroup>
              <col style={{ width: 150 }} />
              <col />
              <col style={{ width: 100 }} />
              <col style={{ width: 70 }} />
            </colgroup>
            <tbody>
              {discountCodes.map((code) => (
                <tr key={code.id}>
                  <td>
                    <LinkStyled to={code.id}>{code.code}</LinkStyled>
                  </td>
                  <td>{formatDiscountValue(code)}</td>
                  <td>
                    {code.timesRedeemed}
                    {code.maxRedemptions && <>/{code.maxRedemptions}</>} used
                  </td>
                  <td>
                    {!code.active ? (
                      <Flare variant="disabled">inactive</Flare>
                    ) : code.maxRedemptions && code.timesRedeemed >= code.maxRedemptions ? (
                      <Flare variant="disabled">used&nbsp;up</Flare>
                    ) : (
                      <Flare variant="success">active</Flare>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p>
            No discount codes. <LinkStyled to="create">Create one.</LinkStyled>
          </p>
        ))}
    </>
  );
};

const SettingsPaymentsDiscountsIndex = () => {
  const featureIncluded = useFeatureIncludedCheck('discounts');

  return (
    <>
      <Helmet title="Discount codes" />
      <ScreenTracker screenName="SettingsPaymentsDiscountsIndex" />
      <ColumnContainer equal={!featureIncluded}>
        {featureIncluded ? (
          <WithHeaderContentColumn
            header="Discount codes"
            whiteBackground
            extendedHeader={
              featureIncluded && (
                <div>
                  <LinkButton to="create" variant="primary" size="md">
                    New discount
                  </LinkButton>
                </div>
              )
            }
          >
            <DiscountsList />
          </WithHeaderContentColumn>
        ) : (
          <WithHeaderContentColumn header="Discount codes" whiteBackground>
            <UpgradePrompt />
          </WithHeaderContentColumn>
        )}
        <div />
      </ColumnContainer>
    </>
  );
};

export default SettingsPaymentsDiscountsIndex;
