import { collection, documentId, limit, orderBy, query, startAt, Timestamp, where } from 'firebase/firestore';
import React, { useContext, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import BackLink from 'web/components/BackLink';
import Bullet from 'web/components/Bullet';
import { CardsList, CardsListItem } from 'web/components/CardsList';
import ColumnContainer from 'web/components/ColumnContainer';
import ContentColumn from 'web/components/ContentColumn';
import { AnchorStyled, Button, InlineButton, LinkStyled } from 'web/components/elements';
import Fake from 'web/components/elements/Fake';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import PackageCard from 'web/components/packages/PackageCard';
import { SessionsList } from 'web/components/session-page/SessionsList';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useFirestoreCollectionData from 'web/hooks/useFirestoreCollectionData';
import themeClasses from 'web/styles/themeClasses.css';
import { firestoreBookedPackageConverter, firestoreNoteConverter, firestoreSessionConverter } from 'web/utils/convert';
import { formatSessionDate, formatSessionTime } from 'web/utils/dateFormat';
import ClientContext from './ClientContext';
import { navButton, navButtonGroup } from './navButton.css';
import NoteAddModalButton from './NoteAddModalButton';
import NoteViewer from './NoteViewer';

const nowMinute = () => Math.floor(Date.now() / (60 * 1000)) * 60 * 1000;

const UpcomingSession = ({ pageId, clientId }: { pageId: string; clientId: string }) => {
  const firestore = useFirestore();
  const now = useMemo(() => Timestamp.fromMillis(nowMinute()), []);
  const [sessions, loading, error] = useFirestoreCollectionData(
    query(
      collection(firestore, 'pages', pageId, 'clients', clientId, 'sessions'),
      where('status', '==', 'confirmed'),
      where('end', '>=', now),
      orderBy('end', 'asc'),
      limit(1),
    ).withConverter(firestoreSessionConverter),
  );

  useErrorHandler(error);

  return (
    <>
      {loading && <Fake animated>Loading...</Fake>}
      {!loading && error && <>Cannot find an upcoming session</>}
      {!loading && !error && !sessions?.length && <>No upcoming sessions</>}
      {!loading && !error && sessions?.length === 1 && (
        <div>
          <LinkStyled to={`/dashboard/home/sessions/${sessions[0].id}`}>{sessions[0].title}</LinkStyled>
          <Bullet />
          {formatSessionDate(sessions[0].start)}
          <Bullet />
          {formatSessionTime(sessions[0].start)}
        </div>
      )}
    </>
  );
};

const LatestPackage = ({ pageId, clientId }: { pageId: string; clientId: string }) => {
  const firestore = useFirestore();
  const [bookedPackages, loading, error] = useFirestoreCollectionData(
    query(
      collection(firestore, 'pages', pageId, 'clients', clientId, 'bookedPackages'),
      orderBy('createdAt', 'desc'),
      limit(1),
    ).withConverter(firestoreBookedPackageConverter),
  );

  useErrorHandler(error);

  return (
    <>
      {loading && <Fake animated>Loading...</Fake>}
      {!loading && error && <>Cannot find any packages</>}
      {!loading && !error && !bookedPackages?.length && <>No packages booked</>}
      {!loading && !error && bookedPackages?.length === 1 && (
        <LinkStyled to={`/dashboard/home/packages/${bookedPackages[0].id}`}>
          {bookedPackages[0].package.title}
        </LinkStyled>
      )}
    </>
  );
};

const listLimit = 10;

const ClientSessionsList = ({
  pageId,
  clientId,
  cancelled,
}: {
  pageId: string;
  clientId: string;
  cancelled?: boolean;
}) => {
  const firestore = useFirestore();
  const [paginationStack, setPaginationStack] = useState<(readonly [Timestamp, string])[]>([]);
  const paginationStart = paginationStack.length && paginationStack[paginationStack.length - 1];
  const [sessions, loading, error] = useFirestoreCollectionData(
    query(
      collection(firestore, 'pages', pageId, 'clients', clientId, 'sessions'),
      where('status', '==', cancelled ? 'cancelled' : 'confirmed'),
      orderBy('end', 'desc'),
      orderBy(documentId(), 'desc'),
      limit(listLimit + 1),
      ...(paginationStart ? [startAt(...paginationStart)] : []),
    ).withConverter(firestoreSessionConverter),
  );

  useErrorHandler(error);

  const hasNext = sessions?.length > listLimit;
  const hasPrev = paginationStack.length > 0;

  const paginationNext = hasNext
    ? ([Timestamp.fromDate(sessions[sessions.length - 1].end), sessions[sessions.length - 1].id] as const)
    : undefined;

  const sortedSessions = sessions?.slice(0, listLimit).sort((a, b) => b.end.getTime() - a.end.getTime());

  return (
    <>
      <SessionsList
        sessions={sortedSessions}
        loading={loading}
        past
        useTitle
        noSessionsElement={
          <ContentColumn whiteBackground>{cancelled ? 'No cancelled sessions' : 'No sessions booked'}</ContentColumn>
        }
      />
      {(hasPrev || hasNext) && (
        <div className={themeClasses({ display: 'flex', justifyContent: 'space-between', marginTop: 5 })}>
          {hasPrev ? (
            <Button sm secondary onClick={() => setPaginationStack((stack) => stack.slice(0, stack.length - 1))}>
              Previous
            </Button>
          ) : (
            <div />
          )}
          {hasNext ? (
            <Button sm secondary onClick={() => setPaginationStack((stack) => [...stack, paginationNext])}>
              Next
            </Button>
          ) : (
            <div />
          )}
        </div>
      )}
    </>
  );
};

const ClientPackagesList = ({ pageId, clientId }: { pageId: string; clientId: string }) => {
  const firestore = useFirestore();
  const [paginationStack, setPaginationStack] = useState<(readonly [Timestamp, string])[]>([]);
  const paginationStart = paginationStack.length && paginationStack[paginationStack.length - 1];
  const [bookedPackages, loading, error] = useFirestoreCollectionData(
    query(
      collection(firestore, 'pages', pageId, 'clients', clientId, 'bookedPackages'),
      orderBy('createdAt', 'desc'),
      orderBy(documentId(), 'desc'),
      limit(listLimit + 1),
      ...(paginationStart ? [startAt(...paginationStart)] : []),
    ).withConverter(firestoreBookedPackageConverter),
  );

  useErrorHandler(error);

  const hasNext = bookedPackages?.length > listLimit;
  const hasPrev = paginationStack.length > 0;

  const paginationNext = hasNext
    ? ([
        Timestamp.fromDate(bookedPackages[bookedPackages.length - 1].createdAt),
        bookedPackages[bookedPackages.length - 1].id,
      ] as const)
    : undefined;

  const sortedPackages = bookedPackages
    ?.slice(0, listLimit)
    .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());

  return (
    <>
      {loading && <Fake height={20} animated />}
      {!loading && error && <>Something went wrong. Please try again later.</>}
      {!loading &&
        !error &&
        (sortedPackages.length === 0 ? (
          <ContentColumn whiteBackground>No packages booked</ContentColumn>
        ) : (
          <CardsList>
            {sortedPackages.map((pack) => (
              <CardsListItem key={pack.id}>
                <PackageCard bookedPackage={pack} />
              </CardsListItem>
            ))}
          </CardsList>
        ))}
      {(hasPrev || hasNext) && (
        <div className={themeClasses({ display: 'flex', justifyContent: 'space-between', marginTop: 5 })}>
          {hasPrev ? (
            <Button sm secondary onClick={() => setPaginationStack((stack) => stack.slice(0, stack.length - 1))}>
              Previous
            </Button>
          ) : (
            <div />
          )}
          {hasNext ? (
            <Button sm secondary onClick={() => setPaginationStack((stack) => [...stack, paginationNext])}>
              Next
            </Button>
          ) : (
            <div />
          )}
        </div>
      )}
    </>
  );
};

const formatDate = (date: Date) => date.toLocaleString('en', { year: 'numeric', month: 'short', day: 'numeric' });

const LatestNote = ({ pageId, clientId }: { pageId: string; clientId: string }) => {
  const firestore = useFirestore();
  const [notes, loading, error] = useFirestoreCollectionData(
    query(
      collection(firestore, 'pages', pageId, 'clients', clientId, 'notes'),
      orderBy('createdAt', 'desc'),
      limit(1),
    ).withConverter(firestoreNoteConverter),
  );

  useErrorHandler(error);

  return (
    <>
      {loading && <Fake animated>Loading...</Fake>}
      {!loading && error && <>Cannot find any packages</>}
      {!loading && !error && !notes?.length && (
        <NoteAddModalButton pageId={pageId} clientId={clientId}>
          {(onClick) => <InlineButton onClick={onClick}>Add a note</InlineButton>}
        </NoteAddModalButton>
      )}
      {!loading && !error && notes?.length === 1 && (
        <NoteViewer note={notes[0]} pageId={pageId} clientId={clientId} withAddButton />
      )}
    </>
  );
};

const notesListLimit = 5;

const ClientNotesList = ({ pageId, clientId }: { pageId: string; clientId: string }) => {
  const firestore = useFirestore();
  const [paginationStack, setPaginationStack] = useState<(readonly [Timestamp, string])[]>([]);
  const paginationStart = paginationStack.length && paginationStack[paginationStack.length - 1];
  const [notes, loading, error] = useFirestoreCollectionData(
    query(
      collection(firestore, 'pages', pageId, 'clients', clientId, 'notes'),
      orderBy('createdAt', 'desc'),
      orderBy(documentId(), 'desc'),
      limit(notesListLimit + 1),
      ...(paginationStart ? [startAt(...paginationStart)] : []),
    ).withConverter(firestoreNoteConverter),
  );
  useErrorHandler(error);

  const hasNext = notes?.length > notesListLimit;
  const hasPrev = paginationStack.length > 0;

  const paginationNext = hasNext
    ? ([Timestamp.fromDate(notes[notes.length - 1].createdAt), notes[notes.length - 1].id] as const)
    : undefined;

  const sortedNotes = notes?.slice(0, notesListLimit).sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());

  return (
    <>
      {loading && <Fake height={20} animated />}
      {!loading && error && <>Something went wrong. Please try again later.</>}
      {!loading &&
        !error &&
        (sortedNotes.length === 0 ? (
          <ContentColumn whiteBackground>No notes</ContentColumn>
        ) : (
          <CardsList>
            {sortedNotes.map((note) => (
              <CardsListItem inactive key={note.id}>
                <NoteViewer note={note} pageId={pageId} clientId={clientId} />
              </CardsListItem>
            ))}
          </CardsList>
        ))}
      {(hasPrev || hasNext) && (
        <div className={themeClasses({ display: 'flex', justifyContent: 'space-between', marginTop: 5 })}>
          {hasPrev ? (
            <Button sm secondary onClick={() => setPaginationStack((stack) => stack.slice(0, stack.length - 1))}>
              Previous
            </Button>
          ) : (
            <div />
          )}
          {hasNext ? (
            <Button sm secondary onClick={() => setPaginationStack((stack) => [...stack, paginationNext])}>
              Next
            </Button>
          ) : (
            <div />
          )}
        </div>
      )}
    </>
  );
};

const DashboardClientsClient = () => {
  const client = useContext(ClientContext);
  const {
    userData: { bookingPageId: pageId },
  } = useContext(UserContext);
  const [searchParams, setSearchParams] = useSearchParams();
  type ActiveTab = 'sessions' | 'packages' | 'notes' | 'cancelled-sessions';
  const activeTab = (searchParams.get('tab') as ActiveTab) || 'sessions';
  const setActiveTab = (tab: ActiveTab) => setSearchParams({ tab }, { replace: true });
  return (
    <>
      <BackLink to="../.." />
      <ColumnContainer>
        <div>
          <ContentColumn whiteBackground>
            <div className={themeClasses({ display: 'flex', flexWrap: 'wrap' })}>
              <div className={themeClasses({ flex: 'auto' })}>
                <h3 className={themeClasses({ margin: 0 })}>
                  {client.firstName} {client.lastName}
                </h3>
                <div>
                  Added on {formatDate(client.createdAt)}
                  <br />
                  <AnchorStyled href={`mailto:${client.primaryEmail}`}>{client.primaryEmail}</AnchorStyled>
                </div>
              </div>
              <div style={{ textAlign: 'right' }}>
                <LinkStyled to="edit">Edit</LinkStyled>
              </div>
            </div>
          </ContentColumn>
          <ContentColumn whiteBackground>
            <div className={themeClasses({ display: 'grid', gridTemplateColumns: { all: 1, md: 2 }, gap: 4 })}>
              <div>
                <h4 className={themeClasses({ marginTop: 0 })}>Upcoming session</h4>
                <UpcomingSession pageId={pageId} clientId={client.id} />
              </div>
              <div>
                <h4 className={themeClasses({ marginTop: 0 })}>Latest package</h4>
                <LatestPackage pageId={pageId} clientId={client.id} />
              </div>
            </div>
          </ContentColumn>
          <ContentColumn whiteBackground>
            <LatestNote pageId={pageId} clientId={client.id} />
          </ContentColumn>
          <WithHeaderContentColumn
            header={
              <nav className={navButtonGroup}>
                {activeTab !== 'cancelled-sessions' && (
                  <>
                    <button
                      className={navButton({ active: activeTab === 'sessions' })}
                      onClick={() => setActiveTab('sessions')}
                    >
                      Sessions
                    </button>
                    <button
                      className={navButton({ active: activeTab === 'packages' })}
                      onClick={() => setActiveTab('packages')}
                    >
                      Packages
                    </button>
                    <button
                      className={navButton({ active: activeTab === 'notes' })}
                      onClick={() => setActiveTab('notes')}
                    >
                      Notes
                    </button>
                  </>
                )}
                {activeTab === 'cancelled-sessions' && (
                  <>
                    <button className={navButton({ active: false })} onClick={() => setActiveTab('sessions')}>
                      Back
                    </button>
                    <button className={navButton({ active: true })}>Cancelled sessions</button>
                  </>
                )}
              </nav>
            }
            extendedHeader={
              activeTab === 'notes' ? (
                <div>
                  <NoteAddModalButton pageId={pageId} clientId={client.id}>
                    {(onClick) => (
                      <Button variant="primary" size="sm" onClick={onClick}>
                        Add
                      </Button>
                    )}
                  </NoteAddModalButton>
                </div>
              ) : activeTab === 'sessions' ? (
                <div>
                  <InlineButton onClick={() => setActiveTab('cancelled-sessions')}>View cancelled</InlineButton>
                </div>
              ) : activeTab === 'cancelled-sessions' ? (
                <div>
                  <InlineButton onClick={() => setActiveTab('sessions')}>View scheduled</InlineButton>
                </div>
              ) : undefined
            }
          >
            {activeTab === 'sessions' && <ClientSessionsList pageId={pageId} clientId={client.id} />}
            {activeTab === 'cancelled-sessions' && (
              <ClientSessionsList pageId={pageId} clientId={client.id} cancelled />
            )}
            {activeTab === 'packages' && <ClientPackagesList pageId={pageId} clientId={client.id} />}
            {activeTab === 'notes' && <ClientNotesList pageId={pageId} clientId={client.id} />}
          </WithHeaderContentColumn>
        </div>
        <div />
      </ColumnContainer>
    </>
  );
};

export default DashboardClientsClient;
