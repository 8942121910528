import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { LinkProps } from 'react-router-dom';
import styled from 'styled-components';
import { Button, LinkUnstyled } from 'web/components/elements';
import themeClasses from 'web/styles/themeClasses.css';
import themeConstants from 'web/styles/themeConstants';
import themeVars from 'web/styles/themeVars.css';
import { formatCurrencyAmount } from 'web/utils/currency';

const InfoSide = styled.div``;

const ButtonSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button {
    min-width: 100%;
  }
`;

const Card = styled.div`
  display: flex;

  padding: 14px 20px;

  border-radius: ${themeConstants.borderRadius.sm};
  background-color: ${themeVars.backgrounds.sessionSlot};

  ${InfoSide} {
    flex-grow: 1;
  }

  ${ButtonSide} {
    margin-left: 20px;
    width: 100px;
    flex: none;
  }
`;

const Title = styled.div`
  ${themeConstants.typography.h4};
`;

const SeriesSlot = ({
  series,
  onClick,
  buttonOnClick,
  linkTo,
  buttonText,
  subButtonText,
  buttonPrimary,
  disabled,
}: {
  series: introwise.Series;
  onClick?: () => void;
  buttonOnClick?: () => void;
  linkTo?: LinkProps['to'];
  buttonText?: string;
  subButtonText?: string;
  buttonPrimary?: boolean;
  disabled?: boolean;
}) => {
  const renderInfo = () => (
    <>
      <Title>
        {series.hidden && (
          <FontAwesomeIcon
            icon={faEyeSlash}
            fixedWidth
            className={themeClasses({ marginRight: 2 })}
            style={{ fontSize: '0.75em', verticalAlign: 'baseline' }}
            title="Hidden series"
          />
        )}
        {series.title}
      </Title>
      <div>
        <small>
          <b>{formatCurrencyAmount(series.price, series.currency)}</b>
          {' for '}
          {series.duration} min{series.duration > 1 ? 's' : ''}
          {', '}
          {series.groupSizeMax} participant{series.groupSizeMax > 1 ? 's' : ''}
        </small>
      </div>
    </>
  );
  return (
    <Card>
      <InfoSide>
        {linkTo ? (
          <LinkUnstyled to={linkTo}>{renderInfo()}</LinkUnstyled>
        ) : onClick ? (
          <div style={{ cursor: 'pointer' }} role="button" onClick={onClick} onKeyPress={onClick} tabIndex={0}>
            {renderInfo()}
          </div>
        ) : (
          renderInfo()
        )}
      </InfoSide>
      {buttonOnClick && (
        <ButtonSide>
          <Button primary={buttonPrimary} secondary={!buttonPrimary} onClick={buttonOnClick} disabled={disabled}>
            {buttonText}
          </Button>
          {subButtonText && (
            <div>
              <small>{subButtonText}</small>
            </div>
          )}
        </ButtonSide>
      )}
    </Card>
  );
};

export default SeriesSlot;
