import { faBolt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useRef, useState } from 'react';
import themeClasses from 'web/styles/themeClasses.css';
import joinClassNames from 'web/utils/joinClassNames';
import settings from 'web/utils/settings';
import { textButton, textButtonStyled } from '../elements/inlineButton.css';
import FrillContext, { FrillProvider, FrillWidget } from '../FrillContext';
import WithNotificationContainer from './WithNotificationContainer';

const WhatsNewButtonImpl = () => {
  const { frill, loading, ssoToken } = useContext(FrillContext);
  const [ready, setReady] = useState(false);
  const [badgeCount, setBadgeCount] = useState(0);
  const widgetRef = useRef<FrillWidget>(null);

  useEffect(() => {
    if (!loading && frill) {
      widgetRef.current = frill.widget({
        key: settings.frill.widgetKey,
        ssoToken,
        callbacks: {
          onReady: () => setReady(true),
          onBadgeCount: ({ count }) => setBadgeCount(count),
        },
      });

      return () => {
        widgetRef.current?.destroy();
      };
    }
  }, [frill, loading, ssoToken]);

  return (
    <WithNotificationContainer notification={badgeCount > 0}>
      <div className="frill-container" style={{ display: 'none' }} />
      <button
        className={joinClassNames(textButton, textButtonStyled)}
        onClick={() => widgetRef.current?.open()}
        disabled={!ready}
      >
        <span className={themeClasses({ display: { all: 'none', md: 'initial' } })}>What&apos;s new</span>
        <span className={themeClasses({ display: { md: 'none' } })}>
          <FontAwesomeIcon icon={faBolt} />
        </span>
      </button>
    </WithNotificationContainer>
  );
};

const WhatsNewButton = () => (
  <FrillProvider>
    <WhatsNewButtonImpl />
  </FrillProvider>
);

export default WhatsNewButton;
