import React from 'react';
import { To } from 'react-router';
import joinClassNames from 'web/utils/joinClassNames';
import linkReset from 'web/components/elements/linkReset.css';
import upgradeButton from './upgradeButton.css';
import { Link } from 'react-router-dom';

const UpgradeButton = ({ to, children }: { to: To; children: React.ReactNode }) => (
  <Link className={joinClassNames(linkReset, upgradeButton)} to={to}>
    {children}
  </Link>
);

export default UpgradeButton;
