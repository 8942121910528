import { httpsCallable } from 'firebase/functions';
import React, { useContext, useEffect, useMemo } from 'react';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import { isClientSide } from 'web/utils/ssr';
import useFunctions from './FirebaseContext/useFunctions';
import UserContext from './UserContext';

type FrillConfig = {
  key: string;
  container?: HTMLElement;
  ssoToken?: string;
  callbacks: {
    onReady?(frillWidget: FrillWidget): void;
    onBadgeCount?({ count }: { count: number }): void;
  };
};

type FrillWidget = {
  open(): void;
  close(): void;
  destroy(): void;
};

type Frill = {
  widget(config: FrillConfig): FrillWidget;
};

declare global {
  interface Window {
    Frill?: Frill;
    Frill_Config?: FrillConfig[];
  }
}

const FrillContext = React.createContext({
  frill: null as Frill,
  ssoToken: null as string,
  loading: true,
  error: null as Error,
});

const FrillProvider = ({ children }: { children: React.ReactNode }) => {
  const [frill, setFrill] = React.useState(() => isClientSide && window.Frill);
  const [loading, setLoading] = React.useState(true);
  const [ssoToken, setSsoToken] = React.useState<string>(null);
  const { user, loading: userLoading } = useContext(UserContext);
  const functions = useFunctions();
  const [error, setError] = useErrorStateHandler();

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const res = await httpsCallable<never, { token: string }>(functions, 'frillGetToken')();
        const token = res.data?.token;
        if (!token) {
          throw new Error('Function returned no token');
        }
        setSsoToken(token);
      } catch (err) {
        setError(new Error(`Failed to generate Frill SSO token: ${err.message}`));
      }
      setLoading(false);
    };
    if (!userLoading && user) {
      void fetch();
    } else {
      setLoading(false);
      setSsoToken(null);
    }
  }, [functions, userLoading, user, setError]);

  useEffect(() => {
    if (!frill) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'frill-script-loader';
      script.src = `https://widget.frill.co/v2/widget.js`;
      script.onload = () => {
        setFrill(window.Frill);
      };
      document.body.appendChild(script);
    }
  }, [frill, ssoToken]);

  const value = useMemo(
    () => ({
      frill,
      ssoToken,
      loading,
      error,
    }),
    [frill, ssoToken, loading, error],
  );

  return <FrillContext.Provider value={value}>{children}</FrillContext.Provider>;
};

export { FrillProvider, FrillWidget };
export default FrillContext;
