import React, { useCallback, useState } from 'react';
import { Controller, RegisterOptions, useForm } from 'react-hook-form';
import ColorPickerInput from 'web/components//form-fields/ColorPickerInput';
import { Button, FormDescription, FormGroup, InlineButton, Label, LabelText } from 'web/components/elements';
import Spinner from 'web/components/Spinner';
import themeClasses from 'web/styles/themeClasses.css';
import BookingPagePreviewModal from './BookingPagePreviewModal';

type CommonValues = Pick<introwise.Page, 'branding'>;
type BrandingFormValues = CommonValues;

export type SubmitValues = CommonValues;

type FormObject<T> = { [K in keyof T]: T[K] };
type FormValues<T> = FormObject<T> | T;

const isFormObject = <T,>(val: FormObject<T> | T): val is FormObject<T> => val && Object.keys(val).length !== 0;

const sanitizeDefaultValues = <T,>(val: FormValues<T>, defaultVal: FormValues<T>): FormValues<T> =>
  isFormObject(defaultVal) && isFormObject(val)
    ? (Object.fromEntries(
        Object.keys(defaultVal).map((key) => [
          key,
          sanitizeDefaultValues(val[key as keyof FormValues<T>], defaultVal[key as keyof FormValues<T>]),
        ]),
      ) as FormObject<T>)
    : val !== undefined
    ? val
    : defaultVal;

const toFormValues = (page: introwise.Page) =>
  sanitizeDefaultValues<BrandingFormValues>(page, {
    branding: {
      accentColor: '',
      backgroundColor: '',
    },
  });

const toSubmitValues = ({ branding }: BrandingFormValues): SubmitValues => ({
  ...(branding?.accentColor || branding?.backgroundColor
    ? {
        branding: {
          accentColor: branding.accentColor || null,
          backgroundColor: branding.backgroundColor || null,
        },
      }
    : { branding: null }),
});

const colorValidationRules: RegisterOptions = {
  pattern: {
    value: /^#(?:[0-9a-fA-F]{3}){1,2}$/,
    message: 'Please enter the color in a #hex format',
  },
};

const BookingPageFormBranding = ({
  page,
  onSubmit,
  submitting,
}: {
  page: introwise.Page;
  onSubmit: (page: SubmitValues) => void;
  submitting: boolean;
  buttonRight?: boolean;
}) => {
  const defaultValues = toFormValues(page);

  const methods = useForm<BrandingFormValues>({
    defaultValues,
  });

  const [previewData, setPreviewData] = useState<introwise.Page>(null);
  const onPreviewOpen = () => setPreviewData({ ...page, ...methods.getValues() });
  const onPreviewClose = () => setPreviewData(null);

  const onSubmitInternal = useCallback(
    (values: BrandingFormValues) => {
      onSubmit(toSubmitValues(values));
    },
    [onSubmit],
  );

  const busy = submitting;

  return (
    <>
      <form onSubmit={methods.handleSubmit(onSubmitInternal)}>
        <h4 className={themeClasses({ marginY: 0 })}>Color scheme</h4>
        <FormGroup>
          <div style={{ display: 'grid', justifyContent: 'start', gap: 20, gridAutoFlow: 'column' }}>
            <Label>
              <LabelText>Background color</LabelText>
              <Controller
                control={methods.control}
                name={'branding.backgroundColor'}
                render={({ field, fieldState }) => (
                  <ColorPickerInput
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    hasError={fieldState.invalid}
                  />
                )}
                rules={colorValidationRules}
                shouldUnregister
              />
            </Label>
            <Label>
              <LabelText>Accent color</LabelText>
              <Controller
                control={methods.control}
                name={'branding.accentColor'}
                render={({ field, fieldState }) => (
                  <ColorPickerInput
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    hasError={fieldState.invalid}
                  />
                )}
                rules={colorValidationRules}
                shouldUnregister
              />
            </Label>
          </div>
          <FormDescription>
            Pick a color or enter its code in a #hex format. Leave empty to use the Introwise color scheme
          </FormDescription>
        </FormGroup>
        <FormGroup className={themeClasses({ display: 'flex', justifyContent: 'space-between' })}>
          <Button primary type="submit" disabled={busy}>
            {busy && <Spinner />}
            <span>Save</span>
          </Button>
          <InlineButton onClick={onPreviewOpen}>Preview</InlineButton>
        </FormGroup>
      </form>
      <BookingPagePreviewModal previewData={previewData} onClose={onPreviewClose} />
    </>
  );
};

export default BookingPageFormBranding;
