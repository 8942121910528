import React from 'react';
import Fake from 'web/components/elements/Fake';
import themeClasses from 'web/styles/themeClasses.css';

const FakeSession = () => <Fake height={14} animated />;

export const SessionLoading = ({ extended }: { extended?: boolean }) => (
  <div className={themeClasses({ display: 'grid', gap: 5 })}>
    <FakeSession />
    {extended && (
      <>
        <FakeSession />
        <FakeSession />
      </>
    )}
  </div>
);
