import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import ColumnContainer from 'web/components/ColumnContainer';
import ContentColumnHeader from 'web/components/ContentColumnHeader';
import { FormError } from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import ScreenTracker from 'web/components/ScreenTracker';
import useTracking from 'web/components/TrackingContext/useTracking';
import UserContext from 'web/components/UserContext';
import useErrorStateHandler from 'web/hooks/useErrorStateHandler';
import LocationForm from './LocationForm';

const SettingsCallsLocationsCreate = () => {
  const { user } = useContext(UserContext);
  const firestore = useFirestore();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useErrorStateHandler();
  const tracking = useTracking();

  const onSave = async (data: { name: string; location: string }) => {
    setSubmitting(true);
    try {
      const newLocation: introwise.FirestoreWriteData<introwise.Location> = {
        createdAt: serverTimestamp(),
        type: 'custom',
        name: data.name,
        location: data.location,
      };
      await addDoc(collection(firestore, 'users', user.uid, 'locations'), newLocation);
      tracking.trackEvent('Location Created');
      navigate('..', { replace: true });
    } catch (error) {
      setError(error);
      setSubmitting(false);
    }
  };

  return (
    <>
      <Helmet title="Add a new location" />
      <ScreenTracker screenName="SettingsCallsLocationsCreate" />
      <ColumnContainer>
        <div>
          <ContentColumnHeader header="Add a new location" />
          <LocationForm onSubmit={onSave} submitting={submitting} />
          {error && <FormError>Something went wrong. Please try again later</FormError>}
        </div>
      </ColumnContainer>
    </>
  );
};

export default SettingsCallsLocationsCreate;
