import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import BackLink from 'web/components/BackLink';
import TimeOffCalendar from 'web/components/calendar/TimeOffCalendar';
import TimeOffEditModal from 'web/components/calendar/TimeOffCalendar/TimeOffEditModal';
import ColumnContainer from 'web/components/ColumnContainer';
import { Button, FormDescription, InlineButton, LinkStyled } from 'web/components/elements';
import ListUnstyled from 'web/components/elements/ListUnstyled';
import ScreenTracker from 'web/components/ScreenTracker';
import Spinner from 'web/components/Spinner';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useQueryCache from 'web/hooks/useQueryCache';
import { useTimeOffPeriods, useTimeOffPeriodsMutations } from 'web/hooks/useTimeOffCalendar';
import themeClasses from 'web/styles/themeClasses.css';
import { formatSessionDateTimeString } from 'web/utils/dateFormat';

const TimeZoneDisplay = ({ timeZone }: { timeZone: string }) => {
  const [now, setNow] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => setNow(new Date()), 1000);
    return () => clearInterval(interval);
  }, []);
  return (
    <>
      <b>{timeZone.replace('_', ' ')}</b> ({now.toLocaleTimeString('en', { hour: 'numeric', minute: 'numeric' })})
    </>
  );
};

const SchedulingTimeOffCalendar = () => {
  return (
    <>
      {/* TODO: maybe remove top margin from the calendar */}
      <div style={{ marginBottom: -44 }} />
      <TimeOffCalendar />
      <FormDescription>
        Hint: Click or drag on the calendar to create a time off period. Click on an existing period to edit or remove
        it.
      </FormDescription>
      <FormDescription>
        Time periods blocked on this calendar will be unavailable for booking for all services.
      </FormDescription>
    </>
  );
};

const PeriodsList = ({
  periods,
  onClick,
}: {
  periods: introwise.AvailabilityPeriod[];
  onClick: (period: introwise.AvailabilityPeriod) => void;
}) => (
  <ListUnstyled>
    {periods.map((period) => (
      <li key={period.id}>
        <InlineButton onClick={() => onClick(period)}>
          {formatSessionDateTimeString(period.start, period.end)}
        </InlineButton>
      </li>
    ))}
  </ListUnstyled>
);

const SchedulingTimeOffList = () => {
  const dateRange = useMemo(
    () => ({ start: new Date(), end: new Date(Date.now() + 10 * 365 * 24 * 60 * 60 * 1000) }),
    [],
  );
  const res = useTimeOffPeriods(dateRange);
  const [periods, loading, error] = useQueryCache('timeOffPeriods', res, null);
  const { deletePeriod, savePeriod } = useTimeOffPeriodsMutations();
  const [editedPeriod, setEditedPeriod] = useState<introwise.AvailabilityPeriod>(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const periodsSorted = useMemo(
    () => (periods ? periods.sort((a, b) => a.start.getTime() - b.start.getTime()) : []),
    [periods],
  );

  const current = useMemo(() => periodsSorted.filter((period) => period.start <= new Date()), [periodsSorted]);
  const future = useMemo(() => periodsSorted.filter((period) => period.start > new Date()), [periodsSorted]);

  const onModalClose = () => {
    setShowEditModal(false);
    setEditedPeriod(null);
  };

  const onModalDelete = () => {
    deletePeriod(editedPeriod.id);
    onModalClose();
  };

  const onModalSave = (start: Date, end: Date) => {
    savePeriod(editedPeriod?.id, {
      start,
      end,
    });
    onModalClose();
  };

  const onPeriodClick = (period: introwise.AvailabilityPeriod) => {
    setShowEditModal(true);
    setEditedPeriod(period);
  };

  return (
    <>
      {loading && (
        <>
          <Spinner />
        </>
      )}
      {!loading && error && <p>Something went wrong. Cannot load your time off periods.</p>}
      {!loading && !error && periodsSorted.length === 0 && (
        <div className={themeClasses({ marginBottom: 4 })}>
          <p className={themeClasses({ marginTop: 0 })}>You don&apos;t have any upcoming time off periods scheduled.</p>
          <p className={themeClasses({ marginBottom: 0 })}>
            Add a time off period to prevent new bookings when you&apos;re on vacation, travelling, or just need a
            break. Already scheduled sessions will not be affected.
          </p>
        </div>
      )}
      {!loading && !error && current.length > 0 && (
        <div className={themeClasses({ marginBottom: 4 })}>
          <p className={themeClasses({ marginTop: 0 })}>You&apos; currently unavailable:</p>
          <PeriodsList periods={current} onClick={onPeriodClick} />
        </div>
      )}
      {!loading && !error && future.length > 0 && (
        <div>
          <p className={themeClasses({ marginTop: 0 })}>You will be unavailable on:</p>
          <PeriodsList periods={future} onClick={onPeriodClick} />
        </div>
      )}
      {!loading && !error && (
        <>
          <TimeOffEditModal
            period={editedPeriod}
            onClose={onModalClose}
            isOpen={showEditModal}
            create={editedPeriod === null}
            onSave={onModalSave}
            onDelete={onModalDelete}
          />
          <div className={themeClasses({ marginTop: 4 })}>
            <Button
              variant="primary"
              size="md"
              onClick={() => {
                setShowEditModal(true);
                setEditedPeriod(null);
              }}
            >
              Add time off
            </Button>
          </div>
        </>
      )}
    </>
  );
};

const SchedulingTimeOff = () => {
  const timeZone = useMemo(() => Intl.DateTimeFormat().resolvedOptions().timeZone, []);
  const [viewAs, setViewAs] = useState<'list' | 'calendar'>('list');

  return (
    <>
      <Helmet title={`Manage time off`} />
      <ScreenTracker screenName="SettingsAvailabilityTimeOff" />
      <BackLink to="/dashboard/scheduling" />
      <ColumnContainer>
        <div>
          <WithHeaderContentColumn
            header="Manage time off"
            whiteBackground
            extendedHeader={
              <InlineButton
                style={{ lineHeight: 'calc(20px*1.62)' }}
                onClick={() => setViewAs((prev) => (prev === 'calendar' ? 'list' : 'calendar'))}
              >
                View as {viewAs === 'calendar' ? 'list' : 'calendar'}
              </InlineButton>
            }
          >
            {viewAs === 'list' && <SchedulingTimeOffList />}
            {viewAs === 'calendar' && <SchedulingTimeOffCalendar />}
          </WithHeaderContentColumn>
        </div>

        <div>
          <WithHeaderContentColumn header="Your time zone">
            <p>Showing your times off in the current time zone:</p>
            <p>
              <TimeZoneDisplay timeZone={timeZone} />
            </p>
          </WithHeaderContentColumn>
          <WithHeaderContentColumn header="More settings">
            <ListUnstyled>
              <li>
                <LinkStyled to="rules">Scheduling rules</LinkStyled>
              </li>
              <li>
                <LinkStyled to="connected-calendars">Connected calendars</LinkStyled>
              </li>
            </ListUnstyled>
          </WithHeaderContentColumn>
        </div>
      </ColumnContainer>
    </>
  );
};

export default SchedulingTimeOff;
