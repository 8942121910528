import React from 'react';
import { formatPriceReadable } from 'web/App/BookingPage/common';
import Bullet from 'web/components/Bullet';
import { LinkUnstyled } from 'web/components/elements';
import themeClasses from 'web/styles/themeClasses.css';
import { formatSessionDate } from 'web/utils/dateFormat';

const PackageCard = ({ bookedPackage }: { bookedPackage: introwise.BookedPackage }) => {
  const { client, package: pack } = bookedPackage;

  const serviceIds = pack.personalSessions ? Object.keys(pack.personalSessions) : [];
  const seriesIds = pack.groupSessionSeries ? Object.keys(pack.groupSessionSeries) : [];

  const servicesCompleted =
    serviceIds.length === 0 ||
    serviceIds.reduce((acc, serviceId) => acc + bookedPackage.booked.personal[serviceId].available, 0) === 0;

  const seriesCompleted =
    seriesIds.length === 0 ||
    seriesIds.reduce((acc, seriesId) => acc + bookedPackage.booked.groupSeries[seriesId].available, 0) === 0;

  const completed = servicesCompleted && seriesCompleted;

  const includedCount =
    (pack.personalSessions
      ? Object.values(pack.personalSessions).reduce((acc, includedService) => acc + includedService.count, 0)
      : 0) +
    (pack.groupSessionSeries
      ? Object.values(pack.groupSessionSeries).reduce((acc, includedSeries) => acc + includedSeries.count, 0)
      : 0);

  const bookedCount = bookedPackage.booked.sessionIds.length;

  return (
    <LinkUnstyled to={`/dashboard/home/packages/${bookedPackage.id}`}>
      <div className={themeClasses({ display: 'flex' })}>
        <div className={themeClasses({ flex: 'auto' })}>
          <div>
            <b>{pack.title}</b>
            <Bullet />
            <span>
              {client.firstName} {client.lastName}{' '}
            </span>
          </div>
          <div className={themeClasses({ marginTop: 2 })}>
            {bookedCount}/{includedCount} booked
            {completed ? (
              <>
                <Bullet />
                Completed
              </>
            ) : (
              bookedPackage.expiresAt && (
                <>
                  <Bullet />
                  Expire{bookedPackage.expiresAt < new Date() ? 'd' : 's'} on{' '}
                  {formatSessionDate(bookedPackage.expiresAt)}
                </>
              )
            )}
          </div>
        </div>
        <div>
          <b>{formatPriceReadable(bookedPackage.paymentAmount, bookedPackage.currency)}</b>
        </div>
      </div>
    </LinkUnstyled>
  );
};

export default PackageCard;
