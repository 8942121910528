import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, FormError, FormGroup, Input, Label, LabelText } from './elements';
import Spinner from './Spinner';

const PasswordReauthForm = ({
  onSubmit,
  submitting,
}: {
  onSubmit: (values: { password: string }) => void;
  submitting: boolean;
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      password: undefined,
    },
  });
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset disabled={submitting}>
        <FormGroup>
          <Label>
            <Input
              {...register('password', {
                required: 'Please enter a password',
                minLength: {
                  value: 6,
                  message: 'Too short, a password is at least 6 characters',
                },
              })}
              type="password"
              autoComplete="current-password"
              hasError={!!errors.password}
            />
            <LabelText>Password</LabelText>
          </Label>
          {errors.password && <FormError>{errors.password.message}</FormError>}
        </FormGroup>
        <FormGroup>
          <Button type="submit">
            {submitting && <Spinner />}
            <span>Confirm</span>
          </Button>
        </FormGroup>
      </fieldset>
    </form>
  );
};

export default PasswordReauthForm;
