import { collection, documentId, limit, orderBy, query, startAt, Timestamp, where } from 'firebase/firestore';
import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import BackLink from 'web/components/BackLink';
import ColumnContainer from 'web/components/ColumnContainer';
import ContentColumn from 'web/components/ContentColumn';
import { Button } from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import ScreenTracker from 'web/components/ScreenTracker';
import BookingPageLinkBlock from 'web/components/session-page/BookingPageLinkBlock';
import { SessionsList } from 'web/components/session-page/SessionsList';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useFirestoreCollectionData from 'web/hooks/useFirestoreCollectionData';
import themeClasses from 'web/styles/themeClasses.css';
import { firestoreSessionConverter } from 'web/utils/convert';

const sessionsLimit = 10;

const CancelledSessionsList = () => {
  const { user } = useContext(UserContext);
  const firestore = useFirestore();
  const [paginationStack, setPaginationStack] = useState<(readonly [Timestamp, string])[]>([]);
  const paginationStart = paginationStack.length && paginationStack[paginationStack.length - 1];
  const [sessions, loading, error] = useFirestoreCollectionData(
    query(
      collection(firestore, 'sessions'),
      where('status', '==', 'cancelled'),
      where('expert.id', '==', user.uid),
      orderBy('end', 'desc'),
      orderBy(documentId(), 'desc'),
      limit(sessionsLimit + 1),
      ...(paginationStart ? [startAt(...paginationStart)] : []),
    ).withConverter(firestoreSessionConverter),
  );

  useErrorHandler(error);

  const hasNext = sessions?.length > sessionsLimit;
  const hasPrev = paginationStack.length > 0;

  const paginationNext = hasNext
    ? ([Timestamp.fromDate(sessions[sessions.length - 1].end), sessions[sessions.length - 1].id] as const)
    : undefined;

  const sortedSessions = sessions?.slice(0, sessionsLimit).sort((a, b) => b.end.getTime() - a.end.getTime());

  return (
    <>
      <SessionsList
        sessions={sortedSessions}
        loading={loading}
        past
        noSessionsElement={<ContentColumn whiteBackground>No cancelled sessions</ContentColumn>}
      />
      {(hasPrev || hasNext) && (
        <div className={themeClasses({ display: 'flex', justifyContent: 'space-between', marginTop: 5 })}>
          {hasPrev ? (
            <Button sm secondary onClick={() => setPaginationStack((stack) => stack.slice(0, stack.length - 1))}>
              Previous
            </Button>
          ) : (
            <div />
          )}
          {hasNext ? (
            <Button sm secondary onClick={() => setPaginationStack((stack) => [...stack, paginationNext])}>
              Next
            </Button>
          ) : (
            <div />
          )}
        </div>
      )}
    </>
  );
};

const DashboardSessionsCancelled = () => (
  <>
    <Helmet title="Cancelled sessions" />
    <ScreenTracker screenName="DashboardSessionsCancelled" />
    <BackLink to=".." />
    <ColumnContainer>
      <div>
        <WithHeaderContentColumn header="Cancelled sessions">
          <CancelledSessionsList />
        </WithHeaderContentColumn>
        <div className={themeClasses({ marginBottom: 14 })} />
      </div>
      <div>
        <BookingPageLinkBlock />
      </div>
    </ColumnContainer>
  </>
);

export default DashboardSessionsCancelled;
