import { collection, doc, writeBatch } from 'firebase/firestore';
import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import BookingPageContext from 'web/components/BookingPageContext';
import BookingPageFormBasic from 'web/components/booking-page-form/BookingPageFormBasic';
import BookingPageLink from 'web/components/BookingPageLink';
import ColumnContainer from 'web/components/ColumnContainer';
import { FormError, LinkStyled } from 'web/components/elements';
import ListUnstyled from 'web/components/elements/ListUnstyled';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import ScreenTracker from 'web/components/ScreenTracker';
import useTracking from 'web/components/TrackingContext/useTracking';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useErrorReporter from 'web/hooks/useErrorReporter';
import useFeatureExperimentalCheck from 'web/hooks/useFeatureExperimentalCheck';

const SettingsBookingPageEditImpl = () => {
  const { user } = useContext(UserContext);
  const firestore = useFirestore();
  const tracking = useTracking();
  const [error, setError] = useState<string>();
  const [pageData] = useContext(BookingPageContext);
  const [submitting, setSubmitting] = useState(false);
  const errorReporter = useErrorReporter();
  const seoSettingsEnabled = useFeatureExperimentalCheck('seoSettings');

  const edit = async (page: introwise.Page) => {
    setSubmitting(true);
    try {
      const batch = writeBatch(firestore);
      const pageRef = doc(collection(firestore, 'pages'), pageData.id);
      const userRef = doc(collection(firestore, 'users'), user.uid);
      batch.set(pageRef, page, { merge: true });
      batch.set(
        userRef,
        {
          bookingPageSlug: page.slug,
        },
        {
          merge: true,
        },
      );
      await batch.commit();
      tracking.trackEvent('Booking Page Updated');
    } catch (err) {
      setError("Can't save the booking page at the moment. Please try again later.");
      errorReporter.report(new Error(`Failed to update a booking page: ${err}`));
    }
    setSubmitting(false);
  };

  const page = pageData;

  return (
    <>
      <ColumnContainer>
        <div>
          <WithHeaderContentColumn header="Edit booking page" whiteBackground>
            <BookingPageFormBasic page={page} submitting={submitting} onSubmit={edit} />
            {error && <FormError>{`${error}`}</FormError>}
          </WithHeaderContentColumn>
        </div>
        <div>
          <WithHeaderContentColumn header="View as a client">
            <BookingPageLink />
            <div style={{ marginTop: 16 }}>
              <small>View your booking page exactly as your clients see it</small>
            </div>
          </WithHeaderContentColumn>
          {seoSettingsEnabled && (
            <WithHeaderContentColumn header="Advanced settings">
              <ListUnstyled>
                <li>
                  <LinkStyled to="seo">SEO settings</LinkStyled>
                </li>
              </ListUnstyled>
            </WithHeaderContentColumn>
          )}
        </div>
      </ColumnContainer>
    </>
  );
};

const SettingsBookingPageEdit = () => {
  const { loading: userLoading } = useContext(UserContext);

  // const [showSavedMark, setShowSavedMark] = useState(false);
  const [pageData, pageLoading, pageError] = useContext(BookingPageContext);
  useErrorHandler(pageError);

  const exists = !!pageData;
  const loading = pageLoading || userLoading;

  return (
    <>
      {!loading && !pageError && exists && <Helmet title="Edit booking page" />}
      {!loading && !pageError && !exists && <Helmet title="Create booking page" />}
      <Helmet title="Edit booking page" />
      <ScreenTracker screenName="SettingsBooking" />
      <SettingsBookingPageEditImpl />
    </>
  );
};

export default SettingsBookingPageEdit;
