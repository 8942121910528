import { collection, orderBy, query, where } from 'firebase/firestore';
import React, { useContext, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { CardsList, CardsListItem } from 'web/components/CardsList';
import ColumnContainer from 'web/components/ColumnContainer';
import ContentColumn from 'web/components/ContentColumn';
import { LinkStyled, LinkUnstyled } from 'web/components/elements';
import Fake from 'web/components/elements/Fake';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import Flare from 'web/components/Flare';
import ScreenTracker from 'web/components/ScreenTracker';
import UserContext from 'web/components/UserContext';
import WithHeaderContentColumn from 'web/components/WithHeaderContentColumn';
import useFirestoreCollectionData from 'web/hooks/useFirestoreCollectionData';
import themeClasses from 'web/styles/themeClasses.css';
import themeVars from 'web/styles/themeVars.css';
import { firestoreWorkflowConverter } from 'web/utils/convert';

const triggerTypeToString: {
  [key in introwise.WorkflowTriggerType]: string;
} = {
  'session.booking.created': 'session is booked',
  'session.created': 'session is scheduled',
  'session.started': 'session stars',
  'session.ended': 'session ends',
};

const triggerToString = (trigger: introwise.WorkflowAction['trigger']) => {
  const { type, offset, offsetUnit, offsetAmount } = trigger;
  const offsetStr =
    offsetAmount === 0
      ? 'immediately '
      : `${Math.abs(offsetAmount)} ${offsetUnit}${Math.abs(offsetAmount) === 1 ? '' : 's'} `;
  const direction = offset < 0 ? 'before' : 'after';
  const typeStr = triggerTypeToString[type] || 'unknown event';
  return `${offsetStr} ${direction} ${typeStr}`;
};

const WorkflowAction = ({ action }: { action: introwise.Workflow['actions'][string] }) => {
  return (
    <div>
      {action.type === 'reminder' && <>Sends a reminder {triggerToString(action.trigger)}</>}
      {action.type === 'email' && <>Sends an email {triggerToString(action.trigger)}</>}
      {action.type === 'automation' && <>Triggers a custom automation {triggerToString(action.trigger)}</>}
    </div>
  );
};

const workflowTriggersOrder = {
  'session.created': 0,
  'session.booking.created': 1,
  'session.started': 2,
  'session.ended': 3,
};

const WorkflowCard = ({ workflow }: { workflow: introwise.Workflow }) => {
  const { actions } = workflow;

  const workflowActionsSorted = useMemo(
    () =>
      Object.values(actions).sort(
        (a, b) =>
          workflowTriggersOrder[a.trigger.type] - workflowTriggersOrder[b.trigger.type] ||
          a.trigger.offset - b.trigger.offset,
      ) || [],
    [actions],
  );

  return (
    <LinkUnstyled to={workflow.id}>
      <div>
        <div className={themeClasses({ display: 'flex', justifyContent: 'space-between' })}>
          <h3 className={themeClasses({ margin: 0 })}>{workflow.name}</h3>
          <div className={themeClasses({ display: 'flex', gap: 2 })}>
            {workflow.default && (
              <div>
                <Flare variant="success">Default</Flare>
              </div>
            )}
            {workflow.active ? (
              <div>
                <Flare variant="success">Active</Flare>
              </div>
            ) : (
              <div>
                <Flare variant="error">Paused</Flare>
              </div>
            )}
          </div>
        </div>
        <div className={themeClasses({ marginY: 1 })}>
          {workflowActionsSorted.length !== 0 &&
            workflowActionsSorted.slice(0, 5).map((action) => <WorkflowAction key={action.id} action={action} />)}
          {workflowActionsSorted.length > 5 && (
            <div style={{ color: themeVars.color.muted }}>
              +{workflowActionsSorted.length - 5} more action{workflowActionsSorted.length === 6 ? '' : 's'}
            </div>
          )}
          {workflowActionsSorted.length === 0 && <div>This workflow doesn&apos;t have any actions yet</div>}
        </div>
      </div>
    </LinkUnstyled>
  );
};

const WorkflowsIndex = () => {
  const firestore = useFirestore();
  const { userData } = useContext(UserContext);
  const { bookingPageId: pageId } = userData;
  const [workflows, loading, error] = useFirestoreCollectionData(
    query(
      collection(firestore, 'pages', pageId, 'workflows'),
      where('deleted', '==', false),
      orderBy('createdAt', 'asc'),
    ).withConverter(firestoreWorkflowConverter),
  );

  return (
    <>
      <Helmet title="Workflows" />
      <ScreenTracker screenName="WorkflowsIndex" />
      <ColumnContainer>
        <WithHeaderContentColumn header="Workflows">
          {loading && <Fake height={16}>Loading...</Fake>}
          {error && <p>Error: {error.message}</p>}
          {!loading && !error && (
            <>
              {workflows.length !== 0 && (
                <CardsList>
                  {workflows.map((workflow) => (
                    <CardsListItem key={workflow.id}>
                      <WorkflowCard workflow={workflow} />
                    </CardsListItem>
                  ))}
                </CardsList>
              )}
              {workflows.length === 0 && <ContentColumn whiteBackground>No workflows</ContentColumn>}
              <ContentColumn whiteBackground>
                {workflows.length <= 20 ? (
                  <LinkStyled to="create">Create new workflow</LinkStyled>
                ) : (
                  <p>You have reached the limit of 20 workflows. Please delete some to create new ones.</p>
                )}
              </ContentColumn>
            </>
          )}
        </WithHeaderContentColumn>
        <div />
      </ColumnContainer>
    </>
  );
};

export default WorkflowsIndex;
