import React, { useContext } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import UserContext from 'web/components/UserContext';
import WithNotificationContainer from './WithNotificationContainer';
import themeConstants from 'web/styles/themeConstants';
import themeVars from 'web/styles/themeVars.css';

const StyledLink = styled(NavLink)`
  display: block;

  &.active > div {
    border: 3px solid ${themeVars.color.navLink};
  }
`;

const Border = styled.div`
  background-color: transparent;
  border-radius: 50%;
  border: 3px solid transparent;
`;

const RoundButton = styled.div`
  ${themeConstants.mixins.nonTextLink};

  border-radius: 50%;
  background-color: ${themeVars.color.halfMuted};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 14px;

  ${themeConstants.media.md} {
    padding: 16px;
  }

  ${themeConstants.media.lg} {
    padding: 18px;
  }
`;

const UserIcon = () => {
  const { userData } = useContext(UserContext);

  return (
    <WithNotificationContainer notification={false}>
      <StyledLink to="/dashboard/account" title="Account">
        <Border>
          <RoundButton style={{ backgroundImage: `url(${userData.photo})` }} />
        </Border>
      </StyledLink>
    </WithNotificationContainer>
  );
};

export default UserIcon;
