import { collection, query, where } from 'firebase/firestore';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { LinkButton } from 'web/components/elements';
import useFirestore from 'web/components/FirebaseContext/useFirestore';
import PackageList from 'web/components/packages/PackageList';
import { ServicesButtonsGroup } from 'web/components/Services/ServicesCommon';
import UserContext from 'web/components/UserContext';
import useErrorHandler from 'web/hooks/useErrorHandler';
import useFirestoreCollectionData from 'web/hooks/useFirestoreCollectionData';
import { firestorePackageConverter } from 'web/utils/convert';

const SettingsPackages = () => {
  const firestore = useFirestore();
  const navigate = useNavigate();
  const { userData } = useContext(UserContext);

  const onSelect = (pack: introwise.Package) => {
    navigate(`${pack.id}`, { state: { pack } });
  };

  const [packages, loadingPackages, errorPackages] = useFirestoreCollectionData(
    query(
      collection(firestore, 'pages', userData.bookingPageId, 'packages'),
      where('isDeleted', '==', false),
    ).withConverter(firestorePackageConverter),
  );
  useErrorHandler(errorPackages);

  if (errorPackages || loadingPackages) return <></>;

  const packagesSorted = packages
    ? packages.sort((a, b) => (!!a.hidden === !!b.hidden ? a.order - b.order : a.hidden ? 1 : -1))
    : [];

  const hasManyNonHiddenPackages = packagesSorted.filter((p) => !p.hidden).length > 1;

  return (
    <>
      <div style={{ maxWidth: 450 }}>
        <PackageList onClick={onSelect} packages={packagesSorted} />
      </div>
      <ServicesButtonsGroup>
        <LinkButton secondary md to="create">
          Add new package
        </LinkButton>
        {hasManyNonHiddenPackages && (
          <LinkButton md secondary to="reorder">
            Reorder
          </LinkButton>
        )}
      </ServicesButtonsGroup>
    </>
  );
};

export default SettingsPackages;
