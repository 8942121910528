import { nanoid } from 'nanoid';
import { useCallback } from 'react';

const popupWidth = 600;
const popupHeight = 600;

const launchPopup = (url: string) => {
  const left = window.screen.width / 2 - popupWidth / 2;
  const top = window.screen.height / 2 - popupHeight / 2;
  const win = window.open(
    url,
    'Authorization',
    'menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=' +
      popupWidth +
      ', height=' +
      popupHeight +
      ', top=' +
      top +
      ', left=' +
      left,
  );
  if (win) win.opener = window;
  return win;
};

class OAuthPopupError extends Error {
  constructor(public readonly message: string, public readonly code: string) {
    super(message);
  }
}

const useOAuthPopup = () => {
  const loginPopup = useCallback(
    async ({
      authorizeUrl,
      clientId,
      scopes,
      redirectUri,
      loginHint,
      prompt,
    }: {
      authorizeUrl: string;
      clientId: string;
      scopes: string[];
      redirectUri: string;
      loginHint?: string;
      prompt?: string;
    }) => {
      return new Promise<string>((resolve, reject) => {
        const state = nanoid();
        const authUrl = `${authorizeUrl}?response_type=code&client_id=${clientId}&scope=${encodeURIComponent(
          scopes.join(' '),
        )}&redirect_uri=${encodeURIComponent(redirectUri)}&state=${state}${
          loginHint ? `&login_hint=${loginHint}` : ''
        }${prompt ? `&prompt=${prompt}` : ''}`;
        const popup = launchPopup(authUrl);

        if (!popup) {
          return reject(new OAuthPopupError('Popup blocked', 'popup_blocked'));
        }

        let cleanup: () => void | undefined = undefined;
        const timer = setInterval(() => {
          if (popup.closed) {
            cleanup?.();
            return reject(new OAuthPopupError('Popup closed', 'user_cancelled'));
          }
        }, 1000);

        const timeout = setTimeout(() => {
          cleanup?.();
          if (popup.open) {
            popup.close();
          }
          return reject(new OAuthPopupError('Timeout', 'timeout'));
        }, 1000 * 60 * 10);

        const handleMessage = (event: MessageEvent) => {
          if (event.origin !== window.location.origin) {
            // Ignore invalid messages
            return;
          }
          const data = event.data as { callback?: { location?: string } };
          const location = data.callback?.location;
          if (!location) {
            // Ignore invalid messages
            return;
          } else {
            const url = new URL(location);
            const params = new URLSearchParams(url.searchParams);
            if (!params.get('state') || params.get('state') !== state) {
              reject(new OAuthPopupError('Invalid state', 'invalid_state'));
            } else if (params.has('error')) {
              const message = params.get('error_description') || 'Unknown error';
              const code = params.get('error');
              reject(new OAuthPopupError(message, code));
            } else if (!params.get('code')) {
              reject(new OAuthPopupError('Missing code', 'missing_code'));
            } else {
              resolve(params.get('code'));
            }
            cleanup?.();
            return;
          }
        };
        window.addEventListener('message', handleMessage);

        cleanup = () => {
          clearTimeout(timeout);
          clearInterval(timer);
          window.removeEventListener('message', handleMessage);
        };
      });
    },
    [],
  );
  return loginPopup;
};

export { OAuthPopupError };
export default useOAuthPopup;
