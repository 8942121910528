import React from 'react';
import styled from 'styled-components';
import { Input, InputPrefix } from 'web/components/elements';
import themeConstants from 'web/styles/themeConstants';
import selectArrow from '../elements/selectArrow.css';

const ColorSelect = styled.span`
  position: relative;
  display: inline-block;
  height: 26px;
  width: 46px;
  border: 3px solid #fff;
  background: #fff;
  border-radius: ${themeConstants.borderRadius.sm};
  &:hover {
    opacity: 0.8;
  }
`;

const ColorPickerInput = React.forwardRef<
  HTMLInputElement,
  { onChange: (value: string) => void; value: string; onBlur: () => void; hasError: boolean }
>(({ onChange, onBlur, value, hasError }, ref) => {
  return (
    <>
      <InputPrefix style={{ display: 'flex' }}>
        <ColorSelect>
          <span
            style={{ position: 'absolute', inset: '0 0 0 22px', backgroundPosition: 'center right 2px' }}
            className={selectArrow}
          />
          <span
            style={{
              position: 'absolute',
              inset: '0 20px 0 0',
              backgroundColor: 'currentColor',
              color: value,
              borderRadius: themeConstants.borderRadius.sm,
            }}
          />
          <input
            style={{
              position: 'absolute',
              opacity: '0',
              inset: '0',
              cursor: 'pointer',
            }}
            type="color"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            onBlur={onBlur}
          />
        </ColorSelect>
        <span style={{ marginLeft: 8 }}>#</span>
      </InputPrefix>
      <Input
        style={{ paddingLeft: 90 }}
        value={value?.startsWith('#') ? value.substring(1) : value}
        onChange={(e) => {
          let submitValue = e.target.value;
          if (submitValue && !submitValue.startsWith('#')) {
            submitValue = '#' + submitValue.substring(0, 6);
          } else {
            submitValue = submitValue.substring(0, 7);
          }
          onChange(submitValue);
        }}
        onBlur={onBlur}
        ref={ref}
        hasError={hasError}
      />
    </>
  );
});

ColorPickerInput.displayName = 'ColorPickerInput';

export default ColorPickerInput;
